import type { AnzuUserAndTimeTrackingAware, IntegerIdNullable } from '@anzusystems/common-admin'
import type { RouteKindAuthor } from '@/types/cms/RouteKind/RouteKindAuthor'
import type { RouteKindArticle } from '@/types/cms/RouteKind/RouteKindArticle'
import type { RouteKindForum } from '@/types/cms/RouteKind/RouteKindForum'
import type { RouteKindGallery } from '@/types/cms/RouteKind/RouteKindGallery'
import type { RouteKindPage } from '@/types/cms/RouteKind/RouteKindPage'
import type { RouteKindOther } from '@/types/cms/RouteKind/RouteKindOther'

export const RouteDiscriminator = {
  Article: 'article',
  Author: 'author',
  Forum: 'forum',
  Gallery: 'gallery',
  Other: 'other',
  Page: 'page',
  Person: 'person',
  Organization: 'organization',
  PersonPage: 'personPage',
  OrganizationPage: 'organizationPage',
} as const
export const RouteDiscriminatorDefault = RouteDiscriminator.Other
export type RouteDiscriminatorType = (typeof RouteDiscriminator)[keyof typeof RouteDiscriminator]

export type RouteDiscriminatorTypeMap = {
  [RouteDiscriminator.Article]: RouteKindArticle
  [RouteDiscriminator.Author]: RouteKindAuthor
  [RouteDiscriminator.Forum]: RouteKindForum
  [RouteDiscriminator.Gallery]: RouteKindGallery
  [RouteDiscriminator.Other]: RouteKindOther
  [RouteDiscriminator.Page]: RouteKindPage
  [RouteDiscriminator.Person]: RouteKindPage
  [RouteDiscriminator.Organization]: RouteKindPage
  [RouteDiscriminator.PersonPage]: RouteKindPage
  [RouteDiscriminator.OrganizationPage]: RouteKindPage
}

export const RouteStatus = {
  Disabled: 'disabled',
  Active: 'active',
  Reserved: 'reserved',
} as const
export const RouteStatusDefault = RouteStatus.Active
export type RouteStatusType = (typeof RouteStatus)[keyof typeof RouteStatus]

export interface RouteKind extends AnzuUserAndTimeTrackingAware {
  id: IntegerIdNullable
  site: IntegerIdNullable
  uri: RouteUri
  status: RouteStatusType
  discriminator: RouteDiscriminatorType
  _resourceName: string
  _system: 'cms'
}

interface RouteUri {
  full: string
  base: string
  baseCount: number
  main: boolean
}
