<script setup lang="ts">
import type { NewsletterSchedule } from '@/types/cms/NewsletterSchedule'
import { useDateTimeHelper } from '@/composables/cms/dateTimeHelper'
import { isNull } from '@anzusystems/common-admin'

const props = withDefaults(
  defineProps<{
    item: NewsletterSchedule
  }>(),
  {}
)
const emit = defineEmits<{
  (e: 'onEditClick', data: NewsletterSchedule): void
  (e: 'onDeleteClick', data: number): void
}>()

const { getDayTranslateByNumber } = useDateTimeHelper()

const onEdit = () => {
  emit('onEditClick', props.item)
}

const onDelete = () => {
  emit('onDeleteClick', props.item.id)
}
</script>

<template>
  <tr>
    <td v-if="!isNull(item.dayOfWeek)">
      {{ getDayTranslateByNumber(item.dayOfWeek) }}
    </td>
    <td>{{ item.dayOfMonth }}</td>
    <td>{{ item.hour }}</td>
    <td>{{ item.minute }}</td>
    <td>{{ item.enabled }}</td>
    <td>
      <VBtn
        icon
        size="x-small"
        variant="text"
        class="mx-1"
        @click.stop="onEdit()"
      >
        <VIcon
          size="small"
          icon="mdi-pencil"
        />
      </VBtn>
      <VBtn
        icon
        size="x-small"
        variant="text"
        class="mx-1"
        @click.stop="onDelete()"
      >
        <VIcon
          size="small"
          icon="mdi-trash-can-outline"
        />
      </VBtn>
    </td>
  </tr>
</template>
