import type { ArticleStatusType } from '@/model/cms/valueObject/ArticleStatus'
import type { ArticleDates } from '@/types/cms/ArticleKind/ArticleKind'
import type { DatetimeUTC, DocId, ImageAware, IntegerId, IntegerIdNullable } from '@anzusystems/common-admin'

export const RelatedItemDiscriminator = {
  Article: 'article',
  Link: 'link',
} as const
export const RelatedItemDiscriminatorDefault = RelatedItemDiscriminator.Article
export type RelatedItemDiscriminatorType = (typeof RelatedItemDiscriminator)[keyof typeof RelatedItemDiscriminator]

export type RelatedItemKind = RelatedItemKindArticle | RelatedItemKindLink

export interface RelatedItemKindArticle {
  id?: DocId
  overline: string
  headline: string
  perex: string
  articleDocId: DocId
  position: number
  discriminator: typeof RelatedItemDiscriminator.Article
}

export interface RelatedItemKindLink {
  id?: DocId
  overline: string
  headline: string
  perex: string
  url: string
  position: number
  external: boolean
  nofollow: boolean
  image: IntegerIdNullable
  publishedAt: DatetimeUTC
  discriminator: typeof RelatedItemDiscriminator.Link
}

export const isRelatedItemKindArticle = (item: RelatedItemKind): item is RelatedItemKindArticle => {
  return item.discriminator === RelatedItemDiscriminator.Article
}

export const isRelatedItemKindLink = (item: RelatedItemKind): item is RelatedItemKindLink => {
  return item.discriminator === RelatedItemDiscriminator.Link
}

export interface RelatedItemDetailKindLink {
  id: DocId
  position: number
  overline: string
  headline: string
  perex: string
  url: string
  external: boolean
  nofollow: boolean
  discriminator: typeof RelatedItemDiscriminator.Link
}

export interface RelatedItemDetailKindArticle {
  id: DocId
  position: number
  overline: string
  headline: string
  perex: string
  article: {
    id: IntegerId
    docId: DocId
    url: string
    status: ArticleStatusType
    dates: ArticleDates
    texts: {
      overline: string
      headline: string
      perex: string
    }
    image: ImageAware | IntegerIdNullable
  }
  discriminator: typeof RelatedItemDiscriminator.Article
}

export type RelatedItemDetailKind = RelatedItemDetailKindArticle | RelatedItemDetailKindLink

export const isRelatedItemDetailKindArticle = (item: RelatedItemDetailKind): item is RelatedItemDetailKindArticle => {
  return item.discriminator === RelatedItemDiscriminator.Article
}

export const isRelatedItemDetailKindLink = (item: RelatedItemDetailKind): item is RelatedItemDetailKindLink => {
  return item.discriminator === RelatedItemDiscriminator.Link
}