import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import {
  apiCreateOne,
  apiDeleteOne,
  apiFetchByIds,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
} from '@anzusystems/common-admin'
import type { AuthorKind } from '@/types/cms/AuthorKind/AuthorKind'
import { type AuthorKindPerson, isAuthorKindPerson } from '@/types/cms/AuthorKind/AuthorKindPerson'
import { AuthorResourceName } from '@/model/cms/valueObject/AuthorResourceName'
import { type AuthorKindSource, isAuthorKindSource } from '@/types/cms/AuthorKind/AuthorKindSource'
import { AuthorDiscriminator } from '@/model/cms/valueObject/AuthorDiscriminator'

const END_POINT = '/adm/v1/author-kind'
export const ENTITY = 'authorKind'

export const fetchAuthorListByIds = (ids: IntegerId[]) =>
  apiFetchByIds<AuthorKind[]>(cmsClient, ids, END_POINT + '/search', {}, SYSTEM_CMS, ENTITY, undefined, true)

export const fetchAuthorList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<AuthorKind[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchAuthor = (id: IntegerId) =>
  apiFetchOne<AuthorKind>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createAuthor = (data: AuthorKind) => {
  if (isAuthorKindPerson(data)) {
    return apiCreateOne<AuthorKindPerson>(
      cmsClient,
      data,
      END_POINT + '/:discriminator',
      { discriminator: AuthorDiscriminator.Person },
      SYSTEM_CMS,
      AuthorResourceName.Person
    )
  }
  if (isAuthorKindSource(data)) {
    return apiCreateOne<AuthorKindSource>(
      cmsClient,
      data,
      END_POINT + '/:discriminator',
      { discriminator: AuthorDiscriminator.Source },
      SYSTEM_CMS,
      AuthorResourceName.Source
    )
  }
  throw Error('Unknown type of Author: ' + data)
}

export const updateAuthor = (id: IntegerId, data: AuthorKind) => {
  if (isAuthorKindPerson(data)) {
    return apiUpdateOne<AuthorKindPerson>(
      cmsClient,
      data,
      END_POINT + '/:discriminator/:id',
      { discriminator: AuthorDiscriminator.Person, id },
      SYSTEM_CMS,
      AuthorResourceName.Person
    )
  }
  if (isAuthorKindSource(data)) {
    return apiUpdateOne<AuthorKindSource>(
      cmsClient,
      data,
      END_POINT + '/:discriminator/:id',
      { discriminator: AuthorDiscriminator.Source, id },
      SYSTEM_CMS,
      AuthorResourceName.Source
    )
  }
  throw Error('Unknown type of Author: ' + data)
}

export const deleteAuthor = (id: IntegerId) =>
  apiDeleteOne<AuthorKind>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)
