import type { ArticleIntention } from '@/types/cms/Task'
import { dateTimeNow } from '@anzusystems/common-admin'

export function useArticleIntentionFactory() {
  const createDefault = (): ArticleIntention => {
    return {
      id: 0,
      name: '',
      defaultIntention: false,
      createdBy: null,
      modifiedBy: null,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      _resourceName: 'articleIntention',
      _system: 'cms',
    }
  }

  return {
    createDefault,
  }
}
