import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsBoxRoutes: RouteRecordRaw[] = [
  {
    path: '/box',
    name: ROUTE.CMS.BOX.LIST,
    component: () => import('@/views/cms/box/BoxListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/box/create',
    name: ROUTE.CMS.BOX.CREATE,
    component: () => import('@/views/cms/box/BoxCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_BOX_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/box/:id(\\d+)/edit',
    name: ROUTE.CMS.BOX.EDIT,
    component: () => import('@/views/cms/box/BoxEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_BOX_READ, ACL.CMS_BOX_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/box/:id(\\d+)',
    name: ROUTE.CMS.BOX.DETAIL,
    component: () => import('@/views/cms/box/BoxDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_BOX_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
