import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import type { NewsletterSchedule } from '@/types/cms/NewsletterSchedule'

export const useNewsletterScheduleStore = defineStore('cmsNewsletterScheduleOneStore', () => {
  const newsletterScheduleList = ref<Map<number, NewsletterSchedule>>(new Map())

  function setNewsletterScheduleList(newsletterSchedules: NewsletterSchedule[]) {
    newsletterSchedules.forEach((newsletterSchedule) => {
      if (!newsletterScheduleList.value.has(newsletterSchedule.id)) {
        newsletterScheduleList.value.set(newsletterSchedule.id, newsletterSchedule)
      }
    })
  }

  function setNewsletterSchedule(newsletterSchedule: NewsletterSchedule) {
    newsletterScheduleList.value.set(newsletterSchedule.id, newsletterSchedule)
  }

  function removeNewsletterSchedule(id: number) {
    if (newsletterScheduleList.value.has(id)) {
      newsletterScheduleList.value.delete(id)
    }
  }

  function reset() {
    newsletterScheduleList.value.clear()
  }

  return {
    newsletterScheduleList,
    setNewsletterScheduleList,
    setNewsletterSchedule,
    removeNewsletterSchedule,
    reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNewsletterScheduleStore, import.meta.hot))
}
