import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { ContentItemKindTeleport } from '@/types/cms/ContentItemKind/ContentItemKindTeleport'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindTeleportFactory() {
  const create = (): ContentItemKindTeleport => {
    return {
      ...createAbstractContentItemKind(),
      identifier: '',
      discriminator: ContentItemDiscriminator.Teleport,
      _resourceName: 'contentItemKindTeleport',
    }
  }

  return {
    create,
  }
}
