import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsSuperAuthorRoutes: RouteRecordRaw[] = [
  {
    path: '/super-author',
    name: ROUTE.CMS.SUPER_AUTHOR.LIST,
    component: () => import('@/views/cms/superAuthor/SuperAuthorListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/super-author/create',
    name: ROUTE.CMS.SUPER_AUTHOR.CREATE,
    component: () => import('@/views/cms/superAuthor/SuperAuthorCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_SUPER_AUTHOR_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/super-author/:id(\\d+)/edit',
    name: ROUTE.CMS.SUPER_AUTHOR.EDIT,
    component: () => import('@/views/cms/superAuthor/SuperAuthorEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_SUPER_AUTHOR_READ, ACL.CMS_SUPER_AUTHOR_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/super-author/:id(\\d+)',
    name: ROUTE.CMS.SUPER_AUTHOR.DETAIL,
    component: () => import('@/views/cms/superAuthor/SuperAuthorDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_SUPER_AUTHOR_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
