<script lang="ts" setup>
import { AFormRemoteAutocomplete, type IntegerId } from '@anzusystems/common-admin'
import { useBoxSelectActions } from '@/views/cms/inbox/composables/inboxActions'
import { useInboxListFilter } from '@/model/cms/filter/InboxFilter'

withDefaults(
  defineProps<{
    label?: string | undefined
    required?: boolean | undefined
    multiple?: boolean
    returnObject?: boolean
  }>(),
  {
    label: undefined,
    required: undefined,
    multiple: false,
    returnObject: false,
  }
)
const modelValue = defineModel<string | IntegerId | string[] | IntegerId[] | null | object>({ required: true })

const { fetchItems, fetchItemsByIds } = useBoxSelectActions()
const innerFilter = useInboxListFilter()
</script>

<template>
  <AFormRemoteAutocomplete
    v-model="modelValue"
    :required="required"
    :label="label"
    :fetch-items="fetchItems"
    :fetch-items-by-ids="fetchItemsByIds"
    :inner-filter="innerFilter"
    :multiple="multiple"
    :return-object="returnObject"
  />
</template>
