<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import {
  type DeskDashboardDtoInterface,
} from '@/types/cms/Dashboard'
import { ROUTE } from '@/router/routes'

withDefaults(
  defineProps<{
    dashboard: DeskDashboardDtoInterface
  }>(),
  {
  }
)

const { t } = useI18n()

</script>

<template>
  <ABtnSecondary
    class="my-2 mr-2"
    size="x-large"
    :to="{ name: ROUTE.CMS.DESK.DASHBOARD, params: { id: dashboard.id } }"
  >
    {{ dashboard.name }}
    <template
      v-if="dashboard.follower"
      #append
    >
      <VChip>{{ t('cms.desk.dashboard.follower') }}</VChip>
    </template>
  </ABtnSecondary>
</template>
