<script lang="ts" setup>
import { computed } from 'vue'
import { type ArticleStatusType, useArticleStatus } from '@/model/cms/valueObject/ArticleStatus'

const props = withDefaults(
  defineProps<{
    status: ArticleStatusType
  }>(),
  {}
)

const { getArticleStatusOption } = useArticleStatus()
const statusOption = computed(() => {
  return getArticleStatusOption(props.status)
})
</script>

<template>
  <VChip
    v-if="statusOption"
    label
    size="small"
    :color="statusOption.color"
  >
    {{ statusOption.title }}
  </VChip>
</template>
