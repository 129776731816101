<script lang="ts" setup>
import { useNewsletterListFilter } from '@/model/cms/filter/NewsletterFilter'
import { useNewsletterSelectActions } from '@/views/cms/newsletter/composables/newsletterActions'
import { AFormRemoteAutocomplete } from '@anzusystems/common-admin'

withDefaults(
  defineProps<{
    label?: string | undefined
    required?: boolean | undefined
    multiple?: boolean
    v?: any
  }>(),
  {
    label: undefined,
    required: undefined,
    multiple: false,
    v: undefined,
  }
)

const modelValue = defineModel<string | number | string[] | number[] | null>({ required: true })

const { fetchItems, fetchItemsByIds } = useNewsletterSelectActions()

const innerFilter = useNewsletterListFilter()
</script>

<template>
  <AFormRemoteAutocomplete
    v-model="modelValue"
    :required="required"
    :label="label"
    :fetch-items="fetchItems"
    :fetch-items-by-ids="fetchItemsByIds"
    :inner-filter="innerFilter"
    filter-by-field="title"
    :multiple="multiple"
    :v="v"
  />
</template>
