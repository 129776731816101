import { useStageAllStore } from '@/stores/cms/stageStore'
import { storeToRefs } from 'pinia'
import { fetchStageAll } from '@/services/api/cms/stageApi'
import { type IntegerId, isNull, isUndefined, useAlerts } from '@anzusystems/common-admin'
import { computed, ref } from 'vue'
import type { Stage } from '@/types/cms/Stage'

const listLoading = ref(false)

export function useStages() {
  const stageAllStore = useStageAllStore()
  const { stages } = storeToRefs(stageAllStore)
  const { showErrorsDefault } = useAlerts()

  function getStage(id: IntegerId | null | undefined): Stage|undefined {
    if (isNull(id) || isUndefined(id)) return undefined
    return stages.value.get(id)
  }

  const stagesArray = computed<Stage[]>(() => {
    return Array.from(stages.value.values())
  })

  const loadStages = async () => {
    if (stageAllStore.loaded)
      return

    listLoading.value = true
    try {
      const res = await fetchStageAll()
      stageAllStore.setStages(res)
      stageAllStore.loaded = true
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      listLoading.value = false
    }
  }

  return {
    listLoading,
    stages,
    stagesArray,
    getStage,
    loadStages
  }
}
