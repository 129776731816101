import type { AuthorKind } from '@/types/cms/AuthorKind/AuthorKind'
import { AuthorResourceName } from '@/model/cms/valueObject/AuthorResourceName'
import { AuthorDiscriminator } from '@/model/cms/valueObject/AuthorDiscriminator'
import type { JSONContent } from '@tiptap/core'

export interface AuthorKindPerson extends AuthorKind {
  discriminator: typeof AuthorDiscriminator.Person
  description: JSONContent
  jobDescription: string
  person: {
    firstName: string
    lastName: string
    fullName: string
  }
  _resourceName: typeof AuthorResourceName.Person
}

export const isAuthorKindPerson = (author: AuthorKind): author is AuthorKindPerson => {
  return author.discriminator === AuthorDiscriminator.Person && Object.hasOwn(author, 'person')
}
