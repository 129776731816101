import { isUndefined, useAlerts, usePagination } from '@anzusystems/common-admin'
import type { NewsletterGeneratorSettings } from '@/types/cms/Newsletter'
import { reactive, ref } from 'vue'
import { useNewsletterGeneratorSettingsFactory } from '@/model/cms/factory/NewsletterGeneratorSettingsFactory'
import { useNewsletterOneStore } from '@/stores/cms/newsletterStore'
import { storeToRefs } from 'pinia'

const dialog = ref(false)
const { createDefaultNewsletterGeneratorSettings } = useNewsletterGeneratorSettingsFactory()
const currentGeneratorSettings = reactive<NewsletterGeneratorSettings>(
  createDefaultNewsletterGeneratorSettings()
)
const generatorSettingsLoading = ref(false)
const saveButtonLoading = ref(false)
const { showErrorsDefault, showRecordWas } = useAlerts()
const pagination = usePagination()

export function useGeneratorSettingsDialogActions() {

  pagination.rowsPerPage = 100

  const closeDialog = () => {
    currentGeneratorSettings.templateId = 0
    currentGeneratorSettings.dataSourceUrl = ''
    currentGeneratorSettings.useEmailSubjectFromSource = null
    currentGeneratorSettings.dataSourceUrlDynamicParam = null
    currentGeneratorSettings.articleMinLimit = null
    dialog.value = false
  }

  const createOrUpdateGeneratorSettings = async () => {
    const newsletterOneStore = useNewsletterOneStore()
    const { newsletter } = storeToRefs(newsletterOneStore)
    if (0 === newsletter.value.id) return
    try {
      saveButtonLoading.value = true
      let isUpdate = false
      newsletter.value.settings.generators.forEach((generatorSettings: NewsletterGeneratorSettings) => {
        if (generatorSettings.dataSourceUrl === currentGeneratorSettings.dataSourceUrl) {
          isUpdate = true
          generatorSettings.templateId = currentGeneratorSettings.templateId
          generatorSettings.dataSourceUrl = currentGeneratorSettings.dataSourceUrl
          generatorSettings.useEmailSubjectFromSource = currentGeneratorSettings.useEmailSubjectFromSource
          generatorSettings.dataSourceUrlDynamicParam = currentGeneratorSettings.dataSourceUrlDynamicParam
          generatorSettings.articleMinLimit = currentGeneratorSettings.articleMinLimit
          return
        }
      })
      if (isUpdate) {
        showRecordWas('updated')
      } else {
        newsletter.value.settings.generators.push(currentGeneratorSettings)
        showRecordWas('created')
      }
      dialog.value = false
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  return {
    saveButtonLoading,
    dialog,
    currentGeneratorSettings,
    createOrUpdateGeneratorSettings,
    closeDialog,
  }
}

export function useGeneratorSettingsListActions() {
  const newsletterStore = useNewsletterOneStore()

  const openDialog = (
    generatorSettings: NewsletterGeneratorSettings | undefined,
  ) => {
    dialog.value = true
    if (isUndefined(generatorSettings)) {
      currentGeneratorSettings.templateId = 0
      currentGeneratorSettings.dataSourceUrl = ''
      currentGeneratorSettings.useEmailSubjectFromSource = null
      currentGeneratorSettings.dataSourceUrlDynamicParam = null
      currentGeneratorSettings.articleMinLimit = null
      return
    }
    currentGeneratorSettings.templateId = generatorSettings.templateId
    currentGeneratorSettings.dataSourceUrl = generatorSettings.dataSourceUrl
    currentGeneratorSettings.useEmailSubjectFromSource = generatorSettings.useEmailSubjectFromSource
    currentGeneratorSettings.dataSourceUrlDynamicParam = generatorSettings.dataSourceUrlDynamicParam
    currentGeneratorSettings.articleMinLimit = generatorSettings.articleMinLimit
  }

  const deleteGeneratorSettingsByTemplateId = async (dataSourceUrl: string) => {
    try {
      newsletterStore.newsletter.settings.generators.forEach(
        (generatorSettings: NewsletterGeneratorSettings, index: number) => {
          if (generatorSettings.dataSourceUrl === dataSourceUrl) {
            newsletterStore.newsletter.settings.generators.splice(index, 1)
            return
        }
      })
      showRecordWas('deleted')
    } catch (error) {
      showErrorsDefault(error)
      return false
    }
    return true
  }

  return {
    openDialog,
    deleteGeneratorSettingsByTemplateId,
    generatorSettingsLoading,
  }
}
