<script lang="ts" setup>
import {
  type IntegerId,
} from '@anzusystems/common-admin'
import { useArticleStageListActions } from '@/views/cms/articleStage/composables/articleStageActions'
import { computed } from 'vue'
import { useArticleStageListStore } from '@/stores/cms/articleStageStore'
import ArticleStatusChip from '@/views/cms/article/components/ArticleStatusChip.vue'

const props = withDefaults(
  defineProps<{
    id: null | IntegerId | undefined
  }>(),
  {}
)

const articleStageListStore = useArticleStageListStore()
const { articleRelLoading } = useArticleStageListActions()

const article = computed(() => {
  return articleStageListStore.getArticleByArticleStage(props.id ?? 0)
})

</script>

<template>
  <VProgressCircular
    v-if="articleRelLoading"
    :size="12"
    :width="2"
    indeterminate
    class="mx-1"
  />

  <ArticleStatusChip
    v-else-if="article"
    :status="article.status"
  />
</template>
