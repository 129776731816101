<script lang="ts" setup>
import { useSiteSelectActions } from '@/views/cms/site/composables/siteActions'
import type { Filter } from '@anzusystems/common-admin'
import { AFilterRemoteAutocomplete } from '@anzusystems/common-admin'
import { useSiteFilter } from '@/model/cms/filter/SiteFilter'

const modelValue = defineModel<Filter>({ required: true })

const { fetchItems, fetchItemsByIds } = useSiteSelectActions()

const innerFilter = useSiteFilter()
</script>

<template>
  <AFilterRemoteAutocomplete
    v-model="modelValue"
    :fetch-items="fetchItems"
    :fetch-items-by-ids="fetchItemsByIds"
    :inner-filter="innerFilter"
  />
</template>
