import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsPageRoutes: RouteRecordRaw[] = [
  {
    path: '/page',
    name: ROUTE.CMS.PAGE.LIST,
    component: () => import('@/views/cms/page/PageListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/page/:id(\\d+)/edit',
    name: ROUTE.CMS.PAGE.EDIT,
    component: () => import('@/views/cms/page/PageEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PAGE_READ, ACL.CMS_PAGE_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/page/:id(\\d+)',
    name: ROUTE.CMS.PAGE.DETAIL,
    component: () => import('@/views/cms/page/PageDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PAGE_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
