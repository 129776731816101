import type { User, UserMinimal } from '@/types/cms/User'
import { fetchUserListByIds } from '@/services/api/cms/userApi'
import type { IntegerId } from '@anzusystems/common-admin'
import { defineCached } from '@anzusystems/common-admin'

export const mapFullToMinimal = (source: User): UserMinimal => {
  return { id: source.id ?? 0, email: source.email, avatar: source.avatar, person: source.person }
}

const mapIdToMinimal = (id: IntegerId): UserMinimal => {
  return { id: id, email: '', person: { firstName: '', lastName: '', fullName: '' }, avatar: { color: '', text: '' } }
}

const { cache, fetch, add, addManual, has, get, isLoaded, addManualMinimal } = defineCached<
  IntegerId,
  User,
  UserMinimal
>(mapFullToMinimal, mapIdToMinimal, fetchUserListByIds)

export const useCachedUsers = () => {
  return {
    addManualToCachedUsers: addManual,
    addManualMinimalToCachedUsers: addManualMinimal,
    addToCachedUsers: add,
    fetchCachedUsers: fetch,
    cachedUsers: cache,
    hasCachedUser: has,
    getCachedUser: get,
    isLoadedCachedUser: isLoaded,
  }
}
