import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'

export const cmsWizardRoutes: RouteRecordRaw[] = [
  {
    path: '/wizard',
    name: ROUTE.CMS.WIZARD.LIST,
    component: () => import('@/views/cms/wizard/WizardListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
]
