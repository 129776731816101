import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ValueObjectOption } from '@anzusystems/common-admin'

export const ArticleStageStatus = {
  New: 'new',
  Done: 'done',
} as const

export type ArticleStageStatusType = (typeof ArticleStageStatus)[keyof typeof ArticleStageStatus]
export const ArticleStageStatusDefault = ArticleStageStatus.New

export function useArticleStageStatus() {
  const { t } = useI18n()

  const articleStageStatusOptions = ref<ValueObjectOption<ArticleStageStatusType>[]>([
    {
      value: ArticleStageStatus.New,
      title: t('cms.articleStage.status.new'),
      color: 'warning',
    },
    {
      value: ArticleStageStatus.Done,
      title: t('cms.articleStage.status.done'),
      color: 'success',
    },
  ])

  const getArticleStageStatusOption = (value: ArticleStageStatusType) => {
    return articleStageStatusOptions.value.find((item) => item.value === value)
  }

  return {
    articleStageStatusOptions,
    getArticleStageStatusOption,
  }
}
