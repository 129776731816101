<script setup lang="ts">
import { isArticleKindMinute } from '@/types/cms/ArticleKind/ArticleKindMinute'
import { isArticleKindStandard } from '@/types/cms/ArticleKind/ArticleKindStandard'
import type { PromoLinkItem } from '@/types/cms/PromoLink'
import { useArticleKindEditActions } from '@/views/cms/article/composables/articleKindActions'
import CachedPromoLinkChip from '@/views/cms/promoLink/components/CachedPromoLinkChip.vue'
import { ACard, isNull, type IntegerId } from '@anzusystems/common-admin'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { article, updatePromoLinkItemsLoading } = useArticleKindEditActions()
const promoLinkDisable = (promoLinkItem: PromoLinkItem) => {
  if (isNull(promoLinkItem.promoLink)) return
  article.value.disabledPromoLinks.push(promoLinkItem.promoLink)
}
const promoLinkEnable = (promoLinkId: IntegerId) => {
  article.value.disabledPromoLinks.splice(article.value.disabledPromoLinks.indexOf(promoLinkId), 1)
}
const enabledAllFlag = computed({
  get() {
    if (isArticleKindStandard(article.value)) {
      return article.value.flagsStandard.enablePromoLinks
    }
    if (isArticleKindMinute(article.value)) {
      return article.value.flagsMinute.enablePromoLinks
    }

    return false
  },
  set(newValue: boolean) {
    if (isArticleKindStandard(article.value)) {
      article.value.flagsStandard.enablePromoLinks = newValue
    }
    if (isArticleKindMinute(article.value)) {
      return (article.value.flagsMinute.enablePromoLinks = newValue)
    }
  },
})
const { t } = useI18n()
</script>

<template>
  <ACard :loading="updatePromoLinkItemsLoading">
    <VRow>
      <VCol
        cols="auto"
        class="ml-2"
      >
        <VSwitch
          v-model="enabledAllFlag"
          hide-details
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="auto">
        <h4 class="font-weight-bold text-subtitle-2">
          {{ t('cms.articleKind.model.promoLinkItems') }}
        </h4>
      </VCol>
    </VRow>
    <VList>
      <VListItem
        v-for="promoLinkItem in article.promoLinkItems"
        :key="promoLinkItem.id"
        :disabled="
          false === enabledAllFlag ||
            (!isNull(promoLinkItem.promoLink) && article.disabledPromoLinks.includes(promoLinkItem.promoLink))
        "
      >
        <VListItemTitle>
          <CachedPromoLinkChip :promo-link-item="promoLinkItem" />
        </VListItemTitle>
        <template #append>
          <VBtn
            icon="mdi-link-off"
            variant="text"
            @click="promoLinkDisable(promoLinkItem)"
          />
        </template>
      </VListItem>
    </VList>
  </ACard>
  <ACard>
    <h4 class="font-weight-bold text-subtitle-2">
      {{ t('cms.articleKind.model.disabledPromoLinks') }}
    </h4>
    <VList>
      <VListItem
        v-for="promoLinkId in article.disabledPromoLinks"
        :key="promoLinkId"
      >
        <VListItemTitle>
          <CachedPromoLinkChip :promo-link-id="promoLinkId" />
        </VListItemTitle>
        <template #append>
          <VBtn
            icon="mdi-link"
            variant="text"
            @click="promoLinkEnable(promoLinkId)"
          />
        </template>
      </VListItem>
    </VList>
  </ACard>
</template>
