import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import {
  AnzuApiResponseCodeError,
  apiCreateOne,
  apiDeleteOne,
  apiFetchByIds,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
  makeFilterHelper,
  usePagination,
} from '@anzusystems/common-admin'
import type { SuperAuthor } from '@/types/cms/SuperAuthor'
import { reactive } from 'vue'

const END_POINT = '/adm/v1/super-author'
export const ENTITY = 'superAuthor'

export const fetchSuperAuthorListByIds = (ids: IntegerId[]) =>
  apiFetchByIds<SuperAuthor[]>(cmsClient, ids, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const fetchSuperAuthorList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<SuperAuthor[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchSuperAuthor = (id: IntegerId) =>
  apiFetchOne<SuperAuthor>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const fetchSuperAuthorByTitle = async (title: string) => {
  const makeFilter = makeFilterHelper(SYSTEM_CMS, ENTITY)
  const pagination = usePagination()
  const filterBag = reactive({
    title: {
      ...makeFilter({ name: 'title', variant: 'eq' }),
    },
  })
  filterBag.title.model = title.trim()
  const res = await apiFetchList<SuperAuthor[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)
  if (res[0]) return res[0]
  throw new AnzuApiResponseCodeError(404)
}

export const createSuperAuthor = (data: SuperAuthor) =>
  apiCreateOne<SuperAuthor>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updateSuperAuthor = (id: IntegerId, data: SuperAuthor) =>
  apiUpdateOne<SuperAuthor>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const deleteSuperAuthor = (id: IntegerId) =>
  apiDeleteOne<SuperAuthor>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)
