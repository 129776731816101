import { ROUTE } from '@/router/routes'

export const commonLogRoutes = [
  {
    path: '/log',
    name: ROUTE.COMMON.LOG.LIST,
    component: () => import('@/views/common/log/LogListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/log/:system/:type/:id',
    name: ROUTE.COMMON.LOG.DETAIL,
    component: () => import('@/views/common/log/LogDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
]
