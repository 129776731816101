import { useArticleFilter } from '@/model/cms/filter/ArticleFilter'
import { ArticleDiscriminator } from '@/model/cms/valueObject/ArticleDiscriminator'
import { ArticleResourceName } from '@/model/cms/valueObject/ArticleResourceName'
import type { ArticleStatusType } from '@/model/cms/valueObject/ArticleStatus'
import { SYSTEM_CMS } from '@/model/systems'
import { cmsClient } from '@/services/api/clients/cmsClient'
import {
  type ArticleKindStandardListResponse,
  filterUrlFilterBag,
  mapVersionDataStandard,
} from '@/services/api/cms/articleKindApiHelpers'
import type { ArticleKind } from '@/types/cms/ArticleKind/ArticleKind'
import { type ArticleKindMinute, isArticleKindMinute } from '@/types/cms/ArticleKind/ArticleKindMinute'
import { type ArticleKindStandard, type ArticleRelatedEntities, isArticleKindStandard } from '@/types/cms/ArticleKind/ArticleKindStandard'
import type { ArticleMinuteCreateDto, ArticleStandardCreateDto } from '@/types/cms/ArticleKind/ArticleMinuteCreateDto'
import type { DocId, FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import {
  apiAnyRequest,
  apiCreateOne,
  apiDeleteOne,
  apiFetchList,
  apiFetchOne,
  apiGenerateListQuery,
  apiUpdateOne,
  makeFilterHelper,
  usePagination,
} from '@anzusystems/common-admin'
import { reactive } from 'vue'

const END_POINT = '/adm/v1/article-kind'
const END_POINT_STANDARD = END_POINT + '/standard'
const END_POINT_MINUTE = END_POINT + '/minute'
export const ENTITY = 'articleKind'
export const ENTITY_STANDARD = 'articleKindStandard'
export const ENTITY_MINUTE = 'articleKindMinute'

export const fetchArticleKindListByDocIds = (ids: DocId[]) => {
  const pagination = usePagination()
  pagination.rowsPerPage = ids.length * 2
  const makeFilter = makeFilterHelper(SYSTEM_CMS, ENTITY)
  const filterBag = reactive({
    _elastic: {
      ...makeFilter({ exclude: true }),
    },
    docIds: {
      ...makeFilter({ name: 'docIds' }),
    },
  })
  filterBag.docIds.model = ids

  return apiFetchList<ArticleKind[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)
}

export const fetchArticleKindList = (pagination: Pagination, filterBag: FilterBag) => {
  filterUrlFilterBag(filterBag)
  return apiFetchList<ArticleKind[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)
}

export const fetchArticleKindStandardListVersionData = async (pagination: Pagination, filterBag: FilterBag) => {
  filterUrlFilterBag(filterBag)
  filterBag.discriminator.model = 'standard'
  const res = await apiAnyRequest<ArticleKindStandardListResponse>(
    cmsClient,
    'GET',
    END_POINT + '/search' + apiGenerateListQuery(pagination, filterBag),
    {},
    undefined,
    SYSTEM_CMS,
    ENTITY
  )
  pagination.hasNextPage = res.hasNextPage
  pagination.currentViewCount = res.data.length

  return mapVersionDataStandard(res.data, res.versionsData)
}

export const fetchArticleKindStandardList = (pagination: Pagination, filterBag: FilterBag) => {
  return apiFetchList<ArticleKindStandard[]>(
    cmsClient,
    END_POINT,
    {},
    pagination,
    filterBag,
    SYSTEM_CMS,
    ENTITY_STANDARD
  )
}

export const fetchArticleKindMinuteList = (pagination: Pagination, filterBag: FilterBag) => {
  filterBag.discriminator.model = 'minute'

  return apiFetchList<ArticleKindMinute[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY_MINUTE)
}

export const fetchArticleKind = (id: IntegerId) =>
  apiFetchOne<ArticleKind>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const fetchArticleKindByDocIdVersion = (docId: DocId, version: number) =>
  apiFetchOne<ArticleKind>(cmsClient, END_POINT + '/:docId/:version', { docId, version }, SYSTEM_CMS, ENTITY)

export const fetchArticleRelatedEntities = (id: IntegerId) =>
  apiFetchOne<ArticleRelatedEntities>(
    cmsClient,
    END_POINT_STANDARD + '/:id' + '/related-entities',
    { id },
    SYSTEM_CMS,
    ENTITY_STANDARD
  )

export const createArticleKind = (data: ArticleMinuteCreateDto) => {
  if (data.discriminator === ArticleDiscriminator.Standard) {
    return apiCreateOne<ArticleMinuteCreateDto, ArticleKindStandard>(
      cmsClient,
      data,
      END_POINT + '/:discriminator',
      { discriminator: ArticleDiscriminator.Standard },
      SYSTEM_CMS,
      ArticleResourceName.Standard
    )
  }
  if (data.discriminator === ArticleDiscriminator.Minute) {
    return apiCreateOne<ArticleMinuteCreateDto, ArticleKindMinute>(
      cmsClient,
      data,
      END_POINT + '/:discriminator',
      { discriminator: ArticleDiscriminator.Minute },
      SYSTEM_CMS,
      ArticleResourceName.Minute
    )
  }
  throw Error('Unknown type of Article: ' + data)
}

export const createArticleKindStandard = (data: ArticleStandardCreateDto) => {
  return apiCreateOne<ArticleStandardCreateDto, ArticleKindStandard>(
    cmsClient,
    data,
    END_POINT_STANDARD,
    {},
    SYSTEM_CMS,
    ArticleResourceName.Standard
  )
}

export const createArticleKindMinute = (data: ArticleMinuteCreateDto) => {
  return apiCreateOne<ArticleMinuteCreateDto, ArticleKindMinute>(
    cmsClient,
    data,
    END_POINT_MINUTE,
    {},
    SYSTEM_CMS,
    ArticleResourceName.Standard
  )
}

export const updateArticleKind = (id: IntegerId, data: ArticleKind) => {
  if (isArticleKindStandard(data)) {
    return apiUpdateOne<ArticleKindStandard>(
      cmsClient,
      data,
      END_POINT + '/:discriminator/:id',
      { discriminator: ArticleDiscriminator.Standard, id },
      SYSTEM_CMS,
      ArticleResourceName.Standard
    )
  }
  if (isArticleKindMinute(data)) {
    return apiUpdateOne<ArticleKindMinute>(
      cmsClient,
      data,
      END_POINT + '/:discriminator/:id',
      { discriminator: ArticleDiscriminator.Minute, id },
      SYSTEM_CMS,
      ArticleResourceName.Minute
    )
  }
  throw Error('Unknown type of Article: ' + data)
}

export const apiUpdatePromoLinkItems = (id: IntegerId) => {
  return apiAnyRequest<ArticleKind>(
    cmsClient,
    'PATCH',
    END_POINT + '/update-promo-link-items/:id',
    { id },
    null,
    SYSTEM_CMS,
    'articleKind'
  )
}

export const deleteArticleKind = (id: IntegerId) =>
  apiDeleteOne<ArticleKind>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const changeArticleKindStatus = (id: IntegerId, newStatus: ArticleStatusType) =>
  apiAnyRequest<ArticleKind>(
    cmsClient,
    'PATCH',
    END_POINT + '/:id/status/:newStatus',
    { id, newStatus },
    null,
    SYSTEM_CMS,
    ENTITY
  )

export const createArticleKindDraftFromPublished = (id: IntegerId) =>
  apiAnyRequest<ArticleKind>(cmsClient, 'PATCH', END_POINT + '/draft/:id', { id }, null, SYSTEM_CMS, ENTITY)

export const fetchArticleKindListByDocId = (docId: DocId) => {
  const pagination = usePagination()
  const filterBag = useArticleFilter()
  filterBag.docId.model = docId

  return apiFetchList<ArticleKind[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)
}
