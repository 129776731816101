<script lang="ts" setup>
import { AActionCreateButton, ACard } from '@anzusystems/common-admin'
import { ROUTE } from '@/router/routes'
import NewsletterPromoDatatable from '@/views/cms/newsletterPromo/components/NewsletterPromoDatatable.vue'
import ActionbarWrapper from '@/components/wrappers/ActionbarWrapper.vue'
import { useNewsletterPromoListActions } from '@/views/cms/newsletterPromo/composables/newsletterPromoActions'

const { listLoading } = useNewsletterPromoListActions()
</script>

<template>
  <ActionbarWrapper>
    <template #buttons>
      <AActionCreateButton :route-name="ROUTE.CMS.NEWSLETTER_PROMO.CREATE" />
    </template>
  </ActionbarWrapper>

  <ACard :loading="listLoading">
    <VCardText>
      <NewsletterPromoDatatable />
    </VCardText>
  </ACard>
</template>
