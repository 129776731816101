import type { IntegerId } from '@anzusystems/common-admin'
import { defineCached } from '@anzusystems/common-admin'
import type { AdvertSettings, AdvertSettingsMinimal } from '@/types/cms/AdvertSettings'
import { fetchAdvertSettingsListByIds } from '@/services/api/cms/advertSettingsApi'

const mapFullToMinimal = (advertSettings: AdvertSettings): AdvertSettingsMinimal => ({
  id: advertSettings.id,
  title: advertSettings.texts.title,
})

const mapIdToMinimal = (id: IntegerId): AdvertSettingsMinimal => {
  return { id: id, title: '' }
}

const { cache, toFetch, fetch, add, addManual, addManualMinimal, has, get, isLoaded } = defineCached<
  IntegerId,
  AdvertSettings,
  AdvertSettingsMinimal
>(mapFullToMinimal, mapIdToMinimal, fetchAdvertSettingsListByIds)

export const useCachedAdvertSettings = () => {
  return {
    addManualToCachedAdvertSettings: addManual,
    addManualMinimalToCachedAdvertSettings: addManualMinimal,
    addToCachedAdvertSettings: add,
    fetchCachedAdvertSettings: fetch,
    toFetchCachedAdvertSettings: toFetch,
    cachedAdvertSettings: cache,
    hasCachedAdvertSettings: has,
    getCachedAdvertSettings: get,
    isLoadedCachedAdvertSettings: isLoaded,
  }
}
