import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'

export const cmsGalleryRoutes: RouteRecordRaw[] = [
  {
    path: '/gallery',
    name: ROUTE.CMS.GALLERY.LIST,
    component: () => import('@/views/cms/gallery/GalleryListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/gallery/:id(\\d+)/edit',
    name: ROUTE.CMS.GALLERY.EDIT,
    component: () => import('@/views/cms/gallery/GalleryEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/gallery/:id(\\d+)',
    name: ROUTE.CMS.GALLERY.DETAIL,
    component: () => import('@/views/cms/gallery/GalleryDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
]
