import type { FilterBag, Pagination } from '@anzusystems/common-admin'
import { apiCreateOne, apiFetchList } from '@anzusystems/common-admin'
import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { ArticleMultiTitle, ArticleMultiTitleUpsertDto } from '@/types/cms/ArticleMultiTitle'

const END_POINT = '/adm/v1/article-multi-title'
export const ENTITY = 'articleMultiTitle'

export const fetchArticleMultiTitleList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<ArticleMultiTitle[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const upsertArticleMultiTitles = (data: ArticleMultiTitleUpsertDto) =>
  apiCreateOne<ArticleMultiTitleUpsertDto, ArticleMultiTitle[]>(
    cmsClient,
    data,
    END_POINT + '/articles/' + data.article,
    {},
    SYSTEM_CMS,
    ENTITY
  )
