import { useAuth } from '@/composables/auth/auth'
import type { User } from '@/types/cms/User'
import {
  fetchNotificationCount,
  fetchNotificationList,
  LIST_LIMIT,
  markNotificationsAsRead,
  markNotificationsAsReadSinceId,
} from '@/services/api/notification/notificationApi'
import { isNumber } from '@anzusystems/common-admin'
import { SUPPORTED_TYPES, useAppNotificationStore } from '@/composables/appNotification/appNotificationStore'

export function useAppNotificationActions() {
  const appNotificationStore = useAppNotificationStore()

  const clearAll = async () => {
    const first = appNotificationStore.notifications[0]
    if (!first) return
    const { useCurrentUser } = useAuth()
    const { currentUser } = useCurrentUser<User>('cms')
    try {
      await markNotificationsAsReadSinceId(currentUser.value!.id!, first.id)
    } catch (error) {
      //
    }
  }

  const clearLoaded = async () => {
    const unread = appNotificationStore.notifications.filter((item) => item.read === false).map((item) => item.id)
    if (unread.length === 0) return
    const { useCurrentUser } = useAuth()
    const { currentUser } = useCurrentUser<User>('cms')
    try {
      await markNotificationsAsRead(currentUser.value!.id!, unread)
    } catch (error) {
      //
    } finally {
      appNotificationStore.markAsRead(unread)
    }
  }

  const loadCount = async () => {
    const { useCurrentUser } = useAuth()
    const { currentUser } = useCurrentUser<User>('cms')
    try {
      const res = await fetchNotificationCount(currentUser.value!.id!, false)
      if (!isNumber(res.count)) return
      appNotificationStore.unreadCountCached = res.hasMore ? res.count + 1 : res.count
    } catch (error) {
      //
    }
  }

  const getLastId = () => {
    if (appNotificationStore.notifications.length > 0) {
      return appNotificationStore.notifications[appNotificationStore.notifications.length - 1].id
    }
    return undefined
  }

  const loadNotifications = async (clear = false) => {
    const { useCurrentUser } = useAuth()
    const { currentUser } = useCurrentUser<User>('cms')

    appNotificationStore.loading[clear ? 'all' : 'more'] = true

    if (clear) {
      appNotificationStore.notifications = []
    }

    try {
      const res = await fetchNotificationList(
        currentUser.value!.id!,
        getLastId(),
        appNotificationStore.mode === 'all' ? undefined : false
      )
      res.forEach((item) => {
        if (SUPPORTED_TYPES.includes(item.message.type as any)) {
          appNotificationStore.notifications.push(item)
        }
      })
      appNotificationStore.showMore = res.length === LIST_LIMIT
    } catch (error) {
      //
    } finally {
      appNotificationStore.loading[clear ? 'all' : 'more'] = false
    }
  }

  return {
    clearAll,
    clearLoaded,
    loadCount,
    loadNotifications,
  }
}
