import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { ContentItemKindThematicBox } from '@/types/cms/ContentItemKind/ContentItemKindThematicBox'
import { ThematicBoxLayoutDefault } from '@/types/cms/ContentItemKind/ContentItemKindThematicBox'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindThematicBoxFactory() {
  const create = (): ContentItemKindThematicBox => {
    return {
      ...createAbstractContentItemKind(),
      title: '',
      url: '',
      box: null,
      linkedList: null,
      layout: ThematicBoxLayoutDefault,
      discriminator: ContentItemDiscriminator.ThematicBox,
      _resourceName: 'contentItemKindThematicBox',
    }
  }

  return {
    create,
  }
}
