import { useAlerts } from '@anzusystems/common-admin'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { fetchDashboardList } from '@/services/api/cms/dashboardApi'
import { useDashboardListStore } from '@/stores/cms/dashboardStore'
import type { DashboardTypeType } from '@/model/cms/valueObject/DashboardType'

const { showErrorsDefault } = useAlerts()

const listLoading = ref(false)

export const useDashboardListActions = () => {
  const dashboardListStore = useDashboardListStore()
  const { listItems, listItemsGrouped } = storeToRefs(dashboardListStore)

  const fetchList = async (type?: DashboardTypeType | undefined) => {
    listLoading.value = true
    try {
      const dashboardRes = await fetchDashboardList(type)
      if (dashboardRes.data.length > 0) {
        listItems.value = dashboardRes.data
        dashboardListStore.setListGrouped(dashboardRes.data)
      }
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      listLoading.value = false
    }
  }

  return {
    listLoading,
    fetchList,
    listItems,
    listItemsGrouped,
  }
}
