import { SYSTEM_CMS } from '@/model/systems'
import { dateTimeNow } from '@anzusystems/common-admin'
import type { EmbedTimelineAware, EmbedTimelineCreateUpdateDto } from '@/components/anzutap/types/EmbedTimeline'

export function useEmbedTimelineFactory() {
  const createDefault = (id = ''): EmbedTimelineAware => {
    return {
      id,
      timeline: null,
      customData: {},
      discriminator: 'timeline',
      _resourceName: 'embedKindTimeline',
      _system: SYSTEM_CMS,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
    }
  }

  const createCreateUpdateDto = (id = ''): EmbedTimelineCreateUpdateDto => {
    return {
      id,
      timeline: null,
      customData: {},
    }
  }

  return {
    createDefault,
    createCreateUpdateDto,
  }
}
