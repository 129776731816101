<script lang="ts" setup>
import type { ArticleStage } from '@/types/cms/ArticleStage'
import ArticleMainBarDialogs from '@/views/cms/article/components/mainBarButtons/ArticleMainBarDialogs.vue'
import ArticleStageArticleActions from '@/views/cms/articleStage/components/ArticleStageArticleActions.vue'
import ArticleStageArticleCharCountColumn from '@/views/cms/articleStage/components/ArticleStageArticleCharCountColumn.vue'
import ArticleStageArticleNameColumn from '@/views/cms/articleStage/components/ArticleStageArticleNameColumn.vue'
import ArticleStageArticleStatusColumn from '@/views/cms/articleStage/components/ArticleStageArticleStatusColumn.vue'
import ArticleStageChip from '@/views/cms/articleStage/components/ArticleStageChip.vue'
import { useArticleStageListActions } from '@/views/cms/articleStage/composables/articleStageActions'
import CachedDeskChip from '@/views/cms/desk/components/CachedDeskChip.vue'
import CachedUserChip from '@/views/cms/user/components/CachedUserChip.vue'
import {
  ADatetime,
} from '@anzusystems/common-admin'

type DatatableItem = ArticleStage

withDefaults(
  defineProps<{
    columnsVisible?: any,
    showActions?: boolean
  }>(),
  {
    columnsVisible: () => [],
    showActions: false,
  }
)

const { listItems } = useArticleStageListActions()

</script>

<template>
  <VDataTableServer
    class="a-datatable"
    :headers="columnsVisible"
    :items="listItems"
    :items-length="listItems.length"
    item-value="id"
  >
    <template #item.articleHeadline="{ item }: { item: DatatableItem }">
      <div class="td--title">
        <ArticleStageArticleNameColumn :id="item.id" />
      </div>
    </template>
    <template #item.articleCharsCount="{ item }: { item: DatatableItem }">
      <div class="td--title">
        <ArticleStageArticleCharCountColumn :id="item.id" />
      </div>
    </template>
    <template #item.articleStatus="{ item }: { item: DatatableItem }">
      <ArticleStageArticleStatusColumn :id="item.id" />
    </template>
    <template #item.createdBy="{ item }: { item: DatatableItem }">
      <CachedUserChip
        :id="item.createdBy"
        class="mr-1 mb-1"
      />
    </template>
    <template #item.completedBy="{ item }: { item: DatatableItem }">
      <CachedUserChip
        :id="item.completedBy"
        class="mr-1 mb-1"
      />
    </template>
    <template #item.desk="{ item }: { item: DatatableItem }">
      <CachedDeskChip :id="item.desk" />
    </template>
    <template #item.createdAt="{ item }: { item: DatatableItem }">
      <ADatetime :date-time="item.createdAt" />
    </template>
    <template #item.completedAt="{ item }: { item: DatatableItem }">
      <ADatetime :date-time="item.createdAt" />
    </template>
    <template #item.modifiedAt="{ item }: { item: DatatableItem }">
      <ADatetime :date-time="item.modifiedAt" />
    </template>

    <template #item.stage="{ item }: { item: DatatableItem }">
      <ArticleStageChip
        v-if="item.stage"
        :stage-id="item.stage"
        :priority="item.priority"
        :status="item.status"
      />
    </template>
    <template
      v-if="showActions"
      #item.actions="{ item }: { item: DatatableItem }"
    >
      <ArticleStageArticleActions :id="item.id" />
    </template>
    <template #bottom>
      <slot name="pagination" />
    </template>
  </VDataTableServer>

  <ArticleMainBarDialogs />
</template>
