import { SYSTEM_CMS } from '@/model/systems'
import { dateTimeNow } from '@anzusystems/common-admin'
import type { EmbedGalleryAware, EmbedGalleryCreateUpdateDto } from '@/components/anzutap/types/EmbedGallery'
import { EmbedGalleryLayoutDefault } from '@/components/anzutap/types/EmbedGallery'

export function useEmbedGalleryFactory() {
  const createDefault = (id = ''): EmbedGalleryAware => {
    return {
      id,
      gallery: null,
      layout: EmbedGalleryLayoutDefault,
      texts: {
        title: '',
        description: '',
      },
      customData: {},
      discriminator: 'gallery',
      _resourceName: 'embedKindGallery',
      _system: SYSTEM_CMS,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
    }
  }

  const createCreateUpdateDto = (id = ''): EmbedGalleryCreateUpdateDto => {
    return {
      id,
      gallery: null,
      layout: EmbedGalleryLayoutDefault,
      texts: {
        title: '',
        description: '',
      },
      customData: {},
    }
  }

  return {
    createDefault,
    createCreateUpdateDto,
  }
}
