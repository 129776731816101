<script setup lang="ts">
import type { CollabRoom } from '@/types/Collab'
import { onMounted, ref, watch } from 'vue'
import { useStages } from '@/views/cms/stage/composables/stagesAll'
import {
  ACard,
  ARow,
  type CollabFieldName, type DocId,
  type IntegerId,
  useCollabAnyDataChange
} from '@anzusystems/common-admin'
import ArticleStageAction from '@/views/cms/articleStage/components/ArticleStageAction.vue'
import ArticleStageDatatable from '@/views/cms/articleStage/components/ArticleStageDatatable.vue'
import {
  COLLAB_FIELD_PREFIX_STAGES_CHANGE,
} from '@/views/cms/article/composables/articleKindActions'
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import { useArticleStageListActions } from '@/views/cms/articleStage/composables/articleStageActions'
import {
  type ArticleStagesChangeCollabUpdate,
  isArticleKindStandard
} from '@/types/cms/ArticleKind/ArticleKindStandard'
import { storeToRefs } from 'pinia'

const props = withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const store = useArticleKindOneStore()
const { article, stageWidgetDatatableReload } = storeToRefs(store)

const { loadStages, listLoading, stagesArray } = useStages()

const { listLoading: articleStageListLoading } = useArticleStageListActions()

onMounted(() => {
  loadStages()
})

const articleStageDatatable = ref<InstanceType<typeof ArticleStageDatatable> | null>(null)

const createCollabStagesChangeieldName = (id: DocId) => {
  return COLLAB_FIELD_PREFIX_STAGES_CHANGE + id as CollabFieldName
}

const dispatchStagesChange = () => {
  if (!isArticleKindStandard(article.value)) return
  changeCollabAnyData(createCollabStagesChangeieldName(article.value.docId), {
    action: 'change',
    data: {
      activeStages: article.value.activeStages,
      completedStages: article.value.completedStages
    },
  } as ArticleStagesChangeCollabUpdate)
}

const onStageActive = (stageId: IntegerId) => {
  articleStageDatatable.value?.refresh()
  store.addActiveStage(stageId)

  dispatchStagesChange()
}

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const { changeCollabAnyData } = useCollabAnyDataChange(props.collabRoom)

const onStageCompleted = (stageId: IntegerId) => {
  articleStageDatatable.value?.refresh()
  store.addCompletedStage(stageId)
  dispatchStagesChange()
}

watch(
  stageWidgetDatatableReload,
  () => {
    articleStageDatatable.value?.refresh()
  }
)
</script>

<template>
  <ARow>
    <ACard :loading="articleStageListLoading">
      <ArticleStageDatatable
        v-if="article"
        ref="articleStageDatatable"
        :doc-id="article.docId"
      />
    </ACard>
  </ARow>
  <ARow>
    <div class="d-flex align-center">
      <VSpacer />
      <ACard :loading="listLoading">
        <ArticleStageAction
          v-for="stage in stagesArray"
          :key="stage.id"
          :stage-id="stage.id"
          @stage-active="onStageActive"
          @stage-completed="onStageCompleted"
        />
      </ACard>
    </div>
  </ARow>
</template>
