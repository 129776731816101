import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import {
  apiCreateOne,
  apiDeleteOne, apiFetchByIds,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
  type FilterBag,
  type IntegerId,
  type Pagination,
} from '@anzusystems/common-admin'
import type { ArticleIntention } from '@/types/cms/Task'

const END_POINT = '/adm/v1/article-intention'
export const ENTITY = 'articleIntention'

export const fetchArticleIntentionList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<ArticleIntention[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchArticleIntentionListByIds = (ids: IntegerId[]) =>
  apiFetchByIds<ArticleIntention[]>(cmsClient, ids, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const fetchArticleIntention = (id: IntegerId) =>
  apiFetchOne<ArticleIntention>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createArticleIntention = (data: ArticleIntention) =>
  apiCreateOne<ArticleIntention>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updateArticleIntention = (id: IntegerId, data: ArticleIntention) =>
  apiUpdateOne<ArticleIntention>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const deleteArticleIntention = (id: IntegerId) =>
  apiDeleteOne<ArticleIntention>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)
