import {
  type FilterBag,
  type IntegerId,
  isDefined,
  isUndefined,
  type Pagination,
  useAlerts,
  type ValueObjectOption,
} from '@anzusystems/common-admin'
import { ROUTE } from '@/router/routes'
import {
  createAuthor,
  deleteAuthor,
  fetchAuthor,
  fetchAuthorList,
  fetchAuthorListByIds,
  updateAuthor,
} from '@/services/api/cms/authorApi'
import useVuelidate from '@vuelidate/core'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useCachedSuperAuthors } from '@/views/cms/superAuthor/composables/cachedSuperAuthors'
import type { AuthorKind } from '@/types/cms/AuthorKind/AuthorKind'
import { useAuthorKindOneStore } from '@/stores/cms/authorKindStore'
import { useCachedSites } from '@/views/cms/site/composables/cachedSites'
import { useSiteGroups } from '@/views/cms/siteGroup/composables/siteGroupsAll'
import { useAuthorHelpers } from '@/views/cms/author/composables/authorHelpers'
import type { AuthorKindPerson } from '@/types/cms/AuthorKind/AuthorKindPerson'
import type { AuthorKindSource } from '@/types/cms/AuthorKind/AuthorKindSource'

const { showValidationError, showRecordWas, showErrorsDefault } = useAlerts()
const { addToCachedSuperAuthors, fetchCachedSuperAuthors } = useCachedSuperAuthors()
const { addToCachedSites, fetchCachedSites } = useCachedSites()

const datatableHiddenColumns = ref<Array<string>>([])
const listLoading = ref(false)
const detailLoading = ref(false)
const saveButtonLoading = ref(false)

export const useAuthorSelectActions = () => {
  const { getSiteGroup } = useSiteGroups()
  const { getAuthorDisplayName } = useAuthorHelpers()

  const mapToValueObject = (author: AuthorKind) => {
    let title = getAuthorDisplayName(author)
    const siteGroup = getSiteGroup(author.siteGroup)
    if (isDefined(siteGroup)) {
      title += ` (${siteGroup.title})`
    }

    return {
      title,
      value: author.id,
    }
  }

  const fetchItems = async (pagination: Pagination, filterBag: FilterBag) => {
    const authors = await fetchAuthorList(pagination, filterBag)

    return <ValueObjectOption<IntegerId>[]>authors.map((author: AuthorKind) => mapToValueObject(author))
  }

  const fetchItemsByIds = async (ids: IntegerId[]) => {
    const authors = await fetchAuthorListByIds(ids)

    return <ValueObjectOption<IntegerId>[]>authors.map((author: AuthorKind) => mapToValueObject(author))
  }

  return {
    fetchItems,
    fetchItemsByIds,
  }
}

export const useAuthorListActions = () => {
  const listItems = ref<AuthorKind[]>([])

  const fetchList = async (pagination: Pagination, filterBag: FilterBag) => {
    listLoading.value = true
    try {
      listItems.value = await fetchAuthorList(pagination, filterBag)
      listItems.value.forEach((item) => {
        addToCachedSuperAuthors(item.superAuthor)
        addToCachedSites(item.site)
      })
      fetchCachedSuperAuthors()
      fetchCachedSites()
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      listLoading.value = false
    }
  }

  return {
    datatableHiddenColumns,
    listLoading,
    fetchList,
    listItems,
  }
}

export const useAuthorDetailActions = () => {
  const authorKindOneStore = useAuthorKindOneStore()
  const { author } = storeToRefs(authorKindOneStore)

  const fetchData = async (id: IntegerId) => {
    detailLoading.value = true
    try {
      const authorRes = await fetchAuthor(id)
      author.value = authorRes
      addToCachedSuperAuthors(authorRes.superAuthor)
      fetchCachedSuperAuthors()
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  return {
    author,
    detailLoading,
    fetchData,
    resetStore: authorKindOneStore.reset,
  }
}

export const useAuthorCreateEditActions = () => {
  const v$ = useVuelidate()
  const authorKindOneStore = useAuthorKindOneStore()
  const { author } = storeToRefs(authorKindOneStore)

  const fetchData = async (id: IntegerId) => {
    detailLoading.value = true
    try {
      author.value = await fetchAuthor(id)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  const onUpdate = async (
    onSuccess: ((author: AuthorKindPerson | AuthorKindSource) => void) | undefined = undefined
  ) => {
    try {
      saveButtonLoading.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      const res = await updateAuthor(authorKindOneStore.author.id, authorKindOneStore.author)
      showRecordWas('updated')
      if (!isUndefined(onSuccess)) onSuccess(res)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  const onCreate = async (
    onSuccess: ((author: AuthorKindPerson | AuthorKindSource) => void) | undefined = undefined
  ) => {
    try {
      saveButtonLoading.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      const authorRes = await createAuthor(authorKindOneStore.author)
      showRecordWas('created')
      if (!isUndefined(onSuccess)) onSuccess(authorRes)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  return {
    detailLoading,
    saveButtonLoading,
    author,
    fetchData,
    onUpdate,
    onCreate,
    resetStore: authorKindOneStore.reset,
  }
}

export const useAuthorDeleteActions = () => {
  const router = useRouter()

  const onDelete = async (id: IntegerId) => {
    try {
      await deleteAuthor(id)
      showRecordWas('deleted')
      router.push({ name: ROUTE.CMS.AUTHOR.LIST })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      // closeDeleteDialog()
    }
  }

  return {
    onDelete,
  }
}
