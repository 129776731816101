<script lang="ts" setup>
import type { IntegerId } from '@anzusystems/common-admin'
import { ACachedChip } from '@anzusystems/common-admin'
import { ROUTE } from '@/router/routes'
import { useCachedSites } from '@/views/cms/site/composables/cachedSites'

withDefaults(
  defineProps<{
    id: null | IntegerId | undefined
  }>(),
  {}
)

const { getCachedSite } = useCachedSites()
</script>

<template>
  <ACachedChip
    :id="id"
    :get-cached-fn="getCachedSite"
    :route="ROUTE.CMS.SITE.DETAIL"
    display-text-path="name"
  />
</template>
