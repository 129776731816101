import { SYSTEM_CMS } from '@/model/systems'
import type {
  ArticleDetail,
  PropagationSuggest,
  PropagationSuggestAttributes,
  PropagationSuggestDates,
} from '@/types/cms/PropagationSuggest'
import { dateTimeNow } from '@anzusystems/common-admin'
import { PropagationSuggestConfirmationDefault } from '@/model/cms/valueObject/PropagationSuggestConfirmation'
import { PropagationSuggestPriorityDefault } from '@/model/cms/valueObject/PropagationSuggestPriority'
import { PropagationSuggestStatusDefault } from '@/model/cms/valueObject/PropagationSuggestStatus'

export function usePropagationSuggestFactory() {
  const createDefault = (): PropagationSuggest => {
    return {
      id: 0,
      articleDocId: '',
      comment: '',
      inbox: null,
      inboxName: '',
      articleDetail: createDefaultPropagationSuggestArticleDetail(),
      attributes: createDefaultPropagationSuggestAttributes(),
      dates: createDefaultPropagationSuggestDates(),
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
      _resourceName: 'propagationSuggest',
      _system: SYSTEM_CMS,
    }
  }

  const createDefaultPropagationSuggestArticleDetail = (): ArticleDetail => {
    return {
      id: 0,
      texts: {
        headline: '',
        perex: '',
      },
    }
  }

  const createDefaultPropagationSuggestAttributes = (): PropagationSuggestAttributes => {
    return {
      confirmation: PropagationSuggestConfirmationDefault,
      priority: PropagationSuggestPriorityDefault,
      status: PropagationSuggestStatusDefault,
    }
  }

  const createDefaultPropagationSuggestDates = (): PropagationSuggestDates => {
    return {
      recommendedPublishAt: null,
      publishLatestAt: null,
      approvedAt: null,
      rejectedAt: null,
    }
  }

  return {
    createDefault,
  }
}
