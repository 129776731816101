import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsCountdownRoutes: RouteRecordRaw[] = [
  {
    path: '/countdown',
    name: ROUTE.CMS.COUNTDOWN.LIST,
    component: () => import('@/views/cms/countdown/CountdownListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/countdown/create',
    name: ROUTE.CMS.COUNTDOWN.CREATE,
    component: () => import('@/views/cms/countdown/CountdownCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_COUNTDOWN_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/countdown/:id(\\d+)/edit',
    name: ROUTE.CMS.COUNTDOWN.EDIT,
    component: () => import('@/views/cms/countdown/CountdownEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_COUNTDOWN_READ, ACL.CMS_COUNTDOWN_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/countdown/:id(\\d+)',
    name: ROUTE.CMS.COUNTDOWN.DETAIL,
    component: () => import('@/views/cms/countdown/CountdownDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_COUNTDOWN_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
