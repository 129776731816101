import type { IntegerId } from '@anzusystems/common-admin'
import { defineCached } from '@anzusystems/common-admin'
import type { PromoLink, PromoLinkMinimal } from '@/types/cms/PromoLink'
import { fetchPromoLinkListByIds } from '@/services/api/cms/promoLinkApi'

const mapFullToMinimal = (promoLink: PromoLink): PromoLinkMinimal => ({
  id: promoLink.id,
  name: promoLink.name,
})

const mapIdToMinimal = (id: IntegerId): PromoLinkMinimal => {
  return { id: id, name: '' }
}

const { cache, toFetch, fetch, add, addManual, addManualMinimal, has, get, isLoaded } = defineCached<
  IntegerId,
  PromoLink,
  PromoLinkMinimal
>(mapFullToMinimal, mapIdToMinimal, fetchPromoLinkListByIds)

export const useCachedPromoLinks = () => {
  return {
    addManualToCachedPromoLinks: addManual,
    addManualMinimalToCachedPromoLinks: addManualMinimal,
    addToCachedPromoLinks: add,
    fetchCachedPromoLinks: fetch,
    toFetchCachedPromoLinks: toFetch,
    cachedPromoLinks: cache,
    hasCachedPromoLink: has,
    getCachedPromoLink: get,
    isLoadedCachedPromoLink: isLoaded,
  }
}
