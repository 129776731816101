import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ValueObjectOption } from '@anzusystems/common-admin'

export const NewsletterPromoType = {
  Top: 'top',
  Bottom: 'bottom',
} as const
export const NewsletterPromoTypeDefault = NewsletterPromoType.Top
export type NewsletterPromoTypeType = (typeof NewsletterPromoType)[keyof typeof NewsletterPromoType]

export function useNewsletterPromoType() {
  const { t } = useI18n()

  const newsletterPromoTypeOptions = ref<ValueObjectOption<NewsletterPromoTypeType>[]>([
    {
      value: NewsletterPromoType.Top,
      title: t('cms.newsletterPromo.promoType.top'),
    },
    {
      value: NewsletterPromoType.Bottom,
      title: t('cms.newsletterPromo.promoType.bottom'),
    },
  ])

  const getNewsletterPromoTypeOptions = (value: NewsletterPromoTypeType) => {
    return newsletterPromoTypeOptions.value.find((item) => item.value === value)
  }

  return {
    newsletterPromoTypeOptions,
    getNewsletterPromoTypeOptions,
  }
}
