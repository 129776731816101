import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ValueObjectOption } from '@anzusystems/common-admin'

export const KeywordStatus = {
  Active: 'active',
  Inactive: 'inactive',
  Old: 'old',
  Ban: 'ban',
} as const
export type KeywordStatusType = (typeof KeywordStatus)[keyof typeof KeywordStatus]

export function useKeywordStatus() {
  const { t } = useI18n()

  const keywordStatusOptions = ref<ValueObjectOption<KeywordStatusType>[]>([
    {
      value: KeywordStatus.Active,
      title: t('cms.keyword.status.active'),
      color: 'success',
    },
    {
      value: KeywordStatus.Inactive,
      title: t('cms.keyword.status.inactive'),
      color: 'info',
    },
    {
      value: KeywordStatus.Old,
      title: t('cms.keyword.status.old'),
      color: 'warning',
    },
    {
      value: KeywordStatus.Ban,
      title: t('cms.keyword.status.ban'),
      color: 'error',
    },
  ])

  const getKeywordStatusOption = (value: KeywordStatusType) => {
    return keywordStatusOptions.value.find((item) => item.value === value)
  }

  return {
    keywordStatusOptions,
    getKeywordStatusOption,
  }
}
