import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ValueObjectOption } from '@anzusystems/common-admin'

export const NewsletterType = {
  Article: 'article',
  ArticleList: 'articleList',
  Follow: 'follow',
} as const
export const NewsletterTypeDefault = NewsletterType.ArticleList
export type NewsletterTypeType = (typeof NewsletterType)[keyof typeof NewsletterType]

export function useNewsletterType() {
  const { t } = useI18n()

  const newsletterTypeOptions = ref<ValueObjectOption<NewsletterTypeType>[]>([
    {
      value: NewsletterType.Article,
      title: t('cms.newsletter.type.article'),
    },
    {
      value: NewsletterType.ArticleList,
      title: t('cms.newsletter.type.articleList'),
    },
    {
      value: NewsletterType.Follow,
      title: t('cms.newsletter.type.follow'),
    },
  ])

  const getNewsletterTypeOptions = (value: NewsletterTypeType) => {
    return newsletterTypeOptions.value.find((item) => item.value === value)
  }

  return {
    newsletterTypeOptions,
    getNewsletterTypeOptions,
  }
}
