import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsExternalSnippetRoutes: RouteRecordRaw[] = [
  {
    path: '/external-snippet',
    name: ROUTE.CMS.EXTERNAL_SNIPPET.LIST,
    component: () => import('@/views/cms/externalSnippet/ExternalSnippetListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/external-snippet/create',
    name: ROUTE.CMS.EXTERNAL_SNIPPET.CREATE,
    component: () => import('@/views/cms/externalSnippet/ExternalSnippetCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_EXTERNAL_SNIPPET_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/external-snippet/:id(\\d+)/edit',
    name: ROUTE.CMS.EXTERNAL_SNIPPET.EDIT,
    component: () => import('@/views/cms/externalSnippet/ExternalSnippetEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_EXTERNAL_SNIPPET_READ, ACL.CMS_EXTERNAL_SNIPPET_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/external-snippet/:id(\\d+)',
    name: ROUTE.CMS.EXTERNAL_SNIPPET.DETAIL,
    component: () => import('@/views/cms/externalSnippet/ExternalSnippetDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_EXTERNAL_SNIPPET_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
