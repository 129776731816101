import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsRouteRoutes: RouteRecordRaw[] = [
  {
    path: '/route',
    name: ROUTE.CMS.ROUTE.LIST,
    component: () => import('@/views/cms/route/RouteListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/route/create',
    name: ROUTE.CMS.ROUTE.CREATE,
    component: () => import('@/views/cms/route/RouteCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ROUTE_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/route/:id(\\d+)/edit',
    name: ROUTE.CMS.ROUTE.EDIT,
    component: () => import('@/views/cms/route/RouteEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ROUTE_READ, ACL.CMS_ROUTE_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/route/:id(\\d+)',
    name: ROUTE.CMS.ROUTE.DETAIL,
    component: () => import('@/views/cms/route/RouteDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ROUTE_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
