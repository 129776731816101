import { acceptHMRUpdate, defineStore } from 'pinia'
import { useNewsletterFactory } from '@/model/cms/factory/NewsletterFactory'
import { ref } from 'vue'

const { createDefaultNewsletter } = useNewsletterFactory()

export const useNewsletterOneStore = defineStore('cmsNewsletterOneStore', () => {
  const newsletter = ref(createDefaultNewsletter())
  const newsletterLoaded = ref(false)

  function reset() {
    newsletter.value = createDefaultNewsletter()
    newsletterLoaded.value = false
  }

  return {
    newsletter,
    newsletterLoaded,
    reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNewsletterOneStore, import.meta.hot))
}
