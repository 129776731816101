export const NodeName = {
  Doc: 'doc',
  Paragraph: 'paragraph',
  Text: 'text',
  HardBreak: 'hardBreak',
  HorizontalRule: 'horizontalRule',
  Heading: 'heading',
  OrderedList: 'orderedList',
  BulletList: 'bulletList',
  ListItem: 'listItem',
  Button: 'button',
  Quote: 'quote',
  QuoteContent: 'quoteContent',
  QuoteAuthor: 'quoteAuthor',
  ContentBreak: 'contentBreak',
  ContentLock: 'contentLock',
  StyledBox: 'styledBox',
  StyledBoxTitle: 'styledBoxTitle',
  StyledBoxContent: 'styledBoxContent',
  Table: 'table',
  TableRow: 'tableRow',
  TableHeader: 'tableHeader',
  TableCell: 'tableCell',
} as const

export const EmbedNodeName = {
  Custom: 'embedCustom',
  ExternalImage: 'embedExternalImage',
  ExternalImageInline: 'embedExternalImageInline',
  Faq: 'embedFaq',
  Gallery: 'embedGallery',
  Image: 'embedImage',
  ImageInline: 'embedImageInline',
  CrossBox: 'embedCrossBox',
  External: 'embedExternal',
  Minute: 'embedMinute',
  Poll: 'embedPoll',
  Quiz: 'embedQuiz',
  Related: 'embedRelated',
  Timeline: 'embedTimeline',
  Weather: 'embedWeather',
  Review: 'embedReview',
  Video: 'embedVideo',
  Audio: 'embedAudio',
} as const

export type EmbedNameType = (typeof EmbedNodeName)[keyof typeof EmbedNodeName]

export const ENTITY_EMBED_NODES_ALL: EmbedNameType[] = [
  EmbedNodeName.Custom,
  EmbedNodeName.ExternalImage,
  EmbedNodeName.ExternalImageInline,
  EmbedNodeName.Faq,
  EmbedNodeName.Gallery,
  EmbedNodeName.Image,
  EmbedNodeName.ImageInline,
  EmbedNodeName.CrossBox,
  EmbedNodeName.External,
  EmbedNodeName.Minute,
  EmbedNodeName.Poll,
  EmbedNodeName.Quiz,
  EmbedNodeName.Related,
  EmbedNodeName.Timeline,
  EmbedNodeName.Video,
  EmbedNodeName.Audio,
  EmbedNodeName.Weather,
  EmbedNodeName.Review,
]
