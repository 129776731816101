import { ArticleDiscriminator } from '@/model/cms/valueObject/ArticleDiscriminator'
import type { ArticleMinutePlacementType } from '@/model/cms/valueObject/ArticleMinutePlacement'
import type { ArticleMinutePriorityType } from '@/model/cms/valueObject/ArticleMinutePriority'
import { ArticleResourceName } from '@/model/cms/valueObject/ArticleResourceName'
import type { ArticleFlags, ArticleKind, ArticleKindAware } from '@/types/cms/ArticleKind/ArticleKind'
import type { IntegerId } from '@anzusystems/common-admin'

export type ArticleKindMinuteWithVersionData = ArticleKindMinute & {
  versionsData?: ArticleKindMinute
}

export interface ArticleKindMinute extends ArticleKindAware {
  discriminator: typeof ArticleDiscriminator.Minute
  minuteTopics: IntegerId[]
  attributesMinute: ArticleAttributesMinute
  flagsMinute: ArticleFlagsMinute
  _resourceName: typeof ArticleResourceName.Minute
}

export interface ArticleAttributesMinute {
  priority: ArticleMinutePriorityType
  placement: ArticleMinutePlacementType
  publicVisibleBodyCharCount: number
  bodyTextCharCount: number
}

export interface ArticleFlagsMinute extends ArticleFlags {
  generateTextImage: false
  showImageInWidget: false
}

export const isArticleKindMinute = (article: ArticleKind): article is ArticleKindMinute => {
  return article.discriminator === ArticleDiscriminator.Minute && Object.hasOwn(article, 'attributesMinute')
}
