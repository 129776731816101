import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { ContentItemKindCrossBox } from '@/types/cms/ContentItemKind/ContentItemKindCrossBox'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindCrossBoxFactory() {
  const create = (): ContentItemKindCrossBox => {
    return {
      ...createAbstractContentItemKind(),
      crossBox: null,
      discriminator: ContentItemDiscriminator.CrossBox,
      _resourceName: 'contentItemKindCrossBox',
    }
  }

  return {
    create,
  }
}
