import type {
  AnzuUserAndTimeTrackingAware,
  IntegerId,
  IntegerIdNullable,
  SortableItemDataAware,
} from '@anzusystems/common-admin'

export const ArticleMultiTitleVariant = {
  A: 'a',
  B: 'b',
  C: 'c',
  D: 'd',
  E: 'e',
  F: 'f',
  G: 'g',
  H: 'h',
  I: 'i',
  J: 'j',
  K: 'k',
  L: 'l',
} as const
export const ArticleMultiTitleVariantDefault = ArticleMultiTitleVariant.A
export type ArticleMultiTitleVariantType = (typeof ArticleMultiTitleVariant)[keyof typeof ArticleMultiTitleVariant]

export interface ArticleMultiTitle extends AnzuUserAndTimeTrackingAware, SortableItemDataAware {
  id: IntegerId
  variant: ArticleMultiTitleVariantType
  inUse: boolean
  headline: string
  perex: string
  article: IntegerIdNullable
  _resourceName: 'articleMultiTitle'
  _system: 'cms'
}

export interface ArticleMultiTitleUpsertDto {
  article: IntegerId
  multiTitles: ArticleMultiTitle[]
}
