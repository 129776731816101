import type { ValueObjectOption } from '@anzusystems/common-admin'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

export const ArticleStandardLockType = {
  Free: 'free',
  Locked: 'locked',
} as const
export type ArticleStandardLockTypeType = (typeof ArticleStandardLockType)[keyof typeof ArticleStandardLockType]
export const ArticleStandardLockTypeDefault = ArticleStandardLockType.Free

export function useArticleStandardLockType() {
  const { t } = useI18n()

  const articleStandardLockTypeOptions = ref<ValueObjectOption<ArticleStandardLockTypeType>[]>([
    {
      value: ArticleStandardLockType.Free,
      title: t('cms.articleKind.articleStandardLockType.free'),
    },
    {
      value: ArticleStandardLockType.Locked,
      title: t('cms.articleKind.articleStandardLockType.locked'),
    },
  ])

  const getArticleStandardLockTypeOption = (value: ArticleStandardLockTypeType) => {
    return articleStandardLockTypeOptions.value.find((item) => item.value === value)
  }

  return {
    articleStandardLockTypeOptions,
    getArticleStandardLockTypeOption,
  }
}
