import { reactive } from 'vue'
import { makeFilterHelper } from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'
import { ENTITY } from '@/services/api/cms/articleMultiTitleApi'

const makeFilter = makeFilterHelper(SYSTEM_CMS, ENTITY)

const filter = reactive({
  article: {
    ...makeFilter({ name: 'article', variant: 'eq' }),
  },
})

export function useArticleMultiTitleListFilter() {
  return filter
}
