import { SYSTEM_CMS } from '@/model/systems'
import { dateTimeNow } from '@anzusystems/common-admin'
import {
  type UserAdminConfig,
  UserAdminConfigLayoutTypeDefault,
  UserAdminConfigTypeDefault,
} from '@/types/cms/UserAdminConfig'

export function useUserAdminConfigFactory() {
  const createDefaultUserAdminConfig = (): UserAdminConfig => {
    return {
      id: 0,
      user: 0,
      configType: UserAdminConfigTypeDefault,
      layoutType: UserAdminConfigLayoutTypeDefault,
      systemResource: '',
      customName: '',
      defaultConfig: false,
      data: [],
      position: 0,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
      _resourceName: 'userAdminConfig',
      _system: SYSTEM_CMS,
    }
  }

  return {
    createDefaultUserAdminConfig,
  }
}
