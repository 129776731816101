import type { Inbox } from '@/types/cms/Inbox'
import { SYSTEM_CMS } from '@/model/systems'
import { dateTimeNow } from '@anzusystems/common-admin'

export function useInboxFactory() {
  const createDefault = (): Inbox => {
    return {
      id: 0,
      texts: {
        title: '',
        description: '',
      },
      boxes: [],
      items: [],
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
      _resourceName: 'inbox',
      _system: SYSTEM_CMS,
    }
  }

  return {
    createDefault,
  }
}
