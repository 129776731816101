<script lang="ts" setup>
import {
  ACopyText, AFormDatetimePicker,
  AFormTextarea,
  AFormValueObjectOptionsSelect, AImageWidget,
  ARow,
  ASystemEntityScope,
} from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'
import { useI18n } from 'vue-i18n'
import { ENTITY } from '@/services/api/cms/newsletterPromoApi'
import { useNewsletterPromoCreateEditActions } from '@/views/cms/newsletterPromo/composables/newsletterPromoActions'
import { useNewsletterPromoType } from '@/model/cms/valueObject/NewsletterPromoType'
import { useNewsletterPromoValidation } from '@/views/cms/newsletterPromo/composables/newsletterPromoValidation'
import {
  IMAGE_SELECT_LICENCE_CMS_LAYOUT_TEMPLATE,
  IMAGE_UPLOAD_LICENCE_CMS_LAYOUT_TEMPLATE
} from '@/composables/cms/imageWidgetConfigProvider'
import { useNewsletterPromoColor } from '@/model/cms/valueObject/NewsletterPromoColor'
import { useNewsletterPromoIcon } from '@/model/cms/valueObject/NewsletterPromoIcon'
import { useSubscribeTargeting } from '@/model/cms/valueObject/SubscribeTargeting'
import NewsletterRemoteAutocomplete from '@/views/cms/newsletter/components/NewsletterRemoteAutocomplete.vue'

withDefaults(
  defineProps<{
    isEdit?: boolean
  }>(),
  {
    isEdit: false,
  }
)
const { newsletterPromo } = useNewsletterPromoCreateEditActions()

const { v$ } = useNewsletterPromoValidation(newsletterPromo)
const { t } = useI18n()

const { newsletterPromoTypeOptions } = useNewsletterPromoType()
const { newsletterPromoColorOptions } = useNewsletterPromoColor()
const { newsletterPromoIconOptions } = useNewsletterPromoIcon()
const { subscribeTargetingOptions } = useSubscribeTargeting()
</script>

<template>
  <ASystemEntityScope
    :system="SYSTEM_CMS"
    :subject="ENTITY"
  >
    <VRow>
      <VCol cols="8">
        <ARow>
          <AFormTextarea
            v-model="newsletterPromo.texts.title"
            :v="v$.newsletterPromo.texts.title"
          />
        </ARow>
        <ARow>
          <AFormTextarea
            v-model="newsletterPromo.texts.titlePublic"
            :v="v$.newsletterPromo.texts.titlePublic"
          />
        </ARow>
        <ARow>
          <AImageWidget
            v-model="newsletterPromo.image"
            :upload-licence="IMAGE_UPLOAD_LICENCE_CMS_LAYOUT_TEMPLATE"
            :select-licences="IMAGE_SELECT_LICENCE_CMS_LAYOUT_TEMPLATE"
            :label="t('cms.newsletterPromo.model.image')"
            queue-key="image"
          />
        </ARow>
        <ARow>
          <AFormTextarea
            v-model="newsletterPromo.url"
            :v="v$.newsletterPromo.url"
          />
        </ARow>
        <ARow>
          <AFormTextarea
            v-model="newsletterPromo.texts.body"
            :v="v$.newsletterPromo.texts.body"
          />
        </ARow>
        <ARow>
          <AFormTextarea
            v-model="newsletterPromo.texts.linkedText"
            :v="v$.newsletterPromo.texts.linkedText"
          />
        </ARow>
        <ARow :title="t('cms.newsletterPromo.model.type')">
          <AFormValueObjectOptionsSelect
            v-model="newsletterPromo.type"
            :items="newsletterPromoTypeOptions"
          />
        </ARow>
        <ARow :title="t('cms.newsletterPromo.model.color')">
          <AFormValueObjectOptionsSelect
            v-model="newsletterPromo.color"
            :items="newsletterPromoColorOptions"
          />
        </ARow>
        <ARow :title="t('cms.newsletterPromo.model.icon')">
          <AFormValueObjectOptionsSelect
            v-model="newsletterPromo.icon"
            :items="newsletterPromoIconOptions"
          />
        </ARow>
      </VCol>
      <VCol cols="4">
        <ARow :title="t('cms.newsletterPromo.model.id')">
          <ACopyText :value="newsletterPromo.id" />
        </ARow>
        <ARow>
          <AFormDatetimePicker
            v-model="newsletterPromo.dates.startAt"
            clearable
            :label="t('cms.newsletterPromo.model.dates.startAt')"
          />
        </ARow>
        <ARow>
          <AFormDatetimePicker
            v-model="newsletterPromo.dates.endAt"
            clearable
            :label="t('cms.newsletterPromo.model.dates.endAt')"
          />
        </ARow>
        <ARow :title="t('cms.newsletterPromo.model.subscribeTargeting')">
          <AFormValueObjectOptionsSelect
            v-model="newsletterPromo.subscribeTargeting"
            :items="subscribeTargetingOptions"
          />
        </ARow>
        <ARow>
          <NewsletterRemoteAutocomplete
            v-model="newsletterPromo.newsletters"
            :label="t('cms.newsletterPromo.model.newsletters')"
            multiple
          />
        </ARow>
      </VCol>
    </VRow>
  </ASystemEntityScope>
</template>
