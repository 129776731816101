<script lang="ts" setup>
import type { IntegerIdNullable } from '@anzusystems/common-admin'
import { ACachedChip } from '@anzusystems/common-admin'
import { useCachedPromoLinks } from '@/views/cms/promoLink/composables/cachedPromoLinks'
import type { PromoLinkItem } from '@/types/cms/PromoLink'
import { ROUTE } from '@/router/routes'

withDefaults(
  defineProps<{
    promoLinkId?: IntegerIdNullable | undefined | null
    promoLinkItem?: PromoLinkItem | undefined | null
  }>(),
  {
    promoLinkId: null,
    promoLinkItem: null,
  }
)

const { getCachedPromoLink } = useCachedPromoLinks()
</script>

<template>
  <ACachedChip
    :id="promoLinkId ?? promoLinkItem?.promoLink ?? null"
    :get-cached-fn="getCachedPromoLink"
    :route="ROUTE.CMS.PROMO_LINK.DETAIL"
    display-text-path="name"
    text-only
    class="d-inline-flex"
  />
  <small v-if="promoLinkItem"> ({{ promoLinkItem.name }}) </small>
</template>
