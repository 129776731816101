import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsCrossBoxRoutes: RouteRecordRaw[] = [
  {
    path: '/cross-box',
    name: ROUTE.CMS.CROSS_BOX.LIST,
    component: () => import('@/views/cms/crossBox/CrossBoxListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/cross-box/create',
    name: ROUTE.CMS.CROSS_BOX.CREATE,
    component: () => import('@/views/cms/crossBox/CrossBoxCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_CROSS_BOX_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/cross-box/:id(\\d+)/edit',
    name: ROUTE.CMS.CROSS_BOX.EDIT,
    component: () => import('@/views/cms/crossBox/CrossBoxEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_CROSS_BOX_READ, ACL.CMS_CROSS_BOX_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/cross-box/:id(\\d+)',
    name: ROUTE.CMS.CROSS_BOX.DETAIL,
    component: () => import('@/views/cms/crossBox/CrossBoxDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_CROSS_BOX_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
