<script lang="ts" setup>
import { AAlerts, ASystemBar, useTheme } from '@anzusystems/common-admin'
import { ref } from 'vue'
import SidebarMain from '@/components/system/sidebar/SidebarMain.vue'
import { envConfig } from '@/services/EnvConfigService'
import ActionbarTeleportTarget from '@/components/system/ActionbarTeleportTarget.vue'
import logoFull from '@/assets/logo-cms-full.svg'
import logoNoText from '@/assets/logo-cms-no-text.svg'
import { useDisplay } from 'vuetify'
import SidebarRail from '@/components/system/sidebar/SidebarRail.vue'
import SidebarAppendMain from '@/components/system/sidebar/SidebarAppendMain.vue'
import SidebarAppendRail from '@/components/system/sidebar/SidebarAppendRail.vue'
import { ROUTE } from '@/router/routes'
import CollabAlertBar from '@/components/system/topbar/CollabAlertBar.vue'
import SidebarAppNotifications from '@/components/system/sidebar/SidebarAppNotifications.vue'
import { useAppNotificationStore } from '@/composables/appNotification/appNotificationStore'
import { storeToRefs } from 'pinia'

const { mobile } = useDisplay()

const drawer = ref(true)
const rail = ref(false)

const navIconClick = () => {
  if (mobile.value) {
    rail.value = false
    drawer.value = !drawer.value
    return
  }
  drawer.value = true
  rail.value = !rail.value
}

const { theme } = useTheme()

const appNotificationStore = useAppNotificationStore()
const { appNotificationSidebar } = storeToRefs(appNotificationStore)
</script>

<template>
  <AAlerts />
  <VApp :theme="theme">
    <ASystemBar :current-version="envConfig.appVersion" />
    <CollabAlertBar />
    <VNavigationDrawer
      v-model="drawer"
      :rail="rail"
    >
      <SidebarMain v-show="!rail" />
      <SidebarRail
        v-show="rail"
        v-if="!mobile"
      />
      <template #append>
        <VDivider />
        <SidebarAppendMain :class="{ 'd-flex': !rail, 'd-none': rail }" />
        <SidebarAppendRail
          v-if="!mobile"
          :class="{ 'd-flex': rail, 'd-none': !rail }"
        />
      </template>
    </VNavigationDrawer>
    <VNavigationDrawer
      v-model="appNotificationSidebar"
      location="right"
      temporary
    >
      <SidebarAppNotifications />
    </VNavigationDrawer>
    <VAppBar
      density="compact"
      elevation="0"
      class="system-border-b"
      :order="-1"
    >
      <div class="d-flex pr-2 w-100 justify-space-between full-width align-center">
        <div class="d-flex align-center">
          <VAppBarNavIcon
            data-cy="navbar-collapse"
            size="small"
            class="mx-1"
            @click.stop="navIconClick"
          />
        </div>
        <div class="main-logo mr-sm-2">
          <RouterLink :to="{ name: ROUTE.SYSTEM.HOMEPAGE }">
            <img
              width="104"
              height="42"
              :src="logoFull"
              alt="Admin"
              class="hidden-xs"
            >
            <img
              width="42"
              height="42"
              :src="logoNoText"
              alt="Inhouse"
              class="hidden-sm-and-up"
            >
          </RouterLink>
        </div>
        <KeepAlive>
          <ActionbarTeleportTarget />
        </KeepAlive>
      </div>
    </VAppBar>
    <VMain>
      <VContainer
        class="pa-2"
        fluid
      >
        <slot />
      </VContainer>
    </VMain>
  </VApp>
</template>

<style lang="scss">
.main-logo img {
  height: 38px;
  display: block;
  width: auto;
}
</style>
