import { reactive } from 'vue'
import { makeFilterHelper } from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'
import { ENTITY } from '@/services/api/cms/boxApi'

const makeFilter = makeFilterHelper(SYSTEM_CMS, ENTITY)

const filter = reactive({
  id: {
    ...makeFilter({ name: 'id' }),
  },
  box: {
    ...makeFilter({ name: 'box' }),
  },
})

export function useBoxItemListFilter() {
  return filter
}

export function useBoxItemFilter() {
  return reactive({
    articleDocId: {
      ...makeFilter({ name: 'articleDocId' }),
    },
  })
}
