<script lang="ts" setup>
import { useRubricSelectActions } from '@/views/cms/rubric/composables/rubricActions'
import { AFormRemoteAutocomplete, type IntegerId, type IntegerIdNullable, isNumber } from '@anzusystems/common-admin'
import { computed, watch } from 'vue'
import { useRubricFilter } from '@/model/cms/filter/RubricFilter'

const props = withDefaults(
  defineProps<{
    modelValue: IntegerIdNullable | IntegerId[]
    label?: string | undefined
    required?: boolean | undefined
    multiple?: boolean
    siteId?: IntegerIdNullable
    deskId?: IntegerIdNullable
    sideGroupId?: IntegerIdNullable
    siteRequired?: boolean
    siteGroupRequired?: boolean
    deskRequired?: boolean
  }>(),
  {
    label: undefined,
    required: undefined,
    multiple: false,
    siteId: null,
    deskId: null,
    sideGroupId: null,
    siteRequired: false,
    deskRequired: false,
    siteGroupRequired: false,
  }
)
const emit = defineEmits<{
  (e: 'update:modelValue', data: IntegerIdNullable | IntegerId[]): void
}>()

const value = computed({
  get(): IntegerIdNullable | IntegerId[] {
    return isNumber(props.modelValue) && props.modelValue === 0 ? null : props.modelValue
  },
  set(newValue: IntegerIdNullable | IntegerId[]) {
    emit('update:modelValue', newValue)
  },
})

const siteIdComputed = computed(() => (isNumber(props.siteId) ? props.siteId : undefined))
const deskIdComputed = computed(() => (isNumber(props.deskId) ? props.deskId : undefined))
const sideGroupId = computed(() => (isNumber(props.sideGroupId) ? props.sideGroupId : undefined))

const { fetchItems, fetchItemsByIds } = useRubricSelectActions()

const innerFilter = useRubricFilter()

watch(
  siteIdComputed,
  (newValue, oldValue) => {
    if (isNumber(newValue)) innerFilter.site.model = newValue
    if (newValue !== oldValue && oldValue) value.value = props.multiple ? [] : null
  },
  { immediate: true }
)

watch(
  deskIdComputed,
  (newValue, oldValue) => {
    if (isNumber(newValue)) innerFilter.desk.model = newValue
    if (newValue !== oldValue && oldValue) value.value = props.multiple ? [] : null
  },
  { immediate: true }
)

watch(
  sideGroupId,
  (newValue, oldValue) => {
    if (isNumber(newValue)) innerFilter.siteGroup.model = newValue
    if (newValue !== oldValue && oldValue) value.value = props.multiple ? [] : null
  },
  { immediate: true }
)

const disabledComputed = computed(() => {
  if (props.siteRequired) {
    return !isNumber(props.siteId)
  }
  if (props.deskRequired) {
    return !isNumber(props.deskId)
  }
  if (props.siteGroupRequired) {
    return !isNumber(props.sideGroupId)
  }
  return false
})
</script>

<template>
  <AFormRemoteAutocomplete
    :key="siteIdComputed"
    v-model="value"
    :required="required"
    :label="label"
    :fetch-items="fetchItems"
    :fetch-items-by-ids="fetchItemsByIds"
    :inner-filter="innerFilter"
    :multiple="multiple"
    filter-by-field="text"
    :disabled="disabledComputed"
  />
</template>
