import { fetchStageListByIds } from '@/services/api/cms/stageApi'
import type { IntegerId } from '@anzusystems/common-admin'
import { defineCached } from '@anzusystems/common-admin'
import type { Stage, StageMinimal } from '@/types/cms/Stage'

const mapFullToMinimal = (stage: Stage): StageMinimal => ({
  id: stage.id,
  name: stage.name,
})

const mapIdToMinimal = (id: IntegerId): StageMinimal => {
  return { id: id, name: '' }
}

const { cache, toFetch, fetch, add, addManual, addManualMinimal, has, get, isLoaded } = defineCached<
  IntegerId,
  Stage,
  StageMinimal
>(mapFullToMinimal, mapIdToMinimal, fetchStageListByIds)

export const useCachedStages = () => {
  return {
    addManualToCachedStages: addManual,
    addManualMinimalToCachedStages: addManualMinimal,
    addToCachedStages: add,
    fetchCachedStages: fetch,
    toFetchCachedStages: toFetch,
    cachedStages: cache,
    hasCachedStage: has,
    getCachedStage: get,
    isLoadedCachedStage: isLoaded,
  }
}
