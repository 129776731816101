<script lang="ts" setup>
import {
  ACollabLockedByUser,
  AFormRemoteAutocomplete, type CollabComponentConfig,
  type IntegerId,
  type IntegerIdNullable,
  isNumber,
} from '@anzusystems/common-admin'
import { computed } from 'vue'
import { useArticleIntentionSelectActions } from '@/views/cms/articleIntention/composables/articleIntentionActions'
import { useArticleIntentionFilter } from '@/model/cms/filter/ArticleIntentionFilter'

const props = withDefaults(
  defineProps<{
    modelValue: IntegerIdNullable | IntegerId[]
    label?: string | undefined
    required?: boolean | undefined
    multiple?: boolean,
    collab?: CollabComponentConfig
  }>(),
  {
    label: undefined,
    required: undefined,
    multiple: false,
    collab: undefined,
  }
)
const emit = defineEmits<{
  (e: 'update:modelValue', data: IntegerIdNullable | IntegerId[]): void
}>()
const value = computed({
  get(): IntegerIdNullable | IntegerId[] {
    return isNumber(props.modelValue) && props.modelValue === 0 ? null : props.modelValue
  },
  set(newValue: IntegerIdNullable | IntegerId[]) {
    emit('update:modelValue', newValue)
  },
})

const { fetchItems, fetchItemsByIds } = useArticleIntentionSelectActions()

const innerFilter = useArticleIntentionFilter()
</script>

<template>
  <AFormRemoteAutocomplete
    v-model="value"
    :required="required"
    :label="label"
    :fetch-items="fetchItems"
    :fetch-items-by-ids="fetchItemsByIds"
    :inner-filter="innerFilter"
    :multiple="multiple"
    filter-by-field="title"
  >
    <template #locked="{ userId }">
      <slot
        name="locked"
        :user-id="userId"
      >
        <ACollabLockedByUser
          v-if="collab"
          :id="userId"
          :users="collab.cachedUsers"
        />
      </slot>
    </template>
  </AFormRemoteAutocomplete>
</template>
