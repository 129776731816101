<script lang="ts" setup>
import { computed } from 'vue'
import { type ArticleDiscriminatorType, useArticleDiscriminator } from '@/model/cms/valueObject/ArticleDiscriminator'

const props = withDefaults(
  defineProps<{
    discriminator: ArticleDiscriminatorType
  }>(),
  {}
)

const { getArticleDiscriminatorOption } = useArticleDiscriminator()
const discriminatorOption = computed(() => {
  return getArticleDiscriminatorOption(props.discriminator)
})
</script>

<template>
  <VChip
    v-if="discriminatorOption"
    label
    size="small"
    :color="discriminatorOption.color"
  >
    {{ discriminatorOption.title }}
  </VChip>
</template>
