import { reactive } from 'vue'
import { makeFilterHelper } from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'
import { ENTITY } from '@/services/api/cms/siteApi'

const makeFilter = makeFilterHelper(SYSTEM_CMS, ENTITY)

const filter = reactive({
  id: {
    ...makeFilter({ name: 'id' }),
  },
  name: {
    ...makeFilter({ name: 'name', variant: 'startsWith' }),
  },
  siteGroup: {
    ...makeFilter({ name: 'siteGroup' }),
  },
  linkedList: {
    ...makeFilter({ name: 'linkedList' }),
  },
})

export function useSiteListFilter() {
  return filter
}

export function useSiteFilter() {
  return reactive({
    id: {
      ...makeFilter({ name: 'id', variant: 'in' }),
    },
    name: {
      ...makeFilter({ name: 'name', variant: 'startsWith' }),
    },
    siteGroup: {
      ...makeFilter({ name: 'siteGroup' }),
    },
    linkedList: {
      ...makeFilter({ name: 'linkedList' }),
    },
  })
}
