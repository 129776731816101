<script lang="ts" setup>
import { AActionCloseButton, AActionSaveButton, ACard } from '@anzusystems/common-admin'
import { onBeforeUnmount } from 'vue'
import { ROUTE } from '@/router/routes'
import ActionbarWrapper from '@/components/wrappers/ActionbarWrapper.vue'
import NewsletterPromoManage from '@/views/cms/newsletterPromo/components/NewsletterPromoManage.vue'
import { useNewsletterPromoCreateEditActions } from '@/views/cms/newsletterPromo/composables/newsletterPromoActions'

const { resetStore, onCreate, newsletterPromo } = useNewsletterPromoCreateEditActions()

onBeforeUnmount(() => {
  resetStore()
})
</script>

<template>
  <ActionbarWrapper :last-breadcrumb-title="newsletterPromo.texts.title">
    <template #buttons>
      <AActionSaveButton @save-record="onCreate" />
      <AActionCloseButton :route-name="ROUTE.CMS.NEWSLETTER_PROMO.LIST" />
    </template>
  </ActionbarWrapper>

  <ACard>
    <VCardText>
      <NewsletterPromoManage />
    </VCardText>
  </ACard>
</template>
