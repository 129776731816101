import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import axios from 'axios'
import { shallowRef } from 'vue'
import { envConfig } from '@/services/EnvConfigService'
import { isNull } from '@anzusystems/common-admin'
import { SYSTEM_ADMIN_CMS } from '@/model/systems'
import { userRefreshRequestInterceptor } from '@/services/api/clients/interceptors/requestRefreshToken'
import { logoutUserResponseInterceptor } from '@/services/api/clients/interceptors/responseLogoutUser'
import { AUTH_PATH_PREFIX } from '@/services/api/cms/authApi'

const mainInstance =  shallowRef<AxiosInstance | null>(null)

const cmsClient = function (): AxiosInstance {
  if (isNull(mainInstance.value)) {
    mainInstance.value = axios.create({
      baseURL: envConfig.cms.apiUrl,
      maxRedirects: 0,
      timeout: envConfig.cms.apiTimeout * 1000,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'X-App-Version': SYSTEM_ADMIN_CMS + '-' + envConfig.appVersion,
      },
    })
    mainInstance.value.interceptors.request.use(userRefreshRequestInterceptor, undefined, {
      runWhen: (requestConfig: AxiosRequestConfig): boolean =>
        !(requestConfig.url?.startsWith(AUTH_PATH_PREFIX) ?? false),
    })
    mainInstance.value.interceptors.response.use((response) => response, logoutUserResponseInterceptor)
  }

  return mainInstance.value
}

export { cmsClient }
