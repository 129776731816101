import { acceptHMRUpdate, defineStore } from 'pinia'
import { useNewsletterPromoFactory } from '@/model/cms/factory/NewsletterPromoFactory'
import { ref } from 'vue'
import type { NewsletterPromo } from '@/types/cms/NewsletterPromo'

const { createDefaultNewsletterPromo } = useNewsletterPromoFactory()

export const useNewsletterPromoOneStore = defineStore('cmsNewsletterPromoOneStore', () => {
  const newsletterPromo = ref(createDefaultNewsletterPromo())
  const newsletterPromoLoaded = ref(false)

  function setNewsletterPromo(newNewsletterPromo: NewsletterPromo) {
    newsletterPromo.value = newNewsletterPromo
  }

  function reset() {
    newsletterPromo.value = createDefaultNewsletterPromo()
    newsletterPromoLoaded.value = false
  }

  return {
    newsletterPromo,
    newsletterPromoLoaded,
    setNewsletterPromo,
    reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNewsletterPromoOneStore, import.meta.hot))
}
