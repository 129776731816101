import { fetchAuthorListByIds } from '@/services/api/cms/authorApi'
import type { IntegerId } from '@anzusystems/common-admin'
import { defineCached } from '@anzusystems/common-admin'
import type { AuthorKind, AuthorMinimal } from '@/types/cms/AuthorKind/AuthorKind'
import { useAuthorHelpers } from '@/views/cms/author/composables/authorHelpers'

const { getAuthorDisplayName } = useAuthorHelpers()

const mapFullToMinimal = (author: AuthorKind): AuthorMinimal => ({
  id: author.id,
  displayName: getAuthorDisplayName(author),
})

const mapIdToMinimal = (id: IntegerId): AuthorMinimal => {
  return { id: id, displayName: '' }
}

const { cache, toFetch, fetch, add, addManual, addManualMinimal, has, get, isLoaded } = defineCached<
  IntegerId,
  AuthorKind,
  AuthorMinimal
>(mapFullToMinimal, mapIdToMinimal, fetchAuthorListByIds)

export const useCachedAuthors = () => {
  return {
    addManualToCachedAuthors: addManual,
    addManualMinimalToCachedAuthors: addManualMinimal,
    addToCachedAuthors: add,
    fetchCachedAuthors: fetch,
    toFetchCachedAuthors: toFetch,
    cachedAuthors: cache,
    hasCachedAuthor: has,
    getCachedAuthor: get,
    isLoadedCachedAuthor: isLoaded,
  }
}
