<script lang="ts" setup>
import { ACurrentUserDropdown } from '@anzusystems/common-admin'
import { ROUTE } from '@/router/routes'
import { computed } from 'vue'
import { mapFullToMinimal } from '@/views/cms/user/composables/cachedUsers'
import type { User } from '@/types/cms/User'
import { useAuth } from '@/composables/auth/auth'

const { useCurrentUser } = useAuth()
const { currentUser } = useCurrentUser('cms')

const userMinimal = computed(() => {
  return mapFullToMinimal(currentUser.value as User)
})
</script>

<template>
  <div class="align-center flex-column justify-space-between px-1">
    <ACurrentUserDropdown
      v-if="currentUser"
      :current-user="userMinimal"
      :settings-route-name="ROUTE.SYSTEM.SETTINGS"
      :logout-route-name="ROUTE.SYSTEM.LOGOUT"
      class="my-2"
    />
  </div>
</template>
