import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ValueObjectOption } from '@anzusystems/common-admin'

export const ArticleStagePriority = {
  Normal: 'normal',
  High: 'high',
} as const

export type ArticleStagePriorityType = (typeof ArticleStagePriority)[keyof typeof ArticleStagePriority]
export const ArticleStagePriorityDefault = ArticleStagePriority.Normal

export function useArticleStagePriority() {
  const { t } = useI18n()

  const articleStagePriorityOptions = ref<ValueObjectOption<ArticleStagePriorityType>[]>([
    {
      value: ArticleStagePriority.Normal,
      title: t('cms.articleStage.priority.normal'),
      color: 'default',
    },
    {
      value: ArticleStagePriority.High,
      title: t('cms.articleStage.priority.high'),
      color: 'success',
    },
  ])

  const getArticleStagePriorityOption = (value: ArticleStagePriorityType) => {
    return articleStagePriorityOptions.value.find((item) => item.value === value)
  }

  return {
    articleStagePriorityOptions,
    getArticleStagePriorityOption,
  }
}
