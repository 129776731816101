import { useSiteGroupAllStore } from '@/stores/cms/siteGroupStore'
import { storeToRefs } from 'pinia'
import { fetchSiteGroupAll } from '@/services/api/cms/siteGroupApi'
import { type IntegerId, isNull, isUndefined } from '@anzusystems/common-admin'
import { computed } from 'vue'
import type { SiteGroup } from '@/types/cms/SiteGroup'

export function initSiteGroups() {
  const siteGroupAllStore = useSiteGroupAllStore()

  const loadSiteGroups = async () => {
    const res = await fetchSiteGroupAll()
    siteGroupAllStore.setSiteGroups(res)
    siteGroupAllStore.loaded = true
  }

  return {
    loadSiteGroups,
  }
}

export function useSiteGroups() {
  const siteGroupAllStore = useSiteGroupAllStore()
  const { siteGroups } = storeToRefs(siteGroupAllStore)

  function getSiteGroup(id: IntegerId | null | undefined) {
    if (isNull(id) || isUndefined(id)) return undefined
    return siteGroups.value.get(id)
  }

  const siteGroupsArray = computed<SiteGroup[]>(() => {
    return Array.from(siteGroups.value.values())
  })

  function findBySite(id: IntegerId | null | undefined) {
    if (isNull(id) || isUndefined(id)) return undefined
    return siteGroupsArray.value.find((item) => item.sites.includes(id))
  }

  return {
    siteGroups,
    siteGroupsArray,
    getSiteGroup,
    findBySite,
  }
}
