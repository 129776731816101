<script lang="ts" setup>
import type { IntegerId } from '@anzusystems/common-admin'
import { ACachedChip } from '@anzusystems/common-admin'
import { ROUTE } from '@/router/routes'
import { useCachedDesks } from '@/views/cms/desk/composables/cachedDesks'

withDefaults(
  defineProps<{
    id: null | IntegerId | undefined
  }>(),
  {}
)

const { getCachedDesk } = useCachedDesks()
</script>

<template>
  <ACachedChip
    :id="id"
    :get-cached-fn="getCachedDesk"
    :route="ROUTE.CMS.DESK.DETAIL"
    display-text-path="name"
  />
</template>
