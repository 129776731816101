import type { Rubric, RubricMinimal } from '@/types/cms/Rubric'
import { fetchRubricListByIds } from '@/services/api/cms/rubricApi'
import type { IntegerId } from '@anzusystems/common-admin'
import { defineCached } from '@anzusystems/common-admin'

const mapFullToMinimal = (rubric: Rubric): RubricMinimal => ({
  id: rubric.id,
  title: rubric.texts.title,
})

const mapIdToMinimal = (id: IntegerId): RubricMinimal => {
  return { id: id, title: '' }
}

const { cache, toFetch, fetch, add, addManual, addManualMinimal, has, get, isLoaded } = defineCached<
  IntegerId,
  Rubric,
  RubricMinimal
>(mapFullToMinimal, mapIdToMinimal, fetchRubricListByIds)

export const useCachedRubrics = () => {
  return {
    addManualToCachedRubrics: addManual,
    addManualMinimalToCachedRubrics: addManualMinimal,
    addToCachedRubrics: add,
    fetchCachedRubrics: fetch,
    toFetchCachedRubrics: toFetch,
    cachedRubrics: cache,
    hasCachedRubric: has,
    getCachedRubric: get,
    isLoadedCachedRubric: isLoaded,
  }
}
