import { ROUTE } from '@/router/routes'
import type { RouteParams, RouteRecordRaw } from 'vue-router'
import { stringToInt, useCollabHelpers } from '@anzusystems/common-admin'
import { CollabRoomJoinStrategy } from '@/types/Collab'
import { ACL } from '@/composables/auth/auth'

const { createCollabRoom } = useCollabHelpers()

export const cmsArticleRoutes: RouteRecordRaw[] = [
  {
    path: '/article',
    name: ROUTE.CMS.ARTICLE.LIST,
    component: () => import('@/views/cms/article/ArticleListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/article-empty',
    name: ROUTE.CMS.ARTICLE.EMPTY,
    component: () => import('@/views/cms/article/ArticleEmptyView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/article/:id(\\d+)/edit',
    name: ROUTE.CMS.ARTICLE.EDIT,
    component: () => import('@/views/cms/article/ArticleEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ARTICLE_READ, ACL.CMS_ARTICLE_UPDATE],
      layout: 'AppLayoutDrawer',
      collab: (params: RouteParams) => ({
        room: createCollabRoom(stringToInt(params.id), 'article', 'cms'),
        joinStrategy: CollabRoomJoinStrategy.Moderated,
        occupiedOrKickedRedirectToRoute: ROUTE.CMS.ARTICLE.DETAIL,
        editors: ['bodyEditor'],
      }),
    },
  },
  {
    path: '/article/:id(\\d+)',
    name: ROUTE.CMS.ARTICLE.DETAIL,
    component: () => import('@/views/cms/article/ArticleDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ARTICLE_READ],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/article/:docId/:version(\\d+)?/edit',
    name: ROUTE.CMS.ARTICLE.REDIRECT_EDIT,
    component: () => import('@/views/cms/article/ArticleDocIdVersionEditRedirectView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ARTICLE_READ, ACL.CMS_ARTICLE_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/article/:docId/:version(\\d+)?',
    name: ROUTE.CMS.ARTICLE.REDIRECT_DETAIL,
    component: () => import('@/views/cms/article/ArticleDocIdVersionDetailRedirectView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ARTICLE_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
