<script lang="ts" setup>
import type { IntegerId } from '@anzusystems/common-admin'
import { ACachedChip } from '@anzusystems/common-admin'
import { ROUTE } from '@/router/routes'
import { useCachedAuthors } from '@/views/cms/author/composables/cachedAuthors'

withDefaults(
  defineProps<{
    id: null | IntegerId | undefined
    textOnly?: boolean
  }>(),
  {
    textOnly: false,
  }
)

const { getCachedAuthor } = useCachedAuthors()
</script>

<template>
  <ACachedChip
    :id="id"
    :get-cached-fn="getCachedAuthor"
    :route="ROUTE.CMS.AUTHOR.DETAIL"
    :text-only="textOnly"
    display-text-path="displayName"
  />
</template>
