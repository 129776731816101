import { acceptHMRUpdate, defineStore } from 'pinia'
import { useSiteFactory } from '@/model/cms/factory/SiteFactory'
import type { Site } from '@/types/cms/Site'
import { ref } from 'vue'

export const useSiteOneStore = defineStore('cmsSiteOneStore', () => {
  const { createDefault } = useSiteFactory()

  const site = ref<Site>(createDefault())

  function reset() {
    site.value = createDefault()
  }

  return {
    site,
    reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSiteOneStore, import.meta.hot))
}
