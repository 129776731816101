import { fetchNewsletterListByIds } from '@/services/api/cms/newsletterApi'
import type { IntegerId } from '@anzusystems/common-admin'
import { defineCached } from '@anzusystems/common-admin'
import type { Newsletter, NewsletterMinimal } from '@/types/cms/Newsletter'

const mapFullToMinimal = (newsletter: Newsletter): NewsletterMinimal => ({
  id: newsletter.id,
  title: newsletter.texts.title,
})

const mapIdToMinimal = (id: IntegerId): NewsletterMinimal => {
  return { id: id, title: '' }
}

const { cache, toFetch, fetch, add, addManual, addManualMinimal, has, get, isLoaded } = defineCached<
  IntegerId,
  Newsletter,
  NewsletterMinimal
>(mapFullToMinimal, mapIdToMinimal, fetchNewsletterListByIds)

export const useCachedNewsletters = () => {
  return {
    addManualToCachedNewsletters: addManual,
    addManualMinimalToCachedNewsletters: addManualMinimal,
    addToCachedNewsletters: add,
    fetchCachedNewsletters: fetch,
    toFetchCachedNewsletters: toFetch,
    cachedNewsletters: cache,
    hasCachedNewsletter: has,
    getCachedNewsletter: get,
    isLoadedCachedNewsletter: isLoaded,
  }
}
