import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ValueObjectOption } from '@anzusystems/common-admin'

export const RubricStatus = {
  Hide: 'hide',
  Show: 'show',
} as const
export type RubricStatusType = (typeof RubricStatus)[keyof typeof RubricStatus]
export const RubricStatusDefault = RubricStatus.Show

export function useRubricStatus() {
  const { t } = useI18n()

  const rubricStatusOptions = ref<ValueObjectOption<RubricStatusType>[]>([
    {
      value: RubricStatus.Hide,
      title: t('cms.rubric.rubricStatus.hide'),
      color: 'warning',
    },
    {
      value: RubricStatus.Show,
      title: t('cms.rubric.rubricStatus.show'),
      color: 'success',
    },
  ])

  const getRubricStatusOption = (value: RubricStatusType) => {
    return rubricStatusOptions.value.find((item) => item.value === value)
  }

  return {
    rubricStatusOptions,
    getRubricStatusOption,
  }
}
