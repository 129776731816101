import { LanguageDefault } from '@/model/cms/valueObject/Language'
import type { SiteGroup } from '@/types/cms/SiteGroup'
import { dateTimeNow } from '@anzusystems/common-admin'

export function useSiteGroupFactory() {
  const createDefault = (): SiteGroup => {
    return {
      id: 0,
      title: '',
      newsroomSections: [],
      sites: [],
      damSettings: {
        audioSettings: {
          defaultSelectLicenceIds: [],
        },
        videoSettings: {
          defaultSelectLicenceIds: [],
        },
        imageSettings: {
          defaultSelectLicenceIds: [],
          defaultUploadLicenceId: null,
          authorSelectLicenceIds: [],
          authorUploadLicenceId: null,
          boxItemSelectLicenceIds: [],
          boxItemUploadLicenceId: null,
        },
      },
      settings: {
        lockAfterPercentage: 0.0,
      },
      language: LanguageDefault,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
      _resourceName: 'siteGroup',
      _system: 'cms',
    }
  }

  return {
    createDefault,
  }
}
