import { reactive } from 'vue'
import { makeFilterHelper } from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'
import { ENTITY } from '@/services/api/cms/rubricApi'

const makeFilter = makeFilterHelper(SYSTEM_CMS, ENTITY)

const filter = reactive({
  _elastic: {
    ...makeFilter({ exclude: true }),
  },
  id: {
    ...makeFilter({ name: 'id', variant: 'in' }),
  },
  text: {
    ...makeFilter({ name: 'text' }),
  },
  site: {
    ...makeFilter({ name: 'site', field: 'siteIds', default: [] }),
  },
  linkedList: {
    ...makeFilter({ name: 'linkedList', field: 'linkedListId' }),
  },
})

export function useRubricListFilter() {
  return filter
}

export function useRubricFilter() {
  return reactive({
    _elastic: {
      ...makeFilter({ exclude: true }),
    },
    id: {
      ...makeFilter({ name: 'id', variant: 'in' }),
    },
    text: {
      ...makeFilter({ name: 'text' }),
    },
    site: {
      ...makeFilter({ name: 'site', field: 'siteIds', default: [] }),
    },
    siteGroup: {
      ...makeFilter({ name: 'siteGroup', field: 'siteGroupIds', default: [] }),
    },
    desk: {
      ...makeFilter({ name: 'desk', field: 'deskIds', default: [] }),
    },
    linkedList: {
      ...makeFilter({ name: 'linkedList', field: 'linkedListId' }),
    },
  })
}
