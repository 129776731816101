import type { NewsletterGeneratorSettings } from '@/types/cms/Newsletter'

export function useNewsletterGeneratorSettingsFactory() {
  const createDefaultNewsletterGeneratorSettings = (): NewsletterGeneratorSettings => {
    return {
      templateId: 0,
      dataSourceUrl: '',
      useEmailSubjectFromSource: null,
      dataSourceUrlDynamicParam: null,
      articleMinLimit: null,
    }
  }

  return {
    createDefaultNewsletterGeneratorSettings,
  }
}
