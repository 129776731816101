import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import { apiAnyRequest, type FilterBag, type IntegerId, type Pagination } from '@anzusystems/common-admin'
import { apiCreateOne, apiDeleteOne, apiFetchList, apiFetchOne, apiUpdateOne } from '@anzusystems/common-admin'
import type { ArticleStage, ArticleStageCompleteDto, ArticleStageCreateDto } from '@/types/cms/ArticleStage'

const END_POINT = '/adm/article-stages'
export const ENTITY = 'articleStage'

export const fetchArticleStageList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<ArticleStage[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchArticleStage = (id: IntegerId) =>
  apiFetchOne<ArticleStage>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createArticleStage = (dto: ArticleStageCreateDto) => {
  return apiCreateOne<ArticleStageCreateDto, ArticleStage>(
    cmsClient,
    dto,
    END_POINT,
    {},
    SYSTEM_CMS,
    ENTITY
  )
}

export const completeArticleStage = (dto: ArticleStageCompleteDto) => {
  return apiAnyRequest<ArticleStageCompleteDto, ArticleStage>(
    cmsClient,
    'PATCH',
    END_POINT + '/complete',
    {},
    dto,
    SYSTEM_CMS,
    ENTITY
  )
}

export const updateArticleStage = (id: IntegerId, data: ArticleStage) =>
  apiUpdateOne<ArticleStage>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const deleteArticleStage = (id: IntegerId) =>
  apiDeleteOne<ArticleStage>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)
