import type { Editor } from '@tiptap/core'
import { ref } from 'vue'
import { isString, isUndefined } from '@anzusystems/common-admin'
import { NodeName } from '@/components/anzutap/nodes/nodes'

export interface AnchorRouteInfo {
  url: string
  type: 'active' | 'suggested' | 'reserved'
}

export const ANCHOR_PREFIX = 'pp-'

export const ANCHOR_MAX_LENGTH_WO_PREFIX = 15

const dialog = ref(false)
const anchorName = ref('')
const anchorRouteInfo = ref<undefined | AnchorRouteInfo>(undefined)

export function useAnchorRouteInfo() {
  return {
    anchorRouteInfo,
  }
}

export function useAnchor(editor: Editor | undefined) {
  const updateAnchorFromState = () => {
    if (isUndefined(editor)) return
    anchorName.value = anchorName.value.trim()
    if (anchorName.value.length === 0) return

    if (!anchorName.value.startsWith(ANCHOR_PREFIX)) {
      anchorName.value = ANCHOR_PREFIX + anchorName.value
    }

    editor.chain().focus().setAnchor(anchorName.value).run()
  }

  const openDialog = () => {
    if (isUndefined(editor)) return
    getAnchorData()
    dialog.value = true
  }

  const getAnchorData = () => {
    if (isUndefined(editor)) return
    anchorName.value = ''
    if (editor.isActive(NodeName.Heading)) {
      const text = editor.getAttributes(NodeName.Heading).anchor
      anchorName.value = isString(text) ? text : ''
    } else if (editor.isActive(NodeName.Paragraph)) {
      const text = editor.getAttributes(NodeName.Paragraph).anchor
      anchorName.value = isString(text) ? text : ''
    }
    if (anchorName.value.startsWith(ANCHOR_PREFIX)) {
      anchorName.value = anchorName.value.substring(ANCHOR_PREFIX.length)
    }
  }

  const removeAnchor = () => {
    if (isUndefined(editor)) return
    editor.chain().focus().unsetAnchor().run()
  }

  return {
    anchorName,
    dialog,
    updateAnchorFromState,
    removeAnchor,
    openDialog,
  }
}
