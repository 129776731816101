import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ValueObjectOption } from '@anzusystems/common-admin'

export const NewsletterPromoIcon = {
  Info: 'info',
  Warning: 'warning',
  None: 'none',
} as const
export const NewsletterPromoIconDefault = NewsletterPromoIcon.None
export type NewsletterPromoIconType = (typeof NewsletterPromoIcon)[keyof typeof NewsletterPromoIcon]

export function useNewsletterPromoIcon() {
  const { t } = useI18n()

  const newsletterPromoIconOptions = ref<ValueObjectOption<NewsletterPromoIconType>[]>([
    {
      value: NewsletterPromoIcon.Info,
      title: t('cms.newsletterPromo.promoIcon.info'),
    },
    {
      value: NewsletterPromoIcon.Warning,
      title: t('cms.newsletterPromo.promoIcon.warning'),
    },
    {
      value: NewsletterPromoIcon.None,
      title: t('cms.newsletterPromo.promoIcon.none'),
    },
  ])

  const getNewsletterPromoIconOptions = (value: NewsletterPromoIconType) => {
    return newsletterPromoIconOptions.value.find((item) => item.value === value)
  }

  return {
    newsletterPromoIconOptions,
    getNewsletterPromoIconOptions,
  }
}
