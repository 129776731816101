import { SYSTEM_CMS } from '@/model/systems'
import type { NewsletterSchedule } from '@/types/cms/NewsletterSchedule'

export function useNewsletterScheduleFactory() {
  const createDefaultNewsletterSchedule = (): NewsletterSchedule => {
    return {
      id: 0,
      newsletter: 0,
      dayOfWeek: null,
      dayOfMonth: null,
      hour: null,
      minute: null,
      enabled: true,
      _resourceName: 'newsletterSchedule',
      _system: SYSTEM_CMS,
    }
  }

  return {
    createDefaultNewsletterSchedule,
  }
}
