import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import { apiCreateOne, apiDeleteOne, apiFetchList, apiFetchOne, apiUpdateOne } from '@anzusystems/common-admin'
import type { MinuteTopic } from '@/types/cms/MinuteTopic'

const END_POINT = '/adm/v1/minute-topic'
export const ENTITY = 'minuteTopic'

export const fetchMinuteTopicList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<MinuteTopic[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchMinuteTopic = (id: IntegerId) =>
  apiFetchOne<MinuteTopic>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createMinuteTopic = (data: MinuteTopic) =>
  apiCreateOne<MinuteTopic>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updateMinuteTopic = (id: IntegerId, data: MinuteTopic) =>
  apiUpdateOne<MinuteTopic>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const deleteMinuteTopic = (id: IntegerId) =>
  apiDeleteOne<MinuteTopic>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)
