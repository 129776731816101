import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsPromoLinkRoutes: RouteRecordRaw[] = [
  {
    path: '/promo-link',
    name: ROUTE.CMS.PROMO_LINK.LIST,
    component: () => import('@/views/cms/promoLink/PromoLinkListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/promo-link/create',
    name: ROUTE.CMS.PROMO_LINK.CREATE,
    component: () => import('@/views/cms/promoLink/PromoLinkCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PROMO_LINK_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/promo-link/:id(\\d+)/edit',
    name: ROUTE.CMS.PROMO_LINK.EDIT,
    component: () => import('@/views/cms/promoLink/PromoLinkEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PROMO_LINK_READ, ACL.CMS_PROMO_LINK_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/promo-link/:id(\\d+)',
    name: ROUTE.CMS.PROMO_LINK.DETAIL,
    component: () => import('@/views/cms/promoLink/PromoLinkDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PROMO_LINK_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
