import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import {
  apiCreateOne,
  apiDeleteOne,
  apiFetchByIds,
  apiFetchList,
  apiFetchListBatch,
  apiFetchOne,
  apiUpdateOne,
  type FilterBag,
  type IntegerId,
  type Pagination,
} from '@anzusystems/common-admin'
import type { SiteGroup } from '@/types/cms/SiteGroup'

const END_POINT = '/adm/v1/site-group'
export const ENTITY = 'siteGroup'

export const fetchSiteGroupAll = () =>
  apiFetchListBatch<SiteGroup[]>(cmsClient, END_POINT, {}, 'id', true, {}, 25, SYSTEM_CMS, ENTITY)

export const fetchSiteGroupListByIds = (ids: IntegerId[]) =>
  apiFetchByIds<SiteGroup[]>(cmsClient, ids, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const fetchSiteGroupList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<SiteGroup[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchSiteGroup = (id: IntegerId) =>
  apiFetchOne<SiteGroup>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createSiteGroup = (data: SiteGroup) =>
  apiCreateOne<SiteGroup>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updateSiteGroup = (id: IntegerId, data: SiteGroup) =>
  apiUpdateOne<SiteGroup>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const deleteSiteGroup = (id: IntegerId) =>
  apiDeleteOne<SiteGroup>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)
