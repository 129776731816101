import type { FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import {
  apiCreateOne,
  apiDeleteOne,
  apiFetchByIds,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
} from '@anzusystems/common-admin'
import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { DesignSettings } from '@/types/cms/DesignSettings'

const END_POINT = '/adm/v1/design-settings'
export const ENTITY = 'designSettings'

export const fetchDesignSettingsList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<DesignSettings[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchDesignSettingsListByIds = (ids: IntegerId[]) =>
  apiFetchByIds<DesignSettings[]>(cmsClient, ids, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const fetchDesignSettings = (id: IntegerId) =>
  apiFetchOne<DesignSettings>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createDesignSettings = (data: DesignSettings) =>
  apiCreateOne<DesignSettings>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updateDesignSettings = (id: IntegerId, data: DesignSettings) =>
  apiUpdateOne<DesignSettings>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const deleteDesignSettings = (id: IntegerId) =>
  apiDeleteOne<DesignSettings>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)
