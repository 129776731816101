import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ValueObjectOption } from '@anzusystems/common-admin'

export const LinkVariant = {
  Link: 'link',
  Email: 'email',
  Anchor: 'anchor',
} as const
export const LinkVariantDefault = LinkVariant.Link
export type LinkVariantType = (typeof LinkVariant)[keyof typeof LinkVariant]

export function useLinkVariant() {
  const { t } = useI18n()

  const linkVariantOptions = ref<ValueObjectOption<LinkVariantType>[]>([
    {
      value: LinkVariant.Link,
      title: t('editor.mark.link.variant.link'),
    },
    {
      value: LinkVariant.Email,
      title: t('editor.mark.link.variant.email'),
    },
    {
      value: LinkVariant.Anchor,
      title: t('editor.mark.link.variant.anchor'),
    },
  ])

  const getLinkVariantOption = (value: LinkVariantType) => {
    return linkVariantOptions.value.find((item) => item.value === value)
  }

  return {
    linkVariantOptions,
    getLinkVariantOption,
  }
}
