import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsDeskRoutes: RouteRecordRaw[] = [
  {
    path: '/desk',
    name: ROUTE.CMS.DESK.LIST,
    component: () => import('@/views/cms/desk/DeskListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/desk/create',
    name: ROUTE.CMS.DESK.CREATE,
    component: () => import('@/views/cms/desk/DeskCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_DESK_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/desk/:id(\\d+)/edit',
    name: ROUTE.CMS.DESK.EDIT,
    component: () => import('@/views/cms/desk/DeskEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_DESK_READ, ACL.CMS_DESK_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/desk/:id(\\d+)',
    name: ROUTE.CMS.DESK.DETAIL,
    component: () => import('@/views/cms/desk/DeskDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_DESK_READ],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/desk/:id(\\d+)/dashboard',
    name: ROUTE.CMS.DESK.DASHBOARD,
    component: () => import('@/views/cms/desk/DeskDashboardView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_DESK_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
