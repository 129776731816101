<script setup lang="ts">
import { ArticleStandardLockType } from '@/model/cms/valueObject/ArticleStandardLockType'
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import { isArticleKindStandard } from '@/types/cms/ArticleKind/ArticleKindStandard'
import type { CollabRoom } from '@/types/Collab'
import { ARow, useI18n } from '@anzusystems/common-admin'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const { article } = storeToRefs(useArticleKindOneStore())

const { t } = useI18n()

const label = computed(() => {
  return isArticleKindStandard(article.value) &&
    article.value.attributesStandard.lockType === ArticleStandardLockType.Locked
    ? t('cms.articleKind.articleStandardLockType.locked')
    : t('cms.articleKind.articleStandardLockType.free')
})
</script>

<template>
  <!-- todo: collab + auto add/remove contentLock embed on change + when embed is removed, auto toggle this field -->
  <ARow v-if="isArticleKindStandard(article)">
    <VSwitch
      v-model="article.attributesStandard.lockType"
      :disabled="readonly || !isModerator"
      :label="label"
      :true-value="ArticleStandardLockType.Locked"
      :false-value="ArticleStandardLockType.Free"
    />
  </ARow>
</template>
