import { ref } from 'vue'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { ROUTE } from '@/router/routes'
import { checkRequirements } from '@/router/checkRequirements'
import {
  isAnzuApiForbiddenError,
  isAnzuFatalError,
  isDefined,
  isUndefined,
  useAlerts,
  useCollabCurrentUserId,
  useCollabInit,
  useLoginStatus,
} from '@anzusystems/common-admin'
import { initCustomFormConfig } from '@/views/common/customForm/composables/customFormConfig'
import { initSiteGroups } from '@/views/cms/siteGroup/composables/siteGroupsAll'
import { damClient } from '@/services/api/clients/damClient'
import { useCookiesState } from '@/composables/cookiesState'
import axios from 'axios'
import { envConfig } from '@/services/EnvConfigService'
import { useAuth } from '@/composables/auth/auth'
import { cmsClient } from '@/services/api/clients/cmsClient'
import { useAppNotificationConnect } from '@/composables/appNotification/appNotification'
import { contentHubClient } from '@/services/api/clients/contentHubClient'
import { appNotificationInit } from '@/composables/appNotification/appNotificationInit'

const isAppInitialized = ref<boolean>(false)

const beforeInitializationIsResolved = async (next: NavigationGuardNext) => {
  const { showErrorsDefault, showError } = useAlerts()
  const { setCollabUserCurrentId } = useCollabCurrentUserId()
  const { useCurrentUser } = useAuth()
  const { currentUser } = useCurrentUser('cms')
  setCollabUserCurrentId(currentUser.value?.id ?? null)

  const { initCollab } = useCollabInit()
  const { loadSiteGroups } = initSiteGroups()
  initCollab()

  const appNotificationConnect = useAppNotificationConnect()
  if (envConfig.notification.enabled) {
    try {
      appNotificationConnect(currentUser.value!.id!)
      appNotificationInit()
    } catch (error) {
      console.error(error)
    }
  }

  const { fetchCurrentUser: fetchCurrentUserDam } = useCurrentUser('dam')

  try {
    await Promise.allSettled([fetchCurrentUserDam(damClient), initCustomFormConfig(), loadSiteGroups()])
  } catch (e) {
    if (
      (isAnzuFatalError(e) || isAnzuApiForbiddenError(e)) &&
      axios.isAxiosError(e.cause) &&
      e.cause.config?.baseURL === envConfig.dam.apiUrl
    ) {
      setTimeout(() => {
        showError('Unable to load DAM user info, features will not work correctly.')
      }, 1000)
      return true
    }
    showErrorsDefault(e)
    next({ name: ROUTE.SYSTEM.UNAUTHORIZED })
    return false
  }

  return true
}

export async function createAppInitialize(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const { isStatusNotDefined, isStatusSsoCommunicationFailure, isStatusInternalErrorFailure, isStatusUnauthorized } =
    useLoginStatus(to)
  const { useCurrentUser } = useAuth()
  const { fetchCurrentUser: fetchCurrentUserCms, currentUser: currentUserCms } = useCurrentUser('cms')
  const { fetchCurrentUser: fetchCurrentUserContentHub } = useCurrentUser('contentHub')

  try {
    await fetchCurrentUserCms(cmsClient)
  } catch (error) {
    next({ name: ROUTE.SYSTEM.LOGIN })
    return
  }

  try {
    await fetchCurrentUserContentHub(contentHubClient)
  } catch (error) {
    // do nothing
  }

  if (
    (isStatusNotDefined() || isStatusSsoCommunicationFailure() || isStatusInternalErrorFailure()) &&
    isUndefined(currentUserCms.value)
  ) {
    next({ name: ROUTE.SYSTEM.LOGIN })
  } else if (isStatusUnauthorized()) {
    next({ name: ROUTE.SYSTEM.UNAUTHORIZED })
  } else if (to.path === '/') {
    const beforeOk = await beforeInitializationIsResolved(next)
    if (!beforeOk) return
    isAppInitialized.value = true
    next({ name: ROUTE.DEFAULT })
  } else {
    const beforeOk = await beforeInitializationIsResolved(next)
    if (!beforeOk) return
    isAppInitialized.value = true
    await checkRequirements(to, from, next)
  }
}

export function useAppInitialize() {
  const { getRefreshTokenExistsName, getJwtPayloadName } = useCookiesState()

  const hasAppAuthCookie = () => {
    const refreshTokenExistsCookie = getRefreshTokenExistsName()
    const jwtPayloadCookie = getJwtPayloadName()

    return isDefined(refreshTokenExistsCookie) || isDefined(jwtPayloadCookie)
  }
  return {
    isAppInitialized,
    hasAppAuthCookie,
  }
}
