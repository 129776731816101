import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import {
  apiCreateOne,
  apiDeleteOne,
  apiFetchByIds,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
} from '@anzusystems/common-admin'
import type { Rubric } from '@/types/cms/Rubric'

const END_POINT = '/adm/v1/rubric'
export const ENTITY = 'rubric'

export const fetchRubricListByIds = (ids: IntegerId[]) =>
  apiFetchByIds<Rubric[]>(cmsClient, ids, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const fetchRubricList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<Rubric[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchRubric = (id: IntegerId) =>
  apiFetchOne<Rubric>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createRubric = (data: Rubric) => apiCreateOne<Rubric>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updateRubric = (id: IntegerId, data: Rubric) =>
  apiUpdateOne<Rubric>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const deleteRubric = (id: IntegerId) =>
  apiDeleteOne<Rubric>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)
