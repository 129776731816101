<script setup lang="ts">
import { useNewsletterScheduleStore } from '@/stores/cms/newsletterScheduleStore'
import type { Newsletter } from '@/types/cms/Newsletter'
import type { NewsletterSchedule } from '@/types/cms/NewsletterSchedule'
import NewsletterScheduleDialog from '@/views/cms/newsletterSchedule/components/NewsletterScheduleDialog.vue'
import NewsletterScheduleItem from '@/views/cms/newsletterSchedule/components/NewsletterScheduleItem.vue'
import NewsletterScheduleRow from '@/views/cms/newsletterSchedule/components/NewsletterScheduleRow.vue'
import { useNewsletterScheduleListActions } from '@/views/cms/newsletterSchedule/composables/newsletterScheduleActions'
import { storeToRefs } from 'pinia'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = withDefaults(
  defineProps<{
    newsletter: Newsletter
    isEdit?: boolean
  }>(),
  {
    isEdit: true,
  }
)

const newsletterScheduleStore = useNewsletterScheduleStore()
const { newsletterScheduleList } = storeToRefs(newsletterScheduleStore)

const deleteDialog = ref(false)
const newsletterScheduleToRemove = ref<number | null>(null)
const newsletterScheduleItemsRef = ref<{
  [key: number]: InstanceType<typeof NewsletterScheduleItem> | null
}>({})

const {
  openDialog,
  deleteNewsletterScheduleById,
  fetchNewsletterSchedules,
  newsletterSchedulesLoading,
} = useNewsletterScheduleListActions()

const { t } = useI18n()
const onEditClick = (newsletterSchedule: NewsletterSchedule) => {
  openDialog(newsletterSchedule, props.newsletter)
}

const onDeleteConfirm = async (id: number) => {
  await deleteNewsletterScheduleById(id)
  newsletterScheduleToRemove.value = null
  deleteDialog.value = false
}

onMounted(() => {
  newsletterScheduleStore.reset()
  fetchNewsletterSchedules(props.newsletter.id)
})
</script>

<template>
  <NewsletterScheduleDialog :newsletter="newsletter" />
  <div>
    <h4 class="font-weight-bold text-subtitle-2">
      Plánovače
    </h4>
    <VBtn
      v-if="isEdit"
      size="small"
      prepend-icon="mdi-plus"
      @click.stop="openDialog(undefined, newsletter)"
    >
      {{ t('cms.newsletter.model.newsletterSchedule.addNew') }}
    </VBtn>
    <div class="anzu-comments mt-2">
      <div
        v-if="newsletterSchedulesLoading"
        class="w-100 d-flex align-center justify-center"
      >
        <VProgressCircular indeterminate />
      </div>
      <div v-else>
        <div v-if="isEdit">
          <table>
            <thead>
              <tr>
                <th>Deň v týždni</th>
                <th>Deň v mesiaci</th>
                <th>Hodina</th>
                <th>Minuta</th>
                <th>Aktívny</th>
              </tr>
            </thead>
            <tbody>
              <NewsletterScheduleItem
                v-for="[key, item] in [...newsletterScheduleList.entries()].reverse()"
                :key="key"
                :ref="
                  (el: any) => {
                    newsletterScheduleItemsRef[key] = el
                  }
                "
                :item="item"
                @on-delete-click="onDeleteConfirm(key)"
                @on-edit-click="onEditClick"
              />
            </tbody>
          </table>
        </div>
        <div v-else>
          <table>
            <thead>
              <tr>
                <th>Deň v týždni</th>
                <th>Deň v mesiaci</th>
                <th>Hodina</th>
                <th>Minuta</th>
                <th>Aktívny</th>
              </tr>
            </thead>
            <tbody>
              <NewsletterScheduleRow
                v-for="[key, item] in [...newsletterScheduleList.entries()].reverse()"
                :key="key"
                :newsletter-schedule="item"
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
