import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import {
  apiAnyRequest,
  apiCreateOne,
  apiDeleteOne,
  apiFetchByIds,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
} from '@anzusystems/common-admin'
import type { MultiPageUpdateKeywordsDto, Page, PageDuplicateDto } from '@/types/cms/Page'

const END_POINT = '/adm/v1/page'
export const ENTITY = 'page'

export const fetchPageListByIds = (ids: IntegerId[]) =>
  apiFetchByIds<Page[]>(cmsClient, ids, END_POINT + '/search', {}, SYSTEM_CMS, ENTITY, undefined, true)

export const fetchPageList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<Page[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchPage = (id: IntegerId) => apiFetchOne<Page>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createPage = (data: Page) => apiCreateOne<Page>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updatePage = (id: IntegerId, data: Page) =>
  apiUpdateOne<Page>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const duplicatePage = (duplicateDto: PageDuplicateDto) =>
  apiAnyRequest<PageDuplicateDto, Page>(
    cmsClient,
    'PATCH',
    END_POINT + '/duplicate/:id',
    { id: duplicateDto.page as IntegerId },
    duplicateDto,
    SYSTEM_CMS,
    ENTITY
  )

export const multiPageUpdateKeywordsPage = (multiUpdateDto: MultiPageUpdateKeywordsDto) =>
  apiAnyRequest<MultiPageUpdateKeywordsDto, void>(
    cmsClient,
    'PATCH',
    END_POINT + '/multi-update-keywords',
    {},
    multiUpdateDto,
    SYSTEM_CMS,
    ENTITY
  )

export const deletePage = (id: IntegerId) =>
  apiDeleteOne<Page>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)
