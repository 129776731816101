import App from '@/App.vue'
import AppLayoutDrawer from '@/layouts/AppLayoutDrawer.vue'
import AppLayoutFullscreen from '@/layouts/AppLayoutFullscreen.vue'
import AppLayoutLoader from '@/layouts/AppLayoutLoader.vue'
import { vuetify } from '@/plugins/vuetify'
import { router } from '@/router'
import { cmsClient } from '@/services/api/clients/cmsClient'
import { damClient } from '@/services/api/clients/damClient'
import { envConfig, loadEnvConfig } from '@/services/EnvConfigService'
import { initErrorHandler } from '@/services/ErrorHandlerApiService'
import type { LanguageCode } from '@anzusystems/common-admin'
import { AnzuSystemsCommonAdmin, i18n, loadCommonFonts } from '@anzusystems/common-admin'
import '@anzusystems/common-admin/styles'
import { createPinia } from 'pinia'
import { createApp } from 'vue'

export const DEFAULT_LANGUAGE: LanguageCode = 'sk'
export const AVAILABLE_LANGUAGES: Array<LanguageCode> = ['sk', 'en']

loadCommonFonts()

loadEnvConfig(() => {
  const app = createApp(App)
    .use(i18n)
    .use(createPinia())
    .use(AnzuSystemsCommonAdmin, {
      languages: {
        available: AVAILABLE_LANGUAGES,
        default: DEFAULT_LANGUAGE,
      },
      collab: {
        enabled: envConfig.collab.enabled,
        socketUrl: envConfig.collab.socketUrl,
      },
      coreDam: {
        configs: {
          default: {
            damClient: damClient,
          },
        },
        apiTimeout: 30,
        uploadStatusFallback: false,
        notification: {
          enabled: envConfig.dam.notification.enabled,
          webSocketUrl: envConfig.dam.notification.webSocketUrl,
        },
      },
      image: {
        configs: {
          default: {
            imageClient: cmsClient,
            previewDomain: envConfig.dam.imageUrl,
            width: 500,
            height: 281,
          },
        },
      },
    })
    .use(vuetify)
    .use(router)
    .component('AppLayoutLoader', AppLayoutLoader)
    .component('AppLayoutDrawer', AppLayoutDrawer)
    .component('AppLayoutFullscreen', AppLayoutFullscreen)
  initErrorHandler(app)
  app.mount('#app')
})
