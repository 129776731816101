import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import {
  apiCreateOne,
  apiFetchList,
  apiUpdateOne,
  type FilterBag,
  type IntegerId,
  type Pagination,
} from '@anzusystems/common-admin'
import type { UserAdminConfig } from '@/types/cms/UserAdminConfig'

const END_POINT = '/adm/v1/user-admin-config'
export const ENTITY = 'userAdminConfig'

export const fetchUserAdminConfigList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<UserAdminConfig[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const createUserAdminConfig = (data: UserAdminConfig) =>
  apiCreateOne<UserAdminConfig>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updateUserAdminConfig = (id: IntegerId, data: UserAdminConfig) =>
  apiUpdateOne<UserAdminConfig>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)
