import useVuelidate from '@vuelidate/core'
import type { Ref } from 'vue'
import { useValidate } from '@anzusystems/common-admin'
import type { Newsletter } from '@/types/cms/Newsletter'

export function useNewsletterValidation(newsletter: Ref<Newsletter>) {
  const { maxLength, minLength, required, minValue } = useValidate()

  const rules = {
    newsletter: {
      texts: {
        title: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
        titlePublic: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
      },
      mailerId: {
        required,
      },
      site: {
        required,
        minValue: minValue(1),
      },
      siteGroup: {
        required,
        minValue: minValue(1),
      },
      rubric: {
        required,
        minValue: minValue(1),
      },
    },
  }
  const v$ = useVuelidate(rules, { newsletter })

  return {
    v$,
  }
}
