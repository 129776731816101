<script lang="ts" setup>
import { useRubricSelectActions } from '@/views/cms/rubric/composables/rubricActions'
import type { Filter, IntegerId } from '@anzusystems/common-admin'
import { AFilterRemoteAutocomplete, cloneDeep, isArray, isNull } from '@anzusystems/common-admin'
import { computed, ref, watch } from 'vue'
import { useRubricFilter } from '@/model/cms/filter/RubricFilter'

const props = withDefaults(
  defineProps<{
    modelValue: Filter
    siteId?: IntegerId | IntegerId[] | null
  }>(),
  {
    siteId: () => [],
  }
)
const emit = defineEmits<{
  (e: 'update:modelValue', data: Filter): void
}>()
const modelValueComputed = computed({
  get() {
    return props.modelValue
  },
  set(newValue: Filter) {
    emit('update:modelValue', cloneDeep(newValue))
  },
})

const forceRerender = ref(0)

const { fetchItems, fetchItemsByIds } = useRubricSelectActions()

const innerFilter = useRubricFilter()

const siteModel = computed(() => {
  if (isNull(props.siteId)) return []
  if (!isArray(props.siteId)) return [props.siteId]
  return props.siteId
})

watch(
  siteModel,
  (newSiteModel: IntegerId[]) => {
    innerFilter.site.model = newSiteModel
    forceRerender.value++
  },
  { immediate: true }
)
</script>

<template>
  <AFilterRemoteAutocomplete
    :key="forceRerender"
    v-model="modelValueComputed"
    :fetch-items="fetchItems"
    :fetch-items-by-ids="fetchItemsByIds"
    :inner-filter="innerFilter"
    :filter-sort-by="null"
    filter-by-field="text"
  />
</template>
