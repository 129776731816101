import { useCookies } from '@vueuse/integrations/useCookies'
import { envConfig } from '@/services/EnvConfigService'
import { isUndefined } from '@anzusystems/common-admin'
import { ref } from 'vue'

let cookies: undefined | ReturnType<typeof useCookies> = undefined
const cookieStateInitialized = ref(false)

export function useCookiesState() {
  const initCookieState = () => {
    cookies = useCookies([envConfig.cookies.refreshTokenExistsName, envConfig.cookies.jwtPayloadName])
    cookieStateInitialized.value = true
  }

  const getRefreshTokenExistsName = () => {
    if (isUndefined(cookies)) throw new Error("Can't use useCookiesState before initCookiesState")

    return cookies.get<string | undefined>(envConfig.cookies.refreshTokenExistsName)
  }

  const getJwtPayloadName = () => {
    if (isUndefined(cookies)) throw new Error("Can't use useCookiesState before initCookiesState")

    return cookies.get<string | undefined>(envConfig.cookies.jwtPayloadName)
  }

  return {
    initCookieState,
    cookieStateInitialized,
    getRefreshTokenExistsName,
    getJwtPayloadName,
  }
}
