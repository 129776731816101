<script setup lang="ts">
import { ARow } from '@anzusystems/common-admin'
import ArticlePromoLinks from '@/views/cms/article/components/ArticlePromoLinks.vue'
import { useI18n } from 'vue-i18n'
import type { CollabRoom } from '@/types/Collab'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const { t } = useI18n()
</script>

<template>
  <ARow>
    <ArticlePromoLinks />
  </ARow>
  <ABtnTertiary
    v-if="!readonly"
    class="d-block ml-auto mt-6 mb-2"
  >
    {{ t('cms.articleKind.action.generate') }}
  </ABtnTertiary>
</template>
