import type { FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import {
  apiCreateOne,
  apiDeleteOne,
  apiFetchByIds,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
} from '@anzusystems/common-admin'
import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { LayoutTemplate } from '@/types/cms/LayoutTemplate'

const END_POINT = '/adm/v1/layout-template'
export const ENTITY = 'layoutTemplate'

export const fetchLayoutTemplateList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<LayoutTemplate[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchLayoutTemplateListByIds = (ids: IntegerId[]) =>
  apiFetchByIds<LayoutTemplate[]>(cmsClient, ids, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const fetchLayoutTemplate = (id: IntegerId) =>
  apiFetchOne<LayoutTemplate>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createLayoutTemplate = (data: LayoutTemplate) =>
  apiCreateOne<LayoutTemplate>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updateLayoutTemplate = (id: IntegerId, data: LayoutTemplate) =>
  apiUpdateOne<LayoutTemplate>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const deleteLayoutTemplate = (id: IntegerId) =>
  apiDeleteOne<LayoutTemplate>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)
