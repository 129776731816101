import type {
  AnzuUserAndTimeTrackingAware,
  CustomDataAware,
  DocId,
  IntegerId,
  IntegerIdNullable,
} from '@anzusystems/common-admin'
import type { EmbedImageAware } from '@/components/anzutap/types/EmbedImage'
import type { EmbedRelatedAware } from '@/components/anzutap/types/EmbedRelated'
import type { EmbedCustomAware } from '@/components/anzutap/types/EmbedCustom'
import type { EmbedGalleryAware } from '@/components/anzutap/types/EmbedGallery'
import type { EmbedMinuteAware } from '@/components/anzutap/types/EmbedMinute'
import type { EmbedCrossBoxAware } from '@/components/anzutap/types/EmbedCrossBox'
import type { EmbedFaqAware } from '@/components/anzutap/types/EmbedFaq'
import type { EmbedExternalAware } from '@/components/anzutap/types/EmbedExternal'
import type { EmbedPollAware } from '@/components/anzutap/types/EmbedPoll'
import type { EmbedQuizAware } from '@/components/anzutap/types/EmbedQuiz'
import type { EmbedReviewAware } from '@/components/anzutap/types/EmbedReview'
import type { EmbedTimelineAware } from '@/components/anzutap/types/EmbedTimeline'
import type { EmbedWeatherAware } from '@/components/anzutap/types/EmbedWeather'
import type { EmbedVideoAware } from '@/components/anzutap/types/EmbedVideo'
import type { EmbedAudioAware } from '@/components/anzutap/types/EmbedAudio'
import type { EmbedExternalImageAware } from '@/components/anzutap/types/EmbedExternalImage'
import type { EmbedExternalImageInlineAware } from '@/components/anzutap/types/EmbedExternalImageInline'
import type { EmbedImageInlineAware } from '@/components/anzutap/types/EmbedImageInline'

export type Embed =
  | EmbedCrossBoxAware
  | EmbedCustomAware
  | EmbedFaqAware
  | EmbedGalleryAware
  | EmbedImageAware
  | EmbedImageInlineAware
  | EmbedExternalAware
  | EmbedMinuteAware
  | EmbedPollAware
  | EmbedQuizAware
  | EmbedRelatedAware
  | EmbedReviewAware
  | EmbedTimelineAware
  | EmbedWeatherAware
  | EmbedVideoAware
  | EmbedAudioAware
  | EmbedExternalImageAware
  | EmbedExternalImageInlineAware

export const EmbedEntity = {
  Article: 'article',
  CrossBox: 'crossBox',
  ContentItemKindWysiwyg: 'contentItemKindWysiwyg',
  Person: 'person',
  Organization: 'organization',
} as const

export type EmbedEntityType = (typeof EmbedEntity)[keyof typeof EmbedEntity]
export const EmbedEntityDefault = EmbedEntity.Article

export interface EmbedEntityIdentity {
  type: EmbedEntityType
  id: IntegerIdNullable
}

interface EmbedBase extends AnzuUserAndTimeTrackingAware, Partial<CustomDataAware> {
  id: DocId
  discriminator: string
  _resourceName: string
  _system: string
}

type EmbedEntityKey = EmbedEntityType

type EmbedEntity = {
  [key in EmbedEntityKey]?: IntegerId | DocId | null
}

export interface EmbedAware extends EmbedBase, EmbedEntity {}
