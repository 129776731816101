import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { ContentItemKindFaq } from '@/types/cms/ContentItemKind/ContentItemKindFaq'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindFaqFactory() {
  const create = (): ContentItemKindFaq => {
    return {
      ...createAbstractContentItemKind(),
      faq: null,
      discriminator: ContentItemDiscriminator.Faq,
      _resourceName: 'contentItemKindFaq',
    }
  }

  return {
    create,
  }
}
