import ListView from '@/views/cms/newsletter/NewsletterListView.vue'
import EditView from '@/views/cms/newsletter/NewsletterEditView.vue'
import CreateView from '@/views/cms/newsletter/NewsletterCreateView.vue'
import DetailView from '@/views/cms/newsletter/NewsletterDetailView.vue'
import { ROUTE } from '@/router/routes'
import { ACL } from '@/composables/auth/auth'
import type { RouteRecordRaw } from 'vue-router'
import { AEmptyRouterView } from '@anzusystems/common-admin'

export const cmsNewsletterRoutes: RouteRecordRaw[] = [
  {
    path: '/newsletter',
    component: AEmptyRouterView,
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
      breadcrumbT: 'breadcrumb.cms.newsletter.list',
    },
    children: [
      {
        path: '',
        name: ROUTE.CMS.NEWSLETTER.LIST,
        component: ListView,
        meta: {
          requiresAuth: true,
          requiredPermissions: [],
          layout: 'AppLayoutDrawer',
        },
      },
      {
        path: 'create',
        name: ROUTE.CMS.NEWSLETTER.CREATE,
        component: CreateView,
        meta: {
          requiresAuth: true,
          requiredPermissions: [ACL.CMS_NEWSLETTER_CREATE],
          layout: 'AppLayoutDrawer',
          breadcrumbT: 'breadcrumb.cms.newsletter.create',
        },
      },
      {
        path: ':id(\\d+)/edit',
        name: ROUTE.CMS.NEWSLETTER.EDIT,
        component: EditView,
        meta: {
          requiresAuth: true,
          requiredPermissions: [ACL.CMS_NEWSLETTER_READ, ACL.CMS_NEWSLETTER_UPDATE],
          layout: 'AppLayoutDrawer',
          breadcrumbT: 'breadcrumb.cms.newsletter.edit',
        },
      },
      {
        path: ':id(\\d+)',
        name: ROUTE.CMS.NEWSLETTER.DETAIL,
        component: DetailView,
        meta: {
          requiresAuth: true,
          requiredPermissions: [ACL.CMS_NEWSLETTER_READ],
          layout: 'AppLayoutDrawer',
          breadcrumbT: 'breadcrumb.cms.newsletter.detail',
        },
      },
    ],
  },
]
