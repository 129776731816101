import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import type { DocId, DocIdNullable, IntegerIdNullable } from '@anzusystems/common-admin'
import { cloneDeep, isArray } from '@anzusystems/common-admin'
import type { Embed, EmbedAware, EmbedEntityIdentity, EmbedEntityType } from '@/components/anzutap/types/Embed'
import { EmbedEntityDefault } from '@/components/anzutap/types/Embed'
import { useEmbedFactory } from '@/components/anzutap/factory/EmbedFactory'
import type { CollabRoom } from '@/types/Collab'
import type { EmbedNameType } from '@/components/anzutap/nodes/nodes'

export interface DialogManage {
  name: EmbedNameType
  embedId: DocIdNullable
  isEdit: boolean
}

export const useEmbedStore = defineStore('cmsEmbedStore', () => {
  const { createDefault, createIdentity } = useEmbedFactory()

  const embeds = ref<Map<DocId, Embed>>(new Map())
  const currentEmbed = ref<EmbedAware | null>(null)
  const dialogDetail = ref<EmbedNameType | null>(null)
  const dialogManage = ref<DialogManage | null>(null)
  const saveButtonLoading = ref(false)
  const currentEntity = ref<EmbedEntityIdentity>(createIdentity(EmbedEntityDefault, null))
  const currentSiteGroup = ref<IntegerIdNullable>(null)
  const currentRoomName = ref<CollabRoom>('')

  function addEmbed(embedId: DocId, data: Embed) {
    embeds.value.set(embedId, data)
  }

  function addEmbeds(data: Embed | Array<Embed>) {
    if (isArray(data)) {
      data.forEach((item) => {
        addEmbed(item.id, item)
      })

      return
    }
    addEmbed(data.id, data)
  }

  function removeEmbed(embedId: DocId) {
    embeds.value.delete(embedId)
  }

  function clearEmbeds() {
    embeds.value.clear()
  }

  function hasEmbedId(id: DocId) {
    return embeds.value.has(id)
  }

  function getEmbed(id: DocId) {
    return embeds.value.get(id)
  }

  function openDialogDetail(name: EmbedNameType, currentEmbedId: DocId) {
    if (hasEmbedId(currentEmbedId)) {
      const foundEmbed = embeds.value.get(currentEmbedId)
      if (foundEmbed) {
        currentEmbed.value = cloneDeep(foundEmbed)
        dialogDetail.value = name
        return
      }
    }
    currentEmbed.value = createDefault(name, currentEmbedId)
    dialogDetail.value = name
  }

  function closeDialogDetail() {
    dialogDetail.value = null
    currentEmbed.value = null
  }

  function openDialogManage(name: EmbedNameType, currentEmbedId: DocId, isEdit: boolean) {
    dialogManage.value = { name: name, embedId: currentEmbedId, isEdit }
  }

  function closeDialogManage() {
    dialogManage.value = null
  }

  function setCurrentEntity(entityType: EmbedEntityType, id: IntegerIdNullable) {
    currentEntity.value = createIdentity(entityType, id)
  }

  function setCurrentSiteGroup(id: IntegerIdNullable) {
    currentSiteGroup.value = id
  }

  function setCurrentRoomName(name: CollabRoom) {
    currentRoomName.value = name
  }

  function reset() {
    clearEmbeds()
    currentEmbed.value = null
    saveButtonLoading.value = false
    currentEntity.value = createIdentity(EmbedEntityDefault, null)
    currentSiteGroup.value = null
    currentRoomName.value = ''
  }

  return {
    saveButtonLoading,
    dialogDetail,
    dialogManage,
    currentEmbed,
    embeds,
    currentEntity,
    currentRoomName,
    currentSiteGroup,
    setCurrentRoomName,
    addEmbed,
    addEmbeds,
    removeEmbed,
    hasEmbedId,
    getEmbed,
    clearEmbeds,
    openDialogDetail,
    closeDialogDetail,
    openDialogManage,
    closeDialogManage,
    setCurrentEntity,
    setCurrentSiteGroup,
    reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useEmbedStore, import.meta.hot))
}
