import type { FilterBag, Pagination } from '@anzusystems/common-admin'
import {
  apiCreateOne,
  apiDeleteOne,
  apiFetchByIds,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
} from '@anzusystems/common-admin'
import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { NewsletterPromo } from '@/types/cms/NewsletterPromo'

const END_POINT = '/adm/v1/newsletter-promo'
export const ENTITY = 'newsletterPromo'

export const fetchNewsletterPromoList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<NewsletterPromo[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchNewsletterPromoListByIds = (ids: number[]) =>
  apiFetchByIds<NewsletterPromo[]>(cmsClient, ids, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const fetchNewsletterPromo = (id: number) =>
  apiFetchOne<NewsletterPromo>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createNewsletterPromo = (data: NewsletterPromo) =>
  apiCreateOne<NewsletterPromo>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updateNewsletterPromo = (id: number, data: NewsletterPromo) =>
  apiUpdateOne<NewsletterPromo>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const deleteNewsletterPromo = (id: number) =>
  apiDeleteOne<NewsletterPromo>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)
