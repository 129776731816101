<script lang="ts" setup>
import { ACustomDataForm, type IntegerId, isUndefined, useI18n, type ValidationScope } from '@anzusystems/common-admin'
import type { CustomFormResourceNameType } from '@/model/common/valueObject/CustomFormResourceNames'
import { computed, ref, watch } from 'vue'
import { useCustomFormConfig } from '@/views/common/customForm/composables/customFormConfig'

const props = withDefaults(
  defineProps<{
    modelValue: { [key: string]: any }
    resourceName: CustomFormResourceNameType
    siteGroup?: IntegerId | null
    validationScope?: ValidationScope
    readonly?: boolean
    hasElements?: boolean
    showNameFilter?: boolean
  }>(),
  {
    siteGroup: null,
    validationScope: undefined,
    readonly: false,
    hasElements: false,
    showNameFilter: false,
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', data: any): void
  (e: 'update:valid', data: boolean): void
  (e: 'update:hasElements', data: boolean): void
  (e: 'anyChange'): void
}>()

/**
 * @param selectedName undefined or empty string = show all, string = show only that name
 */
const selectedName = defineModel<string | undefined>('selectedName', { required: false, default: '' })

const customFormComponentInstance = ref<InstanceType<typeof ACustomDataForm> | null>(null)

const {
  customFormsConfigLoaded,
  customFormsConfigError,
  customFormElements,
  customFormElementsCount,
  customFormNames,
} =
  // eslint-disable-next-line vue/no-setup-props-reactivity-loss
  useCustomFormConfig(props.resourceName, props.siteGroup, selectedName)

const validate = () => {
  if (customFormComponentInstance.value) return customFormComponentInstance.value?.validate()
  return Promise.resolve(false)
}

const { t } = useI18n()

watch(
  customFormElementsCount,
  (newValue) => {
    if (newValue > 0) {
      emit('update:hasElements', true)
      return
    }
    emit('update:hasElements', false)
  },
  { immediate: true }
)

const showOptions = computed(() => {
  return (
    !props.showNameFilter ||
    (props.showNameFilter && !(isUndefined(selectedName.value) || selectedName.value.length === 0))
  )
})

defineExpose({
  validate,
})
</script>

<template>
  <VProgressCircular
    v-if="!customFormsConfigLoaded"
    indeterminate
  />
  <div
    v-else-if="customFormsConfigError"
    class="text-error"
  >
    Error loading config
  </div>
  <div v-else>
    <VSelect
      v-if="showNameFilter"
      v-model="selectedName"
      :label="t('editor.node.embedCustom.model.name')"
      :items="customFormNames"
    />
    <ACustomDataForm
      v-if="showOptions"
      :key="selectedName"
      ref="customFormComponentInstance"
      :elements="customFormElements"
      :model-value="modelValue"
      :readonly="readonly"
      @any-change="emit('anyChange')"
      @update:model-value="emit('update:modelValue', $event)"
    />
  </div>
</template>
