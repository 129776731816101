import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'

export const cmsMinuteTopicRoutes: RouteRecordRaw[] = [
  {
    path: '/minute-topic',
    name: ROUTE.CMS.MINUTE_TOPIC.LIST,
    component: () => import('@/views/cms/minuteTopic/MinuteTopicListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/minute-topic/create',
    name: ROUTE.CMS.MINUTE_TOPIC.CREATE,
    component: () => import('@/views/cms/minuteTopic/MinuteTopicCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/minute-topic/:id(\\d+)/edit',
    name: ROUTE.CMS.MINUTE_TOPIC.EDIT,
    component: () => import('@/views/cms/minuteTopic/MinuteTopicEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/minute-topic/:id(\\d+)',
    name: ROUTE.CMS.MINUTE_TOPIC.DETAIL,
    component: () => import('@/views/cms/minuteTopic/MinuteTopicDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
]
