<script lang="ts" setup>
import { ADialogToolbar } from '@anzusystems/common-admin'
import { useI18n } from 'vue-i18n'
import { computed, onMounted, ref } from 'vue'
import { useArticleKindEditActions } from '@/views/cms/article/composables/articleKindActions'
import { fetchArticleKind } from '@/services/api/cms/articleKindApi'
import { ArticleStatus } from '@/model/cms/valueObject/ArticleStatus'
import { ROUTE } from '@/router/routes'
import { useRouter } from 'vue-router'
import { useMainBarDialogsStore } from '@/views/cms/article/components/mainBarButtons/mainBarDialogsStore'
import { storeToRefs } from 'pinia'

const { t } = useI18n()
const router = useRouter()

const initialized = ref(false)
const loading = ref(false)

const mainBarDialogsStore = useMainBarDialogsStore()
const { article, articleVersionData } = storeToRefs(mainBarDialogsStore)
const { createDraftFromPublished, changeStatus } = useArticleKindEditActions()

const articleHasVersion = computed(() => {
  return article.value && article.value.articleVersions.length > 0
})

const onConfirm = async () => {
  if (!article.value) return
  if (!articleHasVersion.value) {
    await createDraftFromPublished(article.value, ROUTE.CMS.ARTICLE.EDIT)
    mainBarDialogsStore.closeDialog()
    return
  } else if (articleVersionData.value && articleVersionData.value.status === ArticleStatus.Ready) {
    // change status back to draft and redirect to edit
    await changeStatus(articleVersionData.value, ArticleStatus.Draft, ROUTE.CMS.ARTICLE.EDIT)
    mainBarDialogsStore.closeDialog()
  }
}

const onClose = () => {
  mainBarDialogsStore.closeDialog()
}

const onDetail = () => {
  if (!article.value) return
  router.push({ name: ROUTE.CMS.ARTICLE.DETAIL, params: { id: article.value.id } })
}

onMounted(async () => {
  if (article.value && articleHasVersion.value) {
    try {
      articleVersionData.value = await fetchArticleKind(article.value.articleVersions[0])
      if (articleVersionData.value && articleVersionData.value.status === ArticleStatus.Draft) {
        mainBarDialogsStore.closeDialog()
        router.push({ name: ROUTE.CMS.ARTICLE.EDIT, params: { id: articleVersionData.value.id } })
        return
      }
    } catch (error) {
      //
    }
  }
  initialized.value = true
})
</script>

<template>
  <VDialog
    :model-value="true"
    :width="500"
  >
    <VCard>
      <ADialogToolbar @on-cancel="onClose">
        Upraviť
      </ADialogToolbar>
      <VCardText>
        <template v-if="initialized">
          <p v-if="!articleHasVersion">
            Aktualizácia publikovanej verzie sa bude vykonávať v novom koncepte. Chcete pokračovať?
          </p>
          <p v-else-if="articleVersionData && articleVersionData.status === ArticleStatus.Ready">
            Editovaním sa naplánovaná aktualizácia vráti do konceptu a bude potrebné ju znovu naplánovať. Chcete
            pokračovať?
          </p>
        </template>
        <div
          v-else
          class="d-flex w-100 align-center justify-center"
        >
          <VProgressCircular indeterminate />
        </div>
      </VCardText>
      <VCardActions>
        <VSpacer />
        <ABtnTertiary @click.stop="onClose">
          {{ t('common.button.cancel') }}
        </ABtnTertiary>
        <ABtnSecondary @click.stop="onDetail">
          Detail
        </ABtnSecondary>
        <ABtnPrimary
          v-if="initialized"
          :loading="loading"
          @click.stop="onConfirm"
        >
          {{ t('common.button.confirm') }}
        </ABtnPrimary>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
