import { type IntegerId, type IntegerIdNullable, isNull } from '@anzusystems/common-admin'
import { useSiteGroupAllStore } from '@/stores/cms/siteGroupStore'

export function getCmsSiteGroupDamUploadLicence(
  siteGroupId: IntegerId,
  type: 'image',
  subject: 'default' | 'author' | 'boxItem' = 'default'
): IntegerId {
  try {
    if (!isNull(siteGroupId)) {
      const siteGroupAllStore = useSiteGroupAllStore()
      const found = siteGroupAllStore.getSiteGroup(siteGroupId)
      if (found) {
        // @ts-ignore
        const subjectData = found.damSettings[type + 'Settings'][subject + 'UploadLicenceId'] as IntegerIdNullable
        if (isNull(subjectData)) {
          // @ts-ignore
          return found.damSettings[type + 'Settings']['defaultUploadLicenceId'] as IntegerId
        }
        return subjectData
      }
    }
  } catch (e) {
    throw new Error('Unable to get cached SiteGroup damSettings upload data.')
  }
  throw new Error('Unable to get cached SiteGroup damSettings upload data.')
}

export function getCmsSiteGroupDamSelectLicences(
  siteGroupId: IntegerId,
  type: 'audio' | 'video' | 'image',
  subject: 'default' | 'author' | 'boxItem' = 'default'
): IntegerId[] {
  try {
    if (!isNull(siteGroupId)) {
      const siteGroupAllStore = useSiteGroupAllStore()
      const found = siteGroupAllStore.getSiteGroup(siteGroupId)
      if (found) {
        // @ts-ignore
        const subjectData = found.damSettings[type + 'Settings'][subject + 'SelectLicenceIds'] as IntegerId[]
        if (subjectData.length === 0) {
          // @ts-ignore
          return found.damSettings[type + 'Settings']['defaultSelectLicenceIds'] as IntegerId[]
        }
        return subjectData
      }
    }
  } catch (e) {
    throw new Error('Unable to get cached SiteGroup damSettings select data.')
  }
  throw new Error('Unable to get cached SiteGroup damSettings select data.')
}

export const IMAGE_SELECT_LICENCE_CMS_LAYOUT_TEMPLATE = [100000]

export const IMAGE_UPLOAD_LICENCE_CMS_LAYOUT_TEMPLATE = 100000

export const IMAGE_SELECT_LICENCE_CMS_RELATED_ITEM = [100000]

export const IMAGE_UPLOAD_LICENCE_CMS_RELATED_ITEM = 100000

export const IMAGE_UPLOAD_LICENCE_PEOPLE = 100012

export const IMAGE_SELECT_LICENCES_PEOPLE = [100012]
