import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsTaskRoutes: RouteRecordRaw[] = [
  {
    path: '/task',
    name: ROUTE.CMS.TASK.LIST,
    component: () => import('@/views/cms/task/TaskListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/task/create',
    name: ROUTE.CMS.TASK.CREATE,
    component: () => import('@/views/cms/task/TaskCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_TASK_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/task/:id(\\d+)/edit',
    name: ROUTE.CMS.TASK.EDIT,
    component: () => import('@/views/cms/task/TaskEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_TASK_READ, ACL.CMS_TASK_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/task/:id(\\d+)',
    name: ROUTE.CMS.TASK.DETAIL,
    component: () => import('@/views/cms/task/TaskDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_TASK_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
