<script lang="ts" setup>
import '@/styles/main.scss'
import { useRoute } from 'vue-router'
import AppLayout from '@/layouts/AppLayout.vue'
import { initDamNotifications, useDropzoneGlobalDragState } from '@anzusystems/common-admin'
import { onMounted } from 'vue'
import { useCookiesState } from '@/composables/cookiesState'

const route = useRoute()

const { initGlobalDragState } = useDropzoneGlobalDragState()

const { openConnection } = initDamNotifications()

const { initCookieState } = useCookiesState()

onMounted(() => {
  initCookieState()
  openConnection()
  initGlobalDragState()
})
</script>

<template>
  <AppLayout>
    <RouterView :key="route.path" />
  </AppLayout>
</template>
