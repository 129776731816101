import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import { apiCreateOne, apiFetchByIds, apiFetchList, apiFetchOne, apiUpdateOne } from '@anzusystems/common-admin'
import type { Site } from '@/types/cms/Site'

const END_POINT = '/adm/v1/site'
export const ENTITY = 'site'

export const fetchSiteListByIds = (ids: IntegerId[]) =>
  apiFetchByIds<Site[]>(cmsClient, ids, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const fetchSiteList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<Site[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchSite = (id: IntegerId) => apiFetchOne<Site>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createSite = (data: Site) => apiCreateOne<Site>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updateSite = (id: IntegerId, data: Site) =>
  apiUpdateOne<Site>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)
