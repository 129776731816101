import { dateTimeNow, type DocId } from '@anzusystems/common-admin'
import type { RelatedItemKindArticle, RelatedItemKindLink } from '@/types/cms/RelatedItem'
import { RelatedItemDiscriminator } from '@/types/cms/RelatedItem'

export function useRelatedItemFactory() {
  const createItemArticle = (id: undefined | DocId = undefined): RelatedItemKindArticle => {
    return {
      id,
      overline: '',
      headline: '',
      perex: '',
      articleDocId: '',
      position: 0,
      discriminator: RelatedItemDiscriminator.Article,
    }
  }

  const createItemLink = (id: undefined | DocId = undefined): RelatedItemKindLink => {
    return {
      id,
      overline: '',
      headline: '',
      perex: '',
      url: '',
      external: true,
      nofollow: false,
      image: null,
      publishedAt: dateTimeNow(),
      position: 0,
      discriminator: RelatedItemDiscriminator.Link,
    }
  }

  return {
    createItemArticle,
    createItemLink,
  }
}
