import { fetchArticleRelatedEntities } from '@/services/api/cms/articleKindApi'
import { useArticleRelatedEntitiesOneStore } from '@/stores/cms/articleRelatedEntitiesStore'
import { useAlerts, type IntegerId } from '@anzusystems/common-admin'

const { showErrorsDefault } = useAlerts()

export const useArticleRelatedEntitiesActions = () => {
  const articleRelatedEntitiesOneStore = useArticleRelatedEntitiesOneStore()

  const fetchRelatedEntities = async (articleId: IntegerId) => {
    try {
      articleRelatedEntitiesOneStore.relatedEntitiesLoading = true
      const relatedEntities = await fetchArticleRelatedEntities(articleId)
      articleRelatedEntitiesOneStore.articleRelatedEntities = relatedEntities
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      articleRelatedEntitiesOneStore.relatedEntitiesLoading = false
    }
  }

  return {
    fetchRelatedEntities,
  }
}
