import { SYSTEM_CMS } from '@/model/systems'
import { dateTimeNow } from '@anzusystems/common-admin'
import type { CreateKeywordDto, CurrentKeywordDto, Keyword, UpdateKeywordDto } from '@/types/cms/Keyword'
import { KeywordStatus } from '@/model/cms/valueObject/KeywordStatus'

export function useKeywordFactory() {
  const createDefault = (): Keyword => {
    return {
      id: 0,
      name: '',
      attributes: {
        new: false,
        status: KeywordStatus.Inactive,
      },
      currentKeyword: null,
      siteGroup: 0,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
      _resourceName: 'keyword',
      _system: SYSTEM_CMS,
    }
  }

  const createKeywordCreate = (): CreateKeywordDto => {
    return {
      name: '',
      siteGroup: null,
    }
  }

  const createKeywordUpdate = (keyword: Keyword): UpdateKeywordDto => {
    return {
      id: keyword.id,
      name: keyword.name,
    }
  }

  const createChangeToOldKeyword = (): CurrentKeywordDto => {
    return {
      currentKeyword: null,
    }
  }

  return {
    createDefault,
    createKeywordCreate,
    createKeywordUpdate,
    createChangeToOldKeyword,
  }
}
