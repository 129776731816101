import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsPollRoutes: RouteRecordRaw[] = [
  {
    path: '/poll',
    name: ROUTE.CMS.POLL.LIST,
    component: () => import('@/views/cms/poll/PollListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/poll/create',
    name: ROUTE.CMS.POLL.CREATE,
    component: () => import('@/views/cms/poll/PollCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_POLL_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/poll/:id(\\d+)/edit',
    name: ROUTE.CMS.POLL.EDIT,
    component: () => import('@/views/cms/poll/PollEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_POLL_READ, ACL.CMS_POLL_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/poll/:id(\\d+)',
    name: ROUTE.CMS.POLL.DETAIL,
    component: () => import('@/views/cms/poll/PollDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_POLL_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
