import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'

export const commonAnzuUserRoutes: RouteRecordRaw[] = [
  {
    path: '/anzu-user',
    name: ROUTE.COMMON.ANZU_USER.LIST,
    component: () => import('@/views/common/anzuUser/AnzuUserListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/anzu-user/create',
    name: ROUTE.COMMON.ANZU_USER.CREATE,
    component: () => import('@/views/common/anzuUser/AnzuUserCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/anzu-user/:id(\\d+)/edit',
    name: ROUTE.COMMON.ANZU_USER.EDIT,
    component: () => import('@/views/common/anzuUser/AnzuUserEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/anzu-user/:id(\\d+)',
    name: ROUTE.COMMON.ANZU_USER.DETAIL,
    component: () => import('@/views/common/anzuUser/AnzuUserDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/anzu-user/dashboard',
    name: ROUTE.COMMON.ANZU_USER.DASHBOARD,
    component: () => import('@/views/cms/desk/PersonalDashboardView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
]
