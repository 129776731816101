import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const commonPermissionGroupRoutes: RouteRecordRaw[] = [
  {
    path: '/permission-group',
    name: ROUTE.COMMON.PERMISSION_GROUP.LIST,
    component: () => import('@/views/common/permissionGroup/PermissionGroupListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PERMISSION_GROUP_READ],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/permission-group/create',
    name: ROUTE.COMMON.PERMISSION_GROUP.CREATE,
    component: () => import('@/views/common/permissionGroup/PermissionGroupCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PERMISSION_GROUP_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/permission-group/:id(\\d+)/edit',
    name: ROUTE.COMMON.PERMISSION_GROUP.EDIT,
    component: () => import('@/views/common/permissionGroup/PermissionGroupEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PERMISSION_GROUP_READ, ACL.CMS_PERMISSION_GROUP_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/permission-group/:id(\\d+)',
    name: ROUTE.COMMON.PERMISSION_GROUP.DETAIL,
    component: () => import('@/views/common/permissionGroup/PermissionGroupDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PERMISSION_GROUP_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
