import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { ContentItemKindTimeline } from '@/types/cms/ContentItemKind/ContentItemKindTimeline'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindTimelineFactory() {
  const create = (): ContentItemKindTimeline => {
    return {
      ...createAbstractContentItemKind(),
      timeline: null,
      discriminator: ContentItemDiscriminator.Timeline,
      _resourceName: 'contentItemKindTimeline',
    }
  }

  return {
    create,
  }
}
