<script lang="ts" setup>
import { computed } from 'vue'
import { AFormTextarea } from '@anzusystems/common-admin'
import { useEmbedRelatedDialogItemLinkValidation } from '@/components/anzutap/nodes/embedRelated/composables/embedRelatedDialogValidations'
import type { RelatedItemKindLink } from '@/types/cms/RelatedItem'

const props = withDefaults(
  defineProps<{
    modelValue: RelatedItemKindLink
  }>(),
  {}
)

const emit = defineEmits<{
  (e: 'update:modelValue', data: RelatedItemKindLink): void
}>()

const itemComputed = computed({
  get() {
    return props.modelValue
  },
  set(newValue: RelatedItemKindLink) {
    emit('update:modelValue', newValue)
  },
})

const { v$ } = useEmbedRelatedDialogItemLinkValidation(itemComputed)
</script>

<template>
  <VRow class="mt-2">
    <VCol cols="12">
      <AFormTextarea
        v-model="itemComputed.url"
        label="URL"
        :v="v$.item.url"
      />
    </VCol>
    <VCol cols="12">
      <AFormTextarea
        v-model="itemComputed.headline"
        label="Headline"
        :v="v$.item.headline"
      />
    </VCol>
    <VCol cols="12">
      <VSwitch
        v-model="itemComputed.external"
        label="External"
        :v="v$.item.external"
      />
    </VCol>
    <VCol cols="12">
      <VSwitch
        v-model="itemComputed.nofollow"
        label="Nofollow"
        :v="v$.item.nofollow"
      />
    </VCol>
  </VRow>
</template>
