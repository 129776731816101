import { useArticleKindFactory } from '@/model/cms/factory/ArticleKind/ArticleKindFactory'
import type { ArticleKindWithVersionData } from '@/services/api/cms/articleKindApiHelpers'
import type { ArticleKind } from '@/types/cms/ArticleKind/ArticleKind'
import { isArticleKindStandard } from '@/types/cms/ArticleKind/ArticleKindStandard'
import type { SiteMinimal } from '@/types/cms/Site'
import type { ArticleWidgetVariantType } from '@/views/cms/article/components/widgets/articleWidget'
import type { IntegerId } from '@anzusystems/common-admin'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref, shallowRef } from 'vue'

export const useArticleKindOneStore = defineStore('cmsArticleKindOneStore', () => {
  const { createArticleKindStandard } = useArticleKindFactory()

  const article = ref<ArticleKind>(createArticleKindStandard())
  const bodyEditor = shallowRef<any | undefined>(undefined) // todo check why type is causing the issue with IJ tools
  const bodyEditorReady = ref(false)
  const listItems = ref<Array<ArticleKindWithVersionData>>([])
  const articleSite = ref<SiteMinimal | undefined>(undefined)
  const pinnedWidgets = ref<ArticleWidgetVariantType[]>([])
  const pinnedWidgetsLoaded = ref<boolean | IntegerId>(false)
  const stageWidgetDatatableReload = ref(0)
  const articleBookmarksReload = ref(0)

  /**
   * @return If content was undefined, returns false, otherwise on success returns true.
   */
  function updateBodyTextFromEditor() {
    const bodyText = bodyEditor.value?.getJSON()
    if (!bodyText) {
      return false
    }
    article.value.texts.body = bodyText
    return true
  }

  function addActiveStage(stageId: IntegerId) {
    if (!isArticleKindStandard(article.value)) {
      return
    }

    if (!article.value.activeStages.includes(stageId)) {
      article.value.activeStages.push(stageId)
    }

    const index = article.value.completedStages.indexOf(stageId)
    if (index >= 0) {
      article.value.completedStages.splice(index, 1)
    }
  }

  function addCompletedStage(stageId: IntegerId) {
    if (!isArticleKindStandard(article.value)) {
      return
    }

    if (!article.value.completedStages.includes(stageId)) {
      article.value.completedStages.push(stageId)
    }

    const index = article.value.activeStages.indexOf(stageId)
    if (index >= 0) {
      article.value.activeStages.splice(index, 1)
    }
  }

  function reset() {
    article.value = createArticleKindStandard()
    articleSite.value = undefined
    bodyEditor.value?.destroy()
    bodyEditor.value = undefined
    bodyEditorReady.value = false
    stageWidgetDatatableReload.value = 0
    articleBookmarksReload.value = 0
  }

  return {
    article,
    bodyEditor,
    listItems,
    articleSite,
    pinnedWidgets,
    pinnedWidgetsLoaded,
    bodyEditorReady,
    stageWidgetDatatableReload,
    articleBookmarksReload,
    updateBodyTextFromEditor,
    reset,
    addActiveStage,
    addCompletedStage,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useArticleKindOneStore, import.meta.hot))
}
