import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsJobRoutes: RouteRecordRaw[] = [
  {
    path: '/job',
    name: ROUTE.CMS.JOB.LIST,
    component: () => import('@/views/cms/job/JobListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_JOB_READ],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/job/:id',
    name: ROUTE.CMS.JOB.DETAIL,
    component: () => import('@/views/cms/job/JobDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_JOB_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
