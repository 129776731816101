import type { ContentItemKind } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { ContentItemKindArticleList } from '@/types/cms/ContentItemKind/ContentItemKindArticleList'

export const MultiArticleListLayout = {
  Newest: 'newest',
  Author: 'author',
} as const
export const MultiArticleListLayoutDefault = MultiArticleListLayout.Newest
export type MultiArticleListLayoutType = (typeof MultiArticleListLayout)[keyof typeof MultiArticleListLayout]

export interface ContentItemKindMultiArticleList extends ContentItemKind {
  title: string
  url: string
  layout: MultiArticleListLayoutType
  articleLists: ContentItemKindArticleList[]
  _resourceName: 'contentItemKindMultiArticleList'
}
