import { ArticleStagePriorityDefault, type ArticleStagePriorityType } from '@/model/cms/valueObject/ArticleStagePriority'
import { ArticleStageStatusDefault } from '@/model/cms/valueObject/ArticleStageStatus'
import type { ArticleKindStandard } from '@/types/cms/ArticleKind/ArticleKindStandard'
import type { ArticleStage, ArticleStageCompleteDto, ArticleStageCreateDto } from '@/types/cms/ArticleStage'
import type { Stage } from '@/types/cms/Stage'
import { dateTimeNow } from '@anzusystems/common-admin'

export function useArticleStageFactory() {
  const createDefault = (): ArticleStage => {
    return {
      id: 0,
      articleDocId: '',
      stage: null,
      desk: null,
      priority: ArticleStagePriorityDefault,
      status: ArticleStageStatusDefault,
      completedBy: null,
      completedAt: null,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
      _resourceName: 'articleStage',
      _system: 'cms',
    }
  }

  return {
    createDefault,
  }
}

export function useArticleStageCreateDtoFactory() {
  const createDto = (
    stage: Stage,
    article: ArticleKindStandard,
    priority: ArticleStagePriorityType
  ): ArticleStageCreateDto => {
    return {
      articleDocId: article.docId,
      stage: stage.id,
      priority: priority
    }
  }

  const completeDto = (
    stage: Stage,
    article: ArticleKindStandard,
  ): ArticleStageCompleteDto => {
    return {
      articleDocId: article.docId,
      stage: stage.id,
    }
  }

  return {
    createDto,
    completeDto
  }
}
