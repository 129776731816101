import type { ContentItemKind } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { IntegerIdNullable } from '@anzusystems/common-admin'

export const ThematicBoxLayout = {
  Regular: 'regular',
  Video: 'video',
} as const
export const ThematicBoxLayoutDefault = ThematicBoxLayout.Regular
export type ThematicBoxLayoutType = (typeof ThematicBoxLayout)[keyof typeof ThematicBoxLayout]

export interface ContentItemKindThematicBox extends ContentItemKind {
  title: string
  url: string
  box: IntegerIdNullable
  linkedList: IntegerIdNullable
  layout: ThematicBoxLayoutType
  _resourceName: 'contentItemKindThematicBox'
}
