import { isAuthorKindPerson } from '@/types/cms/AuthorKind/AuthorKindPerson'
import { isAuthorKindSource } from '@/types/cms/AuthorKind/AuthorKindSource'
import type { AuthorKind } from '@/types/cms/AuthorKind/AuthorKind'

export function useAuthorHelpers() {
  function getAuthorDisplayName(author: AuthorKind) {
    return isAuthorKindPerson(author)
      ? author.person.fullName + (author.jobDescription.length > 0 ? ` (${author.jobDescription})` : '')
      : isAuthorKindSource(author)
        ? author.title
        : ''
  }

  return {
    getAuthorDisplayName,
  }
}
