import type { IntegerId } from '@anzusystems/common-admin'
import { defineCached } from '@anzusystems/common-admin'
import type { LinkedList, LinkedListMinimal } from '@/types/cms/LinkedList'
import { fetchLinkedListListByIds } from '@/services/api/cms/linkedListApi'

const mapFullToMinimal = (linkedList: LinkedList): LinkedListMinimal => ({
  id: linkedList.id,
  name: linkedList.texts.name,
})

const mapIdToMinimal = (id: IntegerId): LinkedListMinimal => {
  return { id: id, name: '' }
}

const { cache, toFetch, fetch, add, addManual, addManualMinimal, has, get, isLoaded } = defineCached<
  IntegerId,
  LinkedList,
  LinkedListMinimal
>(mapFullToMinimal, mapIdToMinimal, fetchLinkedListListByIds)

export const useCachedLinkedList = () => {
  return {
    addManualToCachedLinkedLists: addManual,
    addManualMinimalToCachedLinkedLists: addManualMinimal,
    addToCachedLinkedLists: add,
    fetchCachedLinkedLists: fetch,
    toFetchCachedLinkedLists: toFetch,
    cachedLinkedLists: cache,
    hasCachedLinkedList: has,
    getCachedLinkedList: get,
    isLoadedCachedLinkedList: isLoaded,
  }
}
