import { SYSTEM_CMS } from '@/model/systems'
import { dateTimeNow } from '@anzusystems/common-admin'

import type { EmbedExternalAware, EmbedExternalCreateUpdateDto } from '@/components/anzutap/types/EmbedExternal'
import { ScrapeStatusDefault, ScrapeTypeDefault } from '@/components/anzutap/types/EmbedExternal'

export function useEmbedExternalFactory() {
  const createDefault = (id = ''): EmbedExternalAware => {
    return {
      id,
      type: ScrapeTypeDefault,
      scrapeStatus: ScrapeStatusDefault,
      params: {},
      data: {},
      customData: {},
      discriminator: 'external',
      _resourceName: 'embedKindExternal',
      _system: SYSTEM_CMS,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
    }
  }

  const createCreateUpdateDto = (id = ''): EmbedExternalCreateUpdateDto => {
    return {
      id,
      type: ScrapeTypeDefault,
      scrapeStatus: ScrapeStatusDefault,
      params: {},
      data: {},
      customData: {},
    }
  }

  return {
    createDefault,
    createCreateUpdateDto,
  }
}
