import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type {
  ContentItemDetailKindBreakingNews,
  ContentItemKindBreakingNews,
} from '@/types/cms/ContentItemKind/ContentItemKindBreakingNews'

const { createAbstractContentItemKind, createAbstractContentItemDetailKind } = useContentItemKindFactory()

export function useContentItemKindBreakingNewsFactory() {
  const create = (): ContentItemKindBreakingNews => {
    return {
      ...createAbstractContentItemKind(),
      dates: {
        showAtFrom: null,
        showAtUntil: null,
      },
      relatedItem: null,
      discriminator: ContentItemDiscriminator.BreakingNews,
      _resourceName: 'contentItemKindBreakingNews',
    }
  }

  const createDetail = (): ContentItemDetailKindBreakingNews => {
    return {
      ...createAbstractContentItemDetailKind(),
      dates: {
        showAtFrom: null,
        showAtUntil: null,
      },
      relatedItem: null,
      discriminator: ContentItemDiscriminator.BreakingNews,
      _resourceName: 'contentItemKindBreakingNews',
    }
  }

  return {
    create,
    createDetail,
  }
}
