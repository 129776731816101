import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsLinkedListRoutes: RouteRecordRaw[] = [
  {
    path: '/linked-list',
    name: ROUTE.CMS.LINKED_LIST.LIST,
    component: () => import('@/views/cms/linkedList/LinkedListListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/linked-list/create',
    name: ROUTE.CMS.LINKED_LIST.CREATE,
    component: () => import('@/views/cms/linkedList/LinkedListCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_LINKED_LIST_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/linked-list/:id(\\d+)/edit',
    name: ROUTE.CMS.LINKED_LIST.EDIT,
    component: () => import('@/views/cms/linkedList/LinkedListEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_LINKED_LIST_READ, ACL.CMS_LINKED_LIST_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/linked-list/:id(\\d+)',
    name: ROUTE.CMS.LINKED_LIST.DETAIL,
    component: () => import('@/views/cms/linkedList/LInkedListDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_LINKED_LIST_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
