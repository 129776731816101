import { reactive } from 'vue'
import { type Filter, makeFilterHelper, type MakeFilterOptions } from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'
import type { KeywordStatusType } from '@/model/cms/valueObject/KeywordStatus'

const makeFilter: <T>(options: Partial<MakeFilterOptions<T>>) => Filter<T> = makeFilterHelper(SYSTEM_CMS, 'keyword')

const filter = reactive({
  _elastic: {
    ...makeFilter({ exclude: true }),
  },
  id: {
    ...makeFilter<number>({ name: 'id' }),
  },
  text: {
    ...makeFilter<string>({ name: 'name', variant: 'contains' }),
  },
  siteGroup: {
    ...makeFilter<number>({ name: 'siteGroup', field: 'siteGroupId' }),
  },
  new: {
    ...makeFilter<boolean>({ name: 'site' }),
  },
})

export function useKeywordListFilter() {
  return filter
}

export function useKeywordFilter() {
  return reactive({
    _elastic: {
      ...makeFilter({ exclude: true }),
    },
    id: {
      ...makeFilter<number>({ name: 'id', variant: 'in' }),
    },
    text: {
      ...makeFilter<string>({ name: 'name', variant: 'contains' }),
    },
    siteGroup: {
      ...makeFilter<number>({ name: 'siteGroup', field: 'siteGroupId' }),
    },
    status: {
      ...makeFilter<KeywordStatusType[]>({ name: 'status', variant: 'in' }),
    },
  })
}
