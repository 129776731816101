import { reactive } from 'vue'
import { makeFilterHelper } from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'
import { ENTITY } from '@/services/api/cms/authorApi'

const makeFilter = makeFilterHelper(SYSTEM_CMS, ENTITY)

const filter = reactive({
  _elastic: {
    ...makeFilter({ exclude: true }),
  },
  id: {
    ...makeFilter({ name: 'id' }),
  },
  discriminator: {
    ...makeFilter({ name: 'discriminator' }),
  },
  firstName: {
    ...makeFilter({ name: 'firstName' }),
  },
  lastName: {
    ...makeFilter({ name: 'lastName' }),
  },
  title: {
    ...makeFilter({ name: 'title' }),
  },
  descriptionShort: {
    ...makeFilter({ name: 'descriptionShort' }),
  },
  enabled: {
    ...makeFilter({ name: 'enabled' }),
  },
  siteGroup: {
    ...makeFilter({ name: 'siteGroup', field: 'siteGroupId' }),
  },
  site: {
    ...makeFilter({ name: 'site', field: 'siteId' }),
  },
  text: {
    ...makeFilter({ name: 'text' }),
  },
})

export function useAuthorListFilter() {
  return filter
}

export function useAuthorFilter() {
  return reactive({
    _elastic: {
      ...makeFilter({ exclude: true }),
    },
    id: {
      ...makeFilter({ name: 'id' }),
    },
    discriminator: {
      ...makeFilter({ name: 'discriminator' }),
    },
    text: {
      ...makeFilter({ name: 'text' }),
    },
  })
}
