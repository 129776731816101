import { useRubricFactory } from '@/model/cms/factory/RubricFactory'
import type { Rubric } from '@/types/cms/Rubric'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'

export const useRubricOneStore = defineStore('cmsRubricOneStore', () => {
  const { createDefault } = useRubricFactory()

  const rubric = ref<Rubric>(createDefault())

  function reset() {
    rubric.value = createDefault()
  }

  return {
    rubric,
    reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRubricOneStore, import.meta.hot))
}
