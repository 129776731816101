import type { RouteRecordRaw } from 'vue-router'
import { ROUTE } from '@/router/routes'
import { ACL } from '@/composables/auth/auth'

export const cmsAuthorRoutes: RouteRecordRaw[] = [
  {
    path: '/author',
    name: ROUTE.CMS.AUTHOR.LIST,
    component: () => import('@/views/cms/author/AuthorListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/author/create',
    name: ROUTE.CMS.AUTHOR.CREATE,
    component: () => import('@/views/cms/author/AuthorCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_AUTHOR_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/author/:id(\\d+)/edit',
    name: ROUTE.CMS.AUTHOR.EDIT,
    component: () => import('@/views/cms/author/AuthorEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_AUTHOR_READ, ACL.CMS_AUTHOR_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/author/:id(\\d+)',
    name: ROUTE.CMS.AUTHOR.DETAIL,
    component: () => import('@/views/cms/author/AuthorDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_AUTHOR_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
