<script setup lang="ts">
import { isArticleKindStandard } from '@/types/cms/ArticleKind/ArticleKindStandard'
import { useI18n } from 'vue-i18n'
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import { storeToRefs } from 'pinia'
import { ARow, useCollabHelpers } from '@anzusystems/common-admin'
import type { CollabRoom } from '@/types/Collab'
import { useCachedUsers } from '@/views/cms/user/composables/cachedUsers'
import NewsletterRemoteAutocomplete from '@/views/cms/newsletter/components/NewsletterRemoteAutocomplete.vue'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const { t } = useI18n()

const { createCollabFieldConfig } = useCollabHelpers()

const articleKindOneStore = useArticleKindOneStore()
const { article } = storeToRefs(articleKindOneStore)

const { cachedUsers } = useCachedUsers()
</script>

<template>
  <div v-if="isArticleKindStandard(article)">
    <ARow>
      <VSwitch
        v-model="article.attributesStandard.inTrending"
        :disabled="readonly"
        :label="t('cms.articleKind.model.attributesStandard.inTrending')"
        :collab="createCollabFieldConfig('attributesStandard.inTrending', collabRoom, cachedUsers)"
      />
      <VSwitch
        :disabled="readonly"
        :model-value="true"
        :label="t('cms.articleKind.model.attributesStandard.discussionEnabled')"
      />
    </ARow>
    <NewsletterRemoteAutocomplete
      v-model="article.newsletter"
      :label="t('cms.articleKind.model.newsletter')"
    />
  </div>
</template>
