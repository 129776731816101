<script lang="ts" setup>
import { useTheme } from '@anzusystems/common-admin'

const { theme } = useTheme()
</script>

<template>
  <VApp :theme="theme">
    <VMain>
      <slot />
    </VMain>
  </VApp>
</template>
