import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsSiteGroupRoutes: RouteRecordRaw[] = [
  {
    path: '/site-group',
    name: ROUTE.CMS.SITE_GROUP.LIST,
    component: () => import('@/views/cms/siteGroup/SiteGroupListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/site-group/create',
    name: ROUTE.CMS.SITE_GROUP.CREATE,
    component: () => import('@/views/cms/siteGroup/SiteGroupCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_SITE_GROUP_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/site-group/:id(\\d+)/edit',
    name: ROUTE.CMS.SITE_GROUP.EDIT,
    component: () => import('@/views/cms/siteGroup/SiteGroupEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_SITE_GROUP_READ, ACL.CMS_SITE_GROUP_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/site-group/:id(\\d+)',
    name: ROUTE.CMS.SITE_GROUP.DETAIL,
    component: () => import('@/views/cms/siteGroup/SiteGroupDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_SITE_GROUP_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
