import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { DatetimeUTCNullable, FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import {
  apiAnyRequest,
  apiCreateOne,
  apiDeleteOne,
  apiFetchByIds,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
} from '@anzusystems/common-admin'
import type { Box } from '@/types/cms/Box'
import type { BoxItem } from '@/types/cms/BoxItem'

const END_POINT = '/adm/v1/box'
export const ENTITY = 'box'

export const fetchBoxListByIds = (ids: IntegerId[]) =>
  apiFetchByIds<Box[]>(cmsClient, ids, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const fetchBoxList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<Box[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchBox = (id: IntegerId) => apiFetchOne<Box>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createBox = (data: Box) => apiCreateOne<Box>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updateBox = (id: IntegerId, data: Box) =>
  apiUpdateOne<Box>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const deleteBox = (id: IntegerId) => apiDeleteOne<Box>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const fetchBoxItems = (
  id: IntegerId,
  dateSince: DatetimeUTCNullable,
  dateUntil: DatetimeUTCNullable,
  position = null
) => {
  let url = END_POINT + '/:id/items?dateSince=' + dateSince + '&dateUntil=' + dateUntil
  if (position !== null) url += '&position=' + position
  return apiAnyRequest<BoxItem[]>(cmsClient, 'GET', url, { id }, null, SYSTEM_CMS, ENTITY)
}
