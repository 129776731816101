<script lang="ts" setup>
import { ABooleanValue, ACopyText, AImageWidgetSimple, ARow, dateTimeFriendly } from '@anzusystems/common-admin'
import CoreTrackingFields from '@/components/cms/CoreTrackingFields.vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { useNewsletterPromoOneStore } from '@/stores/cms/newsletterPromoStore'
import { useNewsletterPromoColor } from '@/model/cms/valueObject/NewsletterPromoColor'
import { useSubscribeTargeting } from '@/model/cms/valueObject/SubscribeTargeting'
import { useNewsletterPromoType } from '@/model/cms/valueObject/NewsletterPromoType'
import CachedNewsletterChip from '@/views/cms/newsletter/components/CachedNewsletterChip.vue'
import { useNewsletterPromoIcon } from '@/model/cms/valueObject/NewsletterPromoIcon'

const { newsletterPromo } = storeToRefs(useNewsletterPromoOneStore())
const { t } = useI18n()

const { getNewsletterPromoTypeOptions } = useNewsletterPromoType()
const newsletterPromoTypeOption = computed(() => {
  return getNewsletterPromoTypeOptions(newsletterPromo.value.type)
})

const { getNewsletterPromoColorOptions } = useNewsletterPromoColor()
const newsletterPromoColorOption = computed(() => {
  return getNewsletterPromoColorOptions(newsletterPromo.value.color)
})

const { getNewsletterPromoIconOptions } = useNewsletterPromoIcon()
const newsletterPromoIconOption = computed(() => {
  return getNewsletterPromoIconOptions(newsletterPromo.value.icon)
})

const { getSubscribeTargetingOptions } = useSubscribeTargeting()
const newsletterPromoSubscribeTargetingOption = computed(() => {
  return getSubscribeTargetingOptions(newsletterPromo.value.subscribeTargeting)
})
</script>

<template>
  <VRow>
    <VCol cols="8">
      <ARow
        :title="t('cms.newsletterPromo.model.texts.title')"
        :value="newsletterPromo.texts.title"
      />
      <ARow
        :title="t('cms.newsletterPromo.model.texts.titlePublic')"
        :value="newsletterPromo.texts.titlePublic"
      />
      <ARow>
        <VCol cols="4">
          <h4 class="text-subtitle-2">
            {{ t('cms.newsletterPromo.model.image') }}
          </h4>
          <AImageWidgetSimple :model-value="newsletterPromo.image" />
        </VCol>
      </ARow>
      <ARow
        :title="t('cms.newsletterPromo.model.url')"
        :value="newsletterPromo.url"
      />
      <ARow
        :title="t('cms.newsletterPromo.model.texts.body')"
        :value="newsletterPromo.texts.body"
      />
      <ARow
        :title="t('cms.newsletterPromo.model.texts.linkedText')"
        :value="newsletterPromo.texts.linkedText"
      />
      <ARow
        v-if="newsletterPromoTypeOption"
        :title="t('cms.newsletterPromo.model.type')"
        :value="newsletterPromoTypeOption.title"
      />
      <ARow
        v-if="newsletterPromoColorOption"
        :title="t('cms.newsletterPromo.model.color')"
        :value="newsletterPromoColorOption.title"
      />
      <ARow
        v-if="newsletterPromoIconOption"
        :title="t('cms.newsletterPromo.model.icon')"
        :value="newsletterPromoIconOption.title"
      />
    </VCol>
    <VCol cols="4">
      <ARow :title="t('cms.newsletterPromo.model.id')">
        <ACopyText :value="newsletterPromo.id" />
      </ARow>
      <ARow :title="t('cms.newsletterPromo.model.enabled')">
        <ABooleanValue :value="newsletterPromo.enabled" />
      </ARow>
      <ARow
        :title="t('cms.newsletterPromo.model.dates.startAt')"
        :value="dateTimeFriendly(newsletterPromo.dates.startAt)"
      />
      <ARow
        :title="t('cms.newsletterPromo.model.dates.endAt')"
        :value="dateTimeFriendly(newsletterPromo.dates.endAt)"
      />
      <ARow
        v-if="newsletterPromoSubscribeTargetingOption"
        :title="t('cms.newsletterPromo.model.subscribeTargeting')"
        :value="newsletterPromoSubscribeTargetingOption.title"
      />
      <ARow
        class="pl-2"
        :title="t('cms.newsletterPromo.model.newsletters')"
      >
        <CachedNewsletterChip
          v-for="newsletterId in newsletterPromo.newsletters"
          :id="newsletterId"
          :key="newsletterId"
          class="mr-1 mb-1"
        />
      </ARow>
      <CoreTrackingFields :data="newsletterPromo" />
    </VCol>
  </VRow>
</template>
