import { SYSTEM_CMS } from '@/model/systems'
import { dateTimeNow } from '@anzusystems/common-admin'
import type { TargetPosition } from '@/types/cms/TargetPosition'
import { WebEntityTypeDefault } from '@/types/cms/TargetPosition'

export function useTargetPositionFactory() {
  const createTargetPosition = (): TargetPosition => {
    return {
      id: 0,
      name: '',
      identifier: '',
      webEntityType: WebEntityTypeDefault,
      lazyLoadedItemsOffset: 0,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
      _resourceName: 'targetPosition',
      _system: SYSTEM_CMS,
    }
  }

  return {
    createTargetPosition,
  }
}
