import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import {
  apiCreateOne,
  apiDeleteOne,
  apiFetchByIds,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
} from '@anzusystems/common-admin'
import type { PromoLink } from '@/types/cms/PromoLink'

const END_POINT = '/adm/v1/promo-link'
export const ENTITY = 'promoLink'

export const fetchPromoLinkListByIds = (ids: IntegerId[]) =>
  apiFetchByIds<PromoLink[]>(cmsClient, ids, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const fetchPromoLinkList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<PromoLink[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchPromoLink = (id: IntegerId) =>
  apiFetchOne<PromoLink>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createPromoLink = (data: PromoLink) =>
  apiCreateOne<PromoLink>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updatePromoLink = (id: IntegerId, data: PromoLink) =>
  apiUpdateOne<PromoLink>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const deletePromoLink = (id: IntegerId) =>
  apiDeleteOne<PromoLink>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)
