import type { FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import {
  apiCreateOne,
  apiDeleteOne,
  apiFetchByIds,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
} from '@anzusystems/common-admin'
import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { Inbox } from '@/types/cms/Inbox'

const END_POINT = '/adm/v1/inbox'
export const ENTITY = 'inbox'

export const fetchInboxList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<Inbox[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchInboxListByIds = (ids: IntegerId[]) =>
  apiFetchByIds<Inbox[]>(cmsClient, ids, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const fetchInbox = (id: IntegerId) =>
  apiFetchOne<Inbox>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createInbox = (data: Inbox) => apiCreateOne<Inbox>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updateInbox = (id: IntegerId, data: Inbox) =>
  apiUpdateOne<Inbox>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const deleteInbox = (id: IntegerId) =>
  apiDeleteOne<Inbox>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)
