<script setup lang="ts">
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import type { CollabRoom } from '@/types/Collab'
import CachedUserChip from '@/views/cms/user/components/CachedUserChip.vue'
import { ADatetime, AFormDatetimePicker, ARow } from '@anzusystems/common-admin'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const articleKindOneStore = useArticleKindOneStore()
const { article } = storeToRefs(articleKindOneStore)

const { t } = useI18n()
</script>

<template>
  <ARow>
    <AFormDatetimePicker
      v-model="article.dates.publicPublishedAt"
      :disabled="readonly"
      :label="t('cms.articleKind.model.dates.publicPublishedAt')"
    />
    <AFormDatetimePicker
      v-model="article.dates.publicUpdatedAt"
      :disabled="readonly"
      clearable
      :label="t('cms.articleKind.model.dates.publicUpdatedAt')"
    />
    <AFormDatetimePicker
      v-model="article.dates.publishedAt"
      :disabled="readonly"
      :label="t('cms.articleKind.model.dates.publishedAt')"
    />
    <div v-if="article.firstPublishedBy && article.dates.firstPublishedAt">
      <span class="text-caption text-medium-emphasis">{{ t('cms.articleKind.model.firstPublishedBy') }}</span><br>
      <ADatetime :date-time="article.dates.firstPublishedAt" />
      <CachedUserChip
        :id="article.firstPublishedBy"
        class="ml-2"
      />
    </div>
  </ARow>
</template>
