import { acceptHMRUpdate, defineStore } from 'pinia'
import { useArticleIntentionFactory } from '@/model/cms/factory/ArticleIntentionFactory'
import { ref } from 'vue'
import type { ArticleIntention } from '@/types/cms/Task'

export const useArticleIntentionOneStore = defineStore('cmsArticleIntentionOneStore', () => {
  const { createDefault } = useArticleIntentionFactory()

  const articleIntention = ref<ArticleIntention>(createDefault())

  function reset() {
    articleIntention.value = createDefault()
  }

  return {
    articleIntention,
    reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useArticleIntentionOneStore, import.meta.hot))
}
