import type { FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import { apiCreateOne, apiFetchList, apiFetchListBatch, apiFetchOne, apiUpdateOne } from '@anzusystems/common-admin'
import type { AxiosInstance } from 'axios'
import type { CustomForm } from '@/types/common/CustomForm'

const SYSTEM = 'common'
export const ENTITY = 'customForm'

export const useCustomFormApi = (client: () => AxiosInstance, endPoint = '/adm/v1/custom-form') => {
  const fetchCustomFormList = (pagination: Pagination, filterBag: FilterBag) =>
    apiFetchList<CustomForm[]>(client, endPoint, {}, pagination, filterBag, SYSTEM, ENTITY)

  const fetchCustomFormListAll = (filterBag: FilterBag | undefined = undefined) =>
    apiFetchListBatch<CustomForm[]>(client, endPoint, {}, 'id', true, filterBag, 100, SYSTEM, ENTITY)

  const fetchCustomForm = (id: IntegerId) => apiFetchOne<CustomForm>(client, endPoint + '/:id', { id }, SYSTEM, ENTITY)

  const createCustomForm = (data: CustomForm) => apiCreateOne<CustomForm>(client, data, endPoint, {}, SYSTEM, ENTITY)

  const updateCustomForm = (id: IntegerId, data: CustomForm) =>
    apiUpdateOne<CustomForm>(client, data, endPoint + '/:id', { id }, SYSTEM, ENTITY)

  return {
    fetchCustomFormList,
    fetchCustomFormListAll,
    fetchCustomForm,
    createCustomForm,
    updateCustomForm,
  }
}
