import type { IntegerId } from '@anzusystems/common-admin'
import { defineCached } from '@anzusystems/common-admin'
import { fetchAutoDistributionListByIds } from '@/services/api/cms/autoDistributionApi'
import type { AutoDistribution, AutoDistributionMinimal } from '@/types/cms/AutoDistribution'

const mapFullToMinimal = (autoDistribution: AutoDistribution): AutoDistributionMinimal => ({
  id: autoDistribution.id,
  title: autoDistribution.texts.title,
})

const mapIdToMinimal = (id: IntegerId): AutoDistributionMinimal => {
  return { id: id, title: '' }
}

const { cache, toFetch, fetch, add, addManual, addManualMinimal, has, get, isLoaded } = defineCached<
  IntegerId,
  AutoDistribution,
  AutoDistributionMinimal
>(mapFullToMinimal, mapIdToMinimal, fetchAutoDistributionListByIds)

export const useCachedAutoDistributions = () => {
  return {
    addManualToCachedAutoDistributions: addManual,
    addManualMinimalToCachedAutoDistributions: addManualMinimal,
    addToCachedAutoDistributions: add,
    fetchCachedAutoDistributions: fetch,
    toFetchCachedAutoDistributions: toFetch,
    cachedAutoDistributions: cache,
    hasCachedAutoDistribution: has,
    getCachedAutoDistribution: get,
    isLoadedCachedAutoDistribution: isLoaded,
  }
}
