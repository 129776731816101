import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import { useDesignSettingsFactory } from '@/model/cms/factory/DesignSettingsFactory'
import type { DesignSettings } from '@/types/cms/DesignSettings'
import type { PropertyValue } from '@/types/common/PropertyValue'

export const useDesignSettingsOneStore = defineStore('cmsDesignSettingsOneStore', () => {
  const { createDesignSettings } = useDesignSettingsFactory()

  const designSettings = ref(createDesignSettings())
  const headerTemplateParams = ref<PropertyValue[]>([])

  function setDesignSettings(newDesignSettings: DesignSettings): void {
    designSettings.value = newDesignSettings
    let position = 0
    for (const [property, value] of Object.entries(newDesignSettings.header.templateParams)) {
      headerTemplateParams.value.push({ id: position, property, value, position: ++position })
    }
  }

  function mapHeaderTemplateParamsToDesignSettings(): void {
    const templateParams: Record<string, string> = {}
    for (const headerTemplateParam of headerTemplateParams.value) {
      templateParams[headerTemplateParam.property] = headerTemplateParam.value
    }
    designSettings.value.header.templateParams = templateParams
  }

  function reset() {
    designSettings.value = createDesignSettings()
    headerTemplateParams.value = []
  }

  return {
    reset,
    designSettings,
    headerTemplateParams,
    mapHeaderTemplateParamsToDesignSettings,
    setDesignSettings,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDesignSettingsOneStore, import.meta.hot))
}
