<script lang="ts" setup>
import type { IntegerId } from '@anzusystems/common-admin'
import { ACachedChip } from '@anzusystems/common-admin'
import { ROUTE } from '@/router/routes'
import { useSiteGroups } from '@/views/cms/siteGroup/composables/siteGroupsAll'

withDefaults(
  defineProps<{
    id: null | IntegerId | undefined
  }>(),
  {}
)

const { getSiteGroup } = useSiteGroups()
</script>

<template>
  <ACachedChip
    :id="id"
    :get-cached-fn="getSiteGroup"
    :route="ROUTE.CMS.SITE_GROUP.DETAIL"
    display-text-path="title"
  />
</template>
