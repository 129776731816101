import { ROUTE } from '@/router/routes'

export const commonCustomFormRoutes = [
  {
    path: '/custom-form',
    name: ROUTE.COMMON.CUSTOM_FORM.LIST,
    component: () => import('@/views/common/customForm/CustomFormListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/custom-form/:id(\\d+)/edit',
    name: ROUTE.COMMON.CUSTOM_FORM.EDIT,
    component: () => import('@/views/common/customForm/CustomFormEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/custom-form/:id(\\d+)',
    name: ROUTE.COMMON.CUSTOM_FORM.DETAIL,
    component: () => import('@/views/common/customForm/CustomFormDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
]
