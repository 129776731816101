<script setup lang="ts">
import { AFormTextarea, ARow, useCollabHelpers } from '@anzusystems/common-admin'
import type { CollabRoom } from '@/types/Collab'
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import { storeToRefs } from 'pinia'
import { useCachedUsers } from '@/views/cms/user/composables/cachedUsers'
import { useArticleUpdateValidation } from '@/views/cms/article/composables/articleValidations'
import ArticleRoute from '@/views/cms/article/components/ArticleRoute.vue'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const { createCollabFieldConfig } = useCollabHelpers()

const articleKindOneStore = useArticleKindOneStore()
const { article } = storeToRefs(articleKindOneStore)

const { v$ } = useArticleUpdateValidation(article)

const { cachedUsers } = useCachedUsers()
</script>

<template>
  <VCol>
    <ARow>
      <ArticleRoute
        :is-edit="!readonly"
        :collab="createCollabFieldConfig('route', collabRoom, cachedUsers)"
        :is-moderator="isModerator"
      />
    </ARow>
    <ARow>
      <AFormTextarea
        v-model="article.seo.title"
        :disabled="readonly"
        :v="v$.article.seo.title"
        :collab="createCollabFieldConfig('seo.title', collabRoom, cachedUsers)"
      />
    </ARow>
    <ARow>
      <AFormTextarea
        v-model="article.seo.description"
        :disabled="readonly"
        :v="v$.article.seo.description"
        :collab="createCollabFieldConfig('seo.description', collabRoom, cachedUsers)"
      />
    </ARow>
  </VCol>
</template>
