import {
  type FilterBag,
  type IntegerId,
  isUndefined,
  type Pagination,
  useAlerts,
  type ValueObjectOption,
} from '@anzusystems/common-admin'
import { ref } from 'vue'
import type { DesignSettings } from '@/types/cms/DesignSettings'
import {
  createDesignSettings,
  deleteDesignSettings,
  fetchDesignSettings,
  fetchDesignSettingsList,
  fetchDesignSettingsListByIds,
  updateDesignSettings,
} from '@/services/api/cms/designSettingsApi'
import { storeToRefs } from 'pinia'
import { useDesignSettingsOneStore } from '@/stores/cms/designSettingsStore'
import useVuelidate from '@vuelidate/core'
import { useRouter } from 'vue-router'
import { ROUTE } from '@/router/routes'

const { showValidationError, showRecordWas, showErrorsDefault } = useAlerts()

const datatableHiddenColumns = ref<Array<string>>([])
const listLoading = ref(false)
const detailLoading = ref(false)
const saveButtonLoading = ref(false)

export const useDesignSettingsListActions = () => {
  const listItems = ref<DesignSettings[]>([])

  const fetchList = async (pagination: Pagination, filterBag: FilterBag) => {
    listLoading.value = true
    try {
      listItems.value = await fetchDesignSettingsList(pagination, filterBag)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      listLoading.value = false
    }
  }

  return {
    datatableHiddenColumns,
    listLoading,
    fetchList,
    listItems,
  }
}

export const useDesignSettingsDetailActions = () => {
  const designSettingsOneStore = useDesignSettingsOneStore()
  const { designSettings } = storeToRefs(designSettingsOneStore)

  const fetchData = async (id: IntegerId) => {
    detailLoading.value = true
    try {
      const designSettingsRes = await fetchDesignSettings(id)
      designSettingsOneStore.setDesignSettings(designSettingsRes)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  return {
    designSettings,
    detailLoading,
    fetchData,
    resetStore: designSettingsOneStore.reset,
  }
}

export const useDesignSettingsCreateEditActions = () => {
  const v$ = useVuelidate()
  const designSettingsOneStore = useDesignSettingsOneStore()
  const { designSettings, headerTemplateParams } = storeToRefs(designSettingsOneStore)

  const fetchData = async (id: IntegerId) => {
    detailLoading.value = true
    try {
      const designSettingsRes = await fetchDesignSettings(id)
      designSettingsOneStore.setDesignSettings(designSettingsRes)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  const onUpdate = async (onSuccess: ((designSettings: DesignSettings) => void) | undefined = undefined) => {
    try {
      saveButtonLoading.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      designSettingsOneStore.mapHeaderTemplateParamsToDesignSettings()
      const res = await updateDesignSettings(
        designSettingsOneStore.designSettings.id,
        designSettingsOneStore.designSettings
      )
      showRecordWas('updated')
      if (!isUndefined(onSuccess)) onSuccess(res)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  const onCreate = async (onSuccess: ((designSettings: DesignSettings) => void) | undefined = undefined) => {
    try {
      saveButtonLoading.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      designSettingsOneStore.mapHeaderTemplateParamsToDesignSettings()
      const designSettingsRes = await createDesignSettings(designSettingsOneStore.designSettings)
      showRecordWas('created')
      if (!isUndefined(onSuccess)) onSuccess(designSettingsRes)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  return {
    saveButtonLoading,
    detailLoading,
    designSettings,
    headerTemplateParams,
    fetchData,
    onUpdate,
    onCreate,
    resetStore: designSettingsOneStore.reset,
  }
}

export const useDesignSettingsDeleteActions = () => {
  const router = useRouter()

  const onDelete = async (id: IntegerId) => {
    try {
      await deleteDesignSettings(id)
      showRecordWas('deleted')
      router.push({ name: ROUTE.CMS.DESIGN_SETTINGS.LIST })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      // closeDeleteDialog()
    }
  }

  return {
    onDelete,
  }
}

export const useDesignSettingsSelectActions = () => {
  const fetchItems = async (pagination: Pagination, filterBag: FilterBag) => {
    const designSettingsList = await fetchDesignSettingsList(pagination, filterBag)

    return <ValueObjectOption<IntegerId>[]>designSettingsList.map((designSettings: DesignSettings) => ({
      title: designSettings.texts.title,
      value: designSettings.id,
    }))
  }

  const fetchItemsByIds = async (ids: IntegerId[]) => {
    const designSettingsList = await fetchDesignSettingsListByIds(ids)

    return <ValueObjectOption<IntegerId>[]>designSettingsList.map((designSettings: DesignSettings) => ({
      title: designSettings.texts.title,
      value: designSettings.id,
    }))
  }

  return {
    fetchItems,
    fetchItemsByIds,
  }
}
