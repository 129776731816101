<script setup lang="ts">
import ArticlePropagation from '@/views/cms/article/components/ArticlePropagation.vue'
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import { storeToRefs } from 'pinia'
import type { CollabRoom } from '@/types/Collab'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const articleKindOneStore = useArticleKindOneStore()
const { article } = storeToRefs(articleKindOneStore)
</script>

<template>
  <ArticlePropagation
    v-if="article.docId"
    :article-doc-id="article.docId"
  />
</template>
