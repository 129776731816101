<script lang="ts" setup>
import { envConfig } from '@/services/EnvConfigService'
import { AAdminSwitcher, ACurrentUserDropdown } from '@anzusystems/common-admin'
import { ROUTE } from '@/router/routes'
import { computed } from 'vue'
import { mapFullToMinimal } from '@/views/cms/user/composables/cachedUsers'
import type { User } from '@/types/cms/User'
import { useAuth } from '@/composables/auth/auth'
import { useAppNotificationStore } from '@/composables/appNotification/appNotificationStore'
import { NOTIFICATION_UNREAD_MAX } from '@/composables/appNotification/appNotificationInit'

const { useCurrentUser } = useAuth()
const { currentUser } = useCurrentUser<User>('cms')

const userMinimal = computed(() => {
  return mapFullToMinimal(currentUser.value as User)
})

const appNotificationStore = useAppNotificationStore()
</script>

<template>
  <div class="align-center justify-space-evenly py-1">
    <AAdminSwitcher :config-url="envConfig.adminSwitcherConfigUrl" />
    <ACurrentUserDropdown
      :current-user="userMinimal"
      :settings-route-name="ROUTE.SYSTEM.SETTINGS"
      :logout-route-name="ROUTE.SYSTEM.LOGOUT"
    />
    <VBtn
      v-tooltip="'Notifications'"
      variant="text"
      size="small"
      icon
      @click.stop="appNotificationStore.appNotificationSidebar = !appNotificationStore.appNotificationSidebar"
    >
      <VBadge
        v-if="appNotificationStore.unreadCount > 0"
        color="success"
        :content="appNotificationStore.unreadCount"
        :max="NOTIFICATION_UNREAD_MAX"
      >
        <VIcon icon="mdi-bell" />
      </VBadge>
      <VIcon
        v-else
        icon="mdi-bell"
      />
    </VBtn>
  </div>
</template>
