<script lang="ts" setup>
import {
  AActionCloseButton,
  AActionDeleteButton,
  AActionEditButton,
  ACard,
  stringToInt,
} from '@anzusystems/common-admin'
import { ROUTE } from '@/router/routes'
import { onBeforeUnmount, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import NewsletterDetail from '@/views/cms/newsletter/components/NewsletterDetail.vue'
import ActionbarWrapper from '@/components/wrappers/ActionbarWrapper.vue'
import {
  useNewsletterDeleteActions,
  useNewsletterDetailActions,
} from '@/views/cms/newsletter/composables/newsletterActions'

const route = useRoute()
const id = stringToInt(route.params.id)

const { onDelete } = useNewsletterDeleteActions()

const { detailLoading, fetchData, resetStore, newsletter } = useNewsletterDetailActions()

const getDetail = () => {
  fetchData(id)
}

onMounted(() => {
  getDetail()
})

onBeforeUnmount(() => {
  resetStore()
})
</script>

<template>
  <ActionbarWrapper :last-breadcrumb-title="newsletter.texts.title">
    <template #buttons>
      <AActionEditButton
        v-if="!detailLoading"
        :record-id="id"
        :route-name="ROUTE.CMS.NEWSLETTER.EDIT"
      />
      <AActionDeleteButton
        v-if="!detailLoading"
        @delete-record="onDelete(id)"
      />
      <AActionCloseButton :route-name="ROUTE.CMS.NEWSLETTER.LIST" />
    </template>
  </ActionbarWrapper>

  <ACard :loading="detailLoading">
    <VCardText>
      <NewsletterDetail />
    </VCardText>
  </ACard>
</template>
