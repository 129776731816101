import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type {
  ContentItemDetailKindImportantNews,
  ContentItemKindImportantNews,
} from '@/types/cms/ContentItemKind/ContentItemKindImportantNews'

const { createAbstractContentItemKind, createAbstractContentItemDetailKind } = useContentItemKindFactory()

export function useContentItemKindImportantNewsFactory() {
  const create = (): ContentItemKindImportantNews => {
    return {
      ...createAbstractContentItemKind(),
      dates: {
        showAtFrom: null,
        showAtUntil: null,
      },
      items: [],
      discriminator: ContentItemDiscriminator.ImportantNews,
      _resourceName: 'contentItemKindImportantNews',
    }
  }

  const createDetail = (): ContentItemDetailKindImportantNews => {
    return {
      ...createAbstractContentItemDetailKind(),
      dates: {
        showAtFrom: null,
        showAtUntil: null,
      },
      items: [],
      discriminator: ContentItemDiscriminator.ImportantNews,
      _resourceName: 'contentItemKindImportantNews',
    }
  }

  return {
    create,
    createDetail,
  }
}
