import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsArticleIntentionRoutes: RouteRecordRaw[] = [
  {
    path: '/article-intention',
    name: ROUTE.CMS.ARTICLE_INTENTION.LIST,
    component: () => import('@/views/cms/articleIntention/ArticleIntentionListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/article-intention/create',
    name: ROUTE.CMS.ARTICLE_INTENTION.CREATE,
    component: () => import('@/views/cms/articleIntention/ArticleIntentionCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ARTICLE_INTENTION_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/article-intention/:id(\\d+)/edit',
    name: ROUTE.CMS.ARTICLE_INTENTION.EDIT,
    component: () => import('@/views/cms/articleIntention/ArticleIntentionEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ARTICLE_INTENTION_READ, ACL.CMS_ARTICLE_INTENTION_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/article-intention/:id(\\d+)',
    name: ROUTE.CMS.ARTICLE_INTENTION.DETAIL,
    component: () => import('@/views/cms/articleIntention/ArticleIntentionDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ARTICLE_INTENTION_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
