import { reactive } from 'vue'
import { type DatatableOrderingOptions, makeFilterHelper, type Pagination } from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'
import { ENTITY } from '@/services/api/cms/stageApi'

const makeFilter = makeFilterHelper(SYSTEM_CMS, ENTITY)

const filter = reactive({
  id: {
    ...makeFilter({ name: 'id' }),
  },
  docId: {
    ...makeFilter({ name: 'docId' }),
  },
  stage: {
    ...makeFilter({ name: 'stage' }),
  },
  desk: {
    ...makeFilter({ name: 'desk', variant: 'in' }),
  },
  status: {
    ...makeFilter({ name: 'status' }),
  },
  priority: {
    ...makeFilter({ name: 'priority' }),
  },
})

export function useArticleStageListFilter() {
  return filter
}

const articleFilter = reactive({
  docId: {
    ...makeFilter({ name: 'docId', field: 'articleDocId' }),
  },
})

export function useArticleArticleStageFilter() {
  return articleFilter
}

const orderItems: DatatableOrderingOptions = [
  { id: 1, titleT: 'system.datatable.ordering.priority' },
  { id: 2, titleT: 'common.system.datatable.ordering.mostRecent', sortBy: { key: 'createdAt', order: 'desc' } },
  { id: 3, titleT: 'common.system.datatable.ordering.oldest', sortBy: { key: 'createdAt', order: 'asc' } },
]

export function useArticleStageListOrder(pagination: Pagination) {
  pagination.sortBy = null

  return orderItems
}
