import type { NewsletterPromo } from '@/types/cms/NewsletterPromo'
import { NewsletterPromoTypeDefault } from '@/model/cms/valueObject/NewsletterPromoType'
import { dateTimeNow } from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'
import { NewsletterPromoColorDefault } from '@/model/cms/valueObject/NewsletterPromoColor'
import { SubscribeTargetingDefault } from '@/model/cms/valueObject/SubscribeTargeting'
import { NewsletterPromoIconDefault } from '@/model/cms/valueObject/NewsletterPromoIcon'

export function useNewsletterPromoFactory() {
  const createDefaultNewsletterPromo = (): NewsletterPromo => {
    return {
      id: 0,
      texts: {
        title: '',
        titlePublic: '',
        body: '',
        linkedText: '',
      },
      dates: {
        startAt: dateTimeNow(),
        endAt: null,
      },
      enabled: true,
      image: null,
      url: '',
      type: NewsletterPromoTypeDefault,
      color: NewsletterPromoColorDefault,
      icon: NewsletterPromoIconDefault,
      subscribeTargeting: SubscribeTargetingDefault,
      newsletters: [],
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
      _resourceName: 'newsletterPromo',
      _system: SYSTEM_CMS,
    }
  }

  return {
    createDefaultNewsletterPromo,
  }
}
