import type { Ref } from 'vue'
import type { PropagationSuggest } from '@/types/cms/PropagationSuggest'
import { useValidate } from '@anzusystems/common-admin'
import useVuelidate from '@vuelidate/core'

export function usePropagationSuggestValidation(propagationSuggest: Ref<PropagationSuggest>) {
  const { maxLength, required } = useValidate()

  const rules = {
    propagationSuggest: {
      inbox: {
        required,
      },
      comment: {
        maxLength: maxLength(255),
      },
    },
  }
  // @ts-ignore
  const v$ = useVuelidate(rules, { propagationSuggest })

  return {
    v$,
  }
}
