import { SYSTEM_CMS } from '@/model/systems'
import type { Bookmark } from '@/types/cms/Bookmark'
import { dateTimeNow } from '@anzusystems/common-admin'

export function useBookmarkFactory() {
  const createDefault = (): Bookmark => {
    return {
      id: 0,
      siteGroup: null,
      enabled: true,
      items: [],
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
      _resourceName: 'bookmark',
      _system: SYSTEM_CMS,
    }
  }

  return {
    createDefault,
  }
}
