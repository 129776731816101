import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const externalContentRoutes: RouteRecordRaw[] = [
  {
    path: '/external-content',
    name: ROUTE.CONTENT_HUB.EXTERNAL_CONTENT.LIST,
    component: () => import('@/views/contentHub/externalContent/ExternalContentListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CONTENT_HUB_EXTERNAL_CONTENT_READ],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/external-content/:id(\\d+)',
    name: ROUTE.CONTENT_HUB.EXTERNAL_CONTENT.DETAIL,
    component: () => import('@/views/contentHub/externalContent/ExternalContentDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CONTENT_HUB_EXTERNAL_CONTENT_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
