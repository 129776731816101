import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { IntegerId } from '@anzusystems/common-admin'
import { apiFetchByIds } from '@anzusystems/common-admin'
import type { User } from '@/types/cms/User'

const END_POINT = '/adm/v1/user'
export const CURRENT_USER_END_POINT = END_POINT + '/current'
export const ENTITY = 'user'

export const fetchUserListByIds = (ids: IntegerId[]) =>
  apiFetchByIds<User[]>(cmsClient, ids, END_POINT, {}, SYSTEM_CMS, ENTITY)
