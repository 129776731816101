<script lang="ts" setup>
import { ADialogToolbar, AFormValueObjectOptionsSelect, ARow } from '@anzusystems/common-admin'
import { useI18n } from 'vue-i18n'
import type { Newsletter } from '@/types/cms/Newsletter'
import {
  useNewsletterScheduleDialogActions
} from '@/views/cms/newsletterSchedule/composables/newsletterScheduleActions'
import { useNewsletterScheduleType } from '@/model/cms/valueObject/NewsletterSchedule'

const props = withDefaults(
  defineProps<{
    newsletter: Newsletter
  }>(),
  {}
)

const { t } = useI18n()
const { dayOfWeekOptions, dayOfMonthOptions, hourOptions, minuteOptions } = useNewsletterScheduleType()

const {
  dialog,
  currentNewsletterSchedule,
  createOrUpdateNewsletterSchedule,
  saveButtonLoading,
  closeDialog,
} = useNewsletterScheduleDialogActions()

const onConfirm = () => {
  createOrUpdateNewsletterSchedule(props.newsletter)
}
</script>

<template>
  <VDialog
    :model-value="dialog"
    :width="500"
  >
    <VCard v-if="dialog">
      <ADialogToolbar @on-cancel="closeDialog">
        {{ t('cms.newsletter.model.newsletterSchedule.title') }}
      </ADialogToolbar>
      <VCardText>
        <ARow :title="t('cms.newsletter.model.newsletterSchedule.dayOfWeek')">
          <AFormValueObjectOptionsSelect
            v-model="currentNewsletterSchedule.dayOfWeek"
            :items="dayOfWeekOptions"
          />
        </ARow>
        <ARow :title="t('cms.newsletter.model.newsletterSchedule.dayOfMonth')">
          <AFormValueObjectOptionsSelect
            v-model="currentNewsletterSchedule.dayOfMonth"
            :items="dayOfMonthOptions"
          />
        </ARow>
        <ARow :title="t('cms.newsletter.model.newsletterSchedule.hour')">
          <AFormValueObjectOptionsSelect
            v-model="currentNewsletterSchedule.hour"
            :items="hourOptions"
          />
        </ARow>
        <ARow :title="t('cms.newsletter.model.newsletterSchedule.minute')">
          <AFormValueObjectOptionsSelect
            v-model="currentNewsletterSchedule.minute"
            :items="minuteOptions"
          />
        </ARow>
        <ARow>
          <VSwitch
            v-model="currentNewsletterSchedule.enabled"
            :label="t('cms.newsletter.model.newsletterSchedule.enabled')"
          />
        </ARow>
      </VCardText>
      <VCardActions>
        <VSpacer />
        <ABtnTertiary @click.stop="closeDialog">
          {{ t('common.button.cancel') }}
        </ABtnTertiary>
        <ABtnPrimary
          :loading="saveButtonLoading"
          @click.stop="onConfirm"
        >
          {{ t('common.button.confirm') }}
        </ABtnPrimary>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
