import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsQuizRoutes: RouteRecordRaw[] = [
  {
    path: '/quiz',
    name: ROUTE.CMS.QUIZ.LIST,
    component: () => import('@/views/cms/quiz/QuizListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/quiz/create',
    name: ROUTE.CMS.QUIZ.CREATE,
    component: () => import('@/views/cms/quiz/QuizCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_QUIZ_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/quiz/:id(\\d+)/edit',
    name: ROUTE.CMS.QUIZ.EDIT,
    component: () => import('@/views/cms/quiz/QuizEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_QUIZ_READ, ACL.CMS_QUIZ_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/quiz/:id(\\d+)',
    name: ROUTE.CMS.QUIZ.DETAIL,
    component: () => import('@/views/cms/quiz/QuizDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_QUIZ_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
