import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { DocId, FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import {
  apiAnyRequest,
  apiCreateOne,
  apiDeleteOne,
  apiFetchByIds,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
} from '@anzusystems/common-admin'
import type { Bookmark } from '@/types/cms/Bookmark'

const END_POINT = '/adm/v1/bookmark'
export const ENTITY = 'bookmark'

export const fetchBookmarkListByIds = (ids: IntegerId[]) =>
  apiFetchByIds<Bookmark[]>(cmsClient, ids, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const fetchBookmarkList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<Bookmark[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchArticleBookmark = async (docId: DocId) => {
  return await apiAnyRequest<Bookmark>(
    cmsClient,
    'GET',
    END_POINT + '/article/:docId',
    { docId },
    undefined,
    SYSTEM_CMS,
    ENTITY
  )
}

export const fetchBookmark = (id: IntegerId) =>
  apiFetchOne<Bookmark>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createBookmark = (data: Bookmark) =>
  apiCreateOne<Bookmark>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updateBookmark = (id: IntegerId, data: Bookmark) =>
  apiUpdateOne<Bookmark>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const deleteBookmark = (id: IntegerId) =>
  apiDeleteOne<Bookmark>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)
