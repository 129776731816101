import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ValueObjectOption } from '@anzusystems/common-admin'

export const ArticleStatus = {
  Draft: 'draft',
  Ready: 'ready',
  Published: 'published',
} as const

export type ArticleStatusType = (typeof ArticleStatus)[keyof typeof ArticleStatus]
export const ArticleStatusDefault = ArticleStatus.Draft

export function useArticleStatus() {
  const { t } = useI18n()

  const articleStatusOptions = ref<ValueObjectOption<ArticleStatusType>[]>([
    {
      value: ArticleStatus.Draft,
      title: t('cms.articleKind.articleStatus.draft'),
      color: 'default',
    },
    {
      value: ArticleStatus.Ready,
      title: t('cms.articleKind.articleStatus.ready'),
      color: 'warning',
    },
    {
      value: ArticleStatus.Published,
      title: t('cms.articleKind.articleStatus.published'),
      color: 'success',
    },
  ])

  const getArticleStatusOption = (value: ArticleStatusType) => {
    return articleStatusOptions.value.find((item) => item.value === value)
  }

  return {
    articleStatusOptions,
    getArticleStatusOption,
  }
}
