import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { ContentItemKindSearch } from '@/types/cms/ContentItemKind/ContentItemKindSearch'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindSearchFactory() {
  const create = (): ContentItemKindSearch => {
    return {
      ...createAbstractContentItemKind(),
      listSiteGroup: null,
      listSite: null,
      rubrics: [],
      allowListing: true,
      itemsLimit: 20,
      discriminator: ContentItemDiscriminator.Search,
      _resourceName: 'contentItemKindSearch',
    }
  }

  return {
    create,
  }
}
