import { isUndefined, useAlerts, usePagination } from '@anzusystems/common-admin'
import type { NewsletterSchedule } from '@/types/cms/NewsletterSchedule'
import type { Newsletter } from '@/types/cms/Newsletter'
import { useNewsletterScheduleStore } from '@/stores/cms/newsletterScheduleStore'
import { reactive, ref } from 'vue'
import { useNewsletterScheduleFactory } from '@/model/cms/factory/NewsletterScheduleFactory'
import {
  createNewsletterSchedule,
  deleteNewsletterSchedule,
  fetchNewsletterScheduleList,
  updateNewsletterSchedule,
} from '@/services/api/cms/newsletterScheduleApi'
import {
  useNewsletterScheduleListFilter
} from '@/model/cms/filter/NewsletterScheduleFilter'

const dialog = ref(false)
const { createDefaultNewsletterSchedule } = useNewsletterScheduleFactory()
const currentNewsletterSchedule = reactive<NewsletterSchedule>(
  createDefaultNewsletterSchedule()
)
const newsletterSchedulesLoading = ref(false)
const saveButtonLoading = ref(false)
const { showErrorsDefault, showRecordWas } = useAlerts()
const pagination = usePagination()

export function useNewsletterScheduleDialogActions() {
  const newsletterScheduleStore = useNewsletterScheduleStore()

  pagination.rowsPerPage = 100

  const closeDialog = () => {
    currentNewsletterSchedule.id = 0
    currentNewsletterSchedule.newsletter = 0
    currentNewsletterSchedule.dayOfMonth = null
    currentNewsletterSchedule.dayOfWeek = null
    currentNewsletterSchedule.hour = null
    currentNewsletterSchedule.minute = null

    dialog.value = false
  }

  const createOrUpdateNewsletterSchedule = async (newsletter: Newsletter) => {
    if (0 === newsletter.id) return
    try {
      currentNewsletterSchedule.newsletter = newsletter.id
      saveButtonLoading.value = true
      if (currentNewsletterSchedule.id > 0) {
        const res = await updateNewsletterSchedule(
          currentNewsletterSchedule.id,
          currentNewsletterSchedule
        )
        currentNewsletterSchedule.id = res.id
        newsletterScheduleStore.setNewsletterSchedule(res)
        showRecordWas('updated')
      } else {
        const res = await createNewsletterSchedule(currentNewsletterSchedule)
        currentNewsletterSchedule.id = res.id
        newsletterScheduleStore.setNewsletterSchedule(res)
        showRecordWas('created')
      }
      dialog.value = false
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  return {
    saveButtonLoading,
    dialog,
    currentNewsletterSchedule,
    createOrUpdateNewsletterSchedule,
    closeDialog,
  }
}

export function useNewsletterScheduleListActions() {
  const newsletterScheduleStore = useNewsletterScheduleStore()

  const openDialog = (
    newsletterSchedule: NewsletterSchedule | undefined,
    newsletter: Newsletter
  ) => {
    dialog.value = true
    currentNewsletterSchedule.newsletter = newsletter.id
    if (isUndefined(newsletterSchedule)) {
      currentNewsletterSchedule.id = 0
      currentNewsletterSchedule.newsletter = 0
      currentNewsletterSchedule.dayOfMonth = null
      currentNewsletterSchedule.dayOfWeek = null
      currentNewsletterSchedule.hour = null
      currentNewsletterSchedule.minute = null
      return
    }
    currentNewsletterSchedule.id = newsletterSchedule.id
    currentNewsletterSchedule.newsletter = newsletterSchedule.newsletter
    currentNewsletterSchedule.dayOfMonth = newsletterSchedule.dayOfMonth
    currentNewsletterSchedule.dayOfWeek = newsletterSchedule.dayOfWeek
    currentNewsletterSchedule.hour = newsletterSchedule.hour
    currentNewsletterSchedule.minute = newsletterSchedule.minute
  }

  const fetchNewsletterSchedules = async (newsletterId: number) => {
    const filterBag = useNewsletterScheduleListFilter()
    filterBag.newsletter.model = newsletterId
    newsletterSchedulesLoading.value = true
    pagination.sortBy = 'id'
    try {
      newsletterScheduleStore.setNewsletterScheduleList(
        await fetchNewsletterScheduleList(pagination, filterBag)
      )
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      newsletterSchedulesLoading.value = false
    }
  }

  const deleteNewsletterScheduleById = async (id: number) => {
    try {
      await deleteNewsletterSchedule(id)
      newsletterScheduleStore.removeNewsletterSchedule(id)
      showRecordWas('deleted')
    } catch (error) {
      showErrorsDefault(error)
      return false
    }
    return true
  }

  return {
    openDialog,
    deleteNewsletterScheduleById,
    fetchNewsletterSchedules,
    newsletterSchedulesLoading,
  }
}
