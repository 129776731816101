import { apiAnyRequest, dateTimeNow, type DatetimeUTC, type IntegerId, isDefined } from '@anzusystems/common-admin'
import { notificationClient } from '@/services/api/clients/notificationClient'
import { SYSTEM_NOTIFICATION } from '@/model/systems'
import type { AppNotification } from '@/composables/appNotification/appNotificationEventBus'

export const LIST_LIMIT = 30

export const fetchNotificationList = (
  userId: IntegerId,
  sinceId: string | undefined = undefined,
  read: boolean | undefined = undefined,
  limit = LIST_LIMIT
) => {
  return apiAnyRequest<AppNotification[]>(
    notificationClient,
    'GET',
    '/v1/users/:userId/notifications?destination=browser' +
      `&perPage=${limit}${isDefined(sinceId) ? '&sinceId=' + sinceId : ''}${isDefined(read) ? '&read=' + read : ''}`,
    { userId },
    undefined,
    SYSTEM_NOTIFICATION,
    'notification'
  )
}

export const fetchNotificationCount = (userId: IntegerId, read: boolean | undefined = undefined) => {
  return apiAnyRequest<{ count: number; hasMore: boolean }>(
    notificationClient,
    'GET',
    '/v1/users/:userId/notifications/count?destination=browser' + `${isDefined(read) ? '&read=' + read : ''}`,
    { userId },
    undefined,
    SYSTEM_NOTIFICATION,
    'notification'
  )
}

export const markNotificationsAsReadSinceId = (userId: IntegerId, sinceId: string) => {
  return apiAnyRequest<{
    read: boolean
    sinceId: string
    destination: string
    lastReadAt: DatetimeUTC
  }>(
    notificationClient,
    'PUT',
    '/v1/users/:userId/notifications',
    { userId },
    {
      read: true,
      sinceId: sinceId,
      destination: 'browser',
      lastReadAt: dateTimeNow(),
    },
    SYSTEM_NOTIFICATION,
    'notification'
  )
}

export const markNotificationsAsRead = (userId: IntegerId, ids: string[]) => {
  const now = dateTimeNow()
  return apiAnyRequest<any>(
    notificationClient,
    'PATCH',
    '/v1/users/:userId/notifications',
    { userId },
    ids.map((id) => ({
      id: id,
      read: true,
      lastReadAt: now,
    })),
    SYSTEM_NOTIFICATION,
    'notification'
  )
}
