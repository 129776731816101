<script lang="ts" setup>
import { ROUTE } from '@/router/routes'
import { useI18n } from 'vue-i18n'
import { envConfig } from '@/services/EnvConfigService'
import TestRouteSwitch from '@/components/TestRouteSwitch.vue'

const { t } = useI18n()
</script>

<template>
  <div>
    <VList
      density="compact"
      nav
      color="primary"
    >
      <VListItem
        :to="{ name: ROUTE.SYSTEM.DASHBOARD }"
        prepend-icon="mdi-monitor-dashboard"
        :title="t('sidebar.dashboard')"
      />
      <VListItem
        :to="{ name: ROUTE.CMS.ARTICLE.LIST }"
        prepend-icon="mdi-file-document-outline"
        :title="t('sidebar.content.article')"
      />
      <VListItem
        :to="{ name: ROUTE.CMS.ARTICLE_MINUTE.LIST }"
        prepend-icon="mdi-file-document-outline"
        :title="t('sidebar.content.articleMinute')"
      />
      <VListGroup>
        <template #activator="{ props: listGroupProps }">
          <VListItem
            v-bind="listGroupProps"
            :title="t('sidebar.contentLibrary.title')"
            prepend-icon="mdi-library-outline"
          />
        </template>
        <VListItem
          :to="{ name: ROUTE.CMS.ARTICLE_ARCHIVE.LIST }"
          :title="t('sidebar.content.articleArchive')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.GALLERY.LIST }"
          :title="t('sidebar.content.gallery')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.POLL.LIST }"
          :title="t('sidebar.content.poll')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.QUIZ.LIST }"
          :title="t('sidebar.content.quiz')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.REVIEW.LIST }"
          :title="t('sidebar.content.review')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.TIMELINE.LIST }"
          :title="t('sidebar.content.timeline')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.FAQ.LIST }"
          :title="t('sidebar.content.faq')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.COUNTDOWN.LIST }"
          :title="t('sidebar.content.countdown')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.CROSS_BOX.LIST }"
          :title="t('sidebar.content.crossBox')"
        />
      </VListGroup>
      <VListItem
        :to="{ name: ROUTE.CMS.DISTRIBUTION_MANAGEMENT.LIST }"
        prepend-icon="mdi-view-list"
        :title="t('sidebar.content.distributionManagement')"
      />
      <VListItem
        :to="{ name: ROUTE.CONTENT_HUB.EXTERNAL_CONTENT.LIST }"
        prepend-icon="mdi-newspaper-variant"
        :title="t('sidebar.externalContent.title')"
      />
      <VListItem
        :to="{ name: ROUTE.CMS.AUTO_DISTRIBUTION.LIST }"
        prepend-icon="mdi-chevron-up-box-outline"
        :title="t('sidebar.content.autoDistribution')"
      />
      <VListItem
        :to="{ name: ROUTE.CMS.PAGE.LIST }"
        prepend-icon="mdi-file-cabinet"
        :title="t('sidebar.content.page')"
      />
      <VListGroup>
        <template #activator="{ props: listGroupProps }">
          <VListItem
            v-bind="listGroupProps"
            :title="t('sidebar.minute.title')"
            prepend-icon="mdi-timeline-clock-outline"
          />
        </template>
        <VListItem
          :to="{ name: ROUTE.CMS.MINUTE_TOPIC.LIST }"
          :title="t('sidebar.content.minuteTopic')"
        />
      </VListGroup>
      <VListGroup>
        <template #activator="{ props: listGroupProps }">
          <VListItem
            v-bind="listGroupProps"
            :title="t('sidebar.keywords.title')"
            prepend-icon="mdi-file-key-outline"
          />
        </template>
        <VListItem
          :to="{ name: ROUTE.CMS.KEYWORD.LIST }"
          :title="t('sidebar.content.keyword')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.KEYWORD_MANAGEMENT.LIST }"
          :title="t('sidebar.content.keywordManagement')"
        />
      </VListGroup>
      <VListGroup>
        <template #activator="{ props: listGroupProps }">
          <VListItem
            v-bind="listGroupProps"
            :title="t('sidebar.boxes.title')"
            prepend-icon="mdi-file-table-box-outline"
          />
        </template>
        <VListItem
          :to="{ name: ROUTE.CMS.BOX.LIST }"
          :title="t('sidebar.content.box')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.BOX_GROUP.LIST }"
          :title="t('sidebar.content.boxGroup')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.INBOX.LIST }"
          :title="t('sidebar.content.inbox')"
        />
      </VListGroup>
      <VListGroup>
        <template #activator="{ props: listGroupProps }">
          <VListItem
            v-bind="listGroupProps"
            :title="t('sidebar.content.newsletter')"
            prepend-icon="mdi mdi-account-group"
          />
        </template>
        <VListItem
          :to="{ name: ROUTE.CMS.NEWSLETTER.LIST }"
          :title="t('sidebar.content.newsletter')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.NEWSLETTER_PROMO.LIST }"
          :title="t('sidebar.content.newsletterPromo')"
        />
      </VListGroup>
      <VListGroup>
        <template #activator="{ props: listGroupProps }">
          <VListItem
            v-bind="listGroupProps"
            :title="t('sidebar.people.title')"
            prepend-icon="mdi mdi-account-group"
          />
        </template>
        <VListItem
          :to="{ name: ROUTE.CMS.PERSON.LIST }"
          :title="t('sidebar.content.person')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.ORGANIZATION.LIST }"
          :title="t('sidebar.content.organization')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.EVENT.LIST }"
          :title="t('sidebar.content.event')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.PERSON_POSITION.LIST }"
          :title="t('sidebar.content.person_position')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.PERSON_PAGE.LIST }"
          :title="t('sidebar.content.person_page')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.ORGANIZATION_PAGE.LIST }"
          :title="t('sidebar.content.organization_page')"
        />
      </VListGroup>
      <VListGroup>
        <template #activator="{ props: listGroupProps }">
          <VListItem
            v-bind="listGroupProps"
            :title="t('sidebar.authors.title')"
            prepend-icon="mdi-account-group-outline"
          />
        </template>
        <VListItem
          :to="{ name: ROUTE.CMS.SUPER_AUTHOR.LIST }"
          :title="t('sidebar.content.superAuthor')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.AUTHOR.LIST }"
          :title="t('sidebar.content.author')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.NEWSROOM_SECTION.LIST }"
          :title="t('sidebar.content.newsroomSection')"
        />
      </VListGroup>

      <VListGroup>
        <template #activator="{ props: listGroupProps }">
          <VListItem
            v-bind="listGroupProps"
            :title="t('sidebar.desk.title')"
            prepend-icon="mdi-desk"
          />
        </template>
        <VListItem
          :to="{ name: ROUTE.CMS.DESK.LIST }"
          :title="t('sidebar.content.desk')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.TASK.LIST }"
          :title="t('sidebar.content.task')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.ARTICLE_INTENTION.LIST }"
          :title="t('sidebar.content.articleIntention')"
        />
      </VListGroup>

      <VListGroup>
        <template #activator="{ props: listGroupProps }">
          <VListItem
            v-bind="listGroupProps"
            :title="t('sidebar.authorizations.title')"
            prepend-icon="mdi-account-key"
          />
        </template>
        <VListItem
          :to="{ name: ROUTE.CMS.WIZARD.LIST }"
          :title="t('sidebar.content.wizard')"
        />
        <VListItem
          :to="{ name: ROUTE.COMMON.ANZU_USER.LIST }"
          :title="t('sidebar.common.anzuUser')"
          data-cy="navbar-user-permissions"
        />
        <VListItem
          :to="{ name: ROUTE.COMMON.PERMISSION_GROUP.LIST }"
          :title="t('sidebar.common.permissionGroup')"
          data-cy="navbar-permission-group"
        />
      </VListGroup>
      <VListGroup>
        <template #activator="{ props: listGroupProps }">
          <VListItem
            v-bind="listGroupProps"
            :title="t('sidebar.system.title')"
            prepend-icon="mdi-cogs"
          />
        </template>
        <VListItem
          :to="{ name: ROUTE.CMS.SITE.LIST }"
          :title="t('sidebar.content.site')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.SITE_GROUP.LIST }"
          :title="t('sidebar.content.siteGroup')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.RUBRIC.LIST }"
          :title="t('sidebar.content.rubric')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.ROUTE.LIST }"
          :title="t('sidebar.content.route')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.ROUTE_SETTINGS.LIST }"
          :title="t('sidebar.content.routeSettings')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.LINKED_LIST.LIST }"
          :title="t('sidebar.content.linkedList')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.STAGE.LIST }"
          :title="t('sidebar.content.stage')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.JOB.LIST }"
          :title="t('sidebar.content.job')"
          data-cy="job-settings"
        />
        <VListItem
          :to="{ name: ROUTE.COMMON.CUSTOM_FORM.LIST }"
          :title="t('sidebar.content.customForm')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.TRENDING_ARTICLE_GROUP_LIST.LIST }"
          :title="t('sidebar.content.trendingArticleGroupList')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.ADVERT_SETTINGS.LIST }"
          :title="t('sidebar.content.advertSettings')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.DESIGN_SETTINGS.LIST }"
          :title="t('sidebar.content.designSettings')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.LAYOUT_TEMPLATE.LIST }"
          :title="t('sidebar.content.layoutTemplate')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.TARGET_POSITION.LIST }"
          :title="t('sidebar.content.targetPosition')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.PROMO_LINK.LIST }"
          :title="t('sidebar.content.promoLink')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.EXTERNAL_SNIPPET.LIST }"
          :title="t('sidebar.content.externalSnippet')"
        />
        <VListItem
          :to="{ name: ROUTE.CMS.PUBLIC_EXPORT.LIST }"
          :title="t('sidebar.content.publicExport')"
        />
        <VListItem
          :to="{ name: ROUTE.COMMON.LOG.LIST }"
          :title="t('sidebar.common.log')"
        />
        <VListItem
          v-if="envConfig.collab.enabled"
          :to="{ name: ROUTE.CMS._PLAYGROUND.COLLAB_DETAIL }"
          :title="t('sidebar.content._playground.collab')"
        />
      </VListGroup>
    </VList>
    <TestRouteSwitch
      v-if="envConfig.anzutapDebug"
      :from="{ name: ROUTE.CMS.ARTICLE.EMPTY }"
      :to="{ name: ROUTE.CMS.ARTICLE.EDIT, params: { id: 30000350 } }"
    />
  </div>
</template>
