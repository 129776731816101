<script lang="ts" setup>
import { useArticleSelectStore } from '@/views/cms/article/composables/articleSelectStore'
import { AFilterDatetimePicker, AFilterString } from '@anzusystems/common-admin'
import FilterAuthorRemoteAutocomplete from '@/views/cms/author/components/FilterAuthorRemoteAutocomplete.vue'
import FilterRubricRemoteAutocomplete from '@/views/cms/rubric/components/FilterRubricRemoteAutocomplete.vue'
import FilterSiteRemoteAutocomplete from '@/views/cms/site/components/FilterSiteRemoteAutocomplete.vue'
import FilterKeywordAutocomplete from '@/views/cms/keyword/components/FilterKeywordAutocomplete.vue'

withDefaults(
  defineProps<{
    touched: boolean
  }>(),
  {}
)
const emit = defineEmits<{
  (e: 'submitFilter'): void
  (e: 'update:touched', data: boolean): void
}>()

const { filter } = useArticleSelectStore()

const submitFilter = () => {
  emit('update:touched', false)
  emit('submitFilter')
}

const onAnyFilterUpdate = () => {
  emit('update:touched', true)
}
</script>

<template>
  <VRow>
    <VCol cols="12">
      <AFilterString
        v-model="filter.text"
        @update:model-value="onAnyFilterUpdate"
        @keydown.enter="submitFilter"
      />
    </VCol>
    <VCol cols="12">
      <AFilterString
        v-model="filter.url"
        @update:model-value="onAnyFilterUpdate"
        @keydown.enter="submitFilter"
      />
    </VCol>
    <VCol cols="12">
      <FilterKeywordAutocomplete
        v-model="filter.keywords"
        @update:model-value="onAnyFilterUpdate"
        @keydown.enter="submitFilter"
      />
    </VCol>
    <VCol cols="12">
      <FilterAuthorRemoteAutocomplete
        v-model="filter.articleAuthors"
        @update:model-value="onAnyFilterUpdate"
        @keydown.enter="submitFilter"
      />
    </VCol>
    <VCol cols="12">
      <FilterRubricRemoteAutocomplete
        v-model="filter.rubric"
        :site-id="filter.site.model"
        @update:model-value="onAnyFilterUpdate"
        @keydown.enter="submitFilter"
      />
    </VCol>
    <VCol cols="12">
      <AFilterDatetimePicker
        v-model="filter.publicPublishedAtFrom"
        @update:model-value="onAnyFilterUpdate"
        @keydown.enter="submitFilter"
      />
    </VCol>
    <VCol cols="12">
      <AFilterDatetimePicker
        v-model="filter.publicPublishedAtUntil"
        @update:model-value="onAnyFilterUpdate"
        @keydown.enter="submitFilter"
      />
    </VCol>
    <VCol cols="12">
      <FilterSiteRemoteAutocomplete
        v-model="filter.site"
        @update:model-value="onAnyFilterUpdate"
        @keydown.enter="submitFilter"
      />
    </VCol>
    <VCol cols="12">
      <AFilterString
        v-model="filter.docId"
        @update:model-value="onAnyFilterUpdate"
        @keydown.enter="submitFilter"
      />
    </VCol>
  </VRow>
</template>
