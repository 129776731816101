import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsDesignSettingsRoutes: RouteRecordRaw[] = [
  {
    path: '/design-settings',
    name: ROUTE.CMS.DESIGN_SETTINGS.LIST,
    component: () => import('@/views/cms/designSettings/DesignSettingsListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/design-settings/create',
    name: ROUTE.CMS.DESIGN_SETTINGS.CREATE,
    component: () => import('@/views/cms/designSettings/DesignSettingsCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_DESIGN_SETTINGS_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/design-settings/:id(\\d+)/edit',
    name: ROUTE.CMS.DESIGN_SETTINGS.EDIT,
    component: () => import('@/views/cms/designSettings/DesignSettingsEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_DESIGN_SETTINGS_READ, ACL.CMS_DESIGN_SETTINGS_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/design-settings/:id(\\d+)',
    name: ROUTE.CMS.DESIGN_SETTINGS.DETAIL,
    component: () => import('@/views/cms/designSettings/DesignSettingsDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_DESIGN_SETTINGS_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
