<script lang="ts" setup>
import type { MinuteTopic } from '@/types/cms/MinuteTopic'
import { useMinuteTopicSelectStore } from '@/views/cms/minuteTopic/composables/minuteTopicSelectStore'
import {
  ADatatableConfigButton,
  ADatatableOrdering,
  ADatetime,
  ASubjectSelect,
  generateDatatableMinMaxSelectStrategy,
  type IntegerIdNullable,
  isNumber,
  useSubjectSelect,
} from '@anzusystems/common-admin'
import { fetchMinuteTopicList } from '@/services/api/cms/minuteTopicApi'
import MinuteTopicSelectFilter from '@/views/cms/minuteTopic/components/MinuteTopicSelectFilter.vue'
import { useI18n } from 'vue-i18n'
import { onMounted } from 'vue'

const props = withDefaults(
  defineProps<{
    minCount?: number
    maxCount?: number
    siteGroup?: undefined | IntegerIdNullable
  }>(),
  {
    siteGroup: undefined,
    minCount: 1,
    maxCount: 1,
  }
)
const emit = defineEmits<{
  (e: 'onConfirm', data: Array<MinuteTopic>): void
}>()

const { datatableHiddenColumns, filter } = useMinuteTopicSelectStore()

const {
  items,
  selected,
  pagination,
  columnsVisible,
  columnsAll,
  columnsHidden,
  submitFilter,
  resetFilter,
  filterTouched,
  onFetchNextPage,
  onOpen,
  sortByChange,
  getList,
  onRowClick,
  loading,
} = useSubjectSelect<MinuteTopic>(
  [{ key: 'id' }, { key: 'title' }, { key: 'seoDescription' }],
  datatableHiddenColumns,
  'cms',
  'minuteTopic',
  fetchMinuteTopicList,
  filter
)

const onConfirm = (items: Array<MinuteTopic>) => {
  emit('onConfirm', items)
}

const { t } = useI18n()

onMounted(() => {
  if (isNumber(props.siteGroup)) {
    filter.siteGroup.model = props.siteGroup
  }
})
</script>

<template>
  <ASubjectSelect
    v-model:pagination="pagination"
    :min-count="minCount"
    :max-count="maxCount"
    :selected-items="selected"
    :submit-filter="submitFilter"
    :reset-filter="resetFilter"
    :loading="loading"
    dialog-title-t="cms.minuteTopic.meta.select"
    @on-open="onOpen"
    @on-fetch-next-page="onFetchNextPage"
    @on-page-change="getList"
    @on-confirm="onConfirm"
  >
    <template #activator="{ props: activatorProps }">
      <slot
        name="activator"
        :props="activatorProps"
      >
        <VBtn
          variant="text"
          v-bind="activatorProps"
        >
          {{ t('cms.minuteTopic.meta.select') }}
        </VBtn>
      </slot>
    </template>
    <template #filter>
      <MinuteTopicSelectFilter
        v-model:touched="filterTouched"
        @submit-filter="submitFilter"
      />
    </template>
    <template #second-bar-right>
      <ADatatableOrdering @sort-by-change="sortByChange" />
      <ADatatableConfigButton
        v-model:columns-hidden="columnsHidden"
        :columns-all="columnsAll"
      />
    </template>
    <template #content>
      <VDataTableServer
        v-model="selected"
        show-select
        class="a-datatable"
        :headers="columnsVisible"
        :items="items"
        :items-length="items.length"
        :loading="loading"
        item-value="id"
        return-object
        :select-strategy="generateDatatableMinMaxSelectStrategy(minCount, maxCount) as any"
        @click:row.stop="onRowClick"
      >
        <template #item.data-table-select="{ internalItem, toggleSelect, isSelected }">
          <VCheckboxBtn
            :disabled="!internalItem.selectable"
            :model-value="isSelected([internalItem])"
            @click.prevent="toggleSelect(internalItem)"
            @change="toggleSelect(internalItem)"
          />
        </template>
        <template #item.createdAt="{ item }: { item: MinuteTopic }">
          <ADatetime :date-time="item.createdAt" />
        </template>
        <template #item.modifiedAt="{ item }: { item: MinuteTopic }">
          <ADatetime :date-time="item.modifiedAt" />
        </template>
        <template #bottom />
      </VDataTableServer>
    </template>
  </ASubjectSelect>
</template>
