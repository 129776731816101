<script lang="ts" setup>
import type { IntegerId } from '@anzusystems/common-admin'
import { ACachedChip } from '@anzusystems/common-admin'
import { ROUTE } from '@/router/routes'
import { useCachedNewsletters } from '@/views/cms/newsletter/composables/cachedNewsletters'

withDefaults(
  defineProps<{
    id: null | IntegerId | undefined
  }>(),
  {}
)

const { getCachedNewsletter } = useCachedNewsletters()
</script>

<template>
  <ACachedChip
    :id="id"
    :get-cached-fn="getCachedNewsletter"
    :route="ROUTE.CMS.NEWSLETTER.DETAIL"
    display-text-path="title"
  />
</template>
