<script lang="ts" setup>
import { useMinuteTopicSelectStore } from '@/views/cms/minuteTopic/composables/minuteTopicSelectStore'
import { AFilterString } from '@anzusystems/common-admin'

withDefaults(
  defineProps<{
    touched: boolean
  }>(),
  {}
)
const emit = defineEmits<{
  (e: 'submitFilter'): void
  (e: 'update:touched', data: boolean): void
}>()

const { filter } = useMinuteTopicSelectStore()

const submitFilter = () => {
  emit('update:touched', false)
  emit('submitFilter')
}

const onAnyFilterUpdate = () => {
  emit('update:touched', true)
}
</script>

<template>
  <VRow>
    <VCol :cols="12">
      <AFilterString
        v-model="filter.title"
        @update:model-value="onAnyFilterUpdate"
        @keydown.enter="submitFilter"
      />
    </VCol>
  </VRow>
</template>
