import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import type { ArticleMultiTitle } from '@/types/cms/ArticleMultiTitle'
import type { IntegerId } from '@anzusystems/common-admin'

export const useArticleMultiTitleOneStore = defineStore('cmsArticleMultiTitleOneStore', () => {
  const articleMultiTitles = ref<ArticleMultiTitle[]>([])
  const selectedBoxItemsToOverrideTexts = ref<IntegerId[]>([])

  function reset() {
    articleMultiTitles.value = []
    selectedBoxItemsToOverrideTexts.value = []
  }

  return {
    articleMultiTitles,
    selectedBoxItemsToOverrideTexts,
    reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useArticleMultiTitleOneStore, import.meta.hot))
}
