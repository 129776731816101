import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsPersonPositionRoutes: RouteRecordRaw[] = [
  {
    path: '/person-position',
    name: ROUTE.CMS.PERSON_POSITION.LIST,
    component: () => import('@/views/cms/personPosition/PersonPositionListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/person-position/create',
    name: ROUTE.CMS.PERSON_POSITION.CREATE,
    component: () => import('@/views/cms/personPosition/PersonPositionCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PERSON_POSITION_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/person-position/:id(\\d+)/edit',
    name: ROUTE.CMS.PERSON_POSITION.EDIT,
    component: () => import('@/views/cms/personPosition/PersonPositionEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PERSON_POSITION_READ, ACL.CMS_PERSON_POSITION_EDIT],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/person-position/:id(\\d+)',
    name: ROUTE.CMS.PERSON_POSITION.DETAIL,
    component: () => import('@/views/cms/personPosition/PersonPositionDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PERSON_POSITION_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
