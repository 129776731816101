import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import {
  apiAnyRequest,
  apiCreateOne,
  apiFetchByIds,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
  type FilterBag,
  type IntegerId,
  type Pagination,
} from '@anzusystems/common-admin'
import type { CreateKeywordDto, CurrentKeywordDto, Keyword, UpdateKeywordDto } from '@/types/cms/Keyword'
import type { KeywordStatusType } from '@/model/cms/valueObject/KeywordStatus'

const END_POINT = '/adm/v1/keyword'
export const ENTITY = 'keyword'

export const fetchKeywordListByIds = (ids: IntegerId[]) =>
  apiFetchByIds<Keyword[]>(cmsClient, ids, `${END_POINT}/search`, {}, SYSTEM_CMS, ENTITY, {}, true)

export const fetchKeywordList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<Keyword[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchKeyword = (id: IntegerId) =>
  apiFetchOne<Keyword>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createKeyword = (data: CreateKeywordDto) =>
  apiCreateOne<CreateKeywordDto, Keyword>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updateKeyword = (id: IntegerId, data: UpdateKeywordDto) =>
  apiUpdateOne<UpdateKeywordDto, Keyword>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const changeKeywordStatus = (id: IntegerId, newStatus: KeywordStatusType, data?: CurrentKeywordDto) =>
  apiAnyRequest<CurrentKeywordDto, Keyword>(
    cmsClient,
    'PATCH',
    END_POINT + '/:id/status/:newStatus',
    { id, newStatus },
    data,
    SYSTEM_CMS,
    ENTITY
  )
