import type { Page, PageMinimal } from '@/types/cms/Page'
import { fetchPageListByIds } from '@/services/api/cms/pageApi'
import type { IntegerId } from '@anzusystems/common-admin'
import { defineCached } from '@anzusystems/common-admin'

const mapFullToMinimal = (page: Page): PageMinimal => ({
  id: page.id,
  title: page.texts.title,
})

const mapIdToMinimal = (id: IntegerId): PageMinimal => {
  return { id: id, title: '' }
}

const { cache, toFetch, fetch, add, addManual, addManualMinimal, has, get, isLoaded } = defineCached<
  IntegerId,
  Page,
  PageMinimal
>(mapFullToMinimal, mapIdToMinimal, fetchPageListByIds)

export const useCachedPages = () => {
  return {
    addManualToCachedPages: addManual,
    addManualMinimalToCachedPages: addManualMinimal,
    addToCachedPages: add,
    fetchCachedPages: fetch,
    toFetchCachedPages: toFetch,
    cachedPages: cache,
    hasCachedPage: has,
    getCachedPage: get,
    isLoadedCachedPage: isLoaded,
  }
}
