import { SYSTEM_CMS } from '@/model/systems'
import { ENTITY } from '@/services/api/cms/autoDistributionApi'
import { makeFilterHelper } from '@anzusystems/common-admin'
import { reactive } from 'vue'

const makeFilter = makeFilterHelper(SYSTEM_CMS, ENTITY)

const filter = reactive({
  _elastic: {
    ...makeFilter({ exclude: true }),
  },
  id: {
    ...makeFilter({ name: 'id' }),
  },
  text: {
    ...makeFilter({ name: 'text' }),
  },
  targetPosition: {
    ...makeFilter({ name: 'targetPosition', field: 'targetPositionId' }),
  },
  siteGroup: {
    ...makeFilter({ name: 'siteGroup', field: 'siteGroupId' }),
  },
  discriminator: {
    ...makeFilter({ name: 'discriminator' }),
  },
})

export function useAutoDistributionListFilter() {
  return filter
}

export function useAutoDistributionFilter() {
  return reactive({
    _elastic: {
      ...makeFilter({ exclude: true }),
    },
    id: {
      ...makeFilter({ name: 'id' }),
    },
    text: {
      ...makeFilter({ name: 'text' }),
    },
    enabled: {
      ...makeFilter({ name: 'enabled' }),
    },
    showAt: {
      ...makeFilter({ name: 'showAt' }),
    },
    webEntityType: {
      ...makeFilter({ name: 'webEntityType' }),
    },
    siteGroup: {
      ...makeFilter({ name: 'siteGroup', field: 'siteGroupId' }),
    },
    site: {
      ...makeFilter({ name: 'site', field: 'siteId' }),
    },
    keywords: {
      ...makeFilter({ name: 'keywords', field: 'keywordIds' }),
    },
    rubrics: {
      ...makeFilter({ name: 'rubrics', field: 'rubricIds' }),
    },
    allowEmptySiteFilter: {
      ...makeFilter({ name: 'allowEmptySiteFilter' }),
    },
    allowEmptySiteGroupFilter: {
      ...makeFilter({ name: 'allowEmptySiteGroupFilter' }),
    },
    allowEmptyKeywordFilter: {
      ...makeFilter({ name: 'allowEmptyKeywordFilter' }),
    },
    allowEmptyRubricFilter: {
      ...makeFilter({ name: 'allowEmptyRubricFilter' }),
    },
    forceSiteGroupFilter: {
      ...makeFilter({ name: 'forceSiteGroupFilter' }),
    },
    forceSiteFilter: {
      ...makeFilter({ name: 'forceSiteFilter' }),
    },
    forceKeywordFilter: {
      ...makeFilter({ name: 'forceKeywordFilter' }),
    },
    forceRubricFilter: {
      ...makeFilter({ name: 'forceRubricFilter' }),
    },
  })
}
