import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ValueObjectOption } from '@anzusystems/common-admin'

export const SubscribeTargeting = {
  All: 'all',
  Subscribers: 'subscribers',
  NonSubscribers: 'nonSubscribers',
} as const
export const SubscribeTargetingDefault = SubscribeTargeting.All
export type SubscribeTargetingType = (typeof SubscribeTargeting)[keyof typeof SubscribeTargeting]

export function useSubscribeTargeting() {
  const { t } = useI18n()

  const subscribeTargetingOptions = ref<ValueObjectOption<SubscribeTargetingType>[]>([
    {
      value: SubscribeTargeting.All,
      title: t('cms.newsletterPromo.subscribeTargeting.all'),
    },
    {
      value: SubscribeTargeting.Subscribers,
      title: t('cms.newsletterPromo.subscribeTargeting.subscribers'),
    },
    {
      value: SubscribeTargeting.NonSubscribers,
      title: t('cms.newsletterPromo.subscribeTargeting.nonSubscribers'),
    },
  ])

  const getSubscribeTargetingOptions = (value: SubscribeTargetingType) => {
    return subscribeTargetingOptions.value.find((item) => item.value === value)
  }

  return {
    subscribeTargetingOptions,
    getSubscribeTargetingOptions,
  }
}
