import { SYSTEM_CMS } from '@/model/systems'
import { dateTimeNow } from '@anzusystems/common-admin'
import type { Stage } from '@/types/cms/Stage'

export function useStageFactory() {
  const createDefault = (): Stage => {
    return {
      id: 0,
      name: '',
      activeName: '',
      solvedName: '',
      dashboardEnabled: true,
      followers: [],
      editors: [],
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
      _resourceName: 'stage',
      _system: SYSTEM_CMS,
    }
  }

  return {
    createDefault,
  }
}
