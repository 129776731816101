import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ValueObjectOption, VuetifyIconValue } from '@anzusystems/common-admin'

export const PropagationSuggestConfirmation = {
  Waiting: 'waiting',
  Approved: 'approved',
  Rejected: 'rejected',
} as const
export type PropagationSuggestConfirmationType =
  (typeof PropagationSuggestConfirmation)[keyof typeof PropagationSuggestConfirmation]
export const PropagationSuggestConfirmationDefault = PropagationSuggestConfirmation.Waiting

export function usePropagationSuggestConfirmation() {
  const { t } = useI18n()

  const propagationSuggestConfirmationOptions = ref<ValueObjectOption<PropagationSuggestConfirmationType>[]>([
    {
      value: PropagationSuggestConfirmation.Waiting,
      title: t('cms.propagationSuggest.confirmation.Waiting'),
    },
    {
      value: PropagationSuggestConfirmation.Approved,
      title: t('cms.propagationSuggest.confirmation.Approved'),
    },
    {
      value: PropagationSuggestConfirmation.Rejected,
      title: t('cms.propagationSuggest.confirmation.Rejected'),
    },
  ])

  const getPropagationConfirmationOption = (value: PropagationSuggestConfirmationType) => {
    return propagationSuggestConfirmationOptions.value.find((item) => item.value === value)
  }

  const getPropagationConfirmationIcon = (value: PropagationSuggestConfirmationType): VuetifyIconValue => {
    if (PropagationSuggestConfirmation.Approved === value) {
      return 'mdi-check-bold'
    }
    if (PropagationSuggestConfirmation.Rejected === value) {
      return 'mdi-check-thick'
    }

    return 'mdi-clock-outline'
  }

  return {
    propagationSuggestConfirmationOptions,
    getPropagationConfirmationOption,
    getPropagationConfirmationIcon,
  }
}
