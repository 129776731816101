<script lang="ts" setup>
import { useSiteSelectActions } from '@/views/cms/site/composables/siteActions'
import {
  ACollabLockedByUser,
  AFormRemoteAutocomplete,
  type CollabComponentConfig,
  type IntegerId,
  type IntegerIdNullable,
  isNumber,
} from '@anzusystems/common-admin'
import { watchImmediate } from '@vueuse/core'
import { useSiteFilter } from '@/model/cms/filter/SiteFilter'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    modelValue: IntegerIdNullable | IntegerId[] | null
    label?: string | undefined
    required?: boolean | undefined
    multiple?: boolean
    siteGroupId?: number | null
    siteGroupRequired?: boolean
    collab?: CollabComponentConfig
  }>(),
  {
    label: undefined,
    required: undefined,
    multiple: false,
    siteGroupId: null,
    siteGroupRequired: false,
    collab: undefined,
  }
)
const emit = defineEmits<{
  (e: 'update:modelValue', data: IntegerIdNullable | IntegerId[]): void
}>()
const value = computed({
  get(): IntegerIdNullable | IntegerId[] {
    return isNumber(props.modelValue) && props.modelValue === 0 ? null : props.modelValue
  },
  set(newValue: IntegerIdNullable | IntegerId[]) {
    emit('update:modelValue', newValue)
  },
})

const siteGroupIdComputed = computed(() => (isNumber(props.siteGroupId) ? props.siteGroupId : undefined))

const { fetchItems, fetchItemsByIds } = useSiteSelectActions()

const innerFilter = useSiteFilter()

watchImmediate(siteGroupIdComputed, (newValue, oldValue) => {
  if (isNumber(newValue)) innerFilter.siteGroup.model = newValue
  if (newValue !== oldValue && oldValue) value.value = props.multiple ? [] : null
})

const disabledComputed = computed(() => {
  const siteGroupIdIsNumber = isNumber(props.siteGroupId)
  if (props.siteGroupRequired && siteGroupIdIsNumber === false) return true
  return undefined
})
</script>

<template>
  <AFormRemoteAutocomplete
    :key="siteGroupIdComputed"
    v-model="value"
    :required="required"
    :collab="collab"
    :label="label"
    :fetch-items="fetchItems"
    :fetch-items-by-ids="fetchItemsByIds"
    :inner-filter="innerFilter"
    :multiple="multiple"
    :disabled="disabledComputed"
  >
    <template #locked="{ userId }">
      <slot
        name="locked"
        :user-id="userId"
      >
        <ACollabLockedByUser
          v-if="collab"
          :id="userId"
          :users="collab.cachedUsers"
        />
      </slot>
    </template>
  </AFormRemoteAutocomplete>
</template>
