import useVuelidate from '@vuelidate/core'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import {
  createInbox,
  deleteInbox,
  fetchInbox,
  fetchInboxList,
  fetchInboxListByIds,
  updateInbox,
} from '@/services/api/cms/inboxApi'
import { ROUTE } from '@/router/routes'
import { useInboxOneStore } from '@/stores/cms/inboxStore'
import {
  type FilterBag,
  type IntegerId,
  isUndefined,
  type Pagination,
  useAlerts,
  type ValueObjectOption,
} from '@anzusystems/common-admin'
import { ref } from 'vue'
import type { Inbox } from '@/types/cms/Inbox'

const { showValidationError, showRecordWas, showErrorsDefault } = useAlerts()

const datatableHiddenColumns = ref<Array<string>>([])
const listLoading = ref(false)
const detailLoading = ref(false)
const saveButtonLoading = ref(false)

export const useInboxListActions = () => {
  const listItems = ref<Inbox[]>([])
  const selectedInboxes = ref<Inbox[]>([])

  const fetchList = async (pagination: Pagination, filterBag: FilterBag) => {
    listLoading.value = true
    try {
      listItems.value = await fetchInboxList(pagination, filterBag)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      listLoading.value = false
    }
  }

  return {
    datatableHiddenColumns,
    listLoading,
    fetchList,
    listItems,
    selectedInboxes,
  }
}

export const useBoxSelectActions = () => {
  const fetchItems = async (pagination: Pagination, filterBag: FilterBag) => {
    const inboxes = await fetchInboxList(pagination, filterBag)

    return <ValueObjectOption<IntegerId>[]>inboxes.map((inbox: Inbox) => ({
      title: inbox.texts.title,
      value: inbox.id,
    }))
  }

  const fetchItemsByIds = async (ids: IntegerId[]) => {
    const inboxes = await fetchInboxListByIds(ids)
    return <ValueObjectOption<IntegerId>[]>inboxes.map((inbox: Inbox) => ({
      value: inbox.id,
      title: inbox.texts.title,
    }))
  }

  return {
    fetchItems,
    fetchItemsByIds,
  }
}

export const useInboxCreateEditActions = () => {
  const v$ = useVuelidate()
  const inboxOneStore = useInboxOneStore()
  const { inbox } = storeToRefs(inboxOneStore)

  const fetchData = async (id: IntegerId) => {
    detailLoading.value = true
    try {
      inbox.value = await fetchInbox(id)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  const onUpdate = async (onSuccess: ((inbox: Inbox) => void) | undefined = undefined) => {
    try {
      saveButtonLoading.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      const res = await updateInbox(inboxOneStore.inbox.id, inboxOneStore.inbox)
      showRecordWas('updated')
      if (!isUndefined(onSuccess)) onSuccess(res)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  const onCreate = async (onSuccess: ((inbox: Inbox) => void) | undefined = undefined) => {
    try {
      saveButtonLoading.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      const inboxRes = await createInbox(inboxOneStore.inbox)
      showRecordWas('created')
      if (!isUndefined(onSuccess)) onSuccess(inboxRes)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  return {
    detailLoading,
    saveButtonLoading,
    inbox,
    fetchData,
    onUpdate,
    onCreate,
    resetStore: inboxOneStore.reset,
  }
}

export const useInboxDetailActions = () => {
  const inboxOneStore = useInboxOneStore()
  const { inbox } = storeToRefs(inboxOneStore)

  const fetchData = async (id: IntegerId) => {
    detailLoading.value = true
    try {
      inbox.value = await fetchInbox(id)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  return {
    inbox,
    detailLoading,
    fetchData,
    resetStore: inboxOneStore.reset,
  }
}

export const useInboxDeleteActions = () => {
  const router = useRouter()

  const onDelete = async (id: IntegerId) => {
    try {
      await deleteInbox(id)
      showRecordWas('deleted')
      router.push({ name: ROUTE.CMS.INBOX.LIST })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      // closeDeleteDialog()
    }
  }

  return {
    onDelete,
  }
}
