import type { FilterBag, Pagination } from '@anzusystems/common-admin'
import {
  apiCreateOne,
  apiDeleteOne,
  apiFetchByIds,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
} from '@anzusystems/common-admin'
import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { NewsletterSchedule } from '@/types/cms/NewsletterSchedule'

const END_POINT = '/adm/v1/newsletter-schedule'
export const ENTITY = 'newsletterSchedule'

export const fetchNewsletterScheduleList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<NewsletterSchedule[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchNewsletterScheduleListByIds = (ids: number[]) =>
  apiFetchByIds<NewsletterSchedule[]>(cmsClient, ids, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const fetchNewsletterSchedule = (id: number) =>
  apiFetchOne<NewsletterSchedule>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createNewsletterSchedule = (data: NewsletterSchedule) =>
  apiCreateOne<NewsletterSchedule>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updateNewsletterSchedule = (id: number, data: NewsletterSchedule) =>
  apiUpdateOne<NewsletterSchedule>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const deleteNewsletterSchedule = (id: number) =>
  apiDeleteOne<NewsletterSchedule>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)
