import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsArticleArchiveRoutes: RouteRecordRaw[] = [
  {
    path: '/article-archive',
    name: ROUTE.CMS.ARTICLE_ARCHIVE.LIST,
    component: () => import('@/views/cms/articleArchive/ArticleArchiveListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/article-archive/:id(\\d+)',
    name: ROUTE.CMS.ARTICLE_ARCHIVE.DETAIL,
    component: () => import('@/views/cms/articleArchive/ArticleArchiveDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ARTICLE_ARCHIVE_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
