import { SYSTEM_CMS } from '@/model/systems'
import { dateTimeNow } from '@anzusystems/common-admin'

import type { EmbedVideoAware, EmbedVideoCreateUpdateDto } from '@/components/anzutap/types/EmbedVideo'

export function useEmbedVideoFactory() {
  const createDefault = (id = ''): EmbedVideoAware => {
    return {
      id,
      dam: {
        imageFileId: null,
        assetId: '',
        licenceId: 0,
      },
      customData: {},
      discriminator: 'video',
      _resourceName: 'embedKindVideo',
      _system: SYSTEM_CMS,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
    }
  }

  const createCreateUpdateDto = (id = ''): EmbedVideoCreateUpdateDto => {
    return {
      id,
      dam: {
        imageFileId: '',
        assetId: '',
        licenceId: 0,
      },
      customData: {},
    }
  }

  return {
    createDefault,
    createCreateUpdateDto,
  }
}
