<script lang="ts" setup>
import type { Filter } from '@anzusystems/common-admin'
import { AFilterRemoteAutocomplete } from '@anzusystems/common-admin'
import { useKeywordSelectActions } from '@/views/cms/keyword/composables/keywordActions'
import { useKeywordFilter } from '@/model/cms/filter/KeywordFilter'

const modelValue = defineModel<Filter>({ required: true })

const { fetchItems, fetchItemsByIds } = useKeywordSelectActions()

const innerFilter = useKeywordFilter()
</script>

<template>
  <AFilterRemoteAutocomplete
    v-model="modelValue"
    :fetch-items="fetchItems"
    :fetch-items-by-ids="fetchItemsByIds"
    :inner-filter="innerFilter"
    :filter-sort-by="null"
    filter-by-field="text"
  />
</template>
