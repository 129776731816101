<script setup lang="ts">
import type { NewsletterSchedule } from '@/types/cms/NewsletterSchedule'
import { useDateTimeHelper } from '@/composables/cms/dateTimeHelper'
import { isNull } from '@anzusystems/common-admin'

withDefaults(
  defineProps<{
    newsletterSchedule: NewsletterSchedule
  }>(),
  {}
)
const { getDayTranslateByNumber } = useDateTimeHelper()
</script>

<template>
  <tr>
    <th v-if="!isNull(newsletterSchedule.dayOfWeek)">
      {{ getDayTranslateByNumber(newsletterSchedule.dayOfWeek) }}
    </th>
    <th>{{ newsletterSchedule.dayOfMonth }}</th>
    <th>{{ newsletterSchedule.hour }}</th>
    <th>{{ newsletterSchedule.minute }}</th>
    <th>{{ newsletterSchedule.enabled }}</th>
  </tr>
</template>
