<script lang="ts" setup>
import { AFormRemoteAutocomplete, type IntegerId, type IntegerIdNullable } from '@anzusystems/common-admin'
import { useAuthorSelectActions } from '@/views/cms/author/composables/authorActions'
import { useAuthorFilter } from '@/model/cms/filter/AuthorFilter'

withDefaults(
  defineProps<{
    label?: string | undefined
    required?: boolean | undefined
    multiple?: boolean
  }>(),
  {
    label: undefined,
    required: undefined,
    multiple: false,
  }
)

const modelValue = defineModel<IntegerIdNullable | IntegerId[]>({ required: true })

const { fetchItems, fetchItemsByIds } = useAuthorSelectActions()

const innerFilter = useAuthorFilter()
</script>

<template>
  <AFormRemoteAutocomplete
    v-model="modelValue"
    :required="required"
    :label="label"
    :fetch-items="fetchItems"
    :fetch-items-by-ids="fetchItemsByIds"
    :inner-filter="innerFilter"
    :filter-sort-by="null"
    filter-by-field="text"
    :multiple="multiple"
  />
</template>
