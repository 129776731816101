<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ARow } from '@anzusystems/common-admin'
import type { CollabRoom } from '@/types/Collab'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const { t } = useI18n()
</script>

<template>
  <ARow>
    <VTextField
      :label="t('cms.articleKind.widget.redirect.url')"
      class="mt-4"
      :disabled="readonly"
    />
  </ARow>
  <ARow>
    <VSelect
      :disabled="readonly"
      :label="t('cms.articleKind.widget.redirect.type')"
      :items="[t('cms.articleKind.widget.redirect.canonical'), t('cms.articleKind.widget.redirect.direct')]"
    />
  </ARow>
</template>
