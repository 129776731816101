import type { IntegerId } from '@anzusystems/common-admin'
import { defineCached } from '@anzusystems/common-admin'
import type { LayoutTemplate, LayoutTemplateMinimal } from '@/types/cms/LayoutTemplate'
import { fetchLayoutTemplateListByIds } from '@/services/api/cms/layoutTemplateApi'

const mapFullToMinimal = (advertSettings: LayoutTemplate): LayoutTemplateMinimal => ({
  id: advertSettings.id,
  title: advertSettings.texts.title,
})

const mapIdToMinimal = (id: IntegerId): LayoutTemplateMinimal => {
  return { id: id, title: '' }
}

const { cache, toFetch, fetch, add, addManual, addManualMinimal, has, get, isLoaded } = defineCached<
  IntegerId,
  LayoutTemplate,
  LayoutTemplateMinimal
>(mapFullToMinimal, mapIdToMinimal, fetchLayoutTemplateListByIds)

export const useCachedLayoutTemplate = () => {
  return {
    addManualToCachedLayoutTemplate: addManual,
    addManualMinimalToCachedLayoutTemplate: addManualMinimal,
    addToCachedLayoutTemplate: add,
    fetchCachedLayoutTemplate: fetch,
    toFetchCachedLayoutTemplate: toFetch,
    cachedLayoutTemplate: cache,
    hasCachedLayoutTemplate: has,
    getCachedLayoutTemplate: get,
    isLoadedCachedLayoutTemplate: isLoaded,
  }
}
