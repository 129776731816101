import type { ContentItemKindArchive } from '@/types/cms/ContentItemKind/ContentItemKindArchive'
import { ContentItemKindArchivePeriodDefault } from '@/types/cms/ContentItemKind/ContentItemKindArchive'
import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { DATETIME_MIN } from '@anzusystems/common-admin'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindArchiveFactory() {
  const create = (): ContentItemKindArchive => {
    return {
      ...createAbstractContentItemKind(),
      listSiteGroup: null,
      listSite: null,
      rubrics: [],
      requiredKeywords: [],
      optionalKeywords: [],
      period: ContentItemKindArchivePeriodDefault,
      itemsLimit: 20,
      dateSince: DATETIME_MIN,
      discriminator: ContentItemDiscriminator.Archive,
      _resourceName: 'contentItemKindArchive',
    }
  }

  return {
    create,
  }
}
