import { useAutoDistributionFactory } from '@/model/cms/factory/AutoDistribution/AutoDistributionFactory'
import { useLayoutTemplateFactory } from '@/model/cms/factory/LayoutTemplateFactory'
import { usePageFactory } from '@/model/cms/factory/PageFactory'
import { useAutoDistributionFilter } from '@/model/cms/filter/AutoDistributionFilter'
import { fetchAutoDistributionList } from '@/services/api/cms/autoDistributionApi'
import { fetchLayoutTemplate } from '@/services/api/cms/layoutTemplateApi'
import type { GroupedAutoDistributions } from '@/types/cms/AutoDistribution'
import { isContentItemKindArticleList } from '@/types/cms/ContentItemKind/ContentItemKindArticleList'
import type { GroupedPageContents, MultiPageUpdateKeywordsDto, Page } from '@/types/cms/Page'
import { WebEntityType } from '@/types/cms/TargetPosition'
import { useCachedKeywords } from '@/views/cms/keyword/composables/cachedKeywords'
import type { IntegerId } from '@anzusystems/common-admin'
import { usePagination } from '@anzusystems/common-admin'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref, watch } from 'vue'

export const usePageOneStore = defineStore('cmsPageOneStore', () => {
  const { createPage, createGroupedPageContents, createMultiPageUpdateKeywordsDto } = usePageFactory()
  const { createLayoutTemplate } = useLayoutTemplateFactory()
  const { createGroupedAutoDistributions } = useAutoDistributionFactory()

  const page = ref(createPage())
  const pageLayoutTemplate = ref(createLayoutTemplate())
  const groupedPageContents = ref<GroupedPageContents>({})
  const groupedAutoDistributions = ref<GroupedAutoDistributions>({})
  const oldAutoDistributionKeywords = ref<IntegerId[]>([])
  const newAutoDistributionKeywords = ref<IntegerId[]>([])
  const multiPageUpdateKeywords = ref<MultiPageUpdateKeywordsDto>(createMultiPageUpdateKeywordsDto())
  const multiPageUpdateKeywordsDialog = ref<boolean>(false)

  const { addToCachedKeywords, fetchCachedKeywords } = useCachedKeywords()

  function reset() {
    page.value = createPage()
    oldAutoDistributionKeywords.value = []
    newAutoDistributionKeywords.value = []
    pageLayoutTemplate.value = createLayoutTemplate()
    groupedPageContents.value = {}
    multiPageUpdateKeywords.value = createMultiPageUpdateKeywordsDto()
    multiPageUpdateKeywordsDialog.value = false
  }

  async function setPage(newPage: Page) {
    page.value = newPage
    await initGroupedPageContents(newPage.layoutTemplate)
  }

  async function initGroupedPageContents(layoutTemplateId: IntegerId | null) {
    if (layoutTemplateId === null) {
      pageLayoutTemplate.value = createLayoutTemplate()
      groupedPageContents.value = {}
      return
    }
    pageLayoutTemplate.value = await fetchLayoutTemplate(layoutTemplateId)
    groupedPageContents.value = createGroupedPageContents(page.value, pageLayoutTemplate.value)
    oldAutoDistributionKeywords.value = getAllKeywordsFromPage()
    newAutoDistributionKeywords.value = oldAutoDistributionKeywords.value
    await initAutoDistributions()
  }

  async function initAutoDistributions() {
    const autoDistributionFilter = useAutoDistributionFilter()
    autoDistributionFilter.enabled.model = true
    autoDistributionFilter.showAt.model = new Date()
    autoDistributionFilter.allowEmptySiteGroupFilter.model = true
    autoDistributionFilter.allowEmptySiteFilter.model = true
    autoDistributionFilter.allowEmptyKeywordFilter.model = true
    autoDistributionFilter.allowEmptyRubricFilter.model = true
    autoDistributionFilter.forceSiteGroupFilter.model = true
    autoDistributionFilter.forceSiteFilter.model = true
    autoDistributionFilter.forceKeywordFilter.model = true
    autoDistributionFilter.forceRubricFilter.model = true
    autoDistributionFilter.webEntityType.model = WebEntityType.Page
    autoDistributionFilter.siteGroup.model = page.value.siteGroup
    autoDistributionFilter.site.model = page.value.site
    autoDistributionFilter.rubrics.model = page.value.rubric ? [page.value.rubric] : []
    autoDistributionFilter.keywords.model = newAutoDistributionKeywords.value
    const pagination = usePagination()
    pagination.sortBy = 'positionInsideTargetPosition'
    pagination.descending = false
    const autoDistributions = await fetchAutoDistributionList(pagination, autoDistributionFilter)
    groupedAutoDistributions.value = createGroupedAutoDistributions(autoDistributions, pageLayoutTemplate.value)
  }

  const getAllKeywordsFromPage = () => {
    for (const pageContent of Object.values(groupedPageContents.value).flat()) {
      if (pageContent.attributes.main && pageContent.enabled && isContentItemKindArticleList(pageContent.contentItem)) {
        return Array.from(
          new Set([...pageContent.contentItem.requiredKeywords, ...pageContent.contentItem.optionalKeywords]).values()
        )
      }
    }
    return []
  }

  function updateNewAutoDistributionKeywords() {
    newAutoDistributionKeywords.value = getAllKeywordsFromPage()
  }

  watch(newAutoDistributionKeywords, (newValue, oldValue) => {
    if (newValue !== oldValue) {
      addToCachedKeywords(newValue)
      fetchCachedKeywords()
    }
  })

  return {
    page,
    setPage,
    pageLayoutTemplate,
    groupedPageContents,
    groupedAutoDistributions,
    oldAutoDistributionKeywords,
    newAutoDistributionKeywords,
    updateNewAutoDistributionKeywords,
    initGroupedPageContents,
    multiPageUpdateKeywords,
    multiPageUpdateKeywordsDialog,
    reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePageOneStore, import.meta.hot))
}
