import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ValueObjectOption } from '@anzusystems/common-admin'

export const AuthorDiscriminator = {
  Person: 'person',
  Source: 'source',
} as const
export type AuthorDiscriminatorType = (typeof AuthorDiscriminator)[keyof typeof AuthorDiscriminator]
export const AuthorDiscriminatorDefault = AuthorDiscriminator.Person

export function useAuthorDiscriminator() {
  const { t } = useI18n()

  const authorDiscriminatorOptions = ref<ValueObjectOption<AuthorDiscriminatorType>[]>([
    {
      value: AuthorDiscriminator.Person,
      title: t('cms.authorKind.discriminator.person'),
      color: 'default',
    },
    {
      value: AuthorDiscriminator.Source,
      title: t('cms.authorKind.discriminator.source'),
      color: 'warning',
    },
  ])

  const getAuthorDiscriminatorOption = (value: AuthorDiscriminatorType) => {
    return authorDiscriminatorOptions.value.find((item) => item.value === value)
  }

  return {
    authorDiscriminatorOptions,
    getAuthorDiscriminatorOption,
  }
}
