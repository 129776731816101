<script lang="ts" setup></script>

<template>
  <VList
    density="compact"
    nav
    color="primary"
  >
    <!--    <VListItem-->
    <!--      :title="t('sidebar.weather.header')"-->
    <!--      :to="{ name: ROUTE.CMS.ARTICLE.LIST }"-->
    <!--      prepend-icon="mdi-weather-sunny"-->
    <!--    >-->
    <!--      <VTooltip-->
    <!--        location="right"-->
    <!--        activator="parent"-->
    <!--      >-->
    <!--        {{ t('sidebar.weather.header') }}-->
    <!--      </VTooltip>-->
    <!--    </VListItem>-->
  </VList>
</template>
