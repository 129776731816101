import type { CustomForm } from '@/types/common/CustomForm'
import { computed, type Ref, ref } from 'vue'
import { useCustomFormApi } from '@/services/api/common/customFormApi'
import { cmsClient } from '@/services/api/clients/cmsClient'
import type { CustomFormResourceNameType } from '@/model/common/valueObject/CustomFormResourceNames'
import type { CustomDataFormElement, IntegerId } from '@anzusystems/common-admin'
import { isNull, isUndefined } from '@anzusystems/common-admin'
import { useCustomFormFilter } from '@/model/common/filter/CustomFormFilter'

const customFormsAll = ref<CustomForm[]>([])
const customFormsConfigLoaded = ref(false)
const customFormsConfigError = ref(false)

export async function initCustomFormConfig() {
  const { fetchCustomFormListAll } = useCustomFormApi(cmsClient)
  const filter = useCustomFormFilter()
  filter.active.model = true
  try {
    customFormsAll.value = await fetchCustomFormListAll(filter)
  } catch (e) {
    customFormsConfigError.value = true
  } finally {
    customFormsConfigLoaded.value = true
  }
}

export function useCustomFormConfig(
  resourceName: CustomFormResourceNameType,
  siteGroup: IntegerId | null,
  name: Ref<undefined | string> // undefined or empty string = show all, string = show only that name
) {
  const customFormsFiltered = computed(() => {
    if (customFormsAll.value.length === 0) return []
    if (!customFormsConfigLoaded.value) return []
    if (customFormsConfigError.value) return []
    const result: CustomForm[] = []
    customFormsAll.value.forEach((customForm) => {
      if (
        customForm.resourceNames.includes(resourceName) &&
        (customForm.siteGroups.length === 0 || isNull(siteGroup) || customForm.siteGroups.includes(siteGroup))
      ) {
        result.push(customForm)
      }
    })
    return result
  })

  const customFormElements = computed(() => {
    if (customFormsAll.value.length === 0) return []
    if (!customFormsConfigLoaded.value) return []
    if (customFormsConfigError.value) return []
    const result: CustomDataFormElement[] = []
    customFormsFiltered.value.forEach((customForm) => {
      if (isUndefined(name.value) || name.value.length === 0 || name.value === customForm.name) {
        customForm.elements.forEach((element) => {
          result.push(element)
        })
      }
    })
    if (result.length === 0) return []
    result.sort((a, b) => a.position - b.position)
    return result
  })

  const customFormNames = computed(() => {
    return customFormsFiltered.value.map((item) => item.name)
  })

  const customFormElementsCount = computed(() => {
    return customFormElements.value.length
  })

  return {
    customFormElements,
    customFormNames,
    customFormElementsCount,
    customFormsConfigLoaded,
    customFormsConfigError,
  }
}
