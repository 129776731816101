import { reactive } from 'vue'
import { makeFilterHelper } from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'
import { ENTITY } from '@/services/api/cms/layoutTemplateApi'

const makeFilter = makeFilterHelper(SYSTEM_CMS, ENTITY)

const filter = reactive({
  id: {
    ...makeFilter({ name: 'id' }),
  },
  title: {
    ...makeFilter({ name: 'title', variant: 'startsWith', field: 'texts.title' }),
  },
  webEntityType: {
    ...makeFilter({ name: 'webEntityType', variant: 'in', field: 'attributes.webEntityType' }),
  },
  default: {
    ...makeFilter({ name: 'default', field: 'attributes.default' }),
  },
})

export function useLayoutTemplateListFilter() {
  return filter
}

export function useLayoutTemplateFilter() {
  return reactive({
    id: {
      ...makeFilter({ name: 'id' }),
    },
    title: {
      ...makeFilter({ name: 'title', variant: 'startsWith', field: 'texts.title' }),
    },
    webEntityType: {
      ...makeFilter({ name: 'webEntityType', variant: 'in', field: 'attributes.webEntityType' }),
    },
  })
}
