<script lang="ts" setup>
import {
  type DashboardDtoInterface,
  isDeskDashboardDto,
  isPersonalDashboardDto, isStageDashboardDto
} from '@/types/cms/Dashboard'
import { computed } from 'vue'
import DeskDashboard from '@/views/cms/dashboard/components/DeskDashboard.vue'
import PersonalDashboard from '@/views/cms/dashboard/components/PersonalDashboard.vue'
import StageDashboard from '@/views/cms/dashboard/components/StageDashboard.vue'

const props = withDefaults(
  defineProps<{
    dashboard: DashboardDtoInterface
  }>(),
  {
  }
)

const deskDashboard = computed(() => isDeskDashboardDto(props.dashboard) ? props.dashboard : undefined)
const personalDashboard = computed(() => isPersonalDashboardDto(props.dashboard) ? props.dashboard : undefined)
const stageDashboard = computed(() => isStageDashboardDto(props.dashboard) ? props.dashboard : undefined)

</script>

<template>
  <DeskDashboard
    v-if="deskDashboard"
    :dashboard="deskDashboard"
  />
  <PersonalDashboard
    v-else-if="personalDashboard"
    :dashboard="personalDashboard"
  />
  <StageDashboard
    v-else-if="stageDashboard"
    :dashboard="stageDashboard"
  />
</template>
