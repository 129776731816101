import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import {
  apiCreateOne,
  apiDeleteOne,
  apiFetchByIds,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
} from '@anzusystems/common-admin'
import type { LinkedList, LinkedListPreviewDto } from '@/types/cms/LinkedList'

const END_POINT = '/adm/v1/linked-list'
export const ENTITY = 'linkedList'

export const fetchLinkedListListByIds = (ids: IntegerId[]) =>
  apiFetchByIds<LinkedList[]>(cmsClient, ids, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const fetchLinkedListList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<LinkedList[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchLinkedList = (id: IntegerId) =>
  apiFetchOne<LinkedList>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const previewLinkedList = (id: IntegerId) =>
  apiFetchOne<LinkedListPreviewDto>(cmsClient, END_POINT + '/:id/preview', { id }, SYSTEM_CMS, ENTITY)

export const createLinkedList = (data: LinkedList) =>
  apiCreateOne<LinkedList>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updateLinkedList = (id: IntegerId, data: LinkedList) =>
  apiUpdateOne<LinkedList>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const deleteLinkedList = (id: IntegerId) =>
  apiDeleteOne<LinkedList>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)
