<script lang="ts" setup>
import {
  AActionCloseButton,
  AActionDeleteButton,
  AActionSaveButton,
  ACard,
  stringToInt,
} from '@anzusystems/common-admin'
import { ROUTE } from '@/router/routes'
import { onBeforeUnmount, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import ActionbarWrapper from '@/components/wrappers/ActionbarWrapper.vue'
import {
  useNewsletterCreateEditActions,
  useNewsletterDeleteActions,
} from '@/views/cms/newsletter/composables/newsletterActions'
import NewsletterManage from '@/views/cms/newsletter/components/NewsletterManage.vue'

const route = useRoute()
const id = stringToInt(route.params.id)

const { onDelete } = useNewsletterDeleteActions()

const { detailLoading, fetchData, resetStore, onUpdate, newsletter } = useNewsletterCreateEditActions()

const getData = () => {
  fetchData(id)
}

onMounted(() => {
  getData()
})

onBeforeUnmount(() => {
  resetStore()
})
</script>

<template>
  <ActionbarWrapper :last-breadcrumb-title="newsletter.texts.title">
    <template #buttons>
      <AActionSaveButton
        v-if="!detailLoading"
        @save-record="onUpdate"
      />
      <AActionDeleteButton
        v-if="!detailLoading"
        @delete-record="onDelete(id)"
      />
      <AActionCloseButton :route-name="ROUTE.CMS.NEWSLETTER.LIST" />
    </template>
  </ActionbarWrapper>

  <ACard :loading="detailLoading">
    <VCardText>
      <NewsletterManage is-edit />
    </VCardText>
  </ACard>
</template>
