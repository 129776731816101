import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsRubricRoutes: RouteRecordRaw[] = [
  {
    path: '/rubric',
    name: ROUTE.CMS.RUBRIC.LIST,
    component: () => import('@/views/cms/rubric/RubricListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/rubric/create',
    name: ROUTE.CMS.RUBRIC.CREATE,
    component: () => import('@/views/cms/rubric/RubricCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_RUBRIC_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/rubric/:id(\\d+)/edit',
    name: ROUTE.CMS.RUBRIC.EDIT,
    component: () => import('@/views/cms/rubric/RubricEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_RUBRIC_READ, ACL.CMS_RUBRIC_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/rubric/:id(\\d+)',
    name: ROUTE.CMS.RUBRIC.DETAIL,
    component: () => import('@/views/cms/rubric/RubricDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_RUBRIC_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
