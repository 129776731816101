<script lang="ts" setup>
import { AActionCloseButton, AActionSaveButton, ACard } from '@anzusystems/common-admin'
import { onBeforeUnmount } from 'vue'
import { ROUTE } from '@/router/routes'
import ActionbarWrapper from '@/components/wrappers/ActionbarWrapper.vue'
import NewsletterManage from '@/views/cms/newsletter/components/NewsletterManage.vue'
import { useNewsletterCreateEditActions } from '@/views/cms/newsletter/composables/newsletterActions'

const { resetStore, onCreate, newsletter } = useNewsletterCreateEditActions()

onBeforeUnmount(() => {
  resetStore()
})
</script>

<template>
  <ActionbarWrapper :last-breadcrumb-title="newsletter.texts.title">
    <template #buttons>
      <AActionSaveButton @save-record="onCreate" />
      <AActionCloseButton :route-name="ROUTE.CMS.NEWSLETTER.LIST" />
    </template>
  </ActionbarWrapper>

  <ACard>
    <VCardText>
      <NewsletterManage />
    </VCardText>
  </ACard>
</template>
