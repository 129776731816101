<script lang="ts" setup>
import type { AnzuUserAndTimeTrackingAware } from '@anzusystems/common-admin'
import { ARow, dateTimePretty } from '@anzusystems/common-admin'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = withDefaults(
  defineProps<{
    data: AnzuUserAndTimeTrackingAware | any
    hideCreatedAt?: boolean
    hideModifiedAt?: boolean
    hideCreatedBy?: boolean
    hideModifiedBy?: boolean
  }>(),
  {
    hideCreatedAt: false,
    hideModifiedAt: false,
    hideCreatedBy: false,
    hideModifiedBy: false,
  }
)

const createdAt = computed(() => {
  return dateTimePretty(props.data.createdAt)
})
const modifiedAt = computed(() => {
  return dateTimePretty(props.data.modifiedAt)
})

const { t } = useI18n()
</script>

<template>
  <ARow :title="t('common.model.tracking.created')">
    <div class="d-flex align-center">
      <div
        v-if="!hideCreatedAt"
        class="mr-2"
      >
        {{ createdAt }}
      </div>
      <!--      <CachedWeatherUserChip-->
      <!--        v-if="!hideCreatedBy"-->
      <!--        :id="data.createdBy"-->
      <!--      />-->
    </div>
  </ARow>
  <ARow :title="t('common.model.tracking.modified')">
    <div class="d-flex align-center">
      <div
        v-if="!hideModifiedAt"
        class="mr-2"
      >
        {{ modifiedAt }}
      </div>
      <!--      <CachedWeatherUserChip-->
      <!--        v-if="!hideModifiedBy"-->
      <!--        :id="data.modifiedBy"-->
      <!--      />-->
    </div>
  </ARow>
</template>
