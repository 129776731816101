import { SYSTEM_CMS } from '@/model/systems'
import {
  apiAnyRequest,
  apiFetchList,
  apiFetchOne,
  type DocId,
  type IntegerId,
  makeFilterHelper,
  usePagination,
} from '@anzusystems/common-admin'
import { cmsClient } from '@/services/api/clients/cmsClient'
import type { Embed, EmbedEntityType } from '@/components/anzutap/types/Embed'
import { reactive } from 'vue'

export const END_POINT = '/adm/v1/embed-kind'
export const ENTITY = 'embedKind'

export const fetchEmbedList = (id: IntegerId, type: EmbedEntityType) => {
  const makeFilter = makeFilterHelper(SYSTEM_CMS, 'embedKind')
  const pagination = usePagination()
  const filterEmbeds = reactive({
    [type]: {
      ...makeFilter({ name: type }),
    },
  })
  filterEmbeds[type].model = id

  return apiFetchList<Embed[]>(cmsClient, END_POINT, {}, pagination, filterEmbeds, SYSTEM_CMS, ENTITY)
}

export const fetchEmbed = (id: DocId) => apiFetchOne<Embed>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const cloneEmbed = (sourceId: DocId, targetId: DocId, embedEntity: EmbedEntityType, embedEntityId: IntegerId) =>
  apiAnyRequest<any, Embed>(
    cmsClient,
    'PATCH',
    END_POINT + '/:sourceId/copy-to-target',
    { sourceId },
    {
      embedCopyId: targetId,
      [embedEntity]: embedEntityId,
    },
    SYSTEM_CMS,
    ENTITY
  )
