import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'

import { ACL } from '@/composables/auth/auth'

export const cmsKeywordManagementRoutes: RouteRecordRaw[] = [
  {
    path: '/keyword-management',
    name: ROUTE.CMS.KEYWORD_MANAGEMENT.LIST,
    component: () => import('@/views/cms/keywordManagement/KeywordManagementListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_KEYWORD_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
]
