<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useCollabState } from '@anzusystems/common-admin'

const { collabReconnecting } = useCollabState()

const { t } = useI18n()
</script>

<template>
  <VAppBar
    v-if="collabReconnecting"
    height="24"
    color="orange accent-3"
    elevation="0"
    :order="-1"
  >
    <div class="text-center w-100 text-caption pb-1">
      <span v-once>
        {{ t('system.topbar.collabAlert.disconnected') }}
      </span>
    </div>
  </VAppBar>
</template>
