import { ROUTE } from '@/router/routes'
import { deletePage, fetchPage, fetchPageList, fetchPageListByIds, updatePage } from '@/services/api/cms/pageApi'
import { usePageOneStore } from '@/stores/cms/pageStore'
import { isContentItemKindArticleList } from '@/types/cms/ContentItemKind/ContentItemKindArticleList'
import type { Page } from '@/types/cms/Page'
import { useCachedAdvertSettings } from '@/views/cms/advertSettings/composables/cachedAdvertSettings'
import { useCachedAutoDistributions } from '@/views/cms/autoDistribution/composables/cachedAutoDistributions'
import { useCachedDesks } from '@/views/cms/desk/composables/cachedDesks'
import { useCachedKeywords } from '@/views/cms/keyword/composables/cachedKeywords'
import { useCachedLayoutTemplate } from '@/views/cms/layoutTemplate/composables/cachedLayoutTemplate'
import { useCachedPages } from '@/views/cms/page/composables/cachedPages'
import { useCachedRubrics } from '@/views/cms/rubric/composables/cachedRubrics'
import { useCachedSites } from '@/views/cms/site/composables/cachedSites'
import { useCachedUsers } from '@/views/cms/user/composables/cachedUsers'
import {
  type FilterBag,
  type IntegerId,
  isNumber,
  isUndefined,
  type Pagination,
  useAlerts,
  type ValueObjectOption,
} from '@anzusystems/common-admin'
import useVuelidate from '@vuelidate/core'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const { showValidationError, showRecordWas, showErrorsDefault } = useAlerts()

const datatableHiddenColumns = ref<Array<string>>(['siteGroup', 'desk', 'createdAt', 'modifiedAt'])
const listLoading = ref(false)
const detailLoading = ref(false)
const saveButtonLoading = ref(false)

const { addToCachedSites, fetchCachedSites } = useCachedSites()
const { addToCachedUsers, fetchCachedUsers } = useCachedUsers()
const { addToCachedPages, fetchCachedPages } = useCachedPages()
const { addToCachedLayoutTemplate, fetchCachedLayoutTemplate } = useCachedLayoutTemplate()
const { addToCachedAutoDistributions, fetchCachedAutoDistributions } = useCachedAutoDistributions()
const { addToCachedAdvertSettings, fetchCachedAdvertSettings } = useCachedAdvertSettings()
const { addToCachedRubrics, fetchCachedRubrics } = useCachedRubrics()
const { addToCachedKeywords, fetchCachedKeywords } = useCachedKeywords()
const { addToCachedDesks, fetchCachedDesks } = useCachedDesks()

export const MAX_CACHED_KEYWORDS = 3

export const usePageListActions = () => {
  const listItems = ref<Page[]>([])

  const fetchList = async (pagination: Pagination, filterBag: FilterBag) => {
    listLoading.value = true
    try {
      listItems.value = await fetchPageList(pagination, filterBag)
      listItems.value.forEach((page) => {
        addToCachedPages(page.parent)
        addToCachedSites(page.site)
        addToCachedLayoutTemplate(page.layoutTemplate)
        if (isNumber(page.desk)) {
          addToCachedDesks(page.desk)
        }

        const contentItem = page.mainContent?.contentItem ?? null

        if (isContentItemKindArticleList(contentItem)) {
          contentItem.requiredKeywords.slice(0, MAX_CACHED_KEYWORDS).forEach(
            (keywordId: IntegerId) => addToCachedKeywords(keywordId)
          )

          contentItem.optionalKeywords.slice(0, MAX_CACHED_KEYWORDS).forEach(
            (keywordId: IntegerId) => addToCachedKeywords(keywordId)
          )

        }
      })

      fetchCachedKeywords()
      fetchCachedPages()
      fetchCachedSites()
      fetchCachedLayoutTemplate()
      fetchCachedDesks()
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      listLoading.value = false
    }
  }

  return {
    listLoading,
    datatableHiddenColumns,
    fetchList,
    listItems,
  }
}

export const usePageDetailActions = () => {
  const pageOneStore = usePageOneStore()
  const { page, newAutoDistributionKeywords } = storeToRefs(pageOneStore)

  const fetchData = async (id: IntegerId) => {
    detailLoading.value = true
    try {
      const pageRes = await fetchPage(id)
      await pageOneStore.setPage(pageRes)
      addToCachedPages(pageRes.parent)
      addToCachedSites(pageRes.site)
      addToCachedUsers(pageRes.supervisors)
      addToCachedUsers(pageRes.owners)
      addToCachedLayoutTemplate(pageRes.layoutTemplate)
      addToCachedAutoDistributions(pageRes.excludedAutoDistributions)
      addToCachedAdvertSettings(pageRes.advertSettings)
      addToCachedRubrics(pageRes.rubric)
      fetchCachedPages()
      fetchCachedSites()
      fetchCachedUsers()
      fetchCachedLayoutTemplate()
      fetchCachedAutoDistributions()
      fetchCachedAdvertSettings()
      fetchCachedRubrics()
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  return {
    page,
    newAutoDistributionKeywords,
    detailLoading,
    fetchData,
    resetStore: pageOneStore.reset,
  }
}

export const usePageEditActions = () => {
  const v$ = useVuelidate()
  const pageOneStore = usePageOneStore()
  const {
    page,
    pageLayoutTemplate,
    groupedPageContents,
    groupedAutoDistributions,
    oldAutoDistributionKeywords,
    newAutoDistributionKeywords,
    multiPageUpdateKeywords,
    multiPageUpdateKeywordsDialog,
  } = storeToRefs(pageOneStore)

  const fetchData = async (id: IntegerId) => {
    detailLoading.value = true
    try {
      const pageRes = await fetchPage(id)
      addToCachedSites(pageRes.site)
      await pageOneStore.setPage(pageRes)
      fetchCachedSites()
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  const onUpdate = async (onSuccess: ((page: Page) => void) | undefined = undefined) => {
    try {
      saveButtonLoading.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      pageOneStore.page.contents = Object.values(groupedPageContents.value).flat()
      showRecordWas('updated')
      newAutoDistributionKeywords.value.forEach((keywordId) => {
        if (!oldAutoDistributionKeywords.value.includes(keywordId)) {
          multiPageUpdateKeywords.value.addKeywords.push(keywordId)
        }
      })
      oldAutoDistributionKeywords.value.forEach((keywordId) => {
        if (!newAutoDistributionKeywords.value.includes(keywordId)) {
          multiPageUpdateKeywords.value.removeKeywords.push(keywordId)
        }
      })
      const pageRes = await updatePage(pageOneStore.page.id, pageOneStore.page)
      await pageOneStore.setPage(pageRes)
      if (
        page.value.parent &&
        (multiPageUpdateKeywords.value.addKeywords.length || multiPageUpdateKeywords.value.removeKeywords.length)
      ) {
        multiPageUpdateKeywordsDialog.value = true
      }
      if (!isUndefined(onSuccess)) onSuccess(pageRes)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  return {
    detailLoading,
    saveButtonLoading,
    page,
    pageLayoutTemplate,
    groupedPageContents,
    groupedAutoDistributions,
    oldAutoDistributionKeywords,
    newAutoDistributionKeywords,
    initGroupedPageContents: pageOneStore.initGroupedPageContents,
    updateNewAutoDistributionKeywords: pageOneStore.updateNewAutoDistributionKeywords,
    fetchData,
    onUpdate,
    resetStore: pageOneStore.reset,
  }
}

export const usePageDeleteActions = () => {
  const router = useRouter()

  const onDelete = async (id: IntegerId) => {
    try {
      await deletePage(id)
      showRecordWas('deleted')
      router.push({ name: ROUTE.CMS.PAGE.LIST })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      // closeDeleteDialog()
    }
  }

  return {
    onDelete,
  }
}

export const usePageSelectActions = () => {
  const fetchItems = async (pagination: Pagination, filterBag: FilterBag) => {
    const pages = await fetchPageList(pagination, filterBag)

    return <ValueObjectOption<IntegerId>[]>pages.map((page: Page) => ({
      title: page.texts.title,
      subtitle: `${page.seo.slug} (${page.siteName})`,
      value: page.id,
    }))
  }

  const fetchItemsByIds = async (ids: IntegerId[]) => {
    const pages = await fetchPageListByIds(ids)

    return <ValueObjectOption<IntegerId>[]>pages.map((page: Page) => ({
      title: page.texts.title,
      subtitle: `${page.seo.slug} (${page.siteName})`,
      value: page.id,
    }))
  }

  return {
    fetchItems,
    fetchItemsByIds,
  }
}
