import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsTimelineRoutes: RouteRecordRaw[] = [
  {
    path: '/timeline',
    name: ROUTE.CMS.TIMELINE.LIST,
    component: () => import('@/views/cms/timeline/TimelineListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/timeline/create',
    name: ROUTE.CMS.TIMELINE.CREATE,
    component: () => import('@/views/cms/timeline/TimelineCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_TIMELINE_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/timeline/:id(\\d+)/edit',
    name: ROUTE.CMS.TIMELINE.EDIT,
    component: () => import('@/views/cms/timeline/TimelineEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_TIMELINE_READ, ACL.CMS_TIMELINE_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/timeline/:id(\\d+)',
    name: ROUTE.CMS.TIMELINE.DETAIL,
    component: () => import('@/views/cms/timeline/TimelineDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_TIMELINE_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
