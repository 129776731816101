import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { ContentItemKindWysiwyg } from '@/types/cms/ContentItemKind/ContentItemKindWysiwyg'
import { useDocumentFactory } from '@/components/anzutap/factory/DocumentFactory'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindWysiwygFactory() {
  const { createEmptyDocument } = useDocumentFactory()

  const create = (): ContentItemKindWysiwyg => {
    return {
      ...createAbstractContentItemKind(),
      body: createEmptyDocument(),
      discriminator: ContentItemDiscriminator.Wysiwyg,
      _resourceName: 'contentItemKindWysiwyg',
    }
  }

  return {
    create,
  }
}
