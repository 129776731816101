import { SYSTEM_CMS } from '@/model/systems'
import { dateTimeNow } from '@anzusystems/common-admin'

import type { EmbedKindCreateUpdateDto, EmbedPollAware } from '@/components/anzutap/types/EmbedPoll'

export function useEmbedPollFactory() {
  const createDefault = (id = ''): EmbedPollAware => {
    return {
      id,
      poll: null,
      customData: {},
      discriminator: 'poll',
      _resourceName: 'embedKindPoll',
      _system: SYSTEM_CMS,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
    }
  }

  const createCreateUpdateDto = (id = ''): EmbedKindCreateUpdateDto => {
    return {
      id,
      poll: null,
      customData: {},
    }
  }

  return {
    createDefault,
    createCreateUpdateDto,
  }
}
