import { makeFilterHelper } from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'
import { ENTITY } from '@/services/api/cms/userAdminConfig'
import { reactive } from 'vue'

const makeFilter = makeFilterHelper(SYSTEM_CMS, ENTITY)

export function useUserAdminConfigFilter() {
  return reactive({
    user: {
      ...makeFilter({ name: 'user' }),
    },
    configType: {
      ...makeFilter({ name: 'configType' }),
    },
    layoutType: {
      ...makeFilter({ name: 'layoutType' }),
    },
    systemResource: {
      ...makeFilter({ name: 'systemResource' }),
    },
    customName: {
      ...makeFilter({ name: 'customName' }),
    },
    defaultConfig: {
      ...makeFilter({ name: 'defaultConfig' }),
    },
  })
}
