<script lang="ts" setup>
import {
  type IntegerId,
  isEmpty,
  isUndefined,
  stringSplitOnFirstOccurrence,
  SubjectScopeSymbol,
  SystemScopeSymbol,
} from '@anzusystems/common-admin'
import { computed, inject } from 'vue'
import { useSiteGroups } from '@/views/cms/siteGroup/composables/siteGroupsAll'
import { useI18n } from 'vue-i18n'
import type { ErrorObject } from '@vuelidate/core'

const props = withDefaults(
  defineProps<{
    modelValue: IntegerId | IntegerId[] | null
    label?: string | undefined
    required?: boolean | undefined
    errorMessage?: string | undefined
    multiple?: boolean
    disabled?: boolean
    v?: any
    dataCy?: string
    hideLabel?: boolean
  }>(),
  {
    label: undefined,
    required: undefined,
    errorMessage: undefined,
    multiple: false,
    disabled: false,
    v: null,
    dataCy: undefined,
    hideLabel: false,
  }
)
const emit = defineEmits<{
  (e: 'update:modelValue', data: IntegerId | IntegerId[] | null): void
  (e: 'blur', data: IntegerId | IntegerId[] | null): void
}>()

const modelValueComputed = computed({
  get() {
    return isEmpty(props.modelValue && !props.multiple) ? null : props.modelValue
  },
  set(newValue: IntegerId | IntegerId[] | null) {
    emit('update:modelValue', newValue)
  },
})

const onBlur = () => {
  emit('blur', props.modelValue)
  props.v?.$touch()
}

const { siteGroupsArray } = useSiteGroups()

const { t } = useI18n()

const system = inject<string | undefined>(SystemScopeSymbol, undefined)
const subject = inject<string | undefined>(SubjectScopeSymbol, undefined)

const labelComputed = computed(() => {
  if (!isUndefined(props.label)) return props.label
  if (isUndefined(system) || isUndefined(subject) || isUndefined(props.v?.$path)) return ''
  const { end: path } = stringSplitOnFirstOccurrence(props.v?.$path, '.')
  return t(system + '.' + subject + '.model.' + path)
})

const requiredComputed = computed(() => {
  if (!isUndefined(props.required)) return props.required
  if (props.v?.required && props.v?.required.$params.type === 'required') return true
  return false
})

const errorMessageComputed = computed(() => {
  if (!isUndefined(props.errorMessage)) return [props.errorMessage]
  if (props.v?.$errors?.length) return [props.v.$errors.map((item: ErrorObject) => item.$message).join(' ')]
  return []
})
</script>

<template>
  <VAutocomplete
    v-model="modelValueComputed"
    :items="siteGroupsArray"
    :required="requiredComputed"
    :label="labelComputed"
    :multiple="multiple"
    :disabled="disabled"
    :error-messages="errorMessageComputed"
    :data-cy="dataCy"
    item-value="id"
    item-title="title"
    @blur="onBlur"
  >
    <template
      v-if="!hideLabel"
      #label
    >
      {{ labelComputed
      }}<span
        v-if="requiredComputed"
        class="required"
      />
    </template>
  </VAutocomplete>
</template>
