import type { FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import {
  apiCreateOne,
  apiDeleteOne,
  apiFetchByIds,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
} from '@anzusystems/common-admin'
import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { AdvertSettings } from '@/types/cms/AdvertSettings'

const END_POINT = '/adm/v1/advert-settings'
export const ENTITY = 'advertSettings'

export const fetchAdvertSettingsList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<AdvertSettings[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchAdvertSettingsListByIds = (ids: IntegerId[]) =>
  apiFetchByIds<AdvertSettings[]>(cmsClient, ids, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const fetchAdvertSettings = (id: IntegerId) =>
  apiFetchOne<AdvertSettings>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createAdvertSettings = (data: AdvertSettings) =>
  apiCreateOne<AdvertSettings>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updateAdvertSettings = (id: IntegerId, data: AdvertSettings) =>
  apiUpdateOne<AdvertSettings>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const deleteAdvertSettings = (id: IntegerId) =>
  apiDeleteOne<AdvertSettings>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)
