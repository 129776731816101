import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsKeywordRoutes: RouteRecordRaw[] = [
  {
    path: '/keyword',
    name: ROUTE.CMS.KEYWORD.LIST,
    component: () => import('@/views/cms/keyword/KeywordListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/keyword/create',
    name: ROUTE.CMS.KEYWORD.CREATE,
    component: () => import('@/views/cms/keyword/KeywordCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_KEYWORD_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/keyword/:id(\\d+)/edit',
    name: ROUTE.CMS.KEYWORD.EDIT,
    component: () => import('@/views/cms/keyword/KeywordEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_KEYWORD_READ, ACL.CMS_KEYWORD_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/keyword/:id(\\d+)',
    name: ROUTE.CMS.KEYWORD.DETAIL,
    component: () => import('@/views/cms/keyword/KeywordDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_KEYWORD_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
