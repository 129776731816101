import type { IntegerId } from '@anzusystems/common-admin'
import { defineCached } from '@anzusystems/common-admin'
import { fetchDesignSettingsListByIds } from '@/services/api/cms/designSettingsApi'
import type { DesignSettings, DesignSettingsMinimal } from '@/types/cms/DesignSettings'

const mapFullToMinimal = (designSettings: DesignSettings): DesignSettingsMinimal => ({
  id: designSettings.id,
  title: designSettings.texts.title,
})

const mapIdToMinimal = (id: IntegerId): DesignSettingsMinimal => {
  return { id: id, title: '' }
}

const { cache, toFetch, fetch, add, addManual, addManualMinimal, has, get, isLoaded } = defineCached<
  IntegerId,
  DesignSettings,
  DesignSettingsMinimal
>(mapFullToMinimal, mapIdToMinimal, fetchDesignSettingsListByIds)

export const useCachedDesignSettings = () => {
  return {
    addManualToCachedDesignSettings: addManual,
    addManualMinimalToCachedDesignSettings: addManualMinimal,
    addToCachedDesignSettings: add,
    fetchCachedDesignSettings: fetch,
    toFetchCachedDesignSettings: toFetch,
    cachedDesignSettings: cache,
    hasCachedDesignSettings: has,
    getCachedDesignSettings: get,
    isLoadedCachedDesignSettings: isLoaded,
  }
}
