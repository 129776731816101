import type { AuthorKind } from '@/types/cms/AuthorKind/AuthorKind'
import { AuthorResourceName } from '@/model/cms/valueObject/AuthorResourceName'
import { AuthorDiscriminator } from '@/model/cms/valueObject/AuthorDiscriminator'

export interface AuthorKindSource extends AuthorKind {
  discriminator: typeof AuthorDiscriminator.Source
  title: string
  _resourceName: typeof AuthorResourceName.Source
}

export const isAuthorKindSource = (author: AuthorKind): author is AuthorKindSource => {
  return author.discriminator === AuthorDiscriminator.Source && Object.hasOwn(author, 'title')
}
