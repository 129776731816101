<script lang="ts" setup>
import { AActionCreateButton, ACard } from '@anzusystems/common-admin'
import { ROUTE } from '@/router/routes'
import NewsletterDatatable from '@/views/cms/newsletter/components/NewsletterDatatable.vue'
import ActionbarWrapper from '@/components/wrappers/ActionbarWrapper.vue'
import { useNewsletterListActions } from '@/views/cms/newsletter/composables/newsletterActions'

const { listLoading } = useNewsletterListActions()
</script>

<template>
  <ActionbarWrapper>
    <template #buttons>
      <AActionCreateButton :route-name="ROUTE.CMS.NEWSLETTER.CREATE" />
    </template>
  </ActionbarWrapper>

  <ACard :loading="listLoading">
    <VCardText>
      <NewsletterDatatable />
    </VCardText>
  </ACard>
</template>
