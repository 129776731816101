import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsPublicExportRoutes: RouteRecordRaw[] = [
  {
    path: '/public-export',
    name: ROUTE.CMS.PUBLIC_EXPORT.LIST,
    component: () => import('@/views/cms/publicExport/PublicExportListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/public-export/create',
    name: ROUTE.CMS.PUBLIC_EXPORT.CREATE,
    component: () => import('@/views/cms/publicExport/PublicExportCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PUBLIC_EXPORT_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/public-export/:id(\\d+)/edit',
    name: ROUTE.CMS.PUBLIC_EXPORT.EDIT,
    component: () => import('@/views/cms/publicExport/PublicExportEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PUBLIC_EXPORT_READ, ACL.CMS_PUBLIC_EXPORT_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/public-export/:id(\\d+)',
    name: ROUTE.CMS.PUBLIC_EXPORT.DETAIL,
    component: () => import('@/views/cms/publicExport/PublicExportDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PUBLIC_EXPORT_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
