import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source'
import {
  type AppNotification,
  useAppNotificationsEventBus,
} from '@/composables/appNotification/appNotificationEventBus'
import type { IntegerId } from '@anzusystems/common-admin'
import { isUndefined } from '@anzusystems/common-admin'
import { envConfig } from '@/services/EnvConfigService'

class RetriableError extends Error {}

class FatalError extends Error {}

export function useAppNotificationConnect() {
  const eventBus = useAppNotificationsEventBus()

  return async function connect(userId: IntegerId) {
    if (!envConfig.notification.enabled) return Promise.resolve()
    const request = new Request(`${envConfig.notification.apiUrl}/v1/users/${userId}/notifications/stream/cms:admin`, {
      credentials: 'include',
    })
    await fetchEventSource(request, {
      openWhenHidden: true,
      async onopen(response) {
        if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
          return
        } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
          throw new FatalError()
        } else {
          throw new RetriableError()
        }
      },
      onmessage(msg) {
        if (msg.event === 'FatalError') {
          throw new FatalError(msg.data)
        }
        const data = msg.data.length ? JSON.parse(msg.data) : undefined
        if (!isUndefined(data)) {
          eventBus.emit(data as AppNotification)
        }
      },
      onclose() {
        throw new RetriableError()
      },
      onerror(err) {
        throw err
      },
    })
  }
}

export function useAppNotificationListener() {
  const eventBus = useAppNotificationsEventBus()

  const addAppNotificationListener = (callback: (event: AppNotification) => void) => {
    return eventBus.on(callback)
  }

  return {
    addAppNotificationListener,
  }
}
