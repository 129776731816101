import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ValueObjectOption } from '@anzusystems/common-admin'

export const ArticleDiscriminator = {
  Standard: 'standard',
  Minute: 'minute',
} as const
export type ArticleDiscriminatorType = (typeof ArticleDiscriminator)[keyof typeof ArticleDiscriminator]
export const ArticleDiscriminatorDefault = ArticleDiscriminator.Standard

export function useArticleDiscriminator() {
  const { t } = useI18n()

  const articleDiscriminatorOptions = ref<ValueObjectOption<ArticleDiscriminatorType>[]>([
    {
      value: ArticleDiscriminator.Standard,
      title: t('cms.articleKind.discriminator.standard'),
      color: 'default',
    },
    {
      value: ArticleDiscriminator.Minute,
      title: t('cms.articleKind.discriminator.minute'),
      color: 'warning',
    },
  ])

  const getArticleDiscriminatorOption = (value: ArticleDiscriminatorType) => {
    return articleDiscriminatorOptions.value.find((item) => item.value === value)
  }

  return {
    articleDiscriminatorOptions,
    getArticleDiscriminatorOption,
  }
}
