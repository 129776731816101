import type { IntegerId } from '@anzusystems/common-admin'
import { useAlerts, usePagination } from '@anzusystems/common-admin'
import { fetchArticleMultiTitleList } from '@/services/api/cms/articleMultiTitleApi'
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useArticleMultiTitleOneStore } from '@/stores/cms/articleMultiTitleStore'
import { useArticleMultiTitleListFilter } from '@/model/cms/filter/ArticleMultiTitleFilter'

const multiTitlesLoading = ref(false)
const { showErrorsDefault } = useAlerts()

export const useArticleMultiTitleCreateAndEditActions = () => {
  const articleMultiTitleOneStore = useArticleMultiTitleOneStore()
  const { articleMultiTitles } = storeToRefs(articleMultiTitleOneStore)
  const articleMultiTitleFilter = useArticleMultiTitleListFilter()

  const fetchArticleMultiTitles = async (articleId: IntegerId) => {
    try {
      multiTitlesLoading.value = true
      const pagination = usePagination()
      pagination.sortBy = 'variant'
      pagination.descending = false
      articleMultiTitleFilter.article.model = articleId
      articleMultiTitles.value = await fetchArticleMultiTitleList(pagination, articleMultiTitleFilter)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      multiTitlesLoading.value = false
    }
  }

  return {
    fetchArticleMultiTitles,
    articleMultiTitles,
    multiTitlesLoading,
  }
}
