import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { ContentItemKindMultiArticleList } from '@/types/cms/ContentItemKind/ContentItemKindMultiArticleList'
import { MultiArticleListLayoutDefault } from '@/types/cms/ContentItemKind/ContentItemKindMultiArticleList'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindMultiArticleListFactory() {
  const create = (): ContentItemKindMultiArticleList => {
    return {
      ...createAbstractContentItemKind(),
      title: '',
      url: '',
      layout: MultiArticleListLayoutDefault,
      articleLists: [],
      discriminator: ContentItemDiscriminator.MultiArticleList,
      _resourceName: 'contentItemKindMultiArticleList',
    }
  }

  return {
    create,
  }
}
