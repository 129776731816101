import { SYSTEM_CMS } from '@/model/systems'
import { dateTimeNow, type IntegerId } from '@anzusystems/common-admin'
import type { ArticleMultiTitle, ArticleMultiTitleUpsertDto } from '@/types/cms/ArticleMultiTitle'
import { ArticleMultiTitleVariantDefault } from '@/types/cms/ArticleMultiTitle'

export function useArticleMultiTitleFactory() {
  const createMultiTitle = (articleId: IntegerId): ArticleMultiTitle => {
    return {
      id: 0,
      variant: ArticleMultiTitleVariantDefault,
      position: 0,
      inUse: false,
      headline: '',
      perex: '',
      article: articleId,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
      _resourceName: 'articleMultiTitle',
      _system: SYSTEM_CMS,
    }
  }

  const createUpsertMultiTitleDto = (
    articleId: IntegerId,
    multiTitles: ArticleMultiTitle[] = []
  ): ArticleMultiTitleUpsertDto => {
    return {
      article: articleId,
      multiTitles: multiTitles,
    }
  }

  return {
    createMultiTitle,
    createUpsertMultiTitleDto,
  }
}
