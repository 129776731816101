import type { FilterBag, Pagination } from '@anzusystems/common-admin'
import {
  apiCreateOne,
  apiDeleteOne,
  apiFetchByIds,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
} from '@anzusystems/common-admin'
import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { Newsletter } from '@/types/cms/Newsletter'

const END_POINT = '/adm/v1/newsletter'
export const ENTITY = 'newsletter'

export const fetchNewsletterList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<Newsletter[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchNewsletterListByIds = (ids: number[]) =>
  apiFetchByIds<Newsletter[]>(cmsClient, ids, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const fetchNewsletter = (id: number) =>
  apiFetchOne<Newsletter>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createNewsletter = (data: Newsletter) =>
  apiCreateOne<Newsletter>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updateNewsletter = (id: number, data: Newsletter) =>
  apiUpdateOne<Newsletter>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const deleteNewsletter = (id: number) =>
  apiDeleteOne<Newsletter>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)
