import type { NavigationGuardNext, RouteLocationNormalized, RouteParams } from 'vue-router'
import { isArray, isUndefined, useAlerts, useCollabRoom } from '@anzusystems/common-admin'
import { CollabAccessRoomStatus } from '@/types/Collab'
import { envConfig } from '@/services/EnvConfigService'
import { useArticleCollabActions } from '@/views/cms/article/composables/articleKindActions'
import { type AclValue, useAuth } from '@/composables/auth/auth'

export const checkRequirements = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const { canForAll } = useAuth()

  if (
    isUndefined(to.meta.requiredPermissions) ||
    (isArray<AclValue>(to.meta.requiredPermissions) && to.meta.requiredPermissions.length === 0)
  ) {
    await checkCollab(to, from, next)
  } else if (canForAll(to.meta.requiredPermissions as AclValue[])) {
    await checkCollab(to, from, next)
  } else {
    next({ name: 'unauthorized' })
  }
}

const checkCollab = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const { showWarningT, showErrorT } = useAlerts()

  if (!envConfig.collab.enabled) return next()

  const { stopArticleFieldsListeners, startArticleFieldsListeners } = useArticleCollabActions()

  if (from.meta.collab) {
    const { leaveCollabRoom } = useCollabRoom(from.meta.collab(from.params).room)
    stopArticleFieldsListeners()
    leaveCollabRoom()
  }

  if (to.meta.collab) {
    const collab = to.meta.collab(to.params)
    const { joinCollabRoom, alertedOccupiedRooms } = useCollabRoom(collab.room)

    try {
      await joinCollabRoom({ joinStrategy: collab.joinStrategy, editors: collab.editors })
      startArticleFieldsListeners(collab.room)
    } catch (error) {
      if (error instanceof Error && error.message === CollabAccessRoomStatus.Failed) {
        showErrorT('common.collab.alert.error')
        return next(from)
      }
      showWarningT('common.collab.alert.occupied')
      alertedOccupiedRooms.value.add(collab.room)

      const redirectToRoute = collab.occupiedOrKickedRedirectToRoute
      const params: RouteParams = {}
      if (to.params && to.params.id) {
        params.id = to.params.id
      }

      return next({ name: redirectToRoute, params })
    }
  }

  next()
}
