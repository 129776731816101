import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { ContentItemKindPageHeader } from '@/types/cms/ContentItemKind/ContentItemKindPageHeader'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindPageHeaderFactory() {
  const create = (): ContentItemKindPageHeader => {
    return {
      ...createAbstractContentItemKind(),
      title: '',
      notificationKey: '',
      body: {},
      discriminator: ContentItemDiscriminator.PageHeader,
      _resourceName: 'contentItemKindPageHeader',
    }
  }

  return {
    create,
  }
}
