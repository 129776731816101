import { SYSTEM_CMS } from '@/model/systems'
import { dateTimeNow } from '@anzusystems/common-admin'
import type { EmbedCustomAware, EmbedCustomCreateUpdateDto } from '@/components/anzutap/types/EmbedCustom'

export function useEmbedCustomFactory() {
  const createDefault = (id = ''): EmbedCustomAware => {
    return {
      id,
      name: '',
      customData: {},
      discriminator: 'custom',
      _resourceName: 'embedKindCustom',
      _system: SYSTEM_CMS,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
    }
  }

  const createCreateUpdateDto = (id = ''): EmbedCustomCreateUpdateDto => {
    return {
      id,
      name: '',
      customData: {},
    }
  }

  return {
    createDefault,
    createCreateUpdateDto,
  }
}
