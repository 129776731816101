import { fetchDeskListByIds } from '@/services/api/cms/deskApi'
import type { IntegerId } from '@anzusystems/common-admin'
import { defineCached } from '@anzusystems/common-admin'
import type { Desk, DeskMinimal } from '@/types/cms/Desk'

const mapFullToMinimal = (desk: Desk): DeskMinimal => ({
  id: desk.id,
  name: desk.name,
})

const mapIdToMinimal = (id: IntegerId): DeskMinimal => {
  return { id: id, name: '' }
}

const { cache, toFetch, fetch, add, addManual, addManualMinimal, has, get, isLoaded } = defineCached<
  IntegerId,
  Desk,
  DeskMinimal
>(mapFullToMinimal, mapIdToMinimal, fetchDeskListByIds)

export const useCachedDesks = () => {
  return {
    addManualToCachedDesks: addManual,
    addManualMinimalToCachedDesks: addManualMinimal,
    addToCachedDesks: add,
    fetchCachedDesks: fetch,
    toFetchCachedDesks: toFetch,
    cachedDesks: cache,
    hasCachedDesk: has,
    getCachedDesk: get,
    isLoadedCachedDesk: isLoaded,
  }
}
