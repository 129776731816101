import type { SuperAuthor, SuperAuthorMinimal } from '@/types/cms/SuperAuthor'
import { fetchSuperAuthorListByIds } from '@/services/api/cms/superAuthorApi'
import type { IntegerId } from '@anzusystems/common-admin'
import { defineCached } from '@anzusystems/common-admin'

const mapFullToMinimal = (superAuthor: SuperAuthor): SuperAuthorMinimal => ({
  id: superAuthor.id,
  title: superAuthor.title,
})

const mapIdToMinimal = (id: IntegerId): SuperAuthorMinimal => {
  return { id: id, title: '' }
}

const { cache, toFetch, fetch, add, addManual, addManualMinimal, has, get, isLoaded } = defineCached<
  IntegerId,
  SuperAuthor,
  SuperAuthorMinimal
>(mapFullToMinimal, mapIdToMinimal, fetchSuperAuthorListByIds)

export const useCachedSuperAuthors = () => {
  return {
    addManualToCachedSuperAuthors: addManual,
    addManualMinimalToCachedSuperAuthors: addManualMinimal,
    addToCachedSuperAuthors: add,
    fetchCachedSuperAuthors: fetch,
    toFetchCachedSuperAuthors: toFetch,
    cachedSuperAuthors: cache,
    hasCachedSuperAuthor: has,
    getCachedSuperAuthor: get,
    isLoadedCachedSuperAuthor: isLoaded,
  }
}
