import { acceptHMRUpdate, defineStore } from 'pinia'
import { useSiteGroupFactory } from '@/model/cms/factory/SiteGroupFactory'
import type { SiteGroup } from '@/types/cms/SiteGroup'
import { ref } from 'vue'
import type { IntegerId } from '@anzusystems/common-admin'

export const useSiteGroupOneStore = defineStore('cmsSiteGroupOneStore', () => {
  const { createDefault } = useSiteGroupFactory()

  const siteGroup = ref<SiteGroup>(createDefault())

  function reset() {
    siteGroup.value = createDefault()
  }

  return {
    siteGroup,
    reset,
  }
})

export const useSiteGroupAllStore = defineStore('cmsSiteGroupAllStore', () => {
  const loaded = ref(false)
  const siteGroups = ref(new Map<IntegerId, SiteGroup>())

  function setSiteGroups(values: SiteGroup[]) {
    values.forEach((value) => {
      addSiteGroup(value)
    })
  }

  function addSiteGroup(value: SiteGroup) {
    siteGroups.value.set(value.id, value)
  }

  function getSiteGroup(id: IntegerId) {
    return siteGroups.value.get(id)
  }

  function reset() {
    siteGroups.value.clear()
  }

  return {
    loaded,
    siteGroups,
    setSiteGroups,
    getSiteGroup,
    reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSiteGroupOneStore, import.meta.hot))
  import.meta.hot.accept(acceptHMRUpdate(useSiteGroupAllStore, import.meta.hot))
}
