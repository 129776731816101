import { isNull, type ValueObjectOption } from '@anzusystems/common-admin'
import type { Embed, EmbedAware } from '@/components/anzutap/types/Embed'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'

export const isEmbedExternal = (embed: EmbedAware | Embed | null): embed is EmbedExternalAware => {
  if (isNull(embed)) return false
  return embed.discriminator === 'external'
}

export interface EmbedExternalAware extends EmbedAware {
  discriminator: 'external'
  params: Record<string, any>
  data: Record<string, any>
  type: ScrapeTypeType
  scrapeStatus: ScrapeStatusType
}

export interface EmbedExternalCreateUpdateDto
  extends Omit<
    EmbedExternalAware,
    'detail' | 'discriminator' | '_resourceName' | '_system' | 'createdAt' | 'modifiedAt' | 'createdBy' | 'modifiedBy'
  > {}

export const ScrapeStatus = {
  Unassigned: 'unassigned',
  Pending: 'pending',
  Done: 'done',
  Error: 'error',
} as const
export const ScrapeStatusDefault = ScrapeStatus.Unassigned
export type ScrapeStatusType = (typeof ScrapeStatus)[keyof typeof ScrapeStatus]

export function useEmbedExternalScrapeStatus() {
  const { t } = useI18n()

  const embedExternalScrapeStatusOptions = ref<ValueObjectOption<ScrapeStatusType>[]>([
    {
      value: ScrapeStatus.Unassigned,
      title: t('editor.node.embedExternal.scrapeStatus.unassigned'),
    },
    {
      value: ScrapeStatus.Pending,
      title: t('editor.node.embedExternal.scrapeStatus.pending'),
    },
    {
      value: ScrapeStatus.Done,
      title: t('editor.node.embedExternal.scrapeStatus.done'),
    },
    {
      value: ScrapeStatus.Error,
      title: t('editor.node.embedExternal.scrapeStatus.error'),
    },
  ])

  const getEmbedExternalScrapeStatusOption = (value: ScrapeStatusType) => {
    return embedExternalScrapeStatusOptions.value.find((item) => item.value === value)
  }

  return {
    embedExternalScrapeStatusOptions,
    getEmbedExternalScrapeStatusOption,
  }
}

export const ScrapeType = {
  FacebookPost: 'facebook_post',
  FacebookVideo: 'facebook_video',
  FlourishVisual: 'flourish_visual',
  GoogleMap: 'google_map',
  InstagramPost: 'instagram_post',
  PodbeanEpisode: 'podbean_episode',
  SoundcloudTrack: 'soundcloud_track',
  ScribdDocument: 'scribd_document',
  SpotifyEpisode: 'spotify_episode',
  Ta3Video: 'ta3_video',
  TwitterPost: 'twitter_post',
  TiktokVideo: 'tiktok_video',
  VimeoVideo: 'vimeo_video',
  YoutubeVideo: 'youtube_video',
  Iframe: 'iframe',
} as const
export const ScrapeTypeDefault = ScrapeType.Iframe
export type ScrapeTypeType = (typeof ScrapeType)[keyof typeof ScrapeType]

export function useEmbedExternalScrapeType() {
  const { t } = useI18n()

  const embedExternalScrapeTypeOptions = ref<ValueObjectOption<ScrapeTypeType>[]>([
    {
      value: ScrapeType.FacebookPost,
      title: t('editor.node.embedExternal.type.facebookPost'),
    },
    {
      value: ScrapeType.FacebookVideo,
      title: t('editor.node.embedExternal.type.facebookVideo'),
    },
    {
      value: ScrapeType.FlourishVisual,
      title: t('editor.node.embedExternal.type.flourishVisual'),
    },
    {
      value: ScrapeType.GoogleMap,
      title: t('editor.node.embedExternal.type.googleMap'),
    },
    {
      value: ScrapeType.InstagramPost,
      title: t('editor.node.embedExternal.type.instagramPost'),
    },
    {
      value: ScrapeType.PodbeanEpisode,
      title: t('editor.node.embedExternal.type.podbeanEpisode'),
    },
    {
      value: ScrapeType.SoundcloudTrack,
      title: t('editor.node.embedExternal.type.soundcloudTrack'),
    },
    {
      value: ScrapeType.ScribdDocument,
      title: t('editor.node.embedExternal.type.scribdDocument'),
    },
    {
      value: ScrapeType.SpotifyEpisode,
      title: t('editor.node.embedExternal.type.spotifyEpisode'),
    },
    {
      value: ScrapeType.Ta3Video,
      title: t('editor.node.embedExternal.type.ta3Video'),
    },
    {
      value: ScrapeType.TwitterPost,
      title: t('editor.node.embedExternal.type.twitterPost'),
    },
    {
      value: ScrapeType.TiktokVideo,
      title: t('editor.node.embedExternal.type.tiktokVideo'),
    },
    {
      value: ScrapeType.VimeoVideo,
      title: t('editor.node.embedExternal.type.vimeoVideo'),
    },
    {
      value: ScrapeType.YoutubeVideo,
      title: t('editor.node.embedExternal.type.youtubeVideo'),
    },
  ])

  const getEmbedExternalScrapeTypeOption = (value: ScrapeTypeType) => {
    return embedExternalScrapeTypeOptions.value.find((item) => item.value === value)
  }

  return {
    embedExternalScrapeTypeOptions,
    getEmbedExternalScrapeTypeOption,
  }
}
