import { defineAuth } from '@anzusystems/common-admin'

export const ACL = {
  CMS_ADVERT_SETTINGS_CREATE: 'cms_advertSettings_create',
  CMS_ADVERT_SETTINGS_DELETE: 'cms_advertSettings_delete',
  CMS_ADVERT_SETTINGS_READ: 'cms_advertSettings_read',
  CMS_ADVERT_SETTINGS_UPDATE: 'cms_advertSettings_update',

  CMS_ARTICLE_ARCHIVE_READ: 'cms_articleArchive_read',
  CMS_ARTICLE_ARCHIVE_RESTORE: 'cms_articleArchive_restore',

  CMS_ARTICLE_CREATE: 'cms_article_create',
  CMS_ARTICLE_DELETE: 'cms_article_delete',
  CMS_ARTICLE_PUBLISH: 'cms_article_publish',
  CMS_ARTICLE_READ: 'cms_article_read',
  CMS_ARTICLE_UNPUBLISH: 'cms_article_unpublish',
  CMS_ARTICLE_UPDATE: 'cms_article_update',

  CMS_AUTHOR_CREATE: 'cms_author_create',
  CMS_AUTHOR_DELETE: 'cms_author_delete',
  CMS_AUTHOR_READ: 'cms_author_read',
  CMS_AUTHOR_UPDATE: 'cms_author_update',

  CMS_AUTO_DISTRIBUTION_CREATE: 'cms_autoDistribution_create',
  CMS_AUTO_DISTRIBUTION_DELETE: 'cms_autoDistribution_delete',
  CMS_AUTO_DISTRIBUTION_READ: 'cms_autoDistribution_read',
  CMS_AUTO_DISTRIBUTION_UPDATE: 'cms_autoDistribution_update',

  CMS_BOX_READ: 'cms_box_read',
  CMS_BOX_UPDATE: 'cms_box_update',
  CMS_BOX_CREATE: 'cms_box_create',
  CMS_BOX_DELETE: 'cms_box_delete',

  CMS_BOX_GROUP_CREATE: 'cms_boxGroup_create',
  CMS_BOX_GROUP_DELETE: 'cms_boxGroup_delete',
  CMS_BOX_GROUP_READ: 'cms_boxGroup_read',
  CMS_BOX_GROUP_UPDATE: 'cms_boxGroup_update',

  CMS_COMMENT_CREATE: 'cms_comment_create',
  CMS_COMMENT_DELETE: 'cms_comment_delete',
  CMS_COMMENT_READ: 'cms_comment_read',
  CMS_COMMENT_UPDATE: 'cms_comment_update',

  CMS_COUNTDOWN_CREATE: 'cms_countdown_create',
  CMS_COUNTDOWN_DELETE: 'cms_countdown_delete',
  CMS_COUNTDOWN_READ: 'cms_countdown_read',
  CMS_COUNTDOWN_UPDATE: 'cms_countdown_update',

  CMS_CROSS_BOX_CREATE: 'cms_crossBox_create',
  CMS_CROSS_BOX_DELETE: 'cms_crossBox_delete',
  CMS_CROSS_BOX_READ: 'cms_crossBox_read',
  CMS_CROSS_BOX_UPDATE: 'cms_crossBox_update',

  CMS_CUSTOM_FORM_CREATE: 'cms_customForm_create',
  CMS_CUSTOM_FORM_DELETE: 'cms_customForm_delete',
  CMS_CUSTOM_FORM_READ: 'cms_customForm_read',
  CMS_CUSTOM_FORM_UPDATE: 'cms_customForm_update',

  CMS_DESIGN_SETTINGS_CREATE: 'cms_designSettings_create',
  CMS_DESIGN_SETTINGS_DELETE: 'cms_designSettings_delete',
  CMS_DESIGN_SETTINGS_READ: 'cms_designSettings_read',
  CMS_DESIGN_SETTINGS_UPDATE: 'cms_designSettings_update',

  CMS_EMBED_CREATE: 'cms_embed_create',
  CMS_EMBED_DELETE: 'cms_embed_delete',
  CMS_EMBED_READ: 'cms_embed_read',
  CMS_EMBED_UPDATE: 'cms_embed_update',

  CMS_EXTERNAL_SNIPPET_CREATE: 'cms_externalSnippet_create',
  CMS_EXTERNAL_SNIPPET_DELETE: 'cms_externalSnippet_delete',
  CMS_EXTERNAL_SNIPPET_READ: 'cms_externalSnippet_read',
  CMS_EXTERNAL_SNIPPET_UPDATE: 'cms_externalSnippet_update',

  CMS_GALLERY_CREATE: 'cms_gallery_create',
  CMS_GALLERY_DELETE: 'cms_gallery_delete',
  CMS_GALLERY_READ: 'cms_gallery_read',
  CMS_GALLERY_UPDATE: 'cms_gallery_update',

  CMS_FAQ_CREATE: 'cms_faq_create',
  CMS_FAQ_DELETE: 'cms_faq_delete',
  CMS_FAQ_READ: 'cms_faq_read',
  CMS_FAQ_UPDATE: 'cms_faq_update',

  CMS_IMAGE_CREATE: 'cms_image_create',
  CMS_IMAGE_DELETE: 'cms_image_delete',
  CMS_IMAGE_READ: 'cms_image_read',
  CMS_IMAGE_UPDATE: 'cms_image_update',

  CMS_INBOX_CREATE: 'cms_inbox_create',
  CMS_INBOX_DELETE: 'cms_inbox_delete',

  CMS_INBOX_READ: 'cms_inbox_read',
  CMS_INBOX_UPDATE: 'cms_inbox_update',

  CMS_JOB_CREATE: 'cms_job_create',
  CMS_JOB_DELETE: 'cms_job_delete',
  CMS_JOB_READ: 'cms_job_read',

  CMS_KEYWORD_CREATE: 'cms_keyword_create',
  CMS_KEYWORD_CREATE_ACTIVE: 'cms_keyword_createActive',
  CMS_KEYWORD_READ: 'cms_keyword_read',
  CMS_KEYWORD_UPDATE: 'cms_keyword_update',

  CMS_LAYOUT_TEMPLATE_CREATE: 'cms_layoutTemplate_create',
  CMS_LAYOUT_TEMPLATE_DELETE: 'cms_layoutTemplate_delete',
  CMS_LAYOUT_TEMPLATE_READ: 'cms_layoutTemplate_read',
  CMS_LAYOUT_TEMPLATE_UPDATE: 'cms_layoutTemplate_update',

  CMS_LINKED_LIST_CREATE: 'cms_linkedList_create',
  CMS_LINKED_LIST_DELETE: 'cms_linkedList_delete',
  CMS_LINKED_LIST_READ: 'cms_linkedList_read',
  CMS_LINKED_LIST_UPDATE: 'cms_linkedList_update',

  CMS_MEDIA_CREATE: 'cms_media_create',
  CMS_MEDIA_DELETE: 'cms_media_delete',
  CMS_MEDIA_READ: 'cms_media_read',
  CMS_MEDIA_UPDATE: 'cms_media_update',

  CMS_MINUTE_CREATE: 'cms_minute_create',
  CMS_MINUTE_DELETE: 'cms_minute_delete',

  CMS_MINUTE_POST_CREATE: 'cms_minutePost_create',
  CMS_MINUTE_POST_DELETE: 'cms_minutePost_delete',
  CMS_MINUTE_POST_READ: 'cms_minutePost_read',
  CMS_MINUTE_POST_STATUS_CHANGE: 'cms_minutePost_statusChange',
  CMS_MINUTE_POST_UPDATE: 'cms_minutePost_update',
  CMS_MINUTE_READ: 'cms_minute_read',

  CMS_MINUTE_TOPIC_CREATE: 'cms_minuteTopic_create',
  CMS_MINUTE_TOPIC_DELETE: 'cms_minuteTopic_delete',
  CMS_MINUTE_TOPIC_READ: 'cms_minuteTopic_read',
  CMS_MINUTE_TOPIC_UPDATE: 'cms_minuteTopic_update',

  CMS_MINUTE_UPDATE: 'cms_minute_update',

  CMS_NEWSLETTER_CREATE: 'cms_newsletter_create',
  CMS_NEWSLETTER_DELETE: 'cms_newsletter_delete',
  CMS_NEWSLETTER_READ: 'cms_newsletter_read',
  CMS_NEWSLETTER_UPDATE: 'cms_newsletter_update',

  CMS_NEWSLETTER_PROMO_CREATE: 'cms_newsletterPromo_create',
  CMS_NEWSLETTER_PROMO_DELETE: 'cms_newsletterPromo_delete',
  CMS_NEWSLETTER_PROMO_READ: 'cms_newsletterPromo_read',
  CMS_NEWSLETTER_PROMO_UPDATE: 'cms_newsletterPromo_update',

  CMS_NEWSROOM_SECTION_AUTHOR_CREATE: 'cms_newsroomSectionAuthor_create',
  CMS_NEWSROOM_SECTION_AUTHOR_DELETE: 'cms_newsroomSectionAuthor_delete',
  CMS_NEWSROOM_SECTION_AUTHOR_READ: 'cms_newsroomSectionAuthor_read',
  CMS_NEWSROOM_SECTION_AUTHOR_UPDATE: 'cms_newsroomSectionAuthor_update',

  CMS_NEWSROOM_SECTION_CREATE: 'cms_newsroomSection_create',
  CMS_NEWSROOM_SECTION_DELETE: 'cms_newsroomSection_delete',
  CMS_NEWSROOM_SECTION_READ: 'cms_newsroomSection_read',
  CMS_NEWSROOM_SECTION_UPDATE: 'cms_newsroomSection_update',

  CMS_PAGE_CREATE: 'cms_page_create',
  CMS_PAGE_DELETE: 'cms_page_delete',
  CMS_PAGE_READ: 'cms_page_read',
  CMS_PAGE_UPDATE: 'cms_page_update',

  CMS_PERMISSION_GROUP_CREATE: 'cms_permissionGroup_create',
  CMS_PERMISSION_GROUP_DELETE: 'cms_permissionGroup_delete',
  CMS_PERMISSION_GROUP_READ: 'cms_permissionGroup_read',
  CMS_PERMISSION_GROUP_UPDATE: 'cms_permissionGroup_update',

  CMS_POLL_CREATE: 'cms_poll_create',
  CMS_POLL_DELETE: 'cms_poll_delete',

  CMS_POLL_READ: 'cms_poll_read',
  CMS_POLL_UPDATE: 'cms_poll_update',

  CMS_PROMO_LINK_CREATE: 'cms_promoLink_create',
  CMS_PROMO_LINK_DELETE: 'cms_promoLink_delete',

  CMS_PROMO_LINK_READ: 'cms_promoLink_read',
  CMS_PROMO_LINK_UPDATE: 'cms_promoLink_update',

  CMS_PROPAGATION_SUGGEST_CREATE: 'cms_propagationSuggest_create',
  CMS_PROPAGATION_SUGGEST_UPDATE: 'cms_propagationSuggest_update',

  CMS_PUBLIC_EXPORT_CREATE: 'cms_publicExport_create',
  CMS_PUBLIC_EXPORT_DELETE: 'cms_publicExport_delete',
  CMS_PUBLIC_EXPORT_READ: 'cms_publicExport_read',
  CMS_PUBLIC_EXPORT_UPDATE: 'cms_publicExport_update',

  CMS_QUIZ_CREATE: 'cms_quiz_create',
  CMS_QUIZ_DELETE: 'cms_quiz_delete',
  CMS_QUIZ_READ: 'cms_quiz_read',
  CMS_QUIZ_UPDATE: 'cms_quiz_update',

  CMS_REVIEW_CREATE: 'cms_review_create',
  CMS_REVIEW_DELETE: 'cms_review_delete',
  CMS_REVIEW_READ: 'cms_review_read',
  CMS_REVIEW_UPDATE: 'cms_review_update',

  CMS_ROUTE_CREATE: 'cms_route_create',
  CMS_ROUTE_DELETE: 'cms_route_delete',
  CMS_ROUTE_READ: 'cms_route_read',

  CMS_ROUTE_SETTINGS_CREATE: 'cms_routeSettings_create',
  CMS_ROUTE_SETTINGS_DELETE: 'cms_routeSettings_delete',
  CMS_ROUTE_SETTINGS_READ: 'cms_routeSettings_read',
  CMS_ROUTE_SETTINGS_UPDATE: 'cms_routeSettings_update',

  CMS_ROUTE_UPDATE: 'cms_route_update',
  CMS_RUBRIC_CREATE: 'cms_rubric_create',
  CMS_RUBRIC_DELETE: 'cms_rubric_delete',
  CMS_RUBRIC_READ: 'cms_rubric_read',
  CMS_RUBRIC_UPDATE: 'cms_rubric_update',

  CMS_SITE_CREATE: 'cms_site_create',
  CMS_SITE_DELETE: 'cms_site_delete',

  CMS_SITE_GROUP_CREATE: 'cms_siteGroup_create',
  CMS_SITE_GROUP_DELETE: 'cms_siteGroup_delete',
  CMS_SITE_GROUP_READ: 'cms_siteGroup_read',
  CMS_SITE_GROUP_UPDATE: 'cms_siteGroup_update',

  CMS_SITE_READ: 'cms_site_read',
  CMS_SITE_UPDATE: 'cms_site_update',

  CMS_STAGE_CREATE: 'cms_stage_create',
  CMS_STAGE_DELETE: 'cms_stage_delete',
  CMS_STAGE_READ: 'cms_stage_read',
  CMS_STAGE_UPDATE: 'cms_stage_update',

  CMS_ARTICLE_STAGE_CREATE: 'cms_articleStage_create',
  CMS_ARTICLE_STAGE_READ: 'cms_articleStage_read',
  CMS_ARTICLE_STAGE_COMPLETE: 'cms_articleStage_complete',

  CMS_SUPER_AUTHOR_CREATE: 'cms_superAuthor_create',
  CMS_SUPER_AUTHOR_DELETE: 'cms_superAuthor_delete',
  CMS_SUPER_AUTHOR_READ: 'cms_superAuthor_read',
  CMS_SUPER_AUTHOR_UPDATE: 'cms_superAuthor_update',

  CMS_TARGET_POSITION_CREATE: 'cms_targetPosition_create',
  CMS_TARGET_POSITION_DELETE: 'cms_targetPosition_delete',
  CMS_TARGET_POSITION_READ: 'cms_targetPosition_read',
  CMS_TARGET_POSITION_UPDATE: 'cms_targetPosition_update',

  CMS_TEMPLATE_CREATE: 'cms_template_create',
  CMS_TEMPLATE_DELETE: 'cms_template_delete',
  CMS_TEMPLATE_READ: 'cms_template_read',
  CMS_TEMPLATE_UPDATE: 'cms_template_update',
  CMS_TIMELINE_CREATE: 'cms_timeline_create',
  CMS_TIMELINE_DELETE: 'cms_timeline_delete',
  CMS_TIMELINE_READ: 'cms_timeline_read',
  CMS_TIMELINE_UPDATE: 'cms_timeline_update',

  CMS_USER_ADMIN_CONFIG_CREATE: 'cms_userAdminConfig_create',
  CMS_USER_ADMIN_CONFIG_DELETE: 'cms_userAdminConfig_delete',
  CMS_USER_ADMIN_CONFIG_UPDATE: 'cms_userAdminConfig_update',

  CMS_USER_CREATE: 'cms_user_create',
  CMS_USER_CURRENT_UPDATE: 'cms_user_currentUpdate',
  CMS_USER_DELETE: 'cms_user_delete',
  CMS_USER_READ: 'cms_user_read',
  CMS_USER_UPDATE: 'cms_user_update',

  CMS_EXTERNAL_CONTENT_ARTICLE_READ: 'cms_externalContentArticle_read',
  CMS_EXTERNAL_CONTENT_ARTICLE_CREATE: 'cms_externalContentArticle_create',

  CMS_PERSON_CREATE: 'cms_person_create',
  CMS_PERSON_DELETE: 'cms_person_delete',
  CMS_PERSON_EDIT: 'cms_person_edit',
  CMS_PERSON_UI: 'cms_person_ui',
  CMS_PERSON_READ: 'cms_person_read',

  CMS_ORGANIZATION_CREATE: 'cms_organization_create',
  CMS_ORGANIZATION_DELETE: 'cms_organization_delete',
  CMS_ORGANIZATION_EDIT: 'cms_organization_edit',
  CMS_ORGANIZATION_UI: 'cms_organization_ui',
  CMS_ORGANIZATION_READ: 'cms_organization_read',

  CMS_EVENT_CREATE: 'cms_event_create',
  CMS_EVENT_DELETE: 'cms_event_delete',
  CMS_EVENT_EDIT: 'cms_event_edit',
  CMS_EVENT_UI: 'cms_event_ui',
  CMS_EVENT_READ: 'cms_event_read',

  CMS_PERSON_PAGE_CREATE: 'cms_person_page_create',
  CMS_PERSON_PAGE_DELETE: 'cms_person_page_delete',
  CMS_PERSON_PAGE_EDIT: 'cms_person_page_edit',
  CMS_PERSON_PAGE_UI: 'cms_person_page_ui',
  CMS_PERSON_PAGE_READ: 'cms_person_page_read',

  CMS_PERSON_POSITION_CREATE: 'cms_person_position_create',
  CMS_PERSON_POSITION_DELETE: 'cms_person_position_delete',
  CMS_PERSON_POSITION_EDIT: 'cms_person_position_edit',
  CMS_PERSON_POSITION_UI: 'cms_person_position_ui',
  CMS_PERSON_POSITION_READ: 'cms_person_position_read',

  CMS_ORGANIZATION_PAGE_CREATE: 'cms_organization_page_create',
  CMS_ORGANIZATION_PAGE_DELETE: 'cms_organization_page_delete',
  CMS_ORGANIZATION_PAGE_EDIT: 'cms_organization_page_edit',
  CMS_ORGANIZATION_PAGE_UI: 'cms_organization_page_ui',
  CMS_ORGANIZATION_PAGE_READ: 'cms_organization_page_read',

  CONTENT_HUB_EXTERNAL_CONTENT_READ: 'contentHub_externalContent_read',
  CONTENT_HUB_EXTERNAL_CONTENT_UPDATE: 'contentHub_externalContent_update',
  CONTENT_HUB_EXTERNAL_CONTENT_PROCESSED: 'contentHub_externalContent_processed',
  CONTENT_HUB_EXTERNAL_CONTENT_UI: 'contentHub_externalContent_ui',

  CONTENT_HUB_EXTERNAL_SOURCE_READ: 'contentHub_externalSource_read',

  CONTENT_HUB_RUBRIC_READ: 'contentHub_rubric_read',

  CONTENT_HUB_ANZU_USER_CREATE: 'contentHub_user_create',
  CONTENT_HUB_ANZU_USER_UPDATE: 'contentHub_user_update',
  CONTENT_HUB_ANZU_USER_READ: 'contentHub_user_read',

  CONTENT_HUB_PERMISSION_GROUP_CREATE: 'contentHub_permissionGroup_create',
  CONTENT_HUB_PERMISSION_GROUP_READ: 'contentHub_permissionGroup_read',
  CONTENT_HUB_PERMISSION_GROUP_UPDATE: 'contentHub_permissionGroup_update',
  CONTENT_HUB_PERMISSION_GROUP_DELETE: 'contentHub_permissionGroup_delete',

  CMS_DESK_CREATE: 'cms_desk_create',
  CMS_DESK_DELETE: 'cms_desk_delete',
  CMS_DESK_READ: 'cms_desk_read',
  CMS_DESK_UPDATE: 'cms_desk_update',

  CMS_TASK_CREATE: 'cms_task_create',
  CMS_TASK_DELETE: 'cms_task_delete',
  CMS_TASK_READ: 'cms_task_read',
  CMS_TASK_UPDATE: 'cms_task_update',

  CMS_ARTICLE_INTENTION_CREATE: 'cms_articleIntention_create',
  CMS_ARTICLE_INTENTION_DELETE: 'cms_articleIntention_delete',
  CMS_ARTICLE_INTENTION_READ: 'cms_articleIntention_read',
  CMS_ARTICLE_INTENTION_UPDATE: 'cms_articleIntention_update',

  CMS_TRENDING_ARTICLE_GROUP_LIST_CREATE: 'cms_trendingArticleGroupList_create',
  CMS_TRENDING_ARTICLE_GROUP_LIST_DELETE: 'cms_trendingArticleGroupList_delete',
  CMS_TRENDING_ARTICLE_GROUP_LIST_READ: 'cms_trendingArticleGroupList_read',
  CMS_TRENDING_ARTICLE_GROUP_LIST_UPDATE: 'cms_trendingArticleGroupList_update',
} as const
type AclKey = keyof typeof ACL
export type AclValue = (typeof ACL)[AclKey]

export function useAuth() {
  const { can, canForAll, canForSome, useCurrentUser } = defineAuth<AclValue>('cms')

  return {
    useCurrentUser,
    can,
    canForAll,
    canForSome,
  }
}
