import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsSiteRoutes: RouteRecordRaw[] = [
  {
    path: '/site',
    name: ROUTE.CMS.SITE.LIST,
    component: () => import('@/views/cms/site/SiteListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/site/create',
    name: ROUTE.CMS.SITE.CREATE,
    component: () => import('@/views/cms/site/SiteCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_SITE_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/site/:id(\\d+)/edit',
    name: ROUTE.CMS.SITE.EDIT,
    component: () => import('@/views/cms/site/SiteEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_SITE_READ, ACL.CMS_SITE_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/site/:id(\\d+)',
    name: ROUTE.CMS.SITE.DETAIL,
    component: () => import('@/views/cms/site/SiteDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_SITE_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
