import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsStageRoutes: RouteRecordRaw[] = [
  {
    path: '/stage',
    name: ROUTE.CMS.STAGE.LIST,
    component: () => import('@/views/cms/stage/StageListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/stage/create',
    name: ROUTE.CMS.STAGE.CREATE,
    component: () => import('@/views/cms/stage/StageCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_STAGE_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/stage/:id(\\d+)/edit',
    name: ROUTE.CMS.STAGE.EDIT,
    component: () => import('@/views/cms/stage/StageEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_STAGE_READ, ACL.CMS_STAGE_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/stage/:id(\\d+)/dashboard',
    name: ROUTE.CMS.STAGE.DASHBOARD,
    component: () => import('@/views/cms/stage/StageDashboardView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_STAGE_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
