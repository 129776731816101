import { SYSTEM_CMS } from '@/model/systems'
import { dateTimeNow } from '@anzusystems/common-admin'
import type { EmbedRelatedAware, EmbedRelatedCreateUpdateDto } from '@/components/anzutap/types/EmbedRelated'

export function useEmbedRelatedFactory() {
  const createDefault = (id = ''): EmbedRelatedAware => {
    return {
      id,
      title: '',
      items: [],
      customData: {},
      discriminator: 'related',
      _resourceName: 'embedKindRelated',
      _system: SYSTEM_CMS,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
    }
  }

  const createCreateUpdateDto = (id = ''): EmbedRelatedCreateUpdateDto => {
    return {
      id,
      title: '',
      items: [],
      customData: {},
    }
  }

  return {
    createDefault,
    createCreateUpdateDto,
  }
}
