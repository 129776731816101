import type { Immutable } from '@anzusystems/common-admin'
import { ensureUniqueValues, objectDeepFreeze, type UniqueValues } from '@anzusystems/common-admin'

const filterKeys = {
  article: 'filter_article',
  advertSettings: 'filter_advertSettings',
  deskArticle: 'filter_deskArticle',
  userArticle: 'filter_userArticle',
  articleArchive: 'filter_articleArchive',
  articleIntention: 'filter_articleIntention',
  articleMinute: 'filter_articleMinute',
  stageDashboardPrefix: 'filter_stageDashboardPrefix_',
  author: 'filter_author',
  autoDistribution: 'filter_autoDistribution',
  box: 'filter_box',
  boxGroup: 'filter_boxGroup',
  countdown: 'filter_countdown',
  crossBox: 'filter_crossBox',
  designSettings: 'filter_designSettings',
  desk: 'filter_desk',
  event: 'filter_event',
  externalSnippet: 'filter_externalSnippet',
  faq: 'filter_faq',
  gallery: 'filter_gallery',
  inbox: 'filter_inbox',
  job: 'filter_job',
  keyword: 'filter_keyword',
  keywordManagement: 'filter_keywordManagement',
  layoutTemplate: 'filter_layoutTemplate',
  linked: 'filter_linked',
  minuteTopic: 'filter_minuteTopic',
  newsletter: 'filter_newsletter',
  newsletterPromo: 'filter_newsletterPromo',
  newsroomSection: 'filter_newsroomSection',
  organization: 'filter_organization',
  organizationPage: 'filter_organizationPage',
  page: 'filter_page',
  person: 'filter_person',
  personPage: 'filter_personPage',
  personPosition: 'filter_personPosition',
  poll: 'filter_poll',
  promoLink: 'filter_promoLink',
  publicExport: 'filter_publicExport',
  quiz: 'filter_quiz',
  review: 'filter_review',
  route: 'filter_route',
  routeSettings: 'filter_routeSettings',
  rubric: 'filter_rubric',
  site: 'filter_site',
  siteGroup: 'filter_siteGroup',
  stage: 'filter_stage',
  superAuthor: 'filter_superAuthor',
  targetPosition: 'filter_targetPosition',
  deskTask: 'filter_deskTask',
  task: 'filter_task',
  userTask: 'filter_userTask',
  timeline: 'filter_timeline',
  anzuUser: 'filter_anzuUser',
  customForm: 'filter_customForm',
  log: 'filter_log',
  permissionGroup: 'filter_permissionGroup',
  externalContent: 'filter_externalContent',
  trendingArticleGroupList: 'filter_trendingArticleGroupList',
} as const

export const FILTER_KEY: Immutable<UniqueValues<typeof filterKeys>> = objectDeepFreeze(ensureUniqueValues(filterKeys))
