import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { ContentItemKindLinkedList } from '@/types/cms/ContentItemKind/ContentItemKindLinkedList'
import { LinkedListLayoutDefault } from '@/types/cms/ContentItemKind/ContentItemKindLinkedList'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindLinkedListFactory() {
  const create = (): ContentItemKindLinkedList => {
    return {
      ...createAbstractContentItemKind(),
      title: '',
      linkedList: null,
      layout: LinkedListLayoutDefault,
      discriminator: ContentItemDiscriminator.LinkedList,
      _resourceName: 'contentItemKindLinkedList',
    }
  }

  return {
    create,
  }
}
