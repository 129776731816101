import { reactive } from 'vue'
import { makeFilterHelper } from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'
import { ENTITY } from '@/services/api/cms/newsletterScheduleApi'

const makeFilter = makeFilterHelper(SYSTEM_CMS, ENTITY)

const filter = reactive({
  id: {
    ...makeFilter({ name: 'id', variant: 'in' }),
  },
  newsletter: {
    ...makeFilter({ name: 'newsletter', variant: 'eq' }),
  },
})

export function useNewsletterScheduleListFilter() {
  return filter
}
