<script lang="ts" setup>
import { ABooleanValue, ACopyText, ARow, isEmpty } from '@anzusystems/common-admin'
import CoreTrackingFields from '@/components/cms/CoreTrackingFields.vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import { useNewsletterType } from '@/model/cms/valueObject/NewsletterType'
import { useNewsletterOneStore } from '@/stores/cms/newsletterStore'
import CachedRubricChip from '@/views/cms/rubric/components/CachedRubricChip.vue'
import CachedSiteChip from '@/views/cms/site/components/CachedSiteChip.vue'
import CachedSiteGroupChip from '@/views/cms/siteGroup/components/CachedSiteGroupChip.vue'
import NewsletterSchedules from '@/views/cms/newsletterSchedule/components/NewsletterSchedules.vue'
import NewsletterGeneratorSettings from '@/views/cms/newsletter/components/NewsletterGeneratorSettings.vue'
import NewsletterPromoList from '@/views/cms/newsletterPromo/components/NewsletterPromoList.vue'
import { NewsletterPromoType } from '@/model/cms/valueObject/NewsletterPromoType'

const { newsletter } = storeToRefs(useNewsletterOneStore())
const rightTab = ref('schedules')

const { t } = useI18n()

const { getNewsletterTypeOptions } = useNewsletterType()
const newsletterTypeOption = computed(() => {
  return getNewsletterTypeOptions(newsletter.value.type)
})
</script>

<template>
  <VRow>
    <VCol cols="8">
      <ARow :title="t('cms.newsletter.model.id')">
        <ACopyText :value="newsletter.id" />
      </ARow>
      <ARow :title="t('cms.newsletter.model.siteGroup')">
        <CachedSiteGroupChip :id="newsletter.siteGroup" />
      </ARow>
      <ARow
        class="pl-2"
        :title="t('cms.newsletter.model.site')"
      >
        <CachedSiteChip :id="newsletter.site" />
      </ARow>
      <ARow
        class="pl-2"
        :title="t('cms.newsletter.model.rubric')"
      >
        <CachedRubricChip :id="newsletter.rubric" />
      </ARow>
      <ARow
        :title="t('cms.newsletter.model.texts.title')"
        :value="newsletter.texts.title"
      />
      <ARow
        :title="t('cms.newsletter.model.texts.titlePublic')"
        :value="newsletter.texts.titlePublic"
      />
      <ARow
        v-if="newsletterTypeOption"
        :title="t('cms.newsletter.model.newsletterType')"
        :value="newsletterTypeOption.title"
      />
      <ARow
        :title="t('cms.newsletter.model.mailerId')"
        :value="newsletter.mailerId"
      />
      <ARow :title="t('cms.newsletter.model.enabled')">
        <ABooleanValue :value="newsletter.enabled" />
      </ARow>
      <NewsletterPromoList
        v-if="!isEmpty(newsletter.newsletterPromos)"
        :id-list="newsletter.newsletterPromos"
        :type="NewsletterPromoType.Top"
        :title="t('cms.newsletter.activeTopPromos')"
      />
      <NewsletterPromoList
        v-if="!isEmpty(newsletter.newsletterPromos)"
        :id-list="newsletter.newsletterPromos"
        :type="NewsletterPromoType.Bottom"
        :title="t('cms.newsletter.activeBottomPromos')"
      />
      <CoreTrackingFields :data="newsletter" />
    </VCol>
    <VCol cols="4">
      <VTabs
        v-model="rightTab"
        color="primary"
        class="mb-2"
        grow
      >
        <VTab value="schedules">
          Plánovače
        </VTab>
        <VTab value="mailerSetting">
          Mailer config
        </VTab>
      </VTabs>
      <div v-show="'schedules' === rightTab">
        <ARow class="pl-2">
          <NewsletterSchedules
            v-if="newsletter.id"
            :newsletter="newsletter"
            :is-edit="false"
          />
        </ARow>
      </div>
      <div v-show="'mailerSetting' === rightTab">
        <ARow class="pl-2">
          <NewsletterGeneratorSettings
            v-if="newsletter.id"
            :newsletter="newsletter"
            :is-edit="false"
          />
        </ARow>
        <ARow
          :title="t('cms.newsletter.model.settings.emailTemplateMailLayoutId')"
          :value="newsletter.settings.emailTemplateMailLayoutId"
        />
        <ARow
          :title="t('cms.newsletter.model.settings.emailTemplateMailTypeCode')"
          :value="newsletter.settings.emailTemplateMailTypeCode"
        />
        <ARow
          :title="t('cms.newsletter.model.settings.emailTemplateFrom')"
          :value="newsletter.settings.emailTemplateFrom"
        />
        <ARow
          :title="t('cms.newsletter.model.settings.emailTemplateSubject')"
          :value="newsletter.settings.emailTemplateSubject"
        />
        <ARow
          :title="t('cms.newsletter.model.settings.emailTemplateSubjectDynamicParam')"
          :value="newsletter.settings.emailTemplateSubjectDynamicParam"
        />
        <ARow
          :title="t('cms.newsletter.model.settings.segmentProvider')"
          :value="newsletter.settings.segmentProvider"
        />
        <ARow
          :title="t('cms.newsletter.model.settings.segmentCode')"
          :value="newsletter.settings.segmentCode"
        />
        <ARow
          :title="t('cms.newsletter.model.settings.segmentCodeWithWebAccess')"
          :value="newsletter.settings.segmentCodeWithWebAccess"
        />
        <ARow
          :title="t('cms.newsletter.model.settings.segmentCodeWithoutWebAccess')"
          :value="newsletter.settings.segmentCodeWithoutWebAccess"
        />
      </div>
    </VCol>
  </VRow>
</template>
