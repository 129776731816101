import type { FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import {
  apiCreateOne,
  apiDeleteOne,
  apiFetchByIds,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
} from '@anzusystems/common-admin'
import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { AutoDistribution } from '@/types/cms/AutoDistribution'

const END_POINT = '/adm/v1/auto-distribution'
export const ENTITY = 'autoDistribution'

export const fetchAutoDistributionList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<AutoDistribution[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchAutoDistributionListByIds = (ids: IntegerId[]) =>
  apiFetchByIds<AutoDistribution[]>(cmsClient, ids, END_POINT + '/search', {}, SYSTEM_CMS, ENTITY, undefined, true)

export const fetchAutoDistribution = (id: IntegerId) =>
  apiFetchOne<AutoDistribution>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createAutoDistribution = (data: AutoDistribution) =>
  apiCreateOne<AutoDistribution>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updateAutoDistribution = (id: IntegerId, data: AutoDistribution) =>
  apiUpdateOne<AutoDistribution>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const deleteAutoDistribution = (id: IntegerId) =>
  apiDeleteOne<AutoDistribution>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)
