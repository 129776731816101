import type { ContentItemKind } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { DatetimeUTC, IntegerId, IntegerIdNullable } from '@anzusystems/common-admin'

export const ContentItemKindArchivePeriod = {
  Monthly: 'monthly',
  Daily: 'daily',
} as const
export const ContentItemKindArchivePeriodDefault = ContentItemKindArchivePeriod.Monthly
export type ContentItemKindArchivePeriodType =
  (typeof ContentItemKindArchivePeriod)[keyof typeof ContentItemKindArchivePeriod]

export interface ContentItemKindArchive extends ContentItemKind {
  listSiteGroup: IntegerIdNullable
  listSite: IntegerIdNullable
  rubrics: IntegerId[]
  requiredKeywords: IntegerId[]
  optionalKeywords: IntegerId[]
  period: ContentItemKindArchivePeriodType
  itemsLimit: number
  dateSince: DatetimeUTC
  _resourceName: 'contentItemKindArchive'
}
