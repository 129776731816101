import { RubricStatusDefault } from '@/model/cms/valueObject/RubricStatus'
import { SYSTEM_CMS } from '@/model/systems'
import type { Rubric } from '@/types/cms/Rubric'
import { dateTimeNow } from '@anzusystems/common-admin'

export function useRubricFactory() {
  const createDefault = (): Rubric => {
    return {
      id: 0,
      texts: {
        title: '',
        shortTitle: '',
        description: '',
      },
      seo: {
        title: '',
        description: '',
        slug: '',
      },
      attributes: {
        status: RubricStatusDefault,
      },
      settings: {
        overrideParentContentLockSettings: false,
        lockAfterPercentage: 0.0,
      },
      flags: {
        enableArticleMinutes: false,
        enableAdverts: false,
        enableForum: true,
      },
      site: null,
      siteGroup: null,
      designSettings: null,
      advertSettings: null,
      linkedList: null,
      primaryNewsletter: null,
      secondaryNewsletter: null,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
      _resourceName: 'rubric',
      _system: SYSTEM_CMS,
    }
  }

  return {
    createDefault,
  }
}
