<script setup lang="ts">
import { AChipNoLink } from '@anzusystems/common-admin'
import { useI18n } from 'vue-i18n'
import type { CollabRoom } from '@/types/Collab'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const { t } = useI18n()
</script>

<template>
  <div class="d-flex gc-2 align-center mb-3">
    <span>{{ t('cms.articleKind.widget.statisticsCounts.readsOverall') }}: </span>
    <AChipNoLink color="blue">
      737
    </AChipNoLink>
  </div>
  <div class="d-flex gc-2 align-center mb-3">
    <span>{{ t('cms.articleKind.widget.statisticsCounts.readsQuality') }}: </span>
    <AChipNoLink color="blue">
      42
    </AChipNoLink>
  </div>
  <div class="d-flex gc-2 align-center mb-3">
    <span>{{ t('cms.articleKind.widget.statisticsCounts.charCountOverall') }}: </span>
    <AChipNoLink color="blue">
      1651
    </AChipNoLink>
  </div>
  <div class="d-flex gc-2 align-center mb-3">
    <span>{{ t('cms.articleKind.widget.statisticsCounts.charCountBeforePaywall') }}: </span>
    <AChipNoLink color="blue">
      593
    </AChipNoLink>
  </div>
  <div class="d-flex gc-2 align-center mb-3">
    <span>{{ t('cms.articleKind.widget.statisticsCounts.timeToSpendLoggedUser') }}: </span>
    <AChipNoLink color="blue">
      89
    </AChipNoLink>
  </div>
  <div class="d-flex gc-2 align-center mb-3">
    <span>{{ t('cms.articleKind.widget.statisticsCounts.timeToSpendSubbedUser') }}: </span>
    <AChipNoLink color="blue">
      152
    </AChipNoLink>
  </div>
</template>
