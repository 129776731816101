import type { ArticleKind } from '@/types/cms/ArticleKind/ArticleKind'
import type { ArticleMinuteCreateDto, ArticleStandardCreateDto } from '@/types/cms/ArticleKind/ArticleMinuteCreateDto'
import { useValidate } from '@anzusystems/common-admin'
import useVuelidate from '@vuelidate/core'
import type { Ref } from 'vue'

export const ArticleCustomDataValidationScope = Symbol.for('ArticleCustomDataValidationScope')

export function useArticleUpdateValidation(article: Ref<ArticleKind>) {
  const { maxLength, minLength, minValue, required } = useValidate()
  const rules = {
    article: {
      texts: {
        headline: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
        overline: {
          maxLength: maxLength(255),
        },
        perex: {
          maxLength: maxLength(4096),
        },
      },
      seo: {
        description: {
          maxLength: maxLength(4096),
        },
        title: {
          maxLength: maxLength(255),
        },
      },
      canonicalUrl: {},
      keywords: {},
      site: {
        required,
        minValue: minValue(1),
      },
      siteGroup: {
        required,
        minValue: minValue(1),
      },
      intention: {
        required,
        minValue: minValue(1),
      },
      layoutTemplate: {
        required,
        minValue: minValue(1),
      },
      designSettings: {},
    },
  }

  const v$ = useVuelidate(rules, { article })

  return {
    v$,
  }
}

export function useArticleMinuteCreateValidation(article: Ref<ArticleMinuteCreateDto>) {
  const { maxLength, minLength, minValue, required } = useValidate()
  const rules = {
    article: {
      texts: {
        headline: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
      },
      site: {
        required,
        minValue: minValue(1),
      },
      siteGroup: {
        required,
        minValue: minValue(1),
      },
      rubric: {
        required,
        minValue: minValue(1),
      },
    },
  }

  const v$ = useVuelidate(rules, { article })

  return {
    v$,
  }
}

export function useArticleStandardCreateValidation(article: Ref<ArticleStandardCreateDto>) {
  const { maxLength, minLength, minValue, required } = useValidate()
  const rules = {
    article: {
      headline: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255),
      },
      desk: {
        required,
        minValue: minValue(1),
      },
      rubric: {
        required,
        minValue: minValue(1),
      },
      intention: {
        required,
        minValue: minValue(1),
      },
    },
  }

  const v$ = useVuelidate(rules, { article })

  return {
    v$,
  }
}
