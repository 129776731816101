<script lang="ts" setup>
import { computed } from 'vue'
import MainBarButtonsDialogDefault from '@/views/cms/article/components/mainBarButtons/MainBarButtonsDialogDefault.vue'
import MainBarButtonsDialogPublishLater from '@/views/cms/article/components/mainBarButtons/MainBarButtonsDialogPublishLater.vue'
import { MainBarDialog } from '@/views/cms/article/components/mainBarButtons/mainBarButtons'
import { storeToRefs } from 'pinia'
import MainBarButtonsDialogEditDialogReady from '@/views/cms/article/components/mainBarButtons/MainBarButtonsDialogEditDialogReady.vue'
import MainBarButtonsDialogEditDialogPublished from '@/views/cms/article/components/mainBarButtons/MainBarButtonsDialogEditDialogPublished.vue'
import MainBarButtonsDialogWithdraw from './MainBarButtonsDialogWithdraw.vue'
import { useMainBarDialogsStore } from '@/views/cms/article/components/mainBarButtons/mainBarDialogsStore'

const mainBarDialogsStore = useMainBarDialogsStore()
const { mainBarDialog } = storeToRefs(mainBarDialogsStore)

const currentDialog = computed(() => {
  switch (mainBarDialog.value) {
    case MainBarDialog.PublishLater:
      return MainBarButtonsDialogPublishLater
    case MainBarDialog.EditDialogReady:
      return MainBarButtonsDialogEditDialogReady
    case MainBarDialog.EditDialogPublished:
      return MainBarButtonsDialogEditDialogPublished
    case MainBarDialog.Withdraw:
      return MainBarButtonsDialogWithdraw
    default:
      return MainBarButtonsDialogDefault
  }
})
</script>

<template>
  <component
    :is="currentDialog"
    v-if="mainBarDialog"
  />
</template>
