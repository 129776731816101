import { SYSTEM_CMS } from '@/model/systems'
import { dateTimeNow } from '@anzusystems/common-admin'

import type { EmbedAudioAware, EmbedAudioCreateUpdateDto } from '@/components/anzutap/types/EmbedAudio'

export function useEmbedAudioFactory() {
  const createDefault = (id = ''): EmbedAudioAware => {
    return {
      id,
      dam: {
        imageFileId: null,
        assetId: '',
        licenceId: 0,
      },
      customData: {},
      discriminator: 'audio',
      _resourceName: 'embedKindAudio',
      _system: SYSTEM_CMS,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
    }
  }

  const createCreateUpdateDto = (id = ''): EmbedAudioCreateUpdateDto => {
    return {
      id,
      dam: {
        imageFileId: '',
        assetId: '',
        licenceId: 0,
      },
      customData: {},
    }
  }

  return {
    createDefault,
    createCreateUpdateDto,
  }
}
