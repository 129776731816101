<script setup lang="ts">
import { useAnchorRouteInfo } from '@/components/anzutap/extensions/anchor/composables/useAnchor'
import {
  fetchArticleActiveAndReservedRouteList,
  fetchArticleSuggestedRoute,
  reserveArticleSuggestedRoute,
} from '@/services/api/cms/routeKindApi'
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import { type RouteKind, RouteStatus } from '@/types/cms/RouteKind/RouteKind'
import { useArticleKindEditActions } from '@/views/cms/article/composables/articleKindActions'
import {
  ACollabLockedByUser,
  ACopyText,
  ADialogToolbar,
  ARow,
  type CollabComponentConfig,
  type CollabFieldData,
  type CollabFieldDataEnvelope,
  type CollabFieldLockOptions,
  CollabFieldLockStatus,
  type CollabFieldLockStatusPayload,
  CollabFieldLockType,
  type DocId,
  type IntegerIdNullable,
  isDefined,
  isUndefined,
  useAlerts,
  useCollabField,
  useCommonAdminCollabOptions,
} from '@anzusystems/common-admin'
import { storeToRefs } from 'pinia'
import { computed, nextTick, onUnmounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const props = withDefaults(
  defineProps<{
    isEdit?: boolean
    isModerator?: boolean
    collab?: CollabComponentConfig
  }>(),
  {
    isEdit: false,
    isModerator: false,
    collab: undefined,
  }
)

const status = ref<'init' | 'loading' | 'error' | 'ready'>('init')
const reserveRegenerateDialog = ref(false)
const confirmButtonLoading = ref(false)
const dialogLoading = ref(false)
const url = ref('')
const url2 = ref('')
const title = ref('')
const title2 = ref('')
const activeRoute = ref<RouteKind | undefined>(undefined)
const reservedRoute = ref<RouteKind | undefined>(undefined)
const suggestedRoute = ref<RouteKind | undefined>(undefined)
const dialogNewUrl = ref('')
const showRefresh = ref(false)
const showReserveRegenerate = ref(false)
const menuOverlay = ref(false)
const routeChanged = ref(false)

const { article, articleSite } = storeToRefs(useArticleKindOneStore())
const { showErrorsDefault } = useAlerts()
const { anchorRouteInfo } = useAnchorRouteInfo()
const { onUpdate: saveArticle } = useArticleKindEditActions()

const menuOrDialogOpened = computed(() => {
  return menuOverlay.value || reserveRegenerateDialog.value
})

// Collaboration
const { collabOptions } = useCommonAdminCollabOptions()
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const releaseFieldLock = ref((data: CollabFieldData, options?: Partial<CollabFieldLockOptions>) => {})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const acquireFieldLock = ref((options?: Partial<CollabFieldLockOptions>) => {})
const lockedByUserLocal = ref<IntegerIdNullable>(null)
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
if (collabOptions.value.enabled && isDefined(props.collab)) {
  const {
    releaseCollabFieldLock,
    acquireCollabFieldLock,
    addCollabFieldDataChangeListener,
    addCollabFieldLockStatusListener,
    addCollabGatheringBufferDataListener,
    lockedByUser,
    // eslint-disable-next-line vue/no-setup-props-reactivity-loss
  } = useCollabField(props.collab.room, props.collab.field)
  releaseFieldLock.value = releaseCollabFieldLock
  acquireFieldLock.value = acquireCollabFieldLock
  watch(
    lockedByUser,
    (newValue) => {
      lockedByUserLocal.value = newValue
    },
    { immediate: true }
  )
  addCollabFieldDataChangeListener((data: CollabFieldDataEnvelope) => {
    const value = data.value as boolean
    if (value) {
      reset()
      init(article.value.docId)
    }
  })
  addCollabFieldLockStatusListener((data: CollabFieldLockStatusPayload) => {
    if (data.status === CollabFieldLockStatus.Failure && data.type === CollabFieldLockType.Acquire) {
      menuOverlay.value = false
      reserveRegenerateDialog.value = false
    }
  })
  addCollabGatheringBufferDataListener(() => {
    menuOverlay.value = false
    reserveRegenerateDialog.value = false
  })
}

const refreshSuggested = async (saveBefore = false) => {
  routeChanged.value = true
  status.value = 'loading'
  try {
    if (saveBefore) {
      await saveArticle()
    }
    suggestedRoute.value = await fetchArticleSuggestedRoute(article.value.id)
    url.value = prefixWithSiteDomain(suggestedRoute.value?.uri.full)
    title.value = 'Pravdepodobná URL'
    status.value = 'ready'
  } catch (e) {
    showErrorsDefault(e)
    status.value = 'error'
  }
}

const openReserveRegenerateDialog = async () => {
  reserveRegenerateDialog.value = true
  dialogLoading.value = true
  try {
    await saveArticle()
    const res = await fetchArticleSuggestedRoute(article.value.id)
    dialogNewUrl.value = prefixWithSiteDomain(res.uri.full)
  } catch (e) {
    showErrorsDefault(e)
  } finally {
    dialogLoading.value = false
  }
}

const closeReserveRegenerateDialog = () => {
  reserveRegenerateDialog.value = false
  dialogNewUrl.value = ''
}

const confirmReserveRegenerateDialog = async () => {
  routeChanged.value = true
  confirmButtonLoading.value = true
  try {
    await reserveArticleSuggestedRoute(article.value.id)
    closeReserveRegenerateDialog()
  } catch (e) {
    showErrorsDefault(e)
  } finally {
    dialogLoading.value = false
    confirmButtonLoading.value = false
    reset()
    init(article.value.docId)
  }
}

const prefixWithSiteDomain = (uri: string | undefined) => {
  return articleSite.value?.domain + '/' + (uri ?? '')
}

const processRouteData = async () => {
  anchorRouteInfo.value = undefined
  if (!isUndefined(activeRoute.value) && !isUndefined(reservedRoute.value)) {
    if (props.isEdit) showReserveRegenerate.value = true
    url.value = prefixWithSiteDomain(reservedRoute.value?.uri.full)
    title.value = 'Rezervovaná URL'
    status.value = 'ready'
    url2.value = prefixWithSiteDomain(activeRoute.value?.uri.full)
    title2.value = 'Aktuálna URL'
    anchorRouteInfo.value = {
      url: url.value,
      type: 'reserved',
    }
    return
  }
  if (!isUndefined(activeRoute.value)) {
    if (props.isEdit) showReserveRegenerate.value = true
    url.value = prefixWithSiteDomain(activeRoute.value?.uri.full)
    title.value = 'Aktuálna URL'
    status.value = 'ready'
    anchorRouteInfo.value = {
      url: url.value,
      type: 'active',
    }
    return
  } else if (!isUndefined(reservedRoute.value)) {
    if (props.isEdit) showReserveRegenerate.value = true
    url.value = prefixWithSiteDomain(reservedRoute.value?.uri.full)
    title.value = 'Rezervovaná URL'
    status.value = 'ready'
    anchorRouteInfo.value = {
      url: url.value,
      type: 'reserved',
    }
    return
  }
  await refreshSuggested()
  if (!isUndefined(suggestedRoute.value)) {
    if (props.isEdit) showReserveRegenerate.value = true
    url.value = prefixWithSiteDomain(suggestedRoute.value?.uri.full)
    title.value = 'Pravdepodobná URL'
    showRefresh.value = true
    status.value = 'ready'
    anchorRouteInfo.value = {
      url: url.value,
      type: 'suggested',
    }
    return
  }
  status.value = 'error'
}

const init = async (articleDocId: DocId) => {
  status.value = 'loading'
  try {
    const res = await fetchArticleActiveAndReservedRouteList(articleDocId)
    activeRoute.value = res.data.find((item: RouteKind) => item.status === RouteStatus.Active)
    reservedRoute.value = res.data.find((item: RouteKind) => item.status === RouteStatus.Reserved)
    await processRouteData()
  } catch (e) {
    showErrorsDefault(e)
    status.value = 'error'
  }
}

const reset = () => {
  url.value = ''
  url2.value = ''
  title.value = ''
  title2.value = ''
  activeRoute.value = undefined
  reservedRoute.value = undefined
  suggestedRoute.value = undefined
  showRefresh.value = false
  showReserveRegenerate.value = false
  anchorRouteInfo.value = undefined
}

watch(
  [article, articleSite],
  async ([articleNewValue, articleSiteNewValue]) => {
    if (status.value !== 'init') return
    if (articleNewValue.docId.length === 0 || isUndefined(articleSiteNewValue)) return
    init(articleNewValue.docId)
  },
  { immediate: true }
)

const { t } = useI18n()

const onMenuClick = (value: boolean) => {
  if (value) {
    acquireFieldLock.value()
  }
}

watch(
  menuOrDialogOpened,
  (newValue, oldValue) => {
    if (newValue === oldValue || newValue) return
    releaseFieldLock.value(routeChanged.value)
    if (routeChanged.value) {
      nextTick(() => {
        routeChanged.value = false
      })
    }
  },
  { immediate: false }
)

onUnmounted(() => {
  anchorRouteInfo.value = undefined
})
</script>

<template>
  <div v-if="status === 'init'" />
  <div v-else-if="status === 'loading'">
    <VProgressCircular indeterminate />
  </div>
  <div v-else-if="status === 'error'">
    Route Error
  </div>
  <div v-else>
    <h4 class="text-subtitle-2">
      {{ title }}
    </h4>
    <div class="d-flex align-center">
      <a
        :href="url"
        class="word-break-all text-caption mr-2"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ url }}
      </a>
      <VSpacer />
      <ABtnTertiary
        v-if="isModerator && showRefresh"
        class="mr-1"
        size="small"
        @click="refreshSuggested(true)"
      >
        Pregenerovať
      </ABtnTertiary>
      <VMenu
        v-model="menuOverlay"
        location="bottom end"
        origin="top end"
        @update:model-value="onMenuClick"
      >
        <template #activator="{ props: menuProps }">
          <VBtn
            v-bind="menuProps"
            icon
            :width="26"
            :height="26"
            variant="text"
            :disabled="!!lockedByUserLocal"
          >
            <VIcon
              size="x-small"
              icon="mdi-dots-vertical"
            />
            <VTooltip
              anchor="bottom"
              activator="parent"
              text="More actions"
            />
          </VBtn>
        </template>
        <VList density="compact">
          <ACopyText :value="url">
            <template #activator="{ props: copyProps }">
              <VListItem
                v-bind="copyProps"
                title="Skopírovať URL"
              />
            </template>
          </ACopyText>
          <VListItem
            v-if="isModerator && showReserveRegenerate"
            :title="showRefresh ? 'Rezervovať' : 'Pregenerovať rezervovanú'"
            @click="openReserveRegenerateDialog"
          />
        </VList>
      </VMenu>
      <VIcon
        v-tooltip="
          'Ak prepíšete titulok článku, zmení sa aj url adresa. Url odlišnú od titulku vytvoríte v slugu. ' +
            'Pre kontrolu zmien použite tlačidlo Pregenerovať.'
        "
        class="ml-1"
        icon="mdi-help-circle-outline"
      />
      <slot
        name="locked"
        :user-id="lockedByUserLocal"
      >
        <ACollabLockedByUser
          v-if="collab"
          :id="lockedByUserLocal"
          class="ml-1"
          :users="collab.cachedUsers"
        />
      </slot>
    </div>
    <template v-if="url2.length > 0">
      <h4 class="text-subtitle-2">
        {{ title2 }}
      </h4>
      <a
        :href="url2"
        class="word-break-all text-caption mr-2"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ url2 }}
      </a>
    </template>
  </div>
  <VDialog
    :model-value="reserveRegenerateDialog"
    :width="500"
  >
    <VCard v-if="reserveRegenerateDialog">
      <ADialogToolbar @on-cancel="closeReserveRegenerateDialog">
        {{ showRefresh ? 'Reserve' : 'Regenerate' }}
      </ADialogToolbar>
      <VCardText class="pb-6">
        <div
          v-if="dialogLoading"
          class="w-100 d-flex align-center justify-center"
        >
          <VProgressCircular indeterminate />
        </div>
        <p
          v-if="showRefresh"
          class="mb-3"
        >
          Do you want to reserve route?
        </p>
        <p
          v-else
          class="mb-3"
        >
          Do you want to regenerate route?
        </p>
        <ARow title="New URL">
          <ACopyText :value="dialogNewUrl" />
        </ARow>
      </VCardText>
      <VCardActions>
        <VSpacer />
        <ABtnTertiary @click.stop="closeReserveRegenerateDialog">
          {{ t('common.button.cancel') }}
        </ABtnTertiary>
        <ABtnPrimary
          :loading="confirmButtonLoading"
          :disabled="dialogLoading"
          @click.stop="confirmReserveRegenerateDialog"
        >
          {{ t('common.button.confirm') }}
        </ABtnPrimary>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
