import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsLayoutTemplateRoutes: RouteRecordRaw[] = [
  {
    path: '/layout-template',
    name: ROUTE.CMS.LAYOUT_TEMPLATE.LIST,
    component: () => import('@/views/cms/layoutTemplate/LayoutTemplateListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/layout-template/create',
    name: ROUTE.CMS.LAYOUT_TEMPLATE.CREATE,
    component: () => import('@/views/cms/layoutTemplate/LayoutTemplateCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_LAYOUT_TEMPLATE_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/layout-template/:id(\\d+)/edit',
    name: ROUTE.CMS.LAYOUT_TEMPLATE.EDIT,
    component: () => import('@/views/cms/layoutTemplate/LayoutTemplateEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_LAYOUT_TEMPLATE_READ, ACL.CMS_LAYOUT_TEMPLATE_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/layout-template/:id(\\d+)',
    name: ROUTE.CMS.LAYOUT_TEMPLATE.DETAIL,
    component: () => import('@/views/cms/layoutTemplate/LayoutTemplateDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_LAYOUT_TEMPLATE_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
