import { acceptHMRUpdate, defineStore } from 'pinia'
import { useKeywordFactory } from '@/model/cms/factory/KeywordFactory'
import type { CreateKeywordDto, Keyword, UpdateKeywordDto } from '@/types/cms/Keyword'
import { ref } from 'vue'

export const useKeywordOneStore = defineStore('cmsKeywordOneStore', () => {
  const { createDefault, createKeywordCreate, createKeywordUpdate } = useKeywordFactory()

  const keyword = ref<Keyword>(createDefault())
  const keywordCreate = ref<CreateKeywordDto>(createKeywordCreate())

  // eslint-disable-next-line vue/no-ref-object-reactivity-loss
  const keywordUpdate = ref<UpdateKeywordDto>(createKeywordUpdate(keyword.value))

  function setKeyword(data: Keyword) {
    keyword.value = data
    keywordUpdate.value = createKeywordUpdate(keyword.value)
  }

  function reset() {
    keyword.value = createDefault()
    keywordCreate.value = createKeywordCreate()
    keywordUpdate.value = createKeywordUpdate(keyword.value)
  }

  return {
    keyword,
    keywordCreate,
    keywordUpdate,
    setKeyword,
    reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useKeywordOneStore, import.meta.hot))
}
