import { SYSTEM_CMS } from '@/model/systems'
import { apiAnyRequest, apiFetchOne } from '@anzusystems/common-admin'
import { cmsClient } from '@/services/api/clients/cmsClient'
import type { User } from '@/types/cms/User'

export const CURRENT_USER_END_POINT = '/adm/v1/user/current'

export const AUTH_PATH_PREFIX = '/auth'

export const refreshToken = () =>
  apiAnyRequest<object, any>(cmsClient, 'POST', AUTH_PATH_PREFIX + '/refresh-token', {}, {}, SYSTEM_CMS, '')

export const fetchCurrentUser = () => apiFetchOne<User>(cmsClient, CURRENT_USER_END_POINT, {}, SYSTEM_CMS, 'user')
