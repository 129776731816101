import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsTrendingArticleGroupListRoutes: RouteRecordRaw[] = [
  {
    path: '/trending-article-group-list',
    name: ROUTE.CMS.TRENDING_ARTICLE_GROUP_LIST.LIST,
    component: () => import('@/views/cms/trendingArticleGroupList/TrendingArticleGroupListListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/trending-article-group-list/create',
    name: ROUTE.CMS.TRENDING_ARTICLE_GROUP_LIST.CREATE,
    component: () => import('@/views/cms/trendingArticleGroupList/TrendingArticleGroupListCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_TRENDING_ARTICLE_GROUP_LIST_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/trending-article-group-list/:id(\\d+)/edit',
    name: ROUTE.CMS.TRENDING_ARTICLE_GROUP_LIST.EDIT,
    component: () => import('@/views/cms/trendingArticleGroupList/TrendingArticleGroupListEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_TRENDING_ARTICLE_GROUP_LIST_READ, ACL.CMS_TRENDING_ARTICLE_GROUP_LIST_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/trending-article-group-list/:id(\\d+)',
    name: ROUTE.CMS.TRENDING_ARTICLE_GROUP_LIST.DETAIL,
    component: () => import('@/views/cms/trendingArticleGroupList/TrendingArticleGroupListDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_TRENDING_ARTICLE_GROUP_LIST_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
