import { ref } from 'vue'
import type { PropagationSuggest } from '@/types/cms/PropagationSuggest'
import type { FilterBag, Pagination } from '@anzusystems/common-admin'
import { useAlerts } from '@anzusystems/common-admin'
import { fetchPropagationSuggestList } from '@/services/api/cms/propagationSuggestApi'

const { showErrorsDefault } = useAlerts()

const listLoading = ref(false)

export const usePropagationSuggestListActions = () => {
  const listItems = ref<PropagationSuggest[]>([])
  const fetchList = async (pagination: Pagination, filterBag: FilterBag) => {
    listLoading.value = true
    try {
      listItems.value = await fetchPropagationSuggestList(pagination, filterBag)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      listLoading.value = false
    }
  }

  return {
    listLoading,
    fetchList,
    listItems,
  }
}
