import type { IntegerId } from '@anzusystems/common-admin'
import { defineCached } from '@anzusystems/common-admin'
import type { NewsletterPromo, NewsletterPromoMinimal } from '@/types/cms/NewsletterPromo'
import { fetchNewsletterPromoListByIds } from '@/services/api/cms/newsletterPromoApi'

const mapFullToMinimal = (newsletterPromo: NewsletterPromo): NewsletterPromoMinimal => ({
  id: newsletterPromo.id,
  title: newsletterPromo.texts.title,
})

const mapIdToMinimal = (id: IntegerId): NewsletterPromoMinimal => {
  return { id: id, title: '' }
}

const { cache, toFetch, fetch, add, addManual, addManualMinimal, has, get, isLoaded } = defineCached<
  IntegerId,
  NewsletterPromo,
  NewsletterPromoMinimal
>(mapFullToMinimal, mapIdToMinimal, fetchNewsletterPromoListByIds)

export const useCachedNewsletterPromos = () => {
  return {
    addManualToCachedNewsletterPromos: addManual,
    addManualMinimalToCachedNewsletterPromos: addManualMinimal,
    addToCachedNewsletterPromos: add,
    fetchCachedNewsletterPromos: fetch,
    toFetchCachedNewsletterPromos: toFetch,
    cachedNewsletterPromos: cache,
    hasCachedNewsletterPromo: has,
    getCachedNewsletterPromo: get,
    isLoadedCachedNewsletterPromo: isLoaded,
  }
}
