import { SYSTEM_CMS } from '@/model/systems'
import { dateTimeNow } from '@anzusystems/common-admin'
import type { EmbedCrossBoxAware, EmbedCrossBoxCreateUpdateDto } from '@/components/anzutap/types/EmbedCrossBox'

export function useEmbedCrossBoxFactory() {
  const createDefault = (id = ''): EmbedCrossBoxAware => {
    return {
      id,
      embeddedCrossBox: null,
      customData: {},
      discriminator: 'crossBox',
      _resourceName: 'embedKindCrossBox',
      _system: SYSTEM_CMS,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
    }
  }

  const createCreateUpdateDto = (id = ''): EmbedCrossBoxCreateUpdateDto => {
    return {
      id,
      embeddedCrossBox: null,
      customData: {},
    }
  }

  return {
    createDefault,
    createCreateUpdateDto,
  }
}
