import { reactive, ref } from 'vue'
import { makeFilterHelper } from '@anzusystems/common-admin'

const datatableHiddenColumns = ref([])

const makeFilter = makeFilterHelper('cms', 'minuteTopic')

const filter = reactive({
  id: {
    ...makeFilter({ name: 'id' }),
  },
  title: {
    ...makeFilter({ name: 'title', variant: 'startsWith', field: 'title' }),
  },
  siteGroup: {
    ...makeFilter({ name: 'siteGroup' }),
  },
})

export function useMinuteTopicSelectStore() {
  return {
    filter,
    datatableHiddenColumns,
  }
}
