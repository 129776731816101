import { acceptHMRUpdate, defineStore } from 'pinia'
import type { Inbox } from '@/types/cms/Inbox'
import { useInboxFactory } from '@/model/cms/factory/InboxFactory'
import { ref } from 'vue'

export const useInboxOneStore = defineStore('cmsInboxOneStore', () => {
  const { createDefault } = useInboxFactory()

  const inbox = ref<Inbox>(createDefault())

  function reset() {
    inbox.value = createDefault()
  }

  return {
    inbox,
    reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useInboxOneStore, import.meta.hot))
}
