import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsTargetPositionRoutes: RouteRecordRaw[] = [
  {
    path: '/target-position',
    name: ROUTE.CMS.TARGET_POSITION.LIST,
    component: () => import('@/views/cms/targetPosition/TargetPositionListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/target-position/create',
    name: ROUTE.CMS.TARGET_POSITION.CREATE,
    component: () => import('@/views/cms/targetPosition/TargetPositionCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_TARGET_POSITION_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/target-position/:id(\\d+)/edit',
    name: ROUTE.CMS.TARGET_POSITION.EDIT,
    component: () => import('@/views/cms/targetPosition/TargetPositionEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_TARGET_POSITION_READ, ACL.CMS_TARGET_POSITION_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/target-position/:id(\\d+)',
    name: ROUTE.CMS.TARGET_POSITION.DETAIL,
    component: () => import('@/views/cms/targetPosition/TargetPositionDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_TARGET_POSITION_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
