import type { Site, SiteMinimal } from '@/types/cms/Site'
import { fetchSite, fetchSiteListByIds } from '@/services/api/cms/siteApi'
import type { IntegerId } from '@anzusystems/common-admin'
import { defineCached, isDefined, useAlerts } from '@anzusystems/common-admin'

const mapFullToMinimal = (site: Site): SiteMinimal => ({
  id: site.id,
  name: site.name,
  domain: site.domain,
  siteGroup: site.siteGroup,
})

const mapIdToMinimal = (id: IntegerId): SiteMinimal => {
  return { id: id, name: '', domain: '', siteGroup: null }
}

const { cache, toFetch, fetch, add, addManual, addManualMinimal, has, get, isLoaded } = defineCached<
  IntegerId,
  Site,
  SiteMinimal
>(mapFullToMinimal, mapIdToMinimal, fetchSiteListByIds)

export const useCachedSites = () => {
  return {
    addManualToCachedSites: addManual,
    addManualMinimalToCachedSites: addManualMinimal,
    addToCachedSites: add,
    fetchCachedSites: fetch,
    toFetchCachedSites: toFetch,
    cachedSites: cache,
    hasCachedSite: has,
    getCachedSite: get,
    isLoadedCachedSite: isLoaded,
  }
}

export const getSiteFromCachedOrLoad = async (id: IntegerId) => {
  const { showErrorsDefault } = useAlerts()
  const { getCachedSite, addManualMinimalToCachedSites } = useCachedSites()
  const cached = getCachedSite(id)
  if (isDefined(cached) && cached._loaded) return Promise.resolve(cached as SiteMinimal)
  try {
    const res = await fetchSite(id)
    const min = mapFullToMinimal(res)
    addManualMinimalToCachedSites(min)

    return Promise.resolve(min)
  } catch (e) {
    showErrorsDefault(e)
    return Promise.resolve(undefined)
  }
}
