<script setup lang="ts">
import type { CollabRoom } from '@/types/Collab'
import {
  ARow,
  ASortable,
  cloneDeep,
  type CollabComponentConfig,
  type CollabFieldData,
  type CollabFieldDataEnvelope,
  type IntegerId,
  type IntegerIdNullable,
  isArray,
  isDefined,
  isNull,
  type SortableItem,
  type SortablePropItem,
  useCollabField,
  useCollabHelpers,
  useCommonAdminCollabOptions,
} from '@anzusystems/common-admin'
import { computed, ref, watch } from 'vue'
import type { ArticleHasAuthor } from '@/types/cms/ArticleHasAuthor'
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import { storeToRefs } from 'pinia'
import { useCachedAuthors } from '@/views/cms/author/composables/cachedAuthors'
import CachedAuthorChip from '@/views/cms/author/components/CachedAuthorChip.vue'
import AuthorRemoteAutocomplete from '@/views/cms/author/components/AuthorRemoteAutocomplete.vue'
import { useI18n } from 'vue-i18n'
import { useCachedUsers } from '@/views/cms/user/composables/cachedUsers'

const props = withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const { cachedUsers } = useCachedUsers()
const { createCollabFieldConfig } = useCollabHelpers()
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const collabConfig: CollabComponentConfig = createCollabFieldConfig(
  'articleAuthors',
  props.collabRoom,
  cachedUsers.value
)

const { t } = useI18n()
const articleKindOneStore = useArticleKindOneStore()
const { article } = storeToRefs(articleKindOneStore)
const { addToCachedAuthors, fetchCachedAuthors } = useCachedAuthors()

// Collaboration
const { collabOptions } = useCommonAdminCollabOptions()
const lockedByUserLocal = ref<IntegerIdNullable>(null)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const changeFieldData = ref((data: CollabFieldData) => {})
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
if (collabOptions.value.enabled && isDefined(collabConfig)) {
  const {
    addCollabFieldDataChangeListener,
    changeCollabFieldData,
    lockedByUser,
    // eslint-disable-next-line vue/no-setup-props-reactivity-loss
  } = useCollabField(collabConfig.room, collabConfig.field)
  changeFieldData.value = changeCollabFieldData
  watch(
    lockedByUser,
    (newValue) => {
      lockedByUserLocal.value = newValue
    },
    { immediate: true }
  )
  addCollabFieldDataChangeListener((data: CollabFieldDataEnvelope) => {
    const value = data.value as ArticleHasAuthor[]
    if (isArray(value) && value.length) {
      article.value.articleAuthors = value
    }
  })
}

const articleAuthors = computed(() => {
  return article.value.articleAuthors
})

const isEditable = computed(() => {
  return !props.readonly && props.isModerator
})

const sortableComponent = ref<InstanceType<typeof ASortable> | null>(null)
const newAuthor = ref<IntegerId | null>(null)
const articleAuthorsData = ref<ArticleHasAuthor[]>([])

const addAuthor = (newValue: IntegerIdNullable | IntegerId[]) => {
  if (isNull(newValue) || isArray(newValue)) return
  sortableComponent.value?.addAfterIndex(null, {
    id: 0,
    article: 353,
    author: newValue,
    position: 1,
  })
  addToCachedAuthors(newValue)
  fetchCachedAuthors()
  newAuthor.value = null
}

const onDeleteItem = (item: SortableItem) => {
  sortableComponent.value?.removeByIndex(item.index)
}

const onSortableChange = (items: SortablePropItem<ArticleHasAuthor>[]) => {
  if (collabOptions.value.enabled && props.isModerator) {
    changeFieldData.value(items)
  }
  article.value.articleAuthors = cloneDeep(articleAuthorsData.value)
}

watch(
  articleAuthors,
  (newValue: ArticleHasAuthor[]) => {
    if (newValue.length === 0) {
      articleAuthorsData.value = []
      return
    }
    articleAuthorsData.value = newValue.sort((a, b) => a.position - b.position)
    newValue.forEach((item) => {
      addToCachedAuthors(item.author)
    })
    fetchCachedAuthors()
  },
  { immediate: true }
)
</script>

<template>
  <div>
    <ARow v-if="isEditable">
      <AuthorRemoteAutocomplete
        v-model="newAuthor"
        :label="t('cms.authorKind.action.addAuthor')"
        @update:model-value="addAuthor"
      />
    </ARow>
    <div>
      <ASortable
        ref="sortableComponent"
        v-model="articleAuthorsData"
        :show-delete-button="isEditable"
        :disable-draggable="!isEditable"
        chips
        disable-delete-dialog
        @on-delete="onDeleteItem"
        @update:model-value="onSortableChange"
      >
        <template #item="{ item }: { item: SortableItem<ArticleHasAuthor> }">
          <CachedAuthorChip
            :id="item.raw.author"
            text-only
          />
          <small>&nbsp;({{ item.raw.author }})</small>
        </template>
      </ASortable>
    </div>
  </div>
</template>
