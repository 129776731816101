import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { ContentItemKindWeather } from '@/types/cms/ContentItemKind/ContentItemKindWeather'
import { WeatherVariantDefault } from '@/types/cms/WeatherVariant'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindWeatherFactory() {
  const create = (): ContentItemKindWeather => {
    return {
      ...createAbstractContentItemKind(),
      locationId: null,
      variant: WeatherVariantDefault,
      discriminator: ContentItemDiscriminator.Weather,
      _resourceName: 'contentItemKindWeather',
    }
  }

  return {
    create,
  }
}
