import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsAdvertSettingsRoutes: RouteRecordRaw[] = [
  {
    path: '/advert-settings',
    name: ROUTE.CMS.ADVERT_SETTINGS.LIST,
    component: () => import('@/views/cms/advertSettings/AdvertSettingsListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/advert-settings/create',
    name: ROUTE.CMS.ADVERT_SETTINGS.CREATE,
    component: () => import('@/views/cms/advertSettings/AdvertSettingsCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ADVERT_SETTINGS_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/advert-settings/:id(\\d+)/edit',
    name: ROUTE.CMS.ADVERT_SETTINGS.EDIT,
    component: () => import('@/views/cms/advertSettings/AdvertSettingsEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ADVERT_SETTINGS_READ, ACL.CMS_ADVERT_SETTINGS_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/advert-settings/:id(\\d+)',
    name: ROUTE.CMS.ADVERT_SETTINGS.DETAIL,
    component: () => import('@/views/cms/advertSettings/AdvertSettingsDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ADVERT_SETTINGS_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
