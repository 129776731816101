import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ValueObjectOption } from '@anzusystems/common-admin'

export const DashboardType = {
  Personal: 'personal',
  Desk: 'desk',
  Stage: 'stage',
} as const

export type DashboardTypeType = (typeof DashboardType)[keyof typeof DashboardType]

export function useDashboardTypes() {
  const { t } = useI18n()

  const dashboardTypeOptions = ref<ValueObjectOption<DashboardTypeType>[]>([
    {
      value: DashboardType.Personal,
      title: t('cms.dashboard.types.personal'),
      color: 'success',
    },
    {
      value: DashboardType.Desk,
      title: t('cms.dashboard.types.desk'),
      color: 'success',
    },
    {
      value: DashboardType.Stage,
      title: t('cms.dashboard.types.stage'),
      color: 'success',
    },
  ])

  const getDashboardTypeOption = (value: DashboardTypeType) => {
    return dashboardTypeOptions.value.find((item) => item.value === value)
  }

  return {
    dashboardTypeOptions,
    getDashboardTypeOption,
  }
}
