import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsAutoDistributionRoutes: RouteRecordRaw[] = [
  {
    path: '/auto-distribution',
    name: ROUTE.CMS.AUTO_DISTRIBUTION.LIST,
    component: () => import('@/views/cms/autoDistribution/AutoDistributionListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_AUTO_DISTRIBUTION_READ],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/auto-distribution/create',
    name: ROUTE.CMS.AUTO_DISTRIBUTION.CREATE,
    component: () => import('@/views/cms/autoDistribution/AutoDistributionCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_AUTO_DISTRIBUTION_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/auto-distribution/:id(\\d+)/edit',
    name: ROUTE.CMS.AUTO_DISTRIBUTION.EDIT,
    component: () => import('@/views/cms/autoDistribution/AutoDistributionEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_AUTO_DISTRIBUTION_READ, ACL.CMS_AUTO_DISTRIBUTION_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/auto-distribution/:id(\\d+)',
    name: ROUTE.CMS.AUTO_DISTRIBUTION.DETAIL,
    component: () => import('@/views/cms/autoDistribution/AutoDistributionDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_AUTO_DISTRIBUTION_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
