import { reactive } from 'vue'
import { makeFilterHelper } from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'
import { ENTITY } from '@/services/api/cms/newsletterApi'

const makeFilter = makeFilterHelper(SYSTEM_CMS, ENTITY)

const filter = reactive({
  id: {
    ...makeFilter({ name: 'id', variant: 'in' }),
  },
  title: {
    ...makeFilter({ name: 'title', variant: 'contains', field: 'texts.title' }),
  },
})

export function useNewsletterListFilter() {
  return filter
}
