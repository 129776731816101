import type { ContentItemKind } from '@/types/cms/ContentItemKind/ContentItemKind'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { IntegerId, IntegerIdNullable } from '@anzusystems/common-admin'

export const ArticleListLayout = {
  LongRegularOpener: 'longRegularOpener',
  LongBigOpener: 'longBigOpener',
  Long: 'long',
  LongWithRegularImages: 'longWithRegularImages',
  LongWithBigImages: 'longWithBigImages',
  WideFourBigImages: 'wideFourBigImages',
} as const
export const ArticleListLayoutDefault = ArticleListLayout.Long
export type ArticleListLayoutType = (typeof ArticleListLayout)[keyof typeof ArticleListLayout]

export interface ContentItemKindArticleList extends ContentItemKind {
  title: string
  url: string
  multiArticleList: IntegerIdNullable
  listSiteGroup: IntegerIdNullable
  listSite: IntegerIdNullable
  author: IntegerIdNullable
  rubrics: IntegerId[]
  requiredKeywords: IntegerId[]
  optionalKeywords: IntegerId[]
  image: IntegerIdNullable
  listLayout: ArticleListLayoutType
  teleportedIdentifiers: Record<number, string>
  allowListing: boolean
  itemsLimit: number
  _resourceName: 'contentItemKindArticleList'
}

export const isContentItemKindArticleList = (item: ContentItemKind | null): item is ContentItemKindArticleList => {
  return !!item && item.discriminator === ContentItemDiscriminator.ArticleList
}
