import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const cmsFaqRoutes: RouteRecordRaw[] = [
  {
    path: '/faq',
    name: ROUTE.CMS.FAQ.LIST,
    component: () => import('@/views/cms/faq/FaqListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/faq/create',
    name: ROUTE.CMS.FAQ.CREATE,
    component: () => import('@/views/cms/faq/FaqCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_FAQ_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/faq/:id(\\d+)/edit',
    name: ROUTE.CMS.FAQ.EDIT,
    component: () => import('@/views/cms/faq/FaqEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_FAQ_READ, ACL.CMS_FAQ_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/faq/:id(\\d+)',
    name: ROUTE.CMS.FAQ.DETAIL,
    component: () => import('@/views/cms/faq/FaqDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_FAQ_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
