<script lang="ts" setup>
import { useActiveNewsletterPromoFilter } from '@/model/cms/filter/NewsletterPromoFilter'
import { onMounted, ref } from 'vue'
import { fetchNewsletterPromoList } from '@/services/api/cms/newsletterPromoApi'
import { ARow, COMMON_CONFIG, type IntegerId, usePagination } from '@anzusystems/common-admin'
import { useRouter } from 'vue-router'
import { ROUTE } from '@/router/routes'
import type { NewsletterPromo } from '@/types/cms/NewsletterPromo'
import type { NewsletterPromoTypeType } from '@/model/cms/valueObject/NewsletterPromoType'

const props = withDefaults(
  defineProps<{
    idList: IntegerId[]
    type: NewsletterPromoTypeType
    title: string
  }>(),
  {}
)

const newsletterPromoList = ref<NewsletterPromo[]>([])
const router = useRouter()
const filter = useActiveNewsletterPromoFilter()
const pagination = usePagination(null)

const onClick = (id: IntegerId) => {
  router.push({ name: ROUTE.CMS.NEWSLETTER_PROMO.DETAIL, params: { id } })
}

onMounted(async () => {
  filter.id.model = props.idList
  filter.type.model = props.type
  newsletterPromoList.value = await fetchNewsletterPromoList(pagination, filter)
})
</script>

<template>
  <ARow
    class="pl-2"
    :title="title"
  >
    <VChip
      v-for="newsletterPromo in newsletterPromoList"
      :key="newsletterPromo.id"
      :title="newsletterPromo.texts.title"
      :append-icon="COMMON_CONFIG.CHIP.ICON.LINK"
      @click.stop="onClick(newsletterPromo.id)"
    >
      {{ newsletterPromo.texts.title }}
    </VChip>
  </ARow>
</template>
