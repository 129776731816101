import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ValueObjectOption } from '@anzusystems/common-admin'

export const NewsletterPromoColor = {
  Red: 'red',
  Blue: 'blue',
  Green: 'green',
} as const
export const NewsletterPromoColorDefault = NewsletterPromoColor.Red
export type NewsletterPromoColorType = (typeof NewsletterPromoColor)[keyof typeof NewsletterPromoColor]

export function useNewsletterPromoColor() {
  const { t } = useI18n()

  const newsletterPromoColorOptions = ref<ValueObjectOption<NewsletterPromoColorType>[]>([
    {
      value: NewsletterPromoColor.Red,
      title: t('cms.newsletterPromo.promoColor.red'),
    },
    {
      value: NewsletterPromoColor.Blue,
      title: t('cms.newsletterPromo.promoColor.blue'),
    },
    {
      value: NewsletterPromoColor.Green,
      title: t('cms.newsletterPromo.promoColor.green'),
    },
  ])

  const getNewsletterPromoColorOptions = (value: NewsletterPromoColorType) => {
    return newsletterPromoColorOptions.value.find((item) => item.value === value)
  }

  return {
    newsletterPromoColorOptions,
    getNewsletterPromoColorOptions,
  }
}
