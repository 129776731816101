<script setup lang="ts">
import type { NewsletterGeneratorSettings } from '@/types/cms/Newsletter'

const props = withDefaults(
  defineProps<{
    item: NewsletterGeneratorSettings
  }>(),
  {}
)
const emit = defineEmits<{
  (e: 'onEditClick', data: NewsletterGeneratorSettings): void
  (e: 'onDeleteClick', data: number): void
}>()

const onEdit = () => {
  emit('onEditClick', props.item)
}

const onDelete = () => {
  emit('onDeleteClick', props.item.templateId)
}
</script>

<template>
  <tr>
    <td>{{ item.templateId }}</td>
    <td>{{ item.dataSourceUrl }}</td>
    <td>
      <VBtn
        icon
        size="x-small"
        variant="text"
        class="mx-1"
        @click.stop="onEdit()"
      >
        <VIcon
          size="small"
          icon="mdi-pencil"
        />
      </VBtn>
      <VBtn
        icon
        size="x-small"
        variant="text"
        class="mx-1"
        @click.stop="onDelete()"
      >
        <VIcon
          size="small"
          icon="mdi-trash-can-outline"
        />
      </VBtn>
    </td>
  </tr>
</template>
