<script lang="ts" setup>
import {
  AActionCloseButton,
  AActionDeleteButton,
  AActionEditButton,
  ACard,
  stringToInt,
} from '@anzusystems/common-admin'
import { ROUTE } from '@/router/routes'
import { onBeforeUnmount, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import NewsletterPromoDetail from '@/views/cms/newsletterPromo/components/NewsletterPromoDetail.vue'
import ActionbarWrapper from '@/components/wrappers/ActionbarWrapper.vue'
import {
  useNewsletterPromoDeleteActions,
  useNewsletterPromoDetailActions,
} from '@/views/cms/newsletterPromo/composables/newsletterPromoActions'

const route = useRoute()
const id = stringToInt(route.params.id)

const { onDelete } = useNewsletterPromoDeleteActions()

const { detailLoading, fetchData, resetStore, newsletterPromo } = useNewsletterPromoDetailActions()

const getDetail = () => {
  fetchData(id)
}

onMounted(() => {
  getDetail()
})

onBeforeUnmount(() => {
  resetStore()
})
</script>

<template>
  <ActionbarWrapper :last-breadcrumb-title="newsletterPromo.texts.title">
    <template #buttons>
      <AActionEditButton
        v-if="!detailLoading"
        :record-id="id"
        :route-name="ROUTE.CMS.NEWSLETTER_PROMO.EDIT"
      />
      <AActionDeleteButton
        v-if="!detailLoading"
        @delete-record="onDelete(id)"
      />
      <AActionCloseButton :route-name="ROUTE.CMS.NEWSLETTER_PROMO.LIST" />
    </template>
  </ActionbarWrapper>

  <ACard :loading="detailLoading">
    <VCardText>
      <NewsletterPromoDetail />
    </VCardText>
  </ACard>
</template>
