<script lang="ts" setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { ROUTE } from '@/router/routes'

const router = useRouter()

onMounted(() => {
  router.push({ name: ROUTE.CMS.ARTICLE.LIST })
})
</script>

<template>
  <div />
</template>
