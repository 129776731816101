import { reactive } from 'vue'
import { makeFilterHelper } from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'
import { ENTITY } from '@/services/api/cms/articleIntentionApi'

const makeFilter = makeFilterHelper(SYSTEM_CMS, ENTITY)

const filter = reactive({
  id: {
    ...makeFilter({ name: 'id' }),
  },
  name: {
    ...makeFilter({ name: 'name', variant: 'startsWith' }),
  },
})

export function useArticleIntentionListFilter() {
  return filter
}

export function useArticleIntentionFilter() {
  return reactive({
    id: {
      ...makeFilter({ name: 'id' }),
    },
  })
}

