<script lang="ts" setup>
import { useAuthorSelectActions } from '@/views/cms/author/composables/authorActions'
import { AFilterRemoteAutocomplete, type Filter } from '@anzusystems/common-admin'
import { useAuthorFilter } from '@/model/cms/filter/AuthorFilter'

const modelValue = defineModel<Filter>({ required: true })

const { fetchItems, fetchItemsByIds } = useAuthorSelectActions()

const innerFilter = useAuthorFilter()
</script>

<template>
  <AFilterRemoteAutocomplete
    v-model="modelValue"
    :fetch-items="fetchItems"
    :fetch-items-by-ids="fetchItemsByIds"
    :inner-filter="innerFilter"
    :filter-sort-by="null"
    filter-by-field="text"
  />
</template>
