import {
  type DocId,
  type IntegerId,
  type IntegerIdNullable,
  isNull,
  type ValueObjectOption,
} from '@anzusystems/common-admin'
import type { Embed, EmbedAware } from '@/components/anzutap/types/Embed'
import type { RubricStatusType } from '@/model/cms/valueObject/RubricStatus'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'

export const isEmbedGallery = (embed: EmbedAware | Embed | null): embed is EmbedGalleryAware => {
  if (isNull(embed)) return false
  return embed.discriminator === 'gallery'
}

export interface EmbedGalleryAware extends EmbedAware {
  discriminator: 'gallery'
  gallery: IntegerIdNullable
  layout: EmbedGalleryLayoutType
  texts: {
    title: string
    description: string
  }
  detail?: {
    gallery: EmbedGalleryDetailGallery
  }
}

export interface EmbedGalleryCreateUpdateDto
  extends Omit<
    EmbedGalleryAware,
    'detail' | 'discriminator' | '_resourceName' | '_system' | 'createdAt' | 'modifiedAt' | 'createdBy' | 'modifiedBy'
  > {}

export interface EmbedGalleryDetailGallery {
  id: IntegerId
  enabled: boolean
  texts: {
    title: string
    description: string
  }
  url: string
  rubric: {
    attributes: {
      status: RubricStatusType
    }
    texts: {
      title: string
      shortTitle: string
      seoTitle: string
      description: string
      seoDescription: string
    }
    site: {
      name: string
      domain: string
    }
  }
  imagesCount: number
  images: Array<{
    position: number
    texts: {
      description: string
      source: string
    }
    dam: {
      damId: DocId
      regionPosition: number
      animation: boolean
      licenceId: number
    }
  }>
}

export const EmbedGalleryLayout = {
  Thumb: 'thumb',
  Whole: 'whole',
} as const
export const EmbedGalleryLayoutDefault = EmbedGalleryLayout.Thumb
export type EmbedGalleryLayoutType = (typeof EmbedGalleryLayout)[keyof typeof EmbedGalleryLayout]

export function useEmbedGalleryLayout() {
  const { t } = useI18n()

  const embedGalleryLayoutOptions = ref<ValueObjectOption<EmbedGalleryLayoutType>[]>([
    {
      value: EmbedGalleryLayout.Thumb,
      title: t('editor.node.embedGallery.layout.thumb'),
    },
    {
      value: EmbedGalleryLayout.Whole,
      title: t('editor.node.embedGallery.layout.whole'),
    },
  ])

  const getEmbedGalleryLayoutOption = (value: EmbedGalleryLayoutType) => {
    return embedGalleryLayoutOptions.value.find((item) => item.value === value)
  }

  return {
    embedGalleryLayoutOptions,
    getEmbedGalleryLayoutOption,
  }
}
