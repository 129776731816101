import { ROUTE } from '@/router/routes'
import type { RouteParams, RouteRecordRaw } from 'vue-router'
import { stringToInt, useCollabHelpers } from '@anzusystems/common-admin'
import { CollabRoomJoinStrategy } from '@/types/Collab'
import { ACL } from '@/composables/auth/auth'

const { createCollabRoom } = useCollabHelpers()

export const cmsArticleMinuteRoutes: RouteRecordRaw[] = [
  {
    path: '/article-minute',
    name: ROUTE.CMS.ARTICLE_MINUTE.LIST,
    component: () => import('@/views/cms/articleMinute/ArticleMinuteListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/article-minute/:id(\\d+)/edit',
    name: ROUTE.CMS.ARTICLE_MINUTE.EDIT,
    component: () => import('@/views/cms/articleMinute/ArticleMinuteEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ARTICLE_READ, ACL.CMS_ARTICLE_UPDATE],
      layout: 'AppLayoutDrawer',
      collab: (params: RouteParams) => ({
        room: createCollabRoom(stringToInt(params.id), 'article', 'cms'),
        joinStrategy: CollabRoomJoinStrategy.Moderated,
        occupiedOrKickedRedirectToRoute: ROUTE.CMS.ARTICLE_MINUTE.DETAIL,
        editors: ['bodyEditor'],
      }),
    },
  },
  {
    path: '/article-minute/:id(\\d+)',
    name: ROUTE.CMS.ARTICLE_MINUTE.DETAIL,
    component: () => import('@/views/cms/articleMinute/ArticleMinuteDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ARTICLE_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
