<script lang="ts" setup>
import ATableActions from '@/components/ATableActions.vue'
import { ACL, useAuth } from '@/composables/auth/auth'
import { FILTER_KEY } from '@/composables/system/filterStoreKey'
import { useNewsletterListFilter } from '@/model/cms/filter/NewsletterFilter'
import { SYSTEM_CMS } from '@/model/systems'
import { ROUTE } from '@/router/routes'
import { ENTITY } from '@/services/api/cms/newsletterApi'
import type { Newsletter } from '@/types/cms/Newsletter'
import NewsletterFilter from '@/views/cms/newsletter/components/NewsletterFilter.vue'
import { useNewsletterListActions } from '@/views/cms/newsletter/composables/newsletterActions'
import {
  ABooleanValue,
  ADatatableConfigButton,
  ADatatableOrdering,
  ADatatablePagination,
  ADatetime,
  createDatatableColumnsConfig,
  type DatatableOrderingOption,
  useFilterHelpers,
} from '@anzusystems/common-admin'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

type DatatableItem = Newsletter

const router = useRouter()
const { can, canForAll } = useAuth()

const filter = useNewsletterListFilter()
const { resetFilter, submitFilter, loadStoredFilter } = useFilterHelpers(FILTER_KEY.newsletter)
const { fetchList, listItems, datatableHiddenColumns } = useNewsletterListActions()

const onRowClick = (event: unknown, { item }: { item: DatatableItem }) => {
  if (!item.id) return
  if (canForAll([ACL.CMS_NEWSLETTER_READ, ACL.CMS_NEWSLETTER_CREATE])) {
    router.push({ name: ROUTE.CMS.NEWSLETTER.EDIT, params: { id: item.id } })
  }
  if (item.id && can(ACL.CMS_NEWSLETTER_READ)) {
    router.push({ name: ROUTE.CMS.NEWSLETTER.DETAIL, params: { id: item.id } })
  }
}

const { columnsVisible, columnsAll, columnsHidden, updateSortBy, pagination } = createDatatableColumnsConfig(
  [
    { key: 'id' },
    { key: 'texts.title' },
    { key: 'mailerId' },
    { key: 'enabled' },
    { key: 'type' },
  ],
  datatableHiddenColumns,
  SYSTEM_CMS,
  ENTITY
)

const getList = () => {
  fetchList(pagination, filter)
}

const sortByChange = (option: DatatableOrderingOption) => {
  updateSortBy(option.sortBy)
  getList()
}

onMounted(() => {
  getList()
  loadStoredFilter(filter)
})

defineExpose({
  refresh: getList,
})
</script>

<template>
  <div>
    <NewsletterFilter
      @submit-filter="submitFilter(filter, pagination, getList)"
      @reset-filter="resetFilter(filter, pagination, getList)"
    />
    <div>
      <div class="d-flex align-center">
        <VSpacer />
        <ADatatableOrdering @sort-by-change="sortByChange" />
        <ADatatableConfigButton
          v-model:columns-hidden="columnsHidden"
          :columns-all="columnsAll"
        />
      </div>
      <VDataTableServer
        class="a-datatable"
        :headers="columnsVisible"
        :items="listItems"
        :items-length="listItems.length"
        item-value="id"
        @click:row="onRowClick"
      >
        <template #item.createdAt="{ item }: { item: DatatableItem }">
          <ADatetime :date-time="item.createdAt" />
        </template>
        <template #item.modifiedAt="{ item }: { item: DatatableItem }">
          <ADatetime :date-time="item.modifiedAt" />
        </template>
        <template #item.enabled="{ item }: { item: DatatableItem }">
          <ABooleanValue
            chip
            :value="item.enabled"
          />
        </template>
        <template #item.actions="{ item }: { item: DatatableItem }">
          <ATableActions
            :id="item.id"
            :detail-route-name="ROUTE.CMS.NEWSLETTER.DETAIL"
            :detail-route-acl="ACL.CMS_NEWSLETTER_READ"
            :edit-route-name="ROUTE.CMS.NEWSLETTER.EDIT"
            :edit-route-acl="[ACL.CMS_NEWSLETTER_READ, ACL.CMS_NEWSLETTER_CREATE]"
          />
        </template>
        <template #bottom>
          <ADatatablePagination
            v-model="pagination"
            @change="getList"
          />
        </template>
      </VDataTableServer>
    </div>
  </div>
</template>
