<script lang="ts" setup>
import {
  AFormRemoteAutocomplete,
  type IntegerId,
  type IntegerIdNullable,
  isDefined,
  isNumber,
} from '@anzusystems/common-admin'
import { computed } from 'vue'
import { useLayoutTemplateSelectActions } from '@/views/cms/layoutTemplate/composables/layoutTemplateActions'
import { useLayoutTemplateFilter } from '@/model/cms/filter/LayoutTemplateFilter'
import { type WebEntityTypeType } from '@/types/cms/TargetPosition'

const props = withDefaults(
  defineProps<{
    modelValue: IntegerIdNullable | IntegerId[]
    label?: string | undefined
    required?: boolean | undefined
    multiple?: boolean
    webEntityType?: WebEntityTypeType | undefined
  }>(),
  {
    label: undefined,
    required: undefined,
    multiple: false,
    webEntityType: undefined,
  }
)
const emit = defineEmits<{
  (e: 'update:modelValue', data: IntegerIdNullable | IntegerId[]): void
}>()
const value = computed({
  get(): IntegerIdNullable | IntegerId[] {
    return isNumber(props.modelValue) && props.modelValue === 0 ? null : props.modelValue
  },
  set(newValue: IntegerIdNullable | IntegerId[]) {
    emit('update:modelValue', newValue)
  },
})

const { fetchItems, fetchItemsByIds } = useLayoutTemplateSelectActions()

const innerFilter = useLayoutTemplateFilter()
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
if (isDefined(props.webEntityType)) innerFilter.webEntityType.model = props.webEntityType
</script>

<template>
  <AFormRemoteAutocomplete
    v-model="value"
    :required="required"
    :label="label"
    :fetch-items="fetchItems"
    :fetch-items-by-ids="fetchItemsByIds"
    :inner-filter="innerFilter"
    :multiple="multiple"
    filter-by-field="title"
    clearable
  />
</template>
