import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { ContentItemKindCountdown } from '@/types/cms/ContentItemKind/ContentItemKindCountdown'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindCountdownFactory() {
  const create = (): ContentItemKindCountdown => {
    return {
      ...createAbstractContentItemKind(),
      countdown: null,
      discriminator: ContentItemDiscriminator.Countdown,
      _resourceName: 'contentItemKindCountdown',
    }
  }

  return {
    create,
  }
}
