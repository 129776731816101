import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import { useLayoutTemplateFactory } from '@/model/cms/factory/LayoutTemplateFactory'

export const useLayoutTemplateOneStore = defineStore('cmsLayoutTemplateOneStore', () => {
  const { createLayoutTemplate } = useLayoutTemplateFactory()

  const layoutTemplate = ref(createLayoutTemplate())

  function reset() {
    layoutTemplate.value = createLayoutTemplate()
  }

  return {
    layoutTemplate,
    reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLayoutTemplateOneStore, import.meta.hot))
}
