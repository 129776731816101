import { useArticleKindFactory } from '@/model/cms/factory/ArticleKind/ArticleKindFactory'
import type { ArticleRelatedEntities } from '@/types/cms/ArticleKind/ArticleKindStandard'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'

export const useArticleRelatedEntitiesOneStore = defineStore('cmsArticleRelatedEntitiesOneStore', () => {
  const { createArticleRelatedEntities } = useArticleKindFactory()

  const relatedEntitiesLoading = ref(false)
  const articleRelatedEntities = ref<ArticleRelatedEntities>(createArticleRelatedEntities())

  function reset() {
    articleRelatedEntities.value = createArticleRelatedEntities()
  }

  return {
    relatedEntitiesLoading,
    articleRelatedEntities,
    reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useArticleRelatedEntitiesOneStore, import.meta.hot))
}
