import ListView from '@/views/cms/newsletterPromo/NewsletterPromoListView.vue'
import EditView from '@/views/cms/newsletterPromo/NewsletterPromoEditView.vue'
import CreateView from '@/views/cms/newsletterPromo/NewsletterPromoCreateView.vue'
import DetailView from '@/views/cms/newsletterPromo/NewsletterPromoDetailView.vue'
import { ROUTE } from '@/router/routes'
import { ACL } from '@/composables/auth/auth'
import type { RouteRecordRaw } from 'vue-router'
import { AEmptyRouterView } from '@anzusystems/common-admin'

export const cmsNewsletterPromoRoutes: RouteRecordRaw[] = [
  {
    path: '/newsletter-promo',
    component: AEmptyRouterView,
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
      breadcrumbT: 'breadcrumb.cms.newsletterPromo.list',
    },
    children: [
      {
        path: '',
        name: ROUTE.CMS.NEWSLETTER_PROMO.LIST,
        component: ListView,
        meta: {
          requiresAuth: true,
          requiredPermissions: [],
          layout: 'AppLayoutDrawer',
        },
      },
      {
        path: 'create',
        name: ROUTE.CMS.NEWSLETTER_PROMO.CREATE,
        component: CreateView,
        meta: {
          requiresAuth: true,
          requiredPermissions: [ACL.CMS_NEWSLETTER_PROMO_CREATE],
          layout: 'AppLayoutDrawer',
          breadcrumbT: 'breadcrumb.cms.newsletterPromo.create',
        },
      },
      {
        path: ':id(\\d+)/edit',
        name: ROUTE.CMS.NEWSLETTER_PROMO.EDIT,
        component: EditView,
        meta: {
          requiresAuth: true,
          requiredPermissions: [ACL.CMS_NEWSLETTER_PROMO_READ, ACL.CMS_NEWSLETTER_PROMO_UPDATE],
          layout: 'AppLayoutDrawer',
          breadcrumbT: 'breadcrumb.cms.newsletterPromo.edit',
        },
      },
      {
        path: ':id(\\d+)',
        name: ROUTE.CMS.NEWSLETTER_PROMO.DETAIL,
        component: DetailView,
        meta: {
          requiresAuth: true,
          requiredPermissions: [ACL.CMS_NEWSLETTER_PROMO_READ],
          layout: 'AppLayoutDrawer',
          breadcrumbT: 'breadcrumb.cms.newsletterPromo.detail',
        },
      },
    ],
  },
]
